import React from 'react';
import { useDispatch } from 'react-redux';
import { submit } from 'redux-form';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import classnames from 'classnames';

import { InputRow } from '../inputs';
import Button from '../inputs/Button';

import './index.css';

export const Modal = ({
  title,
  titleClassName,
  isOpen,
  children,
  onRequestClose,
  submitActions,
  disableSubmit,
  submitButtonColor,
  submitButtonText,
  hideCancelButton,
  cancelButtonText,
  cancelButtonColor,
  hideButtons,
  cancelActions,
  formName,
  testID,
  wide,
  medium,
  className,
  disableCancel,
  tall,
  taller,
  hideSubmitButton,
  onMouseEnter,
  submitTooltip,
  modalMediumTall
}) => {
  const dispatch = useDispatch();
  const handleSubmit =
    submitActions ?? (formName ? () => dispatch(submit(formName)) : () => {});

  return (
    <div>
      <div className={classnames('modal', className, !isOpen && 'modalHide')}>
        <section
          className={classnames(
            'modalBody',
            wide && 'modalWide',
            medium && 'modalMedium'
          )}
        >
          <h2 className={`modalBody-header ${titleClassName}`}>
            <span className="modalBody-header--text">{title}</span>
            <div
              onClick={onRequestClose}
              className="modalBody-header--X"
              data-cy={`${testID}CloseModalButton`}
            >
              <img
                src={require('../../assets/images/modalX.png')}
                alt="close the modal"
              />
            </div>
          </h2>
          <section
            className={classnames(
              hideButtons
                ? 'modalBody-content--hideButtons'
                : 'modalBody-content',
              tall && 'modalTall',
              taller && 'modalTaller',
              modalMediumTall && 'modalMediumTall'
            )}
          >
            {children}
          </section>
          {!hideButtons ? (
            <footer className="modalBody-footer">
              <InputRow>
                {!hideSubmitButton ? (
                  <span data-tip data-tooltip-id="submitTooltip">
                    <Button
                      testID={`${testID}SubmitButton`}
                      text={submitButtonText || 'Submit'}
                      color={submitButtonColor}
                      onClick={handleSubmit}
                      disabled={disableSubmit}
                      onMouseEnter={onMouseEnter}
                      inputClassName={
                        submitTooltip ? 'modalBody-footer-cannotSubmit' : ''
                      }
                    />
                    {submitTooltip ? (
                      <ReactTooltip id="submitTooltip" key="submitTooltip">
                        {submitTooltip}
                      </ReactTooltip>
                    ) : null}
                  </span>
                ) : null}
                {!hideCancelButton ? (
                  <Button
                    onClick={cancelActions ? cancelActions : onRequestClose}
                    text={cancelButtonText || 'Cancel'}
                    color={cancelButtonColor || 'white'}
                    disabled={disableCancel}
                  />
                ) : null}
              </InputRow>
            </footer>
          ) : null}
        </section>
      </div>
    </div>
  );
};

export default Modal;
