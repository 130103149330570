export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';

export const AUTH_SIGNIN = 'AUTH_SIGNIN';
export const AUTH_SIGNOUT = 'AUTH_SIGNOUT';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const REGISTRATION_FAILURE = 'REGISTRATION_FAILURE';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';

export const NEW_USER_REGISTRATION_REQUEST = 'NEW_USER_REGISTRATION_REQUEST';
export const NEW_USER_REGISTRATION_RESPONSE = 'NEW_USER_REGISTRATION_RESPONSE';

export const FETCH_USER_PROFILE = 'FETCH_USER_PROFILE';

export const FETCH_USER_COMPANIES_RESPONSE = 'FETCH_USER_COMPANIES_RESPONSE';

export const SET_ACTIVE_COMPANY = 'SET_ACTIVE_COMPANY';
export const SET_ACTIVE_LOCATION = 'SET_ACTIVE_LOCATION';

export const LOAD_TASK = 'LOAD_TASK';
export const SET_ACTIVE_TASK_REQUEST = 'SET_ACTIVE_TASK_REQUEST';
export const SET_ACTIVE_TASK_RESPONSE = 'SET_ACTIVE_TASK_RESPONSE';

export const SEND_REMINDER_REQUEST = 'SEND_REMINDER_REQUEST';
export const UPDATE_REMINDERS = 'UPDATE_REMINDERS';

export const ADD_USER_TASK_REQUEST = 'ADD_USER_TASK_REQUEST';
export const ADD_USER_TASK_RESPONSE = 'ADD_USER_TASK_RESPONSE';

export const UPDATE_USER_TASK_REQUEST = 'UPDATE_USER_TASK_REQUEST';
export const UPDATE_USER_TASK_RESPONSE = 'UPDATE_USER_TASK_RESPONSE';

export const DELETE_USER_TASK_REQUEST = 'DELETE_USER_TASK_REQUEST';
export const DELETE_USER_TASK_RESPONSE = 'DELETE_USER_TASK_RESPONSE';

export const FETCH_COMPANY_AUDITS_REQUEST = 'FETCH_COMPANY_AUDITS_REQUEST';
export const FETCH_COMPANY_AUDITS_RESPONSE = 'FETCH_COMPANY_AUDITS_RESPONSE';

export const ADD_COMPANY_REQUEST = 'ADD_COMPANY_REQUEST';
export const ADD_COMPANY_RESPONSE = 'ADD_COMPANY_RESPONSE';

export const ADD_COMPANY_LOCATION_REQUEST = 'ADD_COMPANY_LOCATION_REQUEST';
export const ADD_COMPANY_LOCATION_RESPONSE = 'ADD_COMPANY_LOCATION_RESPONSE';

export const UPDATE_COMPANY_LOCATION_REQUEST =
  'UPDATE_COMPANY_LOCATION_REQUEST';
export const UPDATE_COMPANY_LOCATION_RESPONSE =
  'UPDATE_COMPANY_LOCATION_RESPONSE';

export const DELETE_COMPANY_LOCATION_REQUEST =
  'DELETE_COMPANY_LOCATION_REQUEST';
export const DELETE_COMPANY_LOCATION_RESPONSE =
  'DELETE_COMPANY_LOCATION_RESPONSE';

export const UPDATE_LOCATION_EMPLOYEE_REQUEST =
  'UPDATE_LOCATION_EMPLOYEE_REQUEST';

export const SET_ACTIVE_GROUPS = 'SET_ACTIVE_GROUPS';

export const SET_ACTIVE_EMPLOYEE_REQUEST = 'SET_ACTIVE_EMPLOYEE_REQUEST';

export const ADD_UPLOADED_ATTACHMENTS_REQUEST =
  'ADD_UPLOADED_ATTACHMENTS_REQUEST';
export const ADD_UPLOADED_ATTACHMENTS_RESPONSE =
  'ADD_UPLOADED_ATTACHMENTS_RESPONSE';
export const CLEAR_UPLOADED_ATTACHMENTS = 'CLEAR_UPLOADED_ATTACHMENTS';

export const SET_INCIDENT_OWNER = 'SET_INCIDENT_OWNER';

export const UPDATE_COMPANY_REQUEST = 'UPDATE_COMPANY_REQUEST';
export const UPDATE_COMPANY_RESPONSE = 'UPDATE_COMPANY_RESPONSE';

export const DELETE_COMPANY_REQUEST = 'DELETE_COMPANY_REQUEST';
export const DELETE_COMPANY_RESPONSE = 'DELETE_COMPANY_RESPONSE';

export const FETCH_INCIDENT_BASICS_REQUEST = 'FETCH_INCIDENT_BASICS_REQUEST';
export const FETCH_INCIDENT_BASICS_RESPONSE = 'FETCH_INCIDENT_BASICS_RESPONSE';

export const CREATE_INCIDENT_REQUEST = 'CREATE_INCIDENT_REQUEST';

export const UPDATE_REPORT_COMPONENT_REQUEST =
  'UPDATE_REPORT_COMPONENT_REQUEST';

export const UPDATE_INCIDENT_BASICS_REQUEST = 'UPDATE_INCIDENT_BASICS_REQUEST';
export const UPDATE_INCIDENT_BASICS_RESPONSE =
  'UPDATE_INCIDENT_BASICS_RESPONSE';

export const SAVE_INCIDENT_BASICS_REQUEST = 'SAVE_INCIDENT_BASICS_REQUEST';
export const SAVE_INCIDENT_BASICS_RESPONSE = 'SAVE_INCIDENT_BASICS_RESPONSE';

export const SET_ACTIVE_INCIDENT_REQUEST = 'SET_ACTIVE_INCIDENT_REQUEST';
export const SET_ACTIVE_INCIDENT_RESPONSE = 'SET_ACTIVE_INCIDENT_RESPONSE';

export const CREATE_REPORT_RESPONSE = 'CREATE_REPORT_RESPONSE';

export const UPDATE_REPORT_RESPONSE = 'UPDATE_REPORT_RESPONSE';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_RESPONSE = 'UPDATE_USER_RESPONSE';
export const UPDATE_USER_PROFILE_PICTURE_URL =
  'UPDATE_USER_PROFILE_PICTURE_URL';

export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST';

export const SET_REPORT_TEMPLATES = 'SET_REPORT_TEMPLATES';

export const SET_ACTIVE_EMPLOYEE_RESPONSE = 'SET_ACTIVE_EMPLOYEE_RESPONSE';

export const FETCH_INCIDENT_REPORT_RESPONSE = 'FETCH_INCIDENT_REPORT_RESPONSE';

export const DELETE_INCIDENT_ATTACHMENT = 'DELETE_INCIDENT_ATTACHMENT';

export const DELETE_JSA_QUESTION_ATTACHMENT = 'DELETE_JSA_QUESTION_ATTACHMENT';

export const SET_ALL_LOCATIONS = 'SET_ALL_LOCATIONS';

export const CREATE_OSHA_FORM_REQUEST = 'CREATE_OSHA_FORM_REQUEST';
export const CREATE_OSHA_FORM_RESPONSE = 'CREATE_OSHA_FORM_RESPONSE';

export const UPDATE_OSHA_FORM_REQUEST = 'UPDATE_OSHA_FORM_REQUEST';
export const UPDATE_OSHA_FORM_RESPONSE = 'UPDATE_OSHA_FORM_RESPONSE';

export const FETCH_OSHA_LOGS_REQUEST = 'FETCH_OSHA_LOGS_REQUEST';

export const FETCH_OSHA_FORM_RESPONSE = 'FETCH_OSHA_FORM_RESPONSE';

export const FETCH_OSHA_300LOGS_RESPONSE = 'FETCH_OSHA_300LOGS_RESPONSE';

export const FETCH_OSHA_300A_RESPONSE = 'FETCH_OSHA_300A_RESPONSE';

export const CREATE_OSHA_LOG_REQUEST = 'CREATE_OSHA_LOG_REQUEST';
export const CREATE_OSHA_LOG_RESPONSE = 'CREATE_OSHA_LOG_RESPONSE';

export const PRINT_OSHA_REQUEST = 'PRINT_OSHA_REQUEST';
export const PRINT_OSHA_RESPONSE = 'PRINT_OSHA_RESPONSE';

export const PRINT_SELECTED_OSHA_REQUEST = 'PRINT_SELECTED_OSHA_REQUEST';

export const DELETE_ATTACHMENT_REQUEST = 'DELETE_ATTACHMENT_REQUEST';
export const DELETE_ATTACHMENT_RESPONSE = 'DELETE_ATTACHMENT_RESPONSE';

export const DOWNLOAD_EMPLOYEE_QRCODE_REQUEST =
  'DOWNLOAD_EMPLOYEE_QRCODE_REQUEST';
export const DOWNLOAD_EMPLOYEE_QRCODE_RESPONSE =
  'DOWNLOAD_EMPLOYEE_QRCODE_RESPONSE';

export const ADD_PROJECT_REQUEST = 'ADD_PROJECT_REQUEST';

export const UPDATE_PROJECT_REQUEST = 'UPDATE_PROJECT_REQUEST';

export const DELETE_PROJECT_REQUEST = 'DELETE_PROJECT_REQUEST';

export const SET_ACTIVE_PROJECT = 'SET_ACTIVE_PROJECT';

export const SET_ACTIVE_COMPANY_USER = 'SET_ACTIVE_COMPANY_USER';

export const FETCH_DOCUMENT_FOLDERS_REQUEST = 'FETCH_DOCUMENT_FOLDERS_REQUEST';
export const FETCH_DOCUMENT_FOLDERS_RESPONSE =
  'FETCH_DOCUMENT_FOLDERS_RESPONSE';

export const ADD_DOCUMENT_FOLDER_REQUEST = 'ADD_DOCUMENT_FOLDER_REQUEST';
export const ADD_DOCUMENT_FOLDER_RESPONSE = 'ADD_DOCUMENT_FOLDER_RESPONSE';

export const UPDATE_DOCUMENT_FOLDER_REQUEST = 'UPDATE_DOCUMENT_FOLDER_REQUEST';
export const UPDATE_DOCUMENT_FOLDER_RESPONSE =
  'UPDATE_DOCUMENT_FOLDER_RESPONSE';

export const DELETE_DOCUMENT_FOLDER_REQUEST = 'DELETE_DOCUMENT_FOLDER_REQUEST';
export const DELETE_DOCUMENT_FOLDER_RESPONSE =
  'DELETE_DOCUMENT_FOLDER_RESPONSE';

export const SET_ACTIVE_DOCUMENT_FOLDER_REQUEST =
  'SET_ACTIVE_DOCUMENT_FOLDER_REQUEST';
export const SET_ACTIVE_DOCUMENT_FOLDER_RESPONSE =
  'SET_ACTIVE_DOCUMENT_FOLDER_RESPONSE';

export const ADD_DOCUMENTS_TO_FOLDER_REQUEST =
  'ADD_DOCUMENTS_TO_FOLDER_REQUEST';
export const ADD_DOCUMENTS_TO_FOLDER_RESPONSE =
  'ADD_DOCUMENTS_TO_FOLDER_RESPONSE';

export const FETCH_INCIDENT_BY_ID_REQUEST = 'FETCH_INCIDENT_BY_ID_REQUEST';

export const SUBMIT_SECURITY_QUESTIONS = 'SUBMIT_SECURITY_QUESTIONS';
export const SET_SECURITY_QUESTIONS = 'SET_SECURITY_QUESTIONS';

export const FETCH_SAFETY_WALKS_REQUEST = 'FETCH_SAFETY_WALKS_REQUEST';
export const FETCH_SAFETY_WALKS_RESPONSE = 'FETCH_SAFETY_WALKS_RESPONSE';

export const ADD_SAFETY_WALK_REQUEST = 'ADD_SAFETY_WALK_REQUEST';

export const SET_ACTIVE_SAFETY_WALK = 'SET_ACTIVE_SAFETY_WALK';
export const LOAD_SAFETY_WALK = 'LOAD_ACTIVE_SAFETY_WALK';

export const FETCH_CUSTOM_REPORT_QUESTIONS_REQUEST =
  'FETCH_CUSTOM_REPORT_QUESTIONS_REQUEST';

export const SET_ACTIVE_CUSTOM_REPORT_QUESTIONS =
  'SET_ACTIVE_CUSTOM_REPORT_QUESTIONS';
export const CREATE_CUSTOM_REPORT_QUESTIONS_REQUEST =
  'CREATE_CUSTOM_REPORT_QUESTIONS_REQUEST';
export const UPDATE_CUSTOM_REPORT_QUESTIONS_REQUEST =
  'UPDATE_CUSTOM_REPORT_QUESTIONS_REQUEST';
export const DELETE_CUSTOM_REPORT_QUESTIONS_REQUEST =
  'DELETE_CUSTOM_REPORT_QUESTIONS_REQUEST';

export const CLEAR_USER = 'CLEAR_USER';
export const LOAD_USER_REQUEST = 'LOAD_USER_REQUEST';
export const LOAD_USER_RESPONSE = 'LOAD_USER_RESPONSE';
export const LOAD_ALL_USERS_RESPONSE = 'LOAD_ALL_USERS_RESPONSE';
export const LOAD_ALL_USERS_REQUEST = 'LOAD_ALL_USERS_REQUEST';
export const CLEAR_ALL_USERS = 'CLEAR_ALL_USERS';
export const LOAD_ALL_COMPANY_USERS_REQUEST = 'LOAD_ALL_COMPANY_USERS_REQUEST';
export const LOAD_ALL_COMPANY_USERS_RESPONSE =
  'LOAD_ALL_COMPANY_USERS_RESPONSE';

export const UPDATE_ATTACHMENT_REQUEST = 'UPDATE_ATTACHMENT_REQUEST';
