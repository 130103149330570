import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames/bind';

import history from '../../history';
import { signOut } from '../../actions/auth';
import { leftNavigation } from '../../constants/leftNavigation';
import {
  addDocumentFolderRequest,
  setActiveDocumentFolderRequest
} from '../../actions/documents';
import {
  fetchIncidentBasicsRequest,
  setActiveIncidentResponse
} from '../../actions/incidents';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject
} from '../../selectors/company';
import {
  getDocumentFoldersSelector,
  getActiveFolderSelector
} from '../../selectors/documents';
import { getLoggedInUser } from '../../selectors/users';
import Button from '../../components/inputs/Button';
import LeftNavSection from '../../components/LeftNavSection/';
import UserBadge from '../../components/UserBadge';

import styles from './mobileHeader.module.scss';
const bStyles = classnames.bind(styles);

export class MobileHeader extends Component {
  state = {
    menuCollapsed: true,
    sectionOpen: 0
  };

  toggleMenuCollapsed = () => {
    this.setState({ menuCollapsed: !this.state.menuCollapsed });
  };

  handleSectionOpen = sectionOpen => {
    this.setState({ sectionOpen });
  };

  render() {
    const location = window.location?.pathname;
    const { menuCollapsed, sectionOpen } = this.state;
    const {
      user,
      activeCompany,
      activeLocation,
      activeProject,
      documentFolders,
      addDocumentFolder,
      setActiveFolder,
      fetchQualifications,
      logOut,
      activeSection,
      activeFolder
    } = this.props;

    return (
      <div
        style={location?.includes('/external') ? { display: 'none' } : {}}
        className={styles.mobileHeader}
      >
        <div className={styles.header}>
          <div className={styles.container} onClick={this.toggleMenuCollapsed}>
            <div
              className={bStyles('bar1', {
                change: !menuCollapsed
              })}
            />
            <div
              className={bStyles('bar2', {
                change: !menuCollapsed
              })}
            />
            <div
              className={bStyles('bar3', {
                change: !menuCollapsed
              })}
            />
          </div>
        </div>
        <div
          className={bStyles('sideMenu', {
            hidden: menuCollapsed
          })}
        >
          <aside className={styles.sections}>
            {leftNavigation.sections.map((navSection, index) => {
              return navSection.accessLevel.indexOf(user.accessLevel) !== -1 ? (
                <LeftNavSection
                  fromMobileHeader
                  activeSection={
                    index === activeSection?.[0] ? activeSection[1] : null
                  }
                  activeSubSection={index}
                  title={navSection.title}
                  section={navSection.section}
                  key={index}
                  accessLevel={user.accessLevel}
                  sectionOpen={
                    parseInt(sectionOpen) >= 0
                      ? sectionOpen === index
                      : activeSection?.[0] === index
                  }
                  openNewSection={this.handleSectionOpen}
                  closeModalMenu={this.toggleMenuCollapsed}
                  activeCompany={activeCompany}
                  activeLocation={activeLocation}
                  activeProject={activeProject}
                  documentFolders={documentFolders}
                  addDocumentFolder={addDocumentFolder}
                  setActiveFolder={setActiveFolder}
                  fetchQualifications={fetchQualifications}
                  loggedInUser={user}
                  activeFolder={activeFolder}
                />
              ) : (
                ''
              );
            })}
          </aside>
          <div className={styles.bottomNav}>
            <UserBadge userId={user._id} showName showImage nameUnder />
            <Button
              text="Profile"
              color="blue"
              onClick={() => history.push('/app/profile')}
            />
            <Button text="Logout" color="white" onClick={() => logOut()} />
          </div>
        </div>
      </div>
    );
  }
}

export const mapStateToProps = state => ({
  user: getLoggedInUser(state),
  activeCompany: getActiveCompany(state),
  activeLocation: getActiveLocationId(state),
  activeProject: getActiveProject(state),
  documentFolders: getDocumentFoldersSelector(state),
  activeFolder: getActiveFolderSelector(state)
});

export const mapDispatchToProps = dispatch => ({
  fetchIncidentBasics: reportTypes =>
    dispatch(fetchIncidentBasicsRequest(reportTypes)),
  setIncident: () => dispatch(setActiveIncidentResponse({})),
  logOut: () => dispatch(signOut()),
  addDocumentFolder: values => dispatch(addDocumentFolderRequest(values)),
  setActiveFolder: values => dispatch(setActiveDocumentFolderRequest(values))
});

// eslint-disable-next-line import/no-anonymous-default-export
export default connect(mapStateToProps, mapDispatchToProps)(MobileHeader);
