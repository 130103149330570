import React from 'react';
import './svg.css';

const jsa = props => (
  <svg
    width={props.width ?? 30}
    height={props.height ?? 25}
    viewBox="0 0 80 80"
    x={props.x || '0px'}
    y={props.y || '0px'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1651_286068)">
      <path d="M78.4997 38.5711C78.4997 40.9378 76.5806 42.8568 74.214 42.8568H2.7857C0.41904 42.8568 -1.5 40.9378 -1.5 38.5711C-1.5 36.2045 0.41904 34.2854 2.7857 34.2854H4.70847C6.58933 23.1307 13.8608 13.822 23.7552 9.09187L24.6285 18.0625C24.7429 19.2396 25.7694 20.1244 26.9695 19.9874C28.1466 19.873 29.0094 18.8264 28.8944 17.6492L27.4047 2.35245C27.2801 1.07706 28.2821 0 29.538 0H47.4617C48.7169 0 49.7199 1.07698 49.5949 2.35245L48.1053 17.6508C47.9909 18.8279 48.853 19.8746 50.0302 19.989C50.1018 19.9973 50.1712 20.0001 50.241 20.0001C51.3304 20.0001 52.263 19.1715 52.3715 18.064L53.2448 9.09343C63.1392 13.8218 70.4106 23.1307 72.2915 34.2854H74.2143C76.581 34.2854 78.4997 36.2045 78.4997 38.5711Z" />
      <path d="M77.0711 54.2852H72.7854C72.7854 51.1349 70.2225 48.571 67.0711 48.571H9.92852C6.77719 48.571 4.21426 51.1349 4.21426 54.2852H-0.0714345C-0.860121 54.2852 -1.5 54.9242 -1.5 55.7138V61.4281C-1.5 62.2168 -0.860121 62.8566 -0.0714345 62.8566H4.21426V68.5709C4.21426 69.3298 4.51529 70.0556 5.05132 70.591L10.7656 76.3052C11.301 76.8413 12.0286 77.1423 12.7857 77.1423H29.9284C30.8826 77.1423 31.7753 76.6652 32.3056 75.87C34.4246 72.6948 37.2151 69.4805 38.4998 68.6826C39.7846 69.4806 42.575 72.6949 44.694 75.87C45.2244 76.6652 46.117 77.1423 47.0712 77.1423H64.214C64.9711 77.1423 65.6987 76.8413 66.2341 76.3052L71.9483 70.591C72.4844 70.0556 72.7854 69.3298 72.7854 68.5709V62.8566H77.0711C77.8598 62.8566 78.4996 62.2168 78.4996 61.4281V55.7138C78.4996 54.9242 77.8598 54.2852 77.0711 54.2852ZM67.0711 67.3884L63.0313 71.4282H48.579C45.6534 67.2597 41.7877 62.8568 38.4997 62.8568C35.2116 62.8568 31.3453 67.2597 28.4203 71.4282H13.968L9.9282 67.3884L9.92448 54.2852H67.0711L67.0711 67.3884Z" />
    </g>
    <defs>
      <clipPath id="clip0_1651_286068">
        <rect width="80" height="80" />
      </clipPath>
    </defs>
  </svg>
);

export default jsa;
