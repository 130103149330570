import React from 'react';
import './svg.css';

const witnessStatements = props => (
  <svg
    width={props.width ?? 30}
    height={props.width ?? 25}
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M53.4268 14.7212C53.4268 16.5717 54.9006 18.1701 56.7835 18.1701H70.2103C69.9646 17.8336 69.801 17.497 69.4734 17.2448L54.3273 1.68232C54.0815 1.42984 53.7543 1.17765 53.4268 0.925156L53.4268 14.7212Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.0877 14.8054C49.0877 19.1799 52.5264 22.7129 56.7836 22.7129L71.1108 22.713L71.1111 74.2796C71.1111 77.4764 68.5728 80 65.5437 80H14.4563C11.345 80 8.88889 77.392 8.88889 74.2796V5.72041C8.88889 2.52365 11.4272 0 14.4563 0H49.0877V14.8054ZM62.8147 44.2106C62.8147 46.1186 62.5652 47.9702 62.0961 49.7363C56.9973 53.9187 48.9961 58.9475 39.9999 58.9475C31.0037 58.9475 23.0025 53.9187 17.9037 49.7363C17.4346 47.9702 17.1851 46.1186 17.1851 44.2106C17.1851 42.3026 17.4346 40.4511 17.9037 38.685C23.0025 34.5026 31.0037 29.4738 39.9999 29.4738C48.9961 29.4738 56.9973 34.5026 62.0961 38.685C62.5652 40.4511 62.8147 42.3026 62.8147 44.2106ZM39.9999 54.0352C45.6 54.0352 50.1398 49.6366 50.1398 44.2106C50.1398 38.7847 45.6 34.3861 39.9999 34.3861C34.3998 34.3861 29.86 38.7847 29.86 44.2106C29.86 49.6366 34.3998 54.0352 39.9999 54.0352Z"
      fill="white"
    />
  </svg>
);

export default witnessStatements;
