import { createSelector } from 'reselect';

const companySelector = state => state.company;

export const getCompanyLoadingSelector = createSelector(
  companySelector,
  companies => companies && companies.loading
);

export const getUserCompaniesSelector = createSelector(
  companySelector,
  companies => companies && companies.list
);

export const getActiveCompany = createSelector(
  companySelector,
  activeCompany => activeCompany && activeCompany.activeCompany
);

export const getActiveLocationId = createSelector(
  companySelector,
  activeLocationId => activeLocationId && activeLocationId.activeLocationId
);

export const getCompanyLocationsSelector = createSelector(
  getActiveCompany,
  activeCompany => activeCompany && activeCompany.locations
);

export const getAllLocations = createSelector(
  companySelector,
  locations => locations && locations.allLocations
);

export const getActiveProject = createSelector(
  companySelector,
  company => company && company.activeProject
);

export const getIsPerrp = createSelector(
  companySelector,
  company =>
    (company?.activeLocation?.reportingType ??
      company?.activeCompany?.reportingType) === 2
);
