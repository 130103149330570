import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import history from '../../../history';
import { deleteManyAttachments } from '../../../api/v4';
import { getActiveSafetyWalkSelector } from '../../../selectors/safetyWalks';
import { setActiveSafetyWalk } from '../../../actions/safetyWalks';
import { getLoggedInUser } from '../../../selectors/users';
import {
  CAUSAL_FACTORS,
  HAZARD_CLASS,
  PROBABILITY_DROPDOWN,
  SEVERITY_DROPDOWN
} from '../../../constants/constants';
import AttachmentCarousel from '../AttachmentCarousel';
import Card from '../../../components/Card';
import CheckboxGroup from '../../../components/inputs/CheckboxGroup';
import { Dropdown, Textbox, TwoColumn } from '../../../components/inputs';
import Header from '../../../components/Header';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import MissingRequiredModal from '../../../components/Modal/missingRequiredModal';
import Modal from '../../../components/Modal';
import { SaveCancelFooter } from '../../../components/Footer';

import styles from '../itemCard.module.scss';

function LegacyHazardCard() {
  const dispatch = useDispatch();
  const { index } = useParams();
  const hIndex = parseInt(index, 10);
  const myName = history.location.state?.myName;

  const sw = useSelector(getActiveSafetyWalkSelector);
  const loggedInUser = useSelector(getLoggedInUser);
  const setSafetyWalk = payload => dispatch(setActiveSafetyWalk(payload));

  const [editing, setEditing] = useState(true);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [openModalType, setOpenModalType] = useState();
  const [missingRequired, setMissingRequired] = useState(false);
  const [hazard, setHazard] = useState({});

  const {
    title,
    causalFactor,
    immediatelyFixed,
    hazardClass,
    severity,
    probability,
    description,
    solution,
    exactLocation,
    pictures = []
  } = hazard;

  useEffect(() => {
    if (!Number.isNaN(hIndex)) {
      const hazard = sw?.items?.find(b => b.overallOrder === hIndex);
      setHazard({ ...hazard });
      setEditing(!hazard?._id);
    } else {
      setHazard({ overallOrder: sw?.items?.length });
    }
  }, [hIndex, sw.items]);

  const updateHazard = values => {
    setHasUnsavedChanges(true);
    setHazard({ ...hazard, ...values });
  };

  const handleHazardSubmit = () => {
    const hazardObj = {
      ...hazard,
      type: 'hazard'
    };

    if (!Number.isNaN(hIndex)) {
      const index = sw?.items?.findIndex(b => b.overallOrder === hIndex);
      sw.items[index] = hazardObj;
    } else {
      sw.items = [...sw?.items, hazardObj];
    }
    setSafetyWalk(sw);
    setHasUnsavedChanges(false);
    history.push('/app/safetyWalkContainer');
  };

  const handleDiscardHazard = async () => {
    let updated = sw?.items
      .toSpliced(hazard?.overallOrder, 1)
      ?.map((item, index) => ({ ...item, overallOrder: index }));

    if (hazard?.pictures?.length) {
      let ids = hazard?.pictures?.map(a => a._id);
      await deleteManyAttachments(ids);
    }

    setSafetyWalk({ ...sw, items: updated });
    history.push('/app/safetyWalkContainer');
  };

  const header = (
    <Header
      title={myName}
      section={`${editing ? 'Edit' : 'View'} Hazard Info`}
      needsSaved={hasUnsavedChanges}
      clickBack={() =>
        hasUnsavedChanges
          ? setOpenModalType('unsavedChanges')
          : history.push('/app/safetyWalkContainer')
      }
      rightButtons={{
        visible: editing,
        text: 'Discard Hazard',
        color: 'red',
        onClick: () => setOpenModalType('discardHazard')
      }}
    />
  );

  const footer = (
    <SaveCancelFooter
      saveButtonClick={() =>
        !canSubmit ? setOpenModalType('missingRequired') : handleHazardSubmit()
      }
      cancelButtonClick={() => history.goBack()}
      editing={editing}
      onMouseEnter={() => setMissingRequired(true)}
    />
  );

  const canSubmit =
    title?.trim() &&
    hazardClass?.length &&
    probability &&
    severity &&
    description &&
    hasUnsavedChanges;

  return (
    <>
      <HeaderAndFooter Header={header} Footer={footer} showFooter={editing}>
        <Card className={styles.card}>
          <AttachmentCarousel
            isEditing={editing}
            pictures={pictures}
            loggedInUser={loggedInUser}
            ownerType="hazard"
            handlePhotoUpdate={v => updateHazard(v)}
          />
          <div className={styles.padding}>
            <>
              <TwoColumn>
                <Textbox
                  fieldLabel="Hazard Title"
                  isRequired
                  placeholder="Type a clear and concise title."
                  onChange={v => updateHazard({ title: v })}
                  currentValue={title}
                  disabled={!editing}
                  touched={missingRequired && !title}
                />
                <Dropdown
                  multi
                  options={CAUSAL_FACTORS}
                  fieldLabel="Causal Factor"
                  searchable
                  currentValue={causalFactor}
                  onChange={v => updateHazard({ causalFactor: v })}
                  bareValues={false}
                  disabled={!editing}
                />
              </TwoColumn>
              <CheckboxGroup
                options={[
                  {
                    value: 'immediatelyFixed',
                    label: 'Hazard was Immediately Fixed'
                  }
                ]}
                currentValue={immediatelyFixed}
                onChange={v => updateHazard({ immediatelyFixed: v })}
                bareValues={false}
                disabled={!editing}
              />
              <Dropdown
                multi
                options={HAZARD_CLASS}
                searchable
                isRequired
                fieldLabel="Hazard Class"
                currentValue={hazardClass}
                bareValues={false}
                onChange={v => updateHazard({ hazardClass: v })}
                disabled={!editing}
                touched={missingRequired && !hazardClass.length}
              />
              <Dropdown
                options={PROBABILITY_DROPDOWN}
                searchable
                isRequired
                fieldLabel="Probability Rating"
                currentValue={probability}
                bareValues={false}
                onChange={v => updateHazard({ probability: v })}
                disabled={!editing}
                touched={missingRequired && !probability}
              />
              <Dropdown
                options={SEVERITY_DROPDOWN}
                searchable
                isRequired
                fieldLabel="Severity Rating"
                currentValue={severity}
                bareValues={false}
                onChange={v => updateHazard({ severity: v })}
                disabled={!editing}
                touched={missingRequired && !severity}
              />
              <Textbox
                type="textarea"
                fieldLabel="Description"
                placeholder="Describe this Hazard. Be sure to include any important details."
                isRequired
                onChange={v => updateHazard({ description: v })}
                currentValue={description}
                disabled={!editing}
                touched={missingRequired && !description}
              />
              <TwoColumn>
                <Textbox
                  type="textarea"
                  fieldLabel="Solution"
                  placeholder="How to fix and prevent this Hazard."
                  onChange={v => updateHazard({ solution: v })}
                  currentValue={solution}
                  disabled={!editing}
                />
                <Textbox
                  type="textarea"
                  fieldLabel="Exact Location"
                  placeholder="Where to find this Hazard."
                  onChange={v => updateHazard({ exactLocation: v })}
                  currentValue={exactLocation}
                  disabled={!editing}
                />
              </TwoColumn>
            </>
          </div>
        </Card>
      </HeaderAndFooter>
      <MissingRequiredModal
        isOpen={openModalType === 'missingRequired'}
        onRequestClose={() => setOpenModalType()}
        subject={myName}
      />
      <Modal
        title="Discard Hazard"
        titleClassName="redHeader"
        isOpen={openModalType === 'discardHazard'}
        submitButtonColor="red"
        submitButtonText="Discard hazard"
        onRequestClose={() => setOpenModalType()}
        submitActions={handleDiscardHazard}
      >
        <div className="reportSectionContainer-emailAssigneeText">
          <span style={{ color: '#c74846', fontWeight: 'bold' }}>
            Discarding this Hazard will delete the information within, pictures
            taken, and the corrective action associated with it (if any).
          </span>
        </div>
      </Modal>
    </>
  );
}

export default LegacyHazardCard;
