import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router';
import moment from 'moment';
import history from '../../../history';

import {
  deleteTrainingCourse,
  fetchDocumentFoldersByType,
  getCourseTemplates,
  getJobList,
  getTrainingCourseById,
  updateTrainingCourse
} from '../../../api/v4';
import { getLoggedInUser } from '../../../selectors/users';
import { areValidDates } from '../../../utils/trainingHelper';
import customToast from '../../../utils/customToast';

import Card from '../../../components/Card';
import {
  Checkbox,
  Dropdown,
  EmployeeDropdown,
  InputRow,
  Textbox
} from '../../../components/inputs';
import DeleteItemModal from '../../../components/Modal/deleteItemModal';
import Header from '../../../components/Header';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import { IReportDatePicker } from '../../../components/inputs/DateTimePicker';
import LeftNav from '../../../components/LeftNav';
import List from '../../../components/List';
import MissingRequiredModal from '../../../components/Modal/missingRequiredModal';
import Modal from '../../../components/Modal';
import RadioButtons from '../../../components/inputs/RadioButtons';
import { SaveCancelFooter } from '../../../components/Footer';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';

import styles from './styles.module.scss';

export default function TrainingCourseView() {
  const { id } = useParams();
  const location = useLocation();
  const user = useSelector(getLoggedInUser);
  const fromScreen = location.state?.fromScreen;

  const [course, setCourse] = useState({});
  const [hasUnsavedChanges, setUnsavedChanges] = useState(false);
  const [jobList, setJobList] = useState(null);
  const [templates, setTemplates] = useState(null);
  const [editing, setEditing] = useState(false);
  const [openUnsavedModal, setOpenUnsavedModal] = useState(false);
  const [folders, setFolders] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [missingRequired, setMissingRequired] = useState(false);
  const [missingModalOpen, setMissingModalOpen] = useState(false);
  const [removeAssigneeModal, setRemoveAssigneeModal] = useState(false);
  const [removedAssignees, setRemoved] = useState([]);

  const handleAnswer = answer => {
    setUnsavedChanges(true);
    setCourse({ ...course, ...answer });
  };

  useEffect(() => {
    getJobList().then(r => {
      setJobList(r?.map(job => ({ value: job, label: job })));
    });

    getCourseTemplates().then(r =>
      setTemplates(
        [{ label: '', value: null }].concat(
          r.map(temp => ({ ...temp, value: temp._id, label: temp.title }))
        )
      )
    );

    fetchDocumentFoldersByType('TrainingCourses').then(r =>
      setFolders(
        [{ value: null, label: ' ' }].concat(
          r.map(f => {
            return { value: f._id, label: f.name };
          })
        )
      )
    );

    getTrainingCourseById(id).then(setCourse);
  }, [id]);

  const handleBack = () => {
    history.push(fromScreen ?? '/app/trainingCourses');
  };

  const handleSubmit = () => updateTrainingCourse(course).then(handleBack);

  const handleArchive = () => {
    updateTrainingCourse({
      ...course,
      isArchived: !course.isArchived
    }).then(response => {
      if (response.isArchived) history.push('/app/trainingCourses');
      else {
        getTrainingCourseById(response._id).then(setCourse);
      }
    });
  };

  const handleRemoveAssignees = () => {
    updateTrainingCourse({ ...course, removedAssignees }).then(() => {
      customToast('Successfully Removed Assignee(s)', 'success');
      history.goBack();
    });
  };

  const canSubmit = areValidDates(course);
  const removeableAssignees = course?.assignedTo
    ?.map(a => {
      let allTrainingsCompleted = course.trainings
        ?.filter(t => t.assignedTo[0] === a)
        ?.every(t => t.isCompleted || t.completionDate);
      return allTrainingsCompleted ? null : a;
    })
    ?.filter(a => a);

  const header = (
    <Header
      title={`${editing ? 'Edit ' : ''}Training Course`}
      needsSaved={hasUnsavedChanges}
      section="Training Course"
      clickBack={() =>
        hasUnsavedChanges ? setOpenUnsavedModal(true) : handleBack()
      }
      pageActionOptions={[
        {
          label: 'Edit Course',
          onClick: () => setEditing(true),
          color: 'blueOutline',
          visible:
            user.accessLevel === 900 || course.createdByUserId === user._id
        },
        {
          label: `Remove Assignees`,
          onClick: () => setRemoveAssigneeModal(true),
          color: 'blueOutline',
          visible:
            removeableAssignees?.length &&
            course?.assignedTo?.length > 1 &&
            !course.isCompleted &&
            (user.accessLevel === 900 || course.createdByUserId === user._id)
        },
        {
          label: `${course?.isArchived ? 'Un-' : ''}Archive Course`,
          onClick: () => handleArchive(),
          color: 'blueOutline',
          visible: user.accessLevel > 400
        },
        {
          label: 'Delete Course',
          onClick: () => setDeleteModal(true),
          color: 'redOutline',
          visible:
            user.accessLevel === 900 || course.createdByUserId === user._id
        }
      ]}
    />
  );
  const getQuickNav = () => {
    let leftNav = [{ label: course.title, id: 'title' }].concat(
      course.trainingTemplates?.length
        ? course?.trainingTemplates?.map(template => ({
            label: template.title,
            id: template._id.toString()
          }))
        : []
    );

    return leftNav;
  };

  const trainingListColumns = [
    {
      key: 'assignedTo',
      label: 'Employees',
      datatype: 'users'
    },
    {
      key: 'completionStatus',
      enum: [
        'Assigned',
        'Completed',
        'In-Progress',
        'Future Assignment Scheduled',
        'Awaiting Approval',
        'Failed'
      ]
    },
    { key: 'completionDate', datatype: 'date' }
  ];

  const footer = (
    <SaveCancelFooter
      saveButtonClick={() =>
        !canSubmit ? setMissingModalOpen(true) : handleSubmit()
      }
      cancelButtonClick={() =>
        hasUnsavedChanges ? setOpenUnsavedModal(true) : setEditing(false)
      }
      editing={editing}
      onMouseEnter={() => setMissingRequired(!canSubmit)}
    />
  );
  return (
    <>
      <HeaderAndFooter
        Header={header}
        Footer={footer}
        Left={!editing ? <LeftNav items={getQuickNav()} /> : null}
        showFooter={editing}
      >
        <Card showHeader title={course?.title} name="title">
          <Dropdown
            options={templates?.map(t => ({
              ...t,
              isVisible: !t.isArchived
            }))}
            fieldLabel="Course Template"
            currentValue={course.masterTemplate}
            disabled
            searchable
          />
          <Dropdown
            options={folders}
            fieldLabel="Folder"
            onChange={v => handleAnswer({ folderId: v })}
            currentValue={course.folderId}
            disabled={!editing}
            searchable
          />
          <Textbox
            isRequired
            fieldLabel="Training Course Title"
            currentValue={course.title}
            disabled
            onChange={v => handleAnswer({ title: v })}
            touched={missingRequired && !course.title}
          />
          <Textbox
            fieldLabel="Instructions"
            type="textarea"
            onChange={v => handleAnswer({ instructions: v })}
            disabled
            currentValue={course.instructions}
          />
          <Checkbox
            fieldLabel="Release Sequentially"
            currentValue={course.sequential}
            onChange={v => handleAnswer({ sequential: v })}
            disabled
            tooltip="If selected, the trainings will release one after the other. Otherwise trainings will release all at once."
            displayTooltip
          />
          {/* hide until course creating all the trainings is addressed (https://gitlab.com/ireportsource/ireportsource-react-app/-/issues/1162#note_1859805001)*/}
          {/* <Checkbox
            fieldLabel="Immediately Reassign if Assignee(s) fail?"
            currentValue={course.immediatelyReassign}
            displayTooltip
            disabled={!creating || course.masterTemplate}
            tooltip={
              <div>
                <p>
                  When this is selected, any assignee that fails this course
                  will be automatically reassigned this course.
                </p>
                <p>
                  This course will be reassigned after everyone has completed
                  the course.
                </p>
              </div>
            }
            onChange={value =>
              handleAnswer({
                immediatelyReassign: value
              })
            }
          /> */}
          <IReportDatePicker
            fieldLabel="Assign On"
            isRequired
            onChange={v =>
              handleAnswer({
                assignOn: v,
                dueDate: moment(v).isAfter(course.dueDate)
                  ? null
                  : course.dueDate
              })
            }
            currentValue={course.assignOn}
            disabled={!editing || course.hasBeenAssigned}
            name="assignOn"
            minDate={new Date(moment().add(id ? 1 : 0, 'days'))}
            touched={missingRequired && !course.assignOn}
          />
          <IReportDatePicker
            fieldLabel="Due By"
            isRequired
            onChange={v => handleAnswer({ dueDate: v })}
            currentValue={course.dueDate}
            disabled={course?.hasBeenAssigned || !editing}
            name="dueDate"
            minDate={new Date(moment(course.assignOn)?.add(1, 'days'))}
            touched={missingRequired && !course.dueDate && course.assignOn}
          />
          <RadioButtons
            options={[
              { value: 1, label: 'Job Title' },
              { value: 2, label: 'Employee' }
            ]}
            currentValue={course.assignBy}
            onChange={v => {
              handleAnswer({
                assignBy: v,
                assignedTo: [],
                assignByJobTitles: []
              });
            }}
            fieldLabel="Assign By"
            needSeparateLines
            disabled
            isRequired
            touched={missingRequired && !course.assignBy}
          />
          {jobList && course.assignBy === 1 ? (
            <Dropdown
              fieldLabel="Job Title(s)"
              options={jobList}
              selectButtons
              searchable
              disabled
              multi
              isRequired
              currentValue={course.assignByJobTitles}
              touched={missingRequired && !course.assignByJobTitles?.length}
            />
          ) : null}
          {course?.assignedTo?.length ? (
            <EmployeeDropdown
              fieldLabel="Assignees"
              permissions={[100, 400, 500, 900]}
              selectButtons
              searchable
              multi
              disabled
              currentValue={course.assignedTo}
              isRequired
              touched={missingRequired && !course.assignedTo?.length}
            />
          ) : null}
        </Card>

        <InputRow className={styles.overallFooter}>
          {course.trainingTemplates?.map(template => {
            // nsc approval aren't on the template - we need to look at the training
            let nscTraining =
              template?.nscCompanyIds?.length &&
              course.trainings?.find(
                t => t.trainingTemplateId === template._id
              );
            const nscHasApprover = nscTraining?.needsApproval;
            return (
              <Card showHeader title={template.title} name={template._id}>
                <List
                  saveKey={`trainingCourseList-${template.title}`}
                  getRowId={r => r._id}
                  dataIsHash
                  data={course.assignedTo.map(assignee => {
                    const foundMaster = course.trainings?.find(
                      t =>
                        t.trainingTemplateId === template._id &&
                        t.assignedTo.includes(assignee)
                    );
                    const found = foundMaster?.children?.find(
                      t => t.assignedTo === assignee
                    );
                    return {
                      _id:
                        found?._id ??
                        `${assignee} - ${template.trainingTemplateId}`,
                      completionDate: found?.completionDate,
                      assignedTo: [assignee],
                      approver: [found?.approver ?? template?.approver],
                      approveReject: found?.approveReject,
                      completionStatus:
                        found?.completionStatus ??
                        'Future Assignment Scheduled',
                      masterId: foundMaster?._id
                    };
                  })}
                  rowClick={v =>
                    history.push({
                      pathname: `/app/training/approve/${v.masterId}`,
                      state: {
                        fromScreen: `/app/trainingCourses/overallView/${id}`
                      }
                    })
                  }
                  name={template.title}
                  settings={trainingListColumns.concat(
                    template?.needsApproval || nscHasApprover
                      ? [
                          {
                            key: 'approver',
                            datatype: 'users'
                          },
                          {
                            key: 'approveReject',
                            accessor: v =>
                              v.approveReject
                                ? v?.approveReject === 'approve'
                                  ? 'Approved'
                                  : 'Rejected'
                                : ''
                          }
                        ]
                      : []
                  )}
                />
              </Card>
            );
          })}
        </InputRow>
      </HeaderAndFooter>
      <SaveChangesModal
        isOpen={openUnsavedModal}
        savingWhat="Training Course"
        onRequestClose={() => setOpenUnsavedModal(false)}
        submitActions={handleBack}
      />
      <DeleteItemModal
        deletingWhat="Course"
        isOpen={deleteModal}
        onRequestClose={() => setDeleteModal(false)}
        submitActions={() => deleteTrainingCourse(id).then(handleBack)}
      />
      <MissingRequiredModal
        isOpen={missingModalOpen}
        subject="Training Course"
        onRequestClose={() => setMissingModalOpen(false)}
        isEditing
      />
      <Modal
        title="Remove Assignee(s)"
        titleClassName="redHeader"
        isOpen={removeAssigneeModal}
        submitButtonColor="red"
        submitButtonText="Save Changes"
        onRequestClose={() => {
          setRemoved([]);
          setRemoveAssigneeModal(false);
        }}
        submitActions={handleRemoveAssignees}
        disableSubmit={!removedAssignees?.length}
      >
        <span style={{ color: '#c74846', fontWeight: 'bold' }}>
          Removing an assignee is permanent. The assignee will not be able to
          complete this Course. A new Course that is similar or identical can
          still be assigned.
        </span>

        <EmployeeDropdown
          limit={removeableAssignees}
          currentValue={removedAssignees}
          onChange={values => {
            if (values.length < removeableAssignees.length) setRemoved(values);
          }}
          multi
          searchable
        />
      </Modal>
    </>
  );
}
