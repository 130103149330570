import React from 'react';
import './svg.css';

const aircraft = props => (
  <svg
    width={props.width ?? 30}
    height={props.height ?? 25}
    x={props.x || '0px'}
    y={props.y || '0px'}
    viewBox="0 0 80 80"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M58.2601 77.1941L65.239 70.2152C65.8 69.6531 66.0655 68.8607 65.9565 68.0743L60.3044 30.3207L73.1494 18.4604V18.4608C74.6304 17.0893 75.8216 15.4363 76.6544 13.5979C77.4872 11.7599 77.944 9.77371 77.9987 7.75659C78.029 6.47361 77.528 5.23558 76.6133 4.33527C75.699 3.43523 74.4529 2.95336 73.171 3.00385C71.1539 3.05821 69.1677 3.51536 67.3294 4.34776C65.4914 5.18053 63.838 6.37219 62.4668 7.85275L50.6066 20.6978L12.8958 14.9924C12.109 14.8833 11.317 15.1488 10.7549 15.7098L3.77596 22.6888H3.77562C3.33658 23.1111 3.06444 23.6776 3.01009 24.2843C2.95573 24.8913 3.12229 25.4973 3.47944 25.9906C3.83625 26.4844 4.35996 26.8321 4.95305 26.9704L37.269 35.0201L19.5107 54.2235L6.22685 53.9451C5.68153 53.9301 5.1575 54.1573 4.79614 54.566C4.43481 54.975 4.27313 55.5228 4.35502 56.0622C4.43725 56.6015 4.75399 57.0768 5.22055 57.3598L16.5884 64.3283L23.5569 75.6961C23.828 76.1815 24.3043 76.5181 24.8524 76.6125C25.4005 76.7066 25.9615 76.548 26.3792 76.1808C26.797 75.8135 27.0263 75.2776 27.0037 74.7219L26.7252 61.4701L45.9286 43.6798L54.0208 76.0385H54.0212C54.1693 76.618 54.5181 77.126 55.0055 77.4723C55.493 77.8187 56.0878 77.981 56.6836 77.9302C57.2798 77.8793 57.8383 77.6183 58.2599 77.1943L58.2601 77.1941Z" />
  </svg>
);

export default aircraft;
