import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import customToast from '../../../utils/customToast';
import { Button } from '../../../components/inputs';
import Dashboard from '../Dashboard';
import Header from '../../../components/Header';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import Leaderboard from '../Leaderboard';
import LeftNav from '../../../components/LeftNav';
import { SaveCancelFooter } from '../../../components/Footer';
import { SaveChangesModal } from '../../../components/Modal/saveChangesModal';
import MissingRequiredModal from '../../../components/Modal/missingRequiredModal';
import plusSign from '../../../assets/images/newProject-white.png';
import history from '../../../history';
import { createDashboard, createLeaderboard } from '../../../api/v4';

import styles from '../leaderboardStyles.module.scss';

export default function CreateLeaderboard() {
  const [dashboard, setDashboard] = useState({});

  const [hasError, setHasError] = useState(null);
  const [leaderboards, setLeaderboards] = useState([]);
  const [leftNavItems, setLeftNavItems] = useState([]);
  const [showAddButton, setShowAddButton] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [showSaveChangesModal, setShowSaveChangesModal] = useState(false);
  const [missingRequired, setMissingRequired] = useState(false);
  const [missingModalOpen, setMissingModalOpen] = useState(false);

  const constantLeftNavItems = [
    { value: '0', label: 'Dashboard' },
    { value: '1', label: 'Permissions' }
  ];
  const { dashboardId } = useParams();

  useEffect(() => {
    if (dashboardId) {
      setLeaderboards([
        {
          displayContent: null,
          category: '',
          subCategory: ''
        }
      ]);
    }
  }, [dashboardId]);

  const checkForCompleteLeaderBoards = () => {
    let valid = leaderboards?.every(
      l => l.displayContent && l.category && l.subCategory
    );
    return valid ?? false;
  };

  const canSubmit =
    !hasError && leaderboards?.length > 0 && checkForCompleteLeaderBoards();

  const addLeaderboard = () => {
    let obj = {
      displayContent: null,
      category: '',
      subCategory: ''
    };
    let updated = [...leaderboards, obj];
    setLeaderboards(updated);
    let index = updated?.length;
    leftNavItems.push({
      value: `Leaderboard ${index}`,
      label: `Leaderboard ${index}`,
      id: `Leaderboard ${index}`
    });
  };

  const handleGoBack = () => {
    hasChanges ? setShowSaveChangesModal(true) : history.goBack();
  };

  const handleSubmit = () => {
    const data = {
      dashboard: {
        name: dashboard.title,
        shares: dashboard.shares,
        type: 'leaderboard'
      },
      leaderboards
    };
    createDashboard(data).then(() => history.push('/app/leaderboards'));
  };

  const handleCreateLeaderboard = () => {
    createLeaderboard({
      clone: false,
      dashboardId: dashboardId,
      leaderboard: leaderboards[0]
    }).then(() => {
      customToast('Successfully Created!', 'success');
      history.goBack();
    });
  };

  const header = (
    <Header
      title="Leaderboard"
      section="Create Leaderboard"
      clickBack={handleGoBack}
      needsSaved={hasChanges}
    />
  );

  const footer = (
    <SaveCancelFooter
      cancelButtonClick={() => handleGoBack()}
      saveButtonClick={() =>
        missingRequired
          ? setMissingModalOpen(true)
          : dashboardId
            ? handleCreateLeaderboard()
            : handleSubmit()
      }
      cancelButtonText="Discard"
      saveButtonText="Create"
      onMouseEnter={() => setMissingRequired(!canSubmit)}
    />
  );
  return (
    <HeaderAndFooter
      Header={header}
      Left={
        dashboardId ? null : (
          <LeftNav items={[...constantLeftNavItems, ...leftNavItems]} />
        )
      }
      Footer={footer}
    >
      {!dashboardId && (
        <Dashboard
          onChange={value => {
            setDashboard({ ...dashboard, ...value });
            setHasChanges(true);
          }}
          title={dashboard?.title ?? ''}
          shares={dashboard?.shares ?? []}
          setHasError={value => setHasError(value)}
          setShowAddButton={value => setShowAddButton(value)}
          missingRequired={missingRequired}
        />
      )}
      <Leaderboard
        leaderboards={leaderboards ?? []}
        onChange={values => {
          setLeaderboards(values);
          setHasChanges(true);
        }}
        onLeftNavChange={values => setLeftNavItems(values)}
        missingRequired={missingRequired}
      />
      {showAddButton && (
        <Button
          color="blue"
          onClick={() => addLeaderboard()}
          text="Leaderboard Card"
          image={plusSign}
          imageClass={styles.image}
          inputClassName={styles.addButton}
        />
      )}

      <SaveChangesModal
        savingWhat="Leaderboard"
        isOpen={showSaveChangesModal}
        onRequestClose={() => setShowSaveChangesModal(false)}
        submitActions={() => history.goBack()}
      />
      <MissingRequiredModal
        isOpen={missingModalOpen}
        onRequestClose={() => setMissingModalOpen(false)}
        subject="Leaderboard"
      />
    </HeaderAndFooter>
  );
}
