import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { fetchPerformTraining, submitQuiz } from '../../../api/v4';
import history from '../../../history';
import Card from '../../../components/Card';
import Header from '../../../components/Header';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import QuizCard from '../../../components/training/QuizCard';
import { SaveCancelFooter } from '../../../components/Footer';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';
import Input from '../../../components/inputs';
import styles from './styles.module.scss';
import LeftNav from '../../../components/LeftNav';
import { getLoggedInUser } from '../../../selectors/users.js';
import { useSelector } from 'react-redux';
import MissingRequiredModal from '../../../components/Modal/missingRequiredModal.js';

export default function QuizViewer() {
  const location = useLocation();
  const { passedQuiz, isPreview, trainingId, showAnswers, hideCheckmark } =
    location.state;
  const user = useSelector(getLoggedInUser);
  const [training, setTraining] = useState({});
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [unsavedModal, setUnsavedModal] = useState(false);
  const [quizQuestions, setQuestions] = useState([]);
  const [missingRequired, setMissingRequired] = useState(false);
  const [missingModalOpen, setMissingModalOpen] = useState(false);

  useEffect(() => {
    if (!isPreview && trainingId) {
      fetchPerformTraining(trainingId, user._id).then(setTraining);
    }

    setQuestions(
      passedQuiz?.attemptNumber === 1 || isPreview
        ? [...passedQuiz?.questions]
        : [...passedQuiz?.questions]?.sort(() => Math.random() - 0.5)
    );
  }, [isPreview, trainingId, user, passedQuiz]);

  const handleSubmit = () => {
    let correctOrderQuestions = [...quizQuestions];
    if (passedQuiz?.attemptNumber > 1) {
      correctOrderQuestions = passedQuiz?.questions?.map(q => {
        const answer = quizQuestions?.find(
          qq => q._id === qq._id
        )?.assigneeSelectedAnswers;
        return { ...q, assigneeSelectedAnswers: answer };
      });
    }

    submitQuiz({
      ...training,
      quiz: { ...passedQuiz, questions: correctOrderQuestions }
    }).then(history.goBack);
  };

  let leftNav = [{ label: passedQuiz?.title, id: '0' }].concat(
    quizQuestions?.map((q, index) => ({
      label: `Question ${index + 1}`,
      id: index + 1
    })) ?? []
  );

  const canSubmit = quizQuestions?.every(
    q => q.assigneeSelectedAnswers?.length
  );

  const header = (
    <Header
      title="Training Quiz"
      section={passedQuiz?.title}
      needsSaved={unsavedChanges}
      clickBack={() =>
        unsavedChanges ? setUnsavedModal(true) : history.goBack()
      }
    />
  );

  const footer = (
    <SaveCancelFooter
      saveButtonClick={() =>
        canSubmit ? handleSubmit() : setMissingModalOpen(true)
      }
      saveButtonText="Submit Quiz"
      cancelButtonClick={() =>
        unsavedChanges ? setUnsavedModal(true) : history.goBack()
      }
      onMouseEnter={() => setMissingRequired(!canSubmit)}
    />
  );

  const percentToPass =
    passedQuiz?.percentToPass ??
    (passedQuiz?.numberOfQuestionsCorrectNeededToPass /
      passedQuiz?.questions?.length) *
      100;

  const currentAttempt =
    isPreview || passedQuiz?.attemptNumber > passedQuiz?.numberOfRetriesAllowed
      ? null
      : `${passedQuiz?.attemptNumber}/${
          passedQuiz?.numberOfRetriesAllowed ?? 1
        }`;

  return (
    <>
      <HeaderAndFooter
        Header={header}
        showFooter={!isPreview}
        Footer={footer}
        Left={<LeftNav items={leftNav} />}
      >
        <Card
          title={passedQuiz?.title}
          showHeader
          className={styles.quizCard}
          border
          name="0"
        >
          <Input fieldLabel="Number of Questions" />
          <span className={styles.quizAnswers}>
            {passedQuiz?.questions?.length}
          </span>
          <Input fieldLabel="Score to Pass" />
          <span className={styles.quizAnswers}>{percentToPass}%</span>
          {currentAttempt ? (
            <>
              <Input fieldLabel="Current Attempt" />
              <span className={styles.quizAnswers}>{currentAttempt}</span>
            </>
          ) : null}
        </Card>
        {quizQuestions?.map((q, index) => (
          <QuizCard
            handleQuizAnswers={question => {
              const newQuestions = [...quizQuestions];
              newQuestions[index] = question;
              setQuestions(newQuestions);
              setUnsavedChanges(!isPreview && true);
            }}
            question={q}
            questionNumber={index}
            key={index}
            name={index + 1}
            isRetaking={
              passedQuiz?.attemptNumber < passedQuiz?.numberOfRetriesAllowed
            }
            isPreview={isPreview}
            showAnswers={showAnswers}
            hideCheckmark={hideCheckmark}
            missingRequired={missingRequired}
          />
        ))}
      </HeaderAndFooter>
      <SaveChangesModal
        isOpen={unsavedModal}
        savingWhat="Quiz"
        onRequestClose={() => setUnsavedModal(false)}
        submitActions={history.goBack}
      />
      <MissingRequiredModal
        isOpen={missingModalOpen}
        onRequestClose={() => setMissingModalOpen(false)}
        subject="Quiz"
        isSubmitting
      />
    </>
  );
}
