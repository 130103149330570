import React from 'react';
import './svg.css';

const courses = props => (
  <svg
    width={props.width ?? 30}
    height={props.height ?? 25}
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1252_13386)">
      <path d="M14.2856 0H5.71424C4.19996 0.00416667 2.74908 0.607736 1.67854 1.67823C0.60769 2.74908 0.00441945 4.19996 4.40875e-07 5.71424V72.97C-0.000594754 74.4855 0.601481 75.9394 1.6732 77.0111C2.74489 78.0827 4.19852 78.6846 5.71424 78.6843H14.2856C15.8014 78.6846 17.2549 78.0828 18.3266 77.0111C19.3983 75.9394 20.0004 74.4855 19.9998 72.97V5.71424C19.9954 4.19996 19.3921 2.74908 18.3213 1.67823C17.2507 0.60769 15.7999 0.00411425 14.2856 0ZM14.2856 25.7141H5.71424V19.9998H14.2856V25.7141Z" />
      <path d="M25.7141 5.71668V72.9839V72.9836C25.7141 74.4991 26.3161 75.9526 27.3879 77.0243C28.4593 78.0957 29.9129 78.6978 31.4283 78.6978H39.9997C41.5151 78.6978 42.9687 78.0958 44.0401 77.0243C45.1118 75.9526 45.7139 74.499 45.7139 72.9836V23.1067L58.7248 76.0548C59.1939 77.4959 60.2162 78.6917 61.5671 79.3789C62.9177 80.0661 64.4861 80.1884 65.9272 79.7191L76.0528 76.4233C77.4939 75.9539 78.6894 74.9313 79.3766 73.5807C80.0638 72.2299 80.1861 70.6614 79.7168 69.2203L65.0312 11.1264C64.5618 9.68532 63.5392 8.48983 62.1886 7.80229C60.8378 7.1151 59.2693 6.99247 57.8283 7.46151L47.7034 10.7579C46.9707 10.996 46.2936 11.3803 45.7139 11.8874V5.71631C45.7139 4.20082 45.1118 2.74727 44.0404 1.67558C42.9687 0.603898 41.5151 0.0020752 39.9996 0.0020752H31.4283C29.9128 0.0020752 28.4592 0.603853 27.3876 1.67558C26.3162 2.74727 25.7141 4.2009 25.7141 5.71631L25.7141 5.71668Z" />
    </g>
    <defs>
      <clipPath id="clip0_1252_13386">
        <rect width="80" height="80" />
      </clipPath>
    </defs>
  </svg>
);

export default courses;
