import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { fetchCompanyUsers, getJobList } from '../../../../../api/v4';
import {
  FREQUENCY_OPTIONS,
  MONTHLY_FREQUENCY_OPTIONS,
  RECURRING_OPTIONS,
  YEARLY_FREQUENCY_OPTIONS
} from '../../../../../constants/constants';
import { isFutureDate } from '../../../../../utils/formValidation';
import {
  canSubmitImmediateSchedule,
  canSubmitRepeatingSchedule,
  canSubmitSodSchedule,
  getValidAssignees
} from '../../../../../utils/trainingHelper';

import {
  Button,
  Checkbox,
  Dropdown,
  EmployeeDropdown,
  TwoColumn
} from '../../../../inputs';
import Card from '../../../../Card';
import { DayOfWeekButtons } from '../../../../inputs/DayOfWeekButtons';
import HierarchySelector from '../../../../HierarchySelector';
import { IReportDatePicker } from '../../../../inputs/DateTimePicker';
import RadioButtons from '../../../../inputs/RadioButtons';

import styles from '../scheduleCard.module.scss';

export default function ScheduleDigitalCard(props) {
  const {
    training,
    groupDropdown,
    onAnswer,
    setCanSubmit,
    disabled,
    company,
    hideWarnings = false,
    editingAssignees,
    fromView,
    accessLevel,
    missingRequired
  } = props;
  const [users, setUsers] = useState([]);
  const [jobList, setJobList] = useState([]);

  const isRepeating = training?.trainingType === 1 && training?.schedule === 2;

  useEffect(() => {
    if (training.assignBy === 1) {
      getJobList().then(r => {
        setJobList(r?.map(job => ({ value: job, label: job })));
      });
    }
    fetchCompanyUsers().then(setUsers);
  }, [training.assignBy]);

  const futureDateWarning = isFutureDate(moment(training.dueDate).endOf('day'));

  const setSubmit = useCallback(() => {
    if (!setCanSubmit) return;
    if (training.schedule === 1)
      setCanSubmit(canSubmitImmediateSchedule(training));
    else if (training.schedule === 2)
      setCanSubmit(canSubmitRepeatingSchedule(training));
    else return setCanSubmit(canSubmitSodSchedule(training, accessLevel));
  }, [setCanSubmit, training, accessLevel]);

  useEffect(() => {
    setSubmit();
  }, [training, setSubmit]);

  const renderSOD = () => {
    const handleGroupChange = values => {
      const validProjectIds = company.groups
        .map(g => {
          if (values.includes(g._id)) {
            return g.projects.map(p => p._id);
          } else {
            return [];
          }
        })
        .reduce((acc, x) => acc.concat(x), []);

      const newProjectIds = training.projectIds?.filter(p =>
        validProjectIds.includes(p)
      );
      const validUsers = training.assignedTo?.filter(user =>
        users.find(
          u =>
            u._id.toString() === user &&
            u.groupIds.some(g => values.includes(g))
        )
      );
      onAnswer({
        groupIds: values,
        projectIds: newProjectIds,
        assignedTo: values?.length ? validUsers : training.assignedTo
      });
    };

    return (
      <>
        <HierarchySelector
          multi
          groupRequired={accessLevel < 900}
          groupId={training.groupIds}
          projectId={training.projectIds}
          onGroupChange={handleGroupChange}
          onProjectChange={v => onAnswer({ projectIds: v })}
          groupDisabled={disabled}
          projectDisabled={!training.groupIds?.length || disabled}
          groupTouched={missingRequired && !training?.groupIds?.length}
        />

        <Dropdown
          fieldLabel="Due By"
          isRequired
          shrink
          options={RECURRING_OPTIONS}
          currentValue={training?.dueBy}
          onChange={v => onAnswer({ dueBy: v })}
          disabled={disabled}
          touched={missingRequired && !training?.dueBy}
        />
      </>
    );
  };

  const renderRepeatingDueDateOptions = () => (
    <>
      <Dropdown
        options={FREQUENCY_OPTIONS}
        fieldLabel="Frequency"
        currentValue={training?.frequency}
        onChange={value => onAnswer({ frequency: value, repeatingOn: [] })}
        isRequired
        disabled={disabled}
        touched={missingRequired && !training?.frequency}
      />
      {training?.frequency === 'weekly' ? (
        <DayOfWeekButtons
          isRequired
          fieldLabel="Assign Every:"
          onChange={values => onAnswer({ repeatingOn: values })}
          currentValue={training?.repeatingOn}
          disabled={disabled}
          touched={missingRequired && !training?.repeatingOn?.length}
        />
      ) : training?.frequency === 'monthly' ? (
        <Dropdown
          options={MONTHLY_FREQUENCY_OPTIONS}
          fieldLabel="Assign Every:"
          currentValue={training?.repeatingOn}
          onChange={values => onAnswer({ repeatingOn: values })}
          isRequired
          disabled={disabled}
          touched={missingRequired && !training?.repeatingOn?.length}
          initialTypeArray
        />
      ) : training?.frequency === 'yearly' ? (
        <Dropdown
          options={YEARLY_FREQUENCY_OPTIONS}
          fieldLabel="Assign Every:"
          currentValue={training?.repeatingOn}
          onChange={values => onAnswer({ repeatingOn: values })}
          isRequired
          disabled={disabled}
          touched={missingRequired && !training?.repeatingOn?.length}
          initialTypeArray
        />
      ) : null}
      <Dropdown
        fieldLabel="Due By"
        isRequired
        shrink
        options={RECURRING_OPTIONS}
        currentValue={training?.dueBy}
        onChange={v => onAnswer({ dueBy: v })}
        disabled={disabled}
        touched={missingRequired && !training?.dueBy}
      />
    </>
  );

  const renderByJobTitle = () => {
    const groupsSelected = training?.groupAssigneePairs?.map(g => g.groupId);
    return disabled && !isRepeating ? (
      <>
        <HierarchySelector
          groupId={training?.groupIds}
          filteredGroupDropdown={groupDropdown}
          groupDisabled
          showProject={false}
          noTwoColumn
          alwaysShowGroupDropdown
        />
        <Dropdown
          selectButtons
          fieldLabel="Job Title(s)"
          options={jobList}
          searchable
          currentValue={training?.assignByJobTitles}
          isRequired
          multi
          disabled
        />
      </>
    ) : (
      <>
        {training?.schedule === 2 ? null : (
          <div className={styles.adminContainer}>
            <span className={styles.adminInfo}>
              One training will be created for each group/establishment selected
            </span>
          </div>
        )}
        {training.groupAssigneePairs?.map((group, index) => {
          return (
            <TwoColumn className={styles.specialGrid}>
              <TwoColumn>
                <HierarchySelector
                  groupId={group?.groupId}
                  filteredGroupDropdown={groupDropdown}
                  onGroupChange={groupId => {
                    let newGroup = {
                      ...training.groupAssigneePairs[index],
                      groupId
                    };
                    training.groupAssigneePairs[index] = newGroup;
                    onAnswer({
                      groupAssigneePairs: training.groupAssigneePairs
                    });
                  }}
                  groupDisabled={disabled || training.equipmentId}
                  showProject={false}
                  noTwoColumn
                  groupTouched={missingRequired && !group?.groupId}
                  excludeGroups={groupsSelected?.filter(
                    g => g !== group.groupId
                  )}
                  alwaysShowGroupDropdown
                />

                <Dropdown
                  selectButtons
                  fieldLabel="Job Title(s)"
                  options={jobList}
                  searchable
                  onChange={jobTitles => {
                    let newTitles = {
                      ...training.groupAssigneePairs[index],
                      jobTitles
                    };
                    training.groupAssigneePairs[index] = { ...newTitles };
                    onAnswer({
                      groupAssigneePairs: training.groupAssigneePairs
                    });
                  }}
                  currentValue={group.jobTitles}
                  isRequired
                  multi
                  disabled={disabled}
                  touched={missingRequired && !group?.jobTitles?.length}
                />
              </TwoColumn>
              {training.groupAssigneePairs?.length > 1 && (
                <img
                  type="button"
                  src={require('../../../../../assets/images/remove.png')}
                  alt="delete group"
                  onClick={() => {
                    training.groupAssigneePairs.splice(index, 1);
                    onAnswer({
                      groupAssigneePairs: training.groupAssigneePairs
                    });
                  }}
                  className={styles.removeImage}
                  data-cy="removeButton"
                />
              )}
            </TwoColumn>
          );
        })}
        {training?.schedule === 2 ? null : (
          <Button
            text="Add Group"
            color={
              missingRequired && !training?.groupAssigneePairs?.length
                ? 'red'
                : 'blue'
            }
            onClick={() =>
              onAnswer({
                groupAssigneePairs: (training.groupAssigneePairs ?? []).concat(
                  {}
                )
              })
            }
            inputClassName={styles.button}
            disabled={disabled}
          />
        )}
      </>
    );
  };

  const renderByEmployee = () => {
    const groupsSelected = training?.groupAssigneePairs?.map(g => g.groupId);
    return disabled ? (
      <>
        <HierarchySelector
          groupId={training?.groupIds}
          filteredGroupDropdown={groupDropdown}
          groupDisabled
          showProject={false}
          noTwoColumn
          alwaysShowGroupDropdown
        />
        <EmployeeDropdown
          permissions={[100, 400, 500, 900]}
          fieldLabel="Assignee(s)"
          currentValue={training?.assignedTo}
          searchable
          name="dropdownEmployees"
          isRequired
          multi
          disabled
        />
      </>
    ) : (
      <>
        {training?.schedule === 2 ? null : (
          <div className={styles.adminContainer}>
            <span className={styles.adminInfo}>
              One training will be created for each group/establishment selected
            </span>
          </div>
        )}
        {training.groupAssigneePairs?.map((group, index) => {
          return (
            <TwoColumn className={styles.specialGrid}>
              <TwoColumn>
                <HierarchySelector
                  groupId={group?.groupId ?? group?.groupIds}
                  filteredGroupDropdown={groupDropdown}
                  onGroupChange={groupId => {
                    let newGroup = {
                      ...training.groupAssigneePairs[index],
                      groupId
                    };
                    newGroup.assignees = getValidAssignees(newGroup, users);
                    training.groupAssigneePairs[index] = newGroup;
                    onAnswer({
                      groupAssigneePairs: training.groupAssigneePairs
                    });
                  }}
                  groupDisabled={disabled}
                  showProject={false}
                  noTwoColumn
                  groupTouched={missingRequired && !group?.groupId}
                  excludeGroups={groupsSelected?.filter(
                    g => g !== group.groupId
                  )}
                  alwaysShowGroupDropdown
                />

                <EmployeeDropdown
                  permissions={[100, 400, 500, 900]}
                  fieldLabel="Assignee(s)"
                  currentValue={group.assignees}
                  onChange={assignees => {
                    let newAdmin = {
                      ...training.groupAssigneePairs[index],
                      assignees
                    };
                    training.groupAssigneePairs[index] = { ...newAdmin };
                    onAnswer({
                      groupAssigneePairs: training.groupAssigneePairs
                    });
                  }}
                  searchable
                  name="dropdownEmployees"
                  isRequired
                  multi
                  disabled={disabled}
                  touched={missingRequired && !group?.assignees?.length}
                  group={group?.groupId}
                  selectButtons
                />
              </TwoColumn>
              {training.groupAssigneePairs?.length > 1 && (
                <img
                  type="button"
                  src={require('../../../../../assets/images/remove.png')}
                  alt="delete group"
                  onClick={() => {
                    training.groupAssigneePairs.splice(index, 1);
                    onAnswer({
                      groupAssigneePairs: training.groupAssigneePairs
                    });
                  }}
                  className={styles.removeImage}
                  data-cy="removeButton"
                />
              )}
            </TwoColumn>
          );
        })}
        {training?.schedule === 2 ? null : (
          <Button
            text="Add Group"
            color={
              missingRequired && !training?.groupAssigneePairs?.length
                ? 'red'
                : 'blue'
            }
            onClick={() =>
              onAnswer({
                groupAssigneePairs: (training.groupAssigneePairs ?? []).concat(
                  {}
                )
              })
            }
            inputClassName={styles.button}
            disabled={disabled}
          />
        )}
      </>
    );
  };

  return (
    <Card showHeader title="Schedule" name="schedule">
      {training?.schedule === 3 ? (
        renderSOD()
      ) : (
        <>
          <RadioButtons
            options={[
              { value: 1, label: 'Job Title' },
              { value: 2, label: 'Employee' }
            ]}
            currentValue={
              training.assignedTo?.length && !training.assignBy
                ? 2
                : training.assignBy
            }
            onChange={v => {
              onAnswer({
                assignBy: v,
                groupAssigneePairs: training?.schedule === 1 ? [] : [{}]
              });
            }}
            fieldLabel="Assign By"
            needSeparateLines
            isRequired
            disabled={disabled}
            touched={missingRequired && !training.assignBy}
          />
          {training?.assignBy
            ? training?.assignBy === 1
              ? renderByJobTitle()
              : renderByEmployee()
            : null}
          {training.schedule === 1 ? (
            <>
              <IReportDatePicker
                fieldLabel="Assign On"
                isRequired
                onChange={v =>
                  onAnswer({
                    assignOn: v,
                    dueDate: moment(v).isAfter(training.dueDate)
                      ? null
                      : training.dueDate
                  })
                }
                currentValue={training.assignOn}
                touched={missingRequired && !training.assignOn}
                pickDate
                timeNotDetermined
                name="assignOn"
                disabled={disabled}
                minDate={new Date()}
              />
              <IReportDatePicker
                fieldLabel="Due By"
                isRequired
                onChange={v => onAnswer({ dueDate: v })}
                currentValue={training.dueDate}
                touched={
                  !hideWarnings &&
                  futureDateWarning &&
                  missingRequired &&
                  training.assignOn &&
                  !training.dueDate
                }
                name="dueBy"
                disabled={(disabled || !training.assignOn) && !editingAssignees}
                minDate={new Date(moment(training.assignOn).add(1, 'days'))}
              />
            </>
          ) : (
            renderRepeatingDueDateOptions()
          )}
          {fromView && training.schedule === 2 ? (
            <Checkbox
              fieldLabel="Pause Training"
              name="pauseTraining"
              onChange={values => onAnswer({ isPaused: values })}
              currentValue={training?.isPaused}
              disabled={disabled}
            />
          ) : null}
        </>
      )}
    </Card>
  );
}
