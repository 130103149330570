import React, { Component } from 'react';

import classnames from 'classnames/bind';
import AddButton from '../../components/inputs/AddButton';

import styles from './leftNav.module.scss';
const bStyles = classnames.bind(styles);

export class LeftNav extends Component {
  #element = null;

  state = { selectedSection: null };

  componentDidMount() {
    this.startWatching();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.items !== this.props.items) {
      this.stopWatching();
      this.startWatching();
    }
  }

  componentWillUnmount() {
    this.stopWatching();
  }

  stopWatching = () => {
    if (this.element) {
      this.element.removeEventListener('scroll', this.onScroll);
    }
  };

  startWatching = () => {
    let item = (this.props.items || []).find(i => !i.removed);
    if (!item) return;

    this.element = document.getElementById(item.id || 0)?.parentNode;
    if (!this.element) return;
    this.element.addEventListener('scroll', this.onScroll);
    this.onScroll();
  };

  onScroll = e => {
    let distance = Number.POSITIVE_INFINITY;
    let targetId = null;

    (this.props.items || []).forEach((item, i) => {
      let id = item.id || i;
      const element = document.getElementById(id);
      if (!element) return;

      let d = Math.abs(
        element.offsetTop - this.element.offsetTop - this.element.scrollTop
      );
      if (d < distance) {
        distance = d;
        targetId = id;
      }
    });

    if (targetId !== null && targetId !== this.state.selectedSection) {
      this.setState({
        selectedSection: targetId
      });
    }
  };

  scrollIntoView = idToScroll => {
    this.setState({
      selectedSection: idToScroll
    });
    const element = document.getElementById(idToScroll);

    /*
    This is buggy.
    But it might be able to be fixed at some point.
    So it is left here for that eventuality.
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start'
    });
    */
    if (element && element.parentNode) {
      element.parentNode.scrollTop =
        element?.offsetTop - element?.parentNode?.offsetTop;
    }
  };

  renderItem = (item, i) => {
    let id = item.id || i;
    let classes = {
      selected: this.state.selectedSection === id,
      completed: item.complete,
      removed: item.removed,
      hide: item.hide
    };
    return (
      <li
        className={bStyles(classes)}
        onClick={() => this.scrollIntoView(id)}
        key={id}
      >
        {item.label}
      </li>
    );
  };

  render() {
    const {
      onAddBravos,
      onAddEvidence,
      onAddHazard,
      onAddRootCause,
      showAddEvidence,
      showAddRootCause,
      onAddRtwForm,
      showAddRtwForm,
      showSwButton
    } = this.props;
    let items = this.props.items || [];
    const removed = this.props.removed || false;
    const removedItems = items.filter(i => i.removed);
    items = items.filter(i => !i.removed);

    return (
      <div className={styles.leftNav}>
        <ul className={styles.quickNav}>
          {items.map(this.renderItem)}
          {removed && removedItems.length ? (
            <>
              <li className={styles.removedHeader}>Removed</li>
              {removedItems.map(this.renderItem)}
            </>
          ) : (
            <></>
          )}
        </ul>
        {showAddEvidence ? (
          <>
            <AddButton
              text="Add Evidence"
              onClick={() => document.getElementById(`myDoc`).click()}
            />
            <input type="file" id="myDoc" onChange={onAddEvidence} multiple />
          </>
        ) : showAddRootCause ? (
          <AddButton text="Add Root Cause" onClick={onAddRootCause} />
        ) : null}
        {showSwButton ? (
          <AddButton text="Add Hazard" onClick={onAddHazard} />
        ) : null}
        {showSwButton ? (
          <AddButton text="Add Bravo" onClick={onAddBravos} />
        ) : null}
        {showAddRtwForm ? (
          <AddButton text="Add Form" onClick={onAddRtwForm} />
        ) : null}
      </div>
    );
  }
}

export default LeftNav;
