import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { isEqual } from 'lodash';
import history from '../../../history';
import {
  fetchAnnouncementList,
  resendAnnouncementNotification,
  updateAnnouncement,
  archiveMany,
  fetchUserListFilter,
  updateUserListFilter
} from '../../../api/v4';
import { isFutureDate } from '../../../utils/formValidation';
import { usePrevious } from '../../../utils/hooks';
import { getLoggedInUser } from '../../../selectors/users';
import Header from '../../../components/Header';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import List from '../../../components/List';
import TypeSelection from '../../../components/TypeSelection';
import Modal from '../../../components/Modal';
import { IReportDatePicker } from '../../../components/inputs/DateTimePicker';
import { Dropdown } from '../../../components/inputs';
import AnalyticsPeriodPicker from '../../../components/AnalyticsPeriodPicker';

export default function AnnouncementList() {
  const [list, setList] = useState([]);
  const [listType, setListType] = useState('Active');
  const [openExtendShowUntilModal, setOpenExtendShowUntilModal] =
    useState(false);
  const [announcement, setAnnouncement] = useState();
  const [empPeriod, setEmpPeriod] = useState();
  const [currentPeriod, setCurrentPeriod] = useState();
  const [missingRequired, setMissingRequired] = useState();
  const user = useSelector(getLoggedInUser);
  const isAdmin = user.accessLevel === 900;
  const prevPeriod = usePrevious(currentPeriod);
  const prevType = usePrevious(listType);

  useEffect(() => {
    fetchUserListFilter(user._id, 'announcement').then(r => {
      setCurrentPeriod(r);
      setEmpPeriod(r);
    });
  }, [user._id]);

  useEffect(() => {
    if (
      currentPeriod &&
      (!isEqual(prevPeriod, currentPeriod) || !isEqual(prevType, listType))
    )
      fetchAnnouncementList({ ...currentPeriod, selectedType: listType }).then(
        setList
      );
  }, [currentPeriod, prevPeriod, listType, prevType]);

  const header = (
    <Header
      title="Announcements"
      rightButtons={
        isAdmin
          ? {
              text: 'Create Announcement',
              color: 'blue',
              onClick: () => history.push(`/app/announcement/container`)
            }
          : null
      }
    />
  );

  const handleResend = r => {
    const announcementToCheck = list.find(a => r === a._id);

    if (moment(announcementToCheck.showUntilDate) < moment()) {
      setOpenExtendShowUntilModal(true);
      if (announcementToCheck.showUntil === 'custom') {
        setAnnouncement(announcementToCheck);
      } else {
        setAnnouncement({
          ...announcementToCheck,
          showUntilDate: moment(announcementToCheck?.showUntilDate).add(
            1,
            announcementToCheck?.showUntil
          )
        });
      }
    } else {
      resendAnnouncementNotification(r).then(() =>
        fetchAnnouncementList().then(setList)
      );
    }
  };

  const handleAnnouncementDuration = value => {
    if (value !== 'custom') {
      setAnnouncement({
        ...announcement,
        showUntilDate: moment().add(1, value),
        showUntil: value
      });
    } else {
      setAnnouncement({
        ...announcement,
        showUntil: value,
        showUntilDate: null
      });
    }
  };

  const handleArchive = ids => {
    if (!ids.length) return;
    archiveMany({
      type: 'announcement',
      ids: ids,
      isArchived: listType === 'Active'
    }).then(() =>
      fetchAnnouncementList({ ...currentPeriod, selectedType: listType }).then(
        setList
      )
    );
  };

  const columns = [
    {
      key: 'title'
    },
    {
      key: 'message'
    },
    {
      label: 'Sent To',
      key: 'sendToChoice',
      enum: [
        { label: 'Company', value: 'company' },
        { label: 'Groups', value: 'groups' },
        { label: 'Employees', value: 'employees' }
      ]
    },
    { label: 'Created At', key: 'createdAt', datatype: 'date' },
    { key: 'lastSentOn', datatype: 'date' }
  ];

  if (isAdmin && listType !== 'Archived')
    columns.push({
      label: 'Resend',
      key: 'resend',
      datatype: 'button',
      accessor: row => row?._id,
      onclick: r => handleResend(r)
    });

  const canExtendAnnouncement =
    (announcement?.showUntilDate !== null &&
      moment(announcement?.showUntilDate) > moment() &&
      announcement?.showUntil === 'custom') ||
    announcement?.showUntil !== 'custom';

  return (
    <>
      <HeaderAndFooter Header={header}>
        {user.accessLevel > 400 ? (
          <TypeSelection
            selectedArray={['Active', 'Archived']}
            onClick={setListType}
            selected={listType}
          />
        ) : null}
        <AnalyticsPeriodPicker
          period={currentPeriod}
          onChange={v => {
            setList([]);
            setCurrentPeriod(v);
          }}
          onSave={v =>
            updateUserListFilter({
              empId: user._id,
              type: 'announcement',
              period: v
            }).then(() => setEmpPeriod(v))
          }
          allowAll
          savedPeriod={empPeriod}
          listPicker
        />
        <List
          settings={columns}
          data={list}
          dataIsHash
          saveKey={
            listType === 'Active' ? 'announcement' : 'archivedAnnouncement'
          }
          rowClick={row =>
            history.push(`/app/announcement/container/${row._id}`)
          }
          getRowId={r => r._id}
          actions={
            user.accessLevel > 400
              ? [
                  {
                    color: 'blue',
                    label: `${
                      listType === 'Archived' ? 'Un-' : ''
                    }Archive Announcements`,
                    onClick: handleArchive
                  }
                ]
              : null
          }
        />
      </HeaderAndFooter>
      <Modal
        isOpen={openExtendShowUntilModal}
        title="Resend Announcement?"
        titleClassName="blueHeader"
        submitButtonColor="blue"
        submitButtonText="Send"
        cancelButtonText="Close"
        onRequestClose={() => setOpenExtendShowUntilModal(false)}
        submitActions={() => {
          if (!canExtendAnnouncement) return;
          updateAnnouncement({
            ...announcement,
            showUntilDate: moment().add(1, announcement.showUntil)
          }).then(a =>
            resendAnnouncementNotification(a._id).then(() =>
              fetchAnnouncementList().then(setList)
            )
          );
          setOpenExtendShowUntilModal(false);
        }}
        tall={announcement?.showUntil === 'custom'}
        submitTooltip={
          !canExtendAnnouncement ? 'Missing or Invalid Field' : null
        }
        onMouseEnter={() => setMissingRequired(!canExtendAnnouncement)}
      >
        This announcement is expired.
        <p />
        <Dropdown
          options={[
            { label: 'A Day', value: 'days' },
            { label: 'A Week', value: 'weeks' },
            { label: 'A Month', value: 'months' },
            { label: 'A Year', value: 'years' },
            { label: 'Custom', value: 'custom' }
          ]}
          fieldLabel="Extend Announcement for:"
          onChange={handleAnnouncementDuration}
          currentValue={announcement?.showUntil}
          displayTooltip
          tooltip="This is how long the announcement will be visible in a user's announcement list."
        />
        {announcement?.showUntil === 'custom' ? (
          <IReportDatePicker
            onChange={v =>
              setAnnouncement({ ...announcement, showUntilDate: v })
            }
            currentValue={announcement?.showUntilDate}
            errorMessage={isFutureDate(announcement?.showUntilDate)}
            touched={
              (announcement?.showUntilDate && !canExtendAnnouncement) ||
              (missingRequired && !announcement?.showUntilDate)
            }
            name="customDate"
            fieldLabel="Show Announcement Until"
            numberOfMonths={1}
            minDate={new Date()}
          />
        ) : null}
      </Modal>
    </>
  );
}
