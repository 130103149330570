import React, { useEffect, useState } from 'react';
import moment from 'moment';
import history from '../../../history';
import {
  downloadUserTrainings,
  fetchEmployeeTrainings,
  sendTrainingReminder
} from '../../../api/v4';
import Header from '../../Header';
import List from '../../List';
import {
  TRAINING_CATEGORY_DROPDOWN,
  TRAINING_STATUSES
} from '../../../constants/constants';

export const SafetyTrainingList = ({
  trainings,
  addToFolder,
  fromEmployee,
  saveKey,
  userId,
  accessLevel,
  isArchivedList,
  handleArchive,
  loading
}) => {
  const [data, setData] = useState(trainings);
  useEffect(() => {
    if (fromEmployee) fetchEmployeeTrainings(userId, 'all').then(setData);
    else setData(trainings);
  }, [fromEmployee, userId, trainings]);

  const handleViewEdit = async template => {
    fromEmployee
      ? history.push({
          pathname: `/app/training/view/${template._id}`,
          state: {
            userId
          }
        })
      : history.push(`/app/training/approve/${template._id}`);
  };

  const columns = [{ key: 'title' }]
    .concat(
      !fromEmployee && !isArchivedList
        ? [
            {
              key: 'stage',
              label: 'Status',
              enum: TRAINING_STATUSES
            }
          ]
        : []
    )
    .concat([
      {
        key: 'category',
        label: 'Training Category',
        accessor: t =>
          TRAINING_CATEGORY_DROPDOWN.find(c => c.value === t.category)?.label ??
          '',
        enum: TRAINING_CATEGORY_DROPDOWN.map(c => c.label)
      },
      {
        key: 'trainingType',
        label: 'Format',
        accessor: t => (t.trainingType === 1 ? 'Digital' : 'In-Person'),
        enum: ['Digital', 'In-Person']
      },
      {
        key: 'dueDate',
        datatype: 'date',
        accessor: r =>
          r.dueDate ? moment(r.dueDate).format('MM/DD/YYYY') : null
      }
    ])

    .concat(
      !saveKey?.includes('equipment')
        ? [
            {
              key: 'admins',
              label: 'Training Administrator',
              datatype: 'user',
              accessor: r => r?.admins?.[0]?.adminId
            }
          ]
        : []
    )
    .concat([
      {
        key: 'completionDate',
        datatype: 'date',
        accessor: r =>
          (r.childCompletionDate ?? r.completionDate)
            ? moment(r.childCompletionDate ?? r.completionDate).format(
                'MM/DD/YYYY'
              )
            : null
      },
      {
        key: 'expirationDate',
        datatype: 'date',
        accessor: r =>
          r.expirationDate && r.doesExpire
            ? moment(r.expirationDate).format('MM/DD/YYYY')
            : 'Does Not Expire'
      },
      {
        key: 'assignedTo',
        label: 'Employees',
        datatype: 'users'
      }
    ])
    .concat(
      fromEmployee
        ? [
            {
              key: 'isArchived',
              label: 'Archived',
              accessor: t => (t.isArchived ? 'Yes' : 'No'),
              enum: ['Yes', 'No']
            }
          ]
        : []
    );

  return (
    <>
      {fromEmployee ? (
        <Header
          rightButtons={{
            text: 'Download Trainings',
            color: 'blue',
            onClick: () =>
              downloadUserTrainings(userId).then(response =>
                window.open(response, '_blank')
              ),
            visible: data?.length
          }}
          hideBoxShadow
        />
      ) : null}
      <List
        saveKey={saveKey ?? 'trainingList'}
        data={data}
        settings={columns}
        dataIsHash
        rowClick={handleViewEdit}
        getRowId={r => r._id}
        actions={[
          ...(accessLevel > 400
            ? (!isArchivedList
                ? [
                    {
                      label: 'Send Reminder',
                      color: 'blue',
                      onClick: ids => sendTrainingReminder({ trainingIds: ids })
                    }
                  ]
                : []
              ).concat([
                {
                  label: `${isArchivedList ? 'Un-' : ''}Archive Trainings`,
                  color: 'blue',
                  onClick: handleArchive
                }
              ])
            : []),
          ...(addToFolder
            ? [
                {
                  label: 'Add to Folder',
                  color: 'blue',
                  onClick: addToFolder
                }
              ]
            : [])
        ]}
        loading={loading}
      />
    </>
  );
};

export default SafetyTrainingList;
