import React from 'react';
import './svg.css';

const safetyAuditTemplates = props => (
  <svg
    width={props.width ?? 30}
    height={props.height ?? 25}
    viewBox="0 0 80 80"
    x={props.x || '0px'}
    y={props.y || '0px'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M68.5625 27.6802H78.0025V50.2402H68.5625V27.6802Z" />
    <path d="M76.1625 17.76H70.4026C69.3625 17.76 68.5625 18.4801 68.5625 19.36V24.7199H78.0025V19.36C78.0025 18.4801 77.2026 17.76 76.1625 17.76Z" />
    <path d="M71.0384 59.8401L72.1585 61.6802C72.6384 62.4802 73.9183 62.4802 74.3986 61.6802L75.5187 59.8401C76.7986 57.7602 77.5986 55.52 77.8387 53.2H68.7188C68.9586 55.6 69.7582 57.8401 71.0384 59.8401Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.1629 12.5599H44.8829C45.3629 12.5599 45.7629 12.1599 45.7629 11.6799V6.95987C45.7629 4.2398 43.5227 2 40.803 2H22.163C19.4429 2 17.2031 4.24013 17.2031 6.95987V11.6799C17.2828 12.1599 17.6827 12.5599 18.1629 12.5599Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57.3599 8.79981H49.5199V11.5998C49.5199 14.1597 47.44 16.1597 44.9601 16.1597H18.1601C15.6002 16.1597 13.6002 14.0797 13.6002 11.5998L13.5999 8.79981H5.67993C3.67993 8.79981 2 10.4797 2 12.4797V74.3197C2 76.3197 3.67993 77.9997 5.67993 77.9997H57.2799C59.2799 77.9997 60.9599 76.3197 60.9599 74.3197L60.9601 12.4797C60.9601 10.4797 59.3599 8.79981 57.3599 8.79981Z"
    />
  </svg>
);

export default safetyAuditTemplates;
