import React from 'react';
import './svg.css';

const claim = props => (
  <svg
    version="1.1"
    x={props.x || '0px'}
    y={props.y || '0px'}
    width={props.width ?? 30}
    height={props.height ?? 25}
    viewBox="0 0 80 80"
  >
    <path d="M68.5625 27.6802H78.0025V50.2402H68.5625V27.6802Z" />
    <path d="M76.1625 17.76H70.4026C69.3625 17.76 68.5625 18.4801 68.5625 19.36V24.7199H78.0025V19.36C78.0025 18.4801 77.2025 17.76 76.1624 17.76H76.1625Z" />
    <path d="M71.0384 59.8401L72.1585 61.6802C72.6384 62.4802 73.9184 62.4802 74.3986 61.6802L75.5187 59.8401C76.7986 57.7602 77.5986 55.52 77.8387 53.2H68.7188C68.9586 55.6 69.7586 57.8401 71.0388 59.8401H71.0384Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.1629 12.5599H44.8829C45.3629 12.5599 45.7629 12.1599 45.7629 11.6799V6.95987C45.7629 4.2398 43.5227 2 40.803 2H22.163C19.4429 2 17.2031 4.24013 17.2031 6.95987V11.6799C17.2828 12.1599 17.6828 12.5599 18.163 12.5599H18.1629Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.5199 8.7998H57.3599C59.3599 8.7998 60.9601 10.4797 60.9601 12.4797L60.9599 74.3197C60.9599 76.3197 59.2799 77.9997 57.2799 77.9997H5.67993C3.67993 77.9997 2 76.3197 2 74.3197V12.4797C2 10.4797 3.67993 8.7998 5.67993 8.7998H13.5999L13.6002 11.5998C13.6002 14.0797 15.6002 16.1597 18.1601 16.1597H44.9601C47.44 16.1597 49.5199 14.1597 49.5199 11.5998V8.7998ZM26 64V52H14V40H26L26 28H38V40H50V52H38V64H26Z"
    />
  </svg>
);

export default claim;
