import React from 'react';
import classnames from 'classnames/bind';
import Input from '../inputs';
import UserBadge from '../UserBadge';
import Signature from '../inputs/Signature';

import styles from './qrQuestion.module.scss';

const QRSignQuestion = ({ fieldLabel, qrCode, currentValue, ...props }) => {
  const numSignatures = currentValue?.length;
  const signClasses = [styles.signatures].concat(
    numSignatures > 2
      ? styles.threeColumn
      : numSignatures > 1
        ? styles.twoColumn
        : null
  );
  return (
    <>
      <Input fieldLabel={fieldLabel} {...props} />
      {qrCode ? (
        <img
          src={'https://' + qrCode}
          className={styles.qrCode}
          alt={'Error Displaying QR Code'}
        />
      ) : null}
      {numSignatures ? (
        <div className={classnames(signClasses)}>
          {currentValue.map(a => (
            <div className={styles.signatureBox}>
              <UserBadge
                userId={a.createdByUserId}
                className={styles.signatureName}
                nameOnly
              />
              <Signature
                className={styles.sign}
                width={numSignatures > 1 ? '250px' : ''}
                currentValue={a.source_url}
                fieldLabel
                disabled
              />
            </div>
          ))}
        </div>
      ) : qrCode ? (
        <div className="customDocumentGenerator-blockText">
          No Attendee Signatures Yet
        </div>
      ) : (
        <div className="customDocumentGenerator-blockText">
          QR Code will be available upon meeting creation
        </div>
      )}
    </>
  );
};

export default QRSignQuestion;
