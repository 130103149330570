import React from 'react';
import './svg.css';

const admin = props => (
  <svg width="100" height="100" viewBox="0 0 100 100">
    <g>
      <g transform="translate(50 50) scale(0.69 0.69) rotate(0) translate(-50 -50)">
        <svg
          version="1.1"
          x={props.x || '0px'}
          y={props.y || '0px'}
          viewBox="0 0 100 100"
          enableBackground="new 0 0 100 100"
        >
          <path d="M74,28c-8.4,0-16.5-3.2-22.6-9c-0.801-0.7-2-0.7-2.7,0c-6.1,5.8-14.2,9-22.6,9c-1.1,0-2,0.9-2,2v31.2c0,0.3,0.1,0.5,0.2,0.8  c4.1,9.3,12,16.2,21.8,18.9c0.208,0.06,4,1.1,4,1.1s3.749-1.027,4-1.1C63.9,78.2,71.8,71.3,75.9,62c0.1-0.3,0.199-0.5,0.199-0.8V30  C76,28.9,75.1,28,74,28z M72,60.8c-3.6,8-10.5,13.9-19,16.3L50,77.9L47,77.1c-8.5-2.399-15.4-8.3-19-16.3V31.9  c8.1-0.4,15.8-3.5,22-8.8c6.2,5.3,13.9,8.4,22,8.8V60.8z M48.8,47.3L38.2,58l5.4,5.4l2.8-2.801L43.8,58l1.2-1.2l2.6,2.601l2.8-2.801  L47.8,54l3.8-3.8c1,0.5,2.101,0.8,3.301,0.8c3.899,0,7-3.1,7-7s-3.101-7-7-7c-3.9,0-7,3.1-7,7C48,45.2,48.3,46.3,48.8,47.3z M55,41  c1.7,0,3,1.3,3,3s-1.3,3-3,3s-3-1.3-3-3S53.3,41,55,41z" />
        </svg>
      </g>
    </g>
  </svg>
);

export default admin;
