import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import history from '../../history';
import {
  createContinuousCare,
  deleteContinuousCare,
  fetchContinuousCareById,
  printContinuousCare,
  updateContinuousCare,
  updateAttachment,
  updateManyAttachments
} from '../../api/v4';
import { getActiveCompany } from '../../selectors/company';
import { getLoggedInUser, getAllUsers } from '../../selectors/users';
import { hasAccess } from '../../utils/incidentHelper';
import customToast from '../../utils/customToast';
import { EmployeeDropdown } from '../../components/inputs';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import LeftNav from '../../components/LeftNav';
import Modal from '../../components/Modal';
import SaveChangesModal from '../../components/Modal/saveChangesModal';
import { SaveCancelFooter } from '../../components/Footer';
import SignatureCard from '../../components/SignatureCard';
import TransitionalInfoCard from '../../components/transitionalCards/TransitionalInfoCard';
import UserBadge from '../../components/UserBadge';
import MissingRequiredModal from '../../components/Modal/missingRequiredModal';

export default function TransitionContainer() {
  const { transitionId } = useParams();

  const user = useSelector(getLoggedInUser);
  const users = useSelector(getAllUsers);
  const company = useSelector(getActiveCompany);
  const isAssignment = history?.location?.state?.isAssignment;

  const [document, setDocument] = useState({});
  const [isSupervisor, setIsSupervisor] = useState(false);
  const [isHR, setIsHR] = useState(false);
  const [formData, setFormData] = useState({});
  const [reassignUserId, setReassignUserId] = useState('');
  const [isCreating, setCreating] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [openUnsavedChangesModal, setOpenUnsavedChangesModal] = useState(false);
  const [openDiscardDocumentModal, setOpenDiscardDocumentModal] =
    useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openReassignModal, setOpenReassignModal] = useState(false);
  const [openAuthorizationModal, setOpenAuthorizationModal] = useState(false);
  const [missingRequired, setMissingRequired] = useState(false);
  const [missingModalOpen, setMissingModalOpen] = useState(false);
  const [availableApprovers, setAvailableApprovers] = useState([]);

  useEffect(() => {
    if (transitionId) {
      fetchContinuousCareById(transitionId).then(response => {
        setDocument({
          supervisorId: response.supervisorId,
          hrRepId: response.hrRepId,
          isApprovedByHr: response.isApprovedByHr,
          isApprovedBySupervisor: response.isApprovedBySupervisor,
          incidentId: response?.incidentId,
          isNa: response?.isNa,
          employeeId: response?.employeeId
        });
        setIsHR(user._id === response.hrRepId);
        setIsSupervisor(user._id === response.supervisorId);
        setFormData(response.formData);
        if (response?.incidentInfo)
          setAvailableApprovers(
            users?.filter(u => hasAccess(u, 'closure', response?.incidentInfo))
          );
        if (isAssignment) {
          setEditing(response.employeeId === user._id);
        }
      });
    } else {
      setCreating(true);
      setDocument({ employeeId: user._id });
    }
  }, [transitionId, user, isAssignment, users]);

  const handleSubmit = () => {
    let payload = {
      label: 'Transitional Return to Work Program',
      companyId: company._id,
      employeeId: user._id,
      ...document,
      formData,
      employeeFieldsComplete: canSubmit()
    };

    if (transitionId) {
      payload._id = transitionId;
      let signatures = [];
      if (formData.supervisorSignature) {
        let supSig = formData.supervisorSignature;
        payload.isApprovedBySupervisor = supSig !== undefined;
        payload.formData.supervisorSignature = supSig?.source_url || supSig;
        signatures.push(supSig?._id);
      }
      if (formData.hrSignature) {
        let hrSig = formData.hrSignature;
        payload.isApprovedByHr = hrSig !== undefined;
        payload.formData.hrSignature = hrSig?.source_url || hrSig;
        signatures.push(hrSig?._id);
      }
      if (formData.supervisorId) {
        payload.supervisorId = formData.supervisorId;
        delete formData.supervisorId;
      }
      if (formData.hrRepId) {
        payload.hrRepId = formData.hrRepId;
        delete formData.hrRepId;
      }

      setOpenAuthorizationModal(false);
      updateContinuousCare(payload).then(response => {
        if (signatures?.length) {
          updateManyAttachments({
            ids: signatures,
            ownerId: response._id,
            ownerType: 'continuousCare'
          }).then(() => {
            customToast(`Successfully Updated!`, 'success');
          });
          history.goBack();
        } else {
          customToast(`Successfully Updated!`, 'success');
          history.goBack();
        }
      });
    } else {
      let employeeSignatureId = formData?.employeeSignature?._id;
      payload.formData.employeeSignature =
        formData?.employeeSignature?.source_url;

      let signatures = [];
      if (formData.supervisorSignature) {
        let supSig = formData.supervisorSignature;
        payload.isApprovedBySupervisor = supSig !== undefined;
        payload.formData.supervisorSignature = supSig?.source_url || supSig;
        signatures.push(supSig?._id);
      }
      if (formData.hrSignature) {
        let hrSig = formData.hrSignature;
        payload.isApprovedByHr = hrSig !== undefined;
        payload.formData.hrSignature = hrSig?.source_url || hrSig;
        signatures.push(hrSig?._id);
      }
      createContinuousCare(payload).then(response => {
        updateAttachment({
          _id: employeeSignatureId,
          ownerId: response._id,
          ownerType: 'continuousCare'
        }).then(() => {
          customToast(`Successfully Created!`, 'success');
          history.goBack();
        });
      });
    }
  };

  const handleDelete = () => {
    deleteContinuousCare(transitionId).then(response => {
      customToast(response, 'success');
      history.goBack();
    });
  };

  const handleReassignUser = () => {
    const reassigned = isHR
      ? {
          hrRepId: reassignUserId
        }
      : {
          supervisorId: reassignUserId
        };

    updateContinuousCare({
      ...document,
      formData,
      _id: transitionId,
      ...reassigned
    }).then(() => {
      customToast(`Successfully Updated!`, 'success');
      history.goBack();
    });
  };

  const printForm = async () => {
    setDownloading(true);
    const url = await printContinuousCare(transitionId);
    window.open(url, '_blank');
    setDownloading(false);
  };

  const canSubmit = () => {
    const {
      workRestrictions,
      dateOfTransition,
      employeeSignature,
      supervisorSignature,
      hrSignature,
      supervisorId,
      hrRepId
    } = formData;

    if (
      workRestrictions &&
      dateOfTransition &&
      employeeSignature &&
      (company.isColumbus ||
        ((document?.supervisorId || supervisorId) &&
          (document?.hrRepId || hrRepId) &&
          !isSupervisor &&
          !isHR))
    ) {
      return hasUnsavedChanges;
    }

    if (!company.isColumbus) {
      if (isSupervisor && supervisorSignature) {
        if (isHR && !hrSignature) {
          return false;
        }
        return hasUnsavedChanges;
      }
      if (isHR && hrSignature) {
        return hasUnsavedChanges;
      }
    }

    return false;
  };

  const leftNav = [
    { label: 'Transitional Return to Work Program' },
    { label: 'Employee Signature' },
    { label: 'Supervisor Approval' },
    { label: 'HR Approval' }
  ];

  const footer = (
    <SaveCancelFooter
      editing={!isCreating}
      saveButtonClick={() =>
        !formData || !canSubmit()
          ? setMissingModalOpen(true)
          : (isSupervisor &&
                !document?.isApprovedBySupervisor &&
                !isCreating) ||
              (isHR && !document?.isApprovedByHr && !isCreating)
            ? setOpenAuthorizationModal(true)
            : handleSubmit()
      }
      cancelButtonClick={() =>
        isCreating && hasUnsavedChanges
          ? setOpenDiscardDocumentModal(true)
          : isEditing && hasUnsavedChanges
            ? setOpenUnsavedChangesModal(true)
            : history.goBack()
      }
      onMouseEnter={() => setMissingRequired(true)}
    />
  );

  const header = (
    <Header
      title="My Documents"
      section={
        isCreating
          ? 'Create Document'
          : isEditing
            ? 'Edit Document'
            : 'View Document'
      }
      needsSaved={hasUnsavedChanges}
      clickBack={() =>
        hasUnsavedChanges ? setOpenUnsavedChangesModal(true) : history.goBack()
      }
      rightButtons={
        document.incidentId
          ? []
          : [
              {
                visible: isCreating
                  ? false
                  : (!isSupervisor &&
                      !document?.isApprovedBySupervisor &&
                      !isHR &&
                      !document?.isApprovedByHr) ||
                    user?.accessLevel >= 500,
                text: isEditing ? 'Delete Document' : 'Edit Document',
                color: isEditing ? 'red' : 'blue',
                onClick: () =>
                  isEditing ? setOpenDeleteModal(true) : setEditing(true)
              },
              {
                visible:
                  !isCreating &&
                  isSupervisor &&
                  !document?.isApprovedBySupervisor,
                text: 'Change Supervisor',
                color: 'red',
                onClick: () => setOpenReassignModal(true)
              },
              {
                visible: !isCreating && isHR && !document?.isApprovedByHr,
                text: 'Change HR Rep',
                color: 'red',
                onClick: () => setOpenReassignModal(true)
              },
              {
                visible:
                  document?.isApprovedBySupervisor && document?.isApprovedByHr,
                loading: downloading,
                text: 'Print Form',
                color: 'blue',
                onClick: () => printForm()
              }
            ]
      }
    />
  );

  const left = <LeftNav items={leftNav} />;

  const columbusRecurrenceText = (
    <>
      <div>
        In order to process your recurrence injury, please be sure to send all
        supporting medical documentation and a completed Therapy Calendar, if
        applicable, to OnDutyInjuries@columbuspolice.org or fax to 614-645-0112.
      </div>
      <div>
        A blank Therapy Calendar is available via Intranet ➟ Human Resources, or
        iReport menu under Document Repository ➟ Safety Procedures.
      </div>
    </>
  );

  return (
    <>
      <HeaderAndFooter
        Header={header}
        Footer={footer}
        showFooter={
          isEditing ||
          isCreating ||
          (hasUnsavedChanges && (isSupervisor || isHR))
        }
        Left={left}
      >
        <TransitionalInfoCard
          {...document}
          {...formData}
          onChange={values => {
            setFormData({ ...formData, ...values });
            setHasUnsavedChanges(true);
          }}
          disabled={!isCreating && !isEditing}
          name={0}
          missingRequired={missingRequired}
          employeeId={document?.employeeId}
          disableNa
          isColumbus={company?.isColumbus}
          isAssignment={isAssignment}
          availableApprovers={availableApprovers}
          isCreating={isCreating}
        />
        {document?.incidentId ? null : (
          <>
            <SignatureCard
              wide
              header="Employee Signature"
              currentValue={formData?.employeeSignature}
              onChange={employeeSignature => {
                setFormData({ ...formData, employeeSignature });
                setHasUnsavedChanges(true);
              }}
              ownerType="continuousCare"
              ownerId={transitionId}
              name={1}
              disabled={!isCreating}
              missingRequired={missingRequired}
              customSignatureText={
                company?.isColumbus ? columbusRecurrenceText : null
              }
            />
            {company?.isColumbus ? null : (
              <>
                <SignatureCard
                  wide
                  header="Supervisor Approval"
                  dropdownName="Select your Supervisor"
                  permissions={[400, 500, 900]}
                  onUserChange={values => {
                    setDocument({ ...document, supervisorId: values });
                    setIsSupervisor(user._id === values);
                    setHasUnsavedChanges(true);
                  }}
                  userDisabled={
                    document?.isApprovedBySupervisor ||
                    (!isEditing && !isCreating)
                  }
                  userId={document?.supervisorId}
                  disabled={!isSupervisor || document?.isApprovedBySupervisor}
                  currentValue={formData?.supervisorSignature}
                  onChange={supervisorSignature => {
                    setFormData({ ...formData, supervisorSignature });
                    setHasUnsavedChanges(true);
                  }}
                  ownerType="continuousCare"
                  ownerId={transitionId}
                  name={2}
                  missingRequired={missingRequired}
                />
                <SignatureCard
                  wide
                  header="HR Approval"
                  dropdownName="Select your HR Representative"
                  permissions={[400, 500, 900]}
                  onUserChange={values => {
                    setDocument({ ...document, hrRepId: values });
                    setIsHR(user._id === values);
                    setHasUnsavedChanges(true);
                  }}
                  userDisabled={
                    document?.isApprovedByHr || (!isEditing && !isCreating)
                  }
                  userId={document?.hrRepId}
                  disabled={!isHR || document?.isApprovedByHr}
                  currentValue={formData?.hrSignature}
                  onChange={hrSignature => {
                    setFormData({ ...formData, hrSignature });
                    setHasUnsavedChanges(true);
                  }}
                  ownerType="continuousCare"
                  ownerId={transitionId}
                  name={3}
                  missingRequired={missingRequired}
                />
              </>
            )}
          </>
        )}
      </HeaderAndFooter>
      <Modal
        title="Delete Document"
        titleClassName="redHeader"
        isOpen={openDeleteModal}
        submitButtonColor="red"
        submitButtonText="Delete Document"
        onRequestClose={() => setOpenDeleteModal(false)}
        submitActions={handleDelete}
      >
        <div className="reportSectionContainer-emailAssigneeText">
          <span style={{ color: '#c74846', fontWeight: 'bold' }}>
            Deleting the Document will delete it for you as well as anyone who
            may be associated with it.
          </span>
        </div>
      </Modal>
      <Modal
        title="Discard Document"
        titleClassName="redHeader"
        isOpen={openDiscardDocumentModal}
        submitButtonColor="red"
        submitButtonText="Discard Document"
        onRequestClose={() => setOpenDiscardDocumentModal(false)}
        submitActions={() => history.goBack()}
      >
        <div className="reportSectionContainer-emailAssigneeText">
          <span style={{ color: '#c74846', fontWeight: 'bold' }}>
            Discarding the Document will delete all information that has been
            gathered and pictures taken.
          </span>
        </div>
      </Modal>
      <SaveChangesModal
        isOpen={openUnsavedChangesModal}
        onRequestClose={() => setOpenUnsavedChangesModal(false)}
        submitActions={() => history.goBack()}
      />
      <Modal
        title={isHR ? 'Change HR' : 'Change Supervisor'}
        titleClassName="redHeader"
        isOpen={openReassignModal}
        submitButtonColor="red"
        submitButtonText="Save Changes"
        onRequestClose={() => setOpenReassignModal(false)}
        submitActions={handleReassignUser}
        disableSubmit={!reassignUserId}
      >
        {isHR ? (
          <div className="reportSectionContainer-emailAssigneeText">
            From here you can assign a different user as the HR Representative
            for this Document.
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              This is to be used only if you are not the person meant to approve
              this document (as an HR Representative). You will be removed from
              this document.
            </span>
          </div>
        ) : (
          <div className="reportSectionContainer-emailAssigneeText">
            From here you can assign a different user as the Supervisor for this
            Document. .{' '}
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              This is to be used only if you are not the person meant to approve
              this document (as a Supervisor). You will be removed from this
              document.
            </span>
          </div>
        )}
        <div className="incidentContainer-reassignIncident">
          <UserBadge
            userId={isHR ? document?.hrRepId : document?.supervisorId}
            showImage
            size="large"
            hoverName
          />
          <img
            src={require('../../assets/images/reassignArrow.png')}
            className="reassignArrow"
            alt="assign arrow"
          />
          <UserBadge userId={reassignUserId} showImage size="large" hoverName />
        </div>
        <EmployeeDropdown
          permissions={[400, 500, 900]}
          currentValue={reassignUserId}
          onChange={value => setReassignUserId(value)}
          placeholder="Select one..."
        />
      </Modal>
      <Modal
        title="Authorization Notice"
        titleClassName="blueHeader"
        isOpen={openAuthorizationModal}
        submitButtonColor="blue"
        submitButtonText="Save Changes"
        onRequestClose={() => setOpenAuthorizationModal(false)}
        submitActions={handleSubmit}
      >
        <div className="reportSectionContainer-emailAssigneeText">
          <span style={{ color: '#c74846', fontWeight: 'bold' }}>
            Upon saving, this form will be removed from your To-Do list.
          </span>
          If you need to see it later, it can be found in the Doc Repo section
          of the Employee Profile.
        </div>
      </Modal>
      <MissingRequiredModal
        isOpen={missingModalOpen}
        onRequestClose={() => setMissingModalOpen(false)}
        subject="Document"
      />
    </>
  );
}
