import React from 'react';
import { HAZARD_CLASSIFICATION } from '../../../constants/constants';
import CheckboxGroup from '../../../components/inputs/CheckboxGroup';
import Dropdown from '../../../components/inputs/Dropdown';
import Textbox from '../../../components/inputs/Textbox';

function HazardCard({
  immediatelyFixed,
  description,
  exactLocation,
  hazardClassification,
  otherHazardClassification,
  updateHazard,
  editing,
  missingRequired
}) {
  return (
    <>
      <CheckboxGroup
        options={[
          {
            value: 'immediatelyFixed',
            label: 'Hazard was Immediately Fixed'
          }
        ]}
        currentValue={immediatelyFixed}
        onChange={v => updateHazard({ immediatelyFixed: v })}
        bareValues={false}
        disabled={!editing}
      />
      <Dropdown
        options={HAZARD_CLASSIFICATION?.map(o => ({ label: o, value: o }))}
        searchable
        isRequired
        fieldLabel="Hazard Classification"
        currentValue={hazardClassification}
        bareValues
        onChange={v => updateHazard({ hazardClassification: v })}
        disabled={!editing}
        touched={missingRequired && !hazardClassification}
      />
      {hazardClassification === 'Other' ? (
        <Textbox
          fieldLabel="Name of other hazard classification"
          isRequired
          onChange={v => updateHazard({ otherHazardClassification: v })}
          currentValue={otherHazardClassification}
          disabled={!editing}
          touched={missingRequired && !otherHazardClassification?.trim()}
        />
      ) : null}

      <Textbox
        type="textarea"
        fieldLabel="Description"
        placeholder="Describe this Hazard. Be sure to include any important details."
        isRequired
        onChange={v => updateHazard({ description: v })}
        currentValue={description}
        disabled={!editing}
        touched={missingRequired && !description?.trim()}
      />

      <Textbox
        type="textarea"
        fieldLabel="Exact Location"
        placeholder="Where to find this Hazard."
        onChange={v => updateHazard({ exactLocation: v })}
        currentValue={exactLocation}
        disabled={!editing}
      />
    </>
  );
}

export default HazardCard;
