import React from 'react';
import moment from 'moment';
import classnames from 'classnames/bind';
import { useDispatch } from 'react-redux';

import UserBadge from '../../UserBadge';
import { loadTask, setActiveTaskRequest } from '../../../actions/tasks';

import styles from './corrective.module.scss';
const bStyles = classnames.bind(styles);

const getCorrectiveActionHeaders = (task, immediatelyFixed = false) => {
  if (!task) {
    if (immediatelyFixed) {
      return {
        top: 'Immediately Fixed',
        bottom: 'Assign Corrective Action (if needed)',
        style: 'greenOutline'
      };
    } else {
      return {
        top: 'NO CORRECTIVE ACTION',
        bottom: 'Assign Corrective Action',
        style: 'blueOutline'
      };
    }
  }

  const isPastDue = moment().isAfter(Date.parse(task.dueDate));

  if (task.isCompleted) {
    return {
      top: `Corrective Action Completed${task.isArchived ? ' (Archived)' : ''}`,
      style: 'greenOutline'
    };
  } else if (isPastDue) {
    return {
      top: `Corrective Action Past Due${task.isArchived ? ' (Archived)' : ''}`,
      style: 'redOutline'
    };
  } else {
    return {
      top: `Corrective Action Assigned${task.isArchived ? ' (Archived)' : ''}`,
      style: 'blueOutline'
    };
  }
};

export default function AssignCorrectiveActionButton({
  task,
  ownerId,
  ownerType,
  label,
  description,
  groupId,
  hazardNumber,
  notes,
  schedule,
  projectId,
  onClick,
  pictures,
  disabled,
  immediatelyFixed,
  testID,
  assignedTo,
  category,
  type
}) {
  const dispatch = useDispatch();
  const loadActiveTask = task => dispatch(loadTask(task._id));
  const setActiveTask = task => dispatch(setActiveTaskRequest(task));

  const header = getCorrectiveActionHeaders(task, immediatelyFixed);
  const classes = bStyles({
    button: true,
    disabled: disabled,
    [header.style]: true
  });

  return (
    <div
      className={classes}
      onClick={() => {
        if (disabled) return;
        task
          ? loadActiveTask({
              ...task,
              ownerType,
              ownerId
            })
          : setActiveTask({
              label,
              description,
              ownerId,
              ownerType,
              currentCategory: category,
              groupId,
              notes,
              schedule,
              hazardNumber,
              projectId,
              pictures,
              assignedTo,
              type
            });
        onClick && onClick();
      }}
      data-cy={testID}
    >
      {task?.assignedTo?.[0] && (
        <UserBadge
          size="medium"
          userId={task.assignedTo}
          showImage
          hideMultipleUsers={
            Array.isArray(task?.assignedTo) && task?.assignedTo?.length > 1
          }
        />
      )}
      <div>
        <div className={styles.text}>{header.top}</div>
        <div className={`${styles.text} ${styles.notBold}`}>
          {task?.assignedTo?.length ? (
            <UserBadge
              userId={task.assignedTo}
              nameOnly
              hideMultipleUsers={
                Array.isArray(task?.assignedTo) && task?.assignedTo?.length > 1
              }
            />
          ) : (
            (header.bottom ?? '')
          )}
        </div>
        <div className={`${styles.text} ${styles.notBold}`}>
          {task?.dueDate ? (
            moment(task.dueDate).format('MM/DD/YYYY hh:mm A')
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
