import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ReactLoading from 'react-loading';
import moment from 'moment';
import history from '../../history';
import {
  createExternalWitnessStatements,
  createWitnessStatement,
  emailAssignees
} from '../../api/v4';
import {
  fetchIncidentByIdRequest,
  updateReportComponentRequest
} from '../../actions/incidents';
import { createReportResponse } from '../../actions/reports';
import {
  getActiveIncidentSelector,
  getIncidentsLoadingSelector
} from '../../selectors/incidents';
import { getActiveReportsSelector } from '../../selectors/reports';
import { getLoggedInUser, getAllUsers } from '../../selectors/users';
import { email } from '../../utils/formValidation';
import { isComplete } from '../../utils/reportHelper';
import { useSocket } from '../../utils/withSocket';
import { Button, EmployeeDropdown, InputRow } from '../../components/inputs';
import { IReportDatePicker } from '../../components/inputs/DateTimePicker';
import ExternalWitnessCard from '../../components/ExternalWitnessCard';
import Header from '../../components/Header';
import { HeaderAndFooter } from '../../components/HeaderAndFooter';
import LeftNav from '../../components/LeftNav';
import MarkAsCompleteModal from '../../components/Modal/markAsCompleteModal';
import Modal from '../../components/Modal';
import { SaveCancelFooter } from '../../components/Footer';
import SaveChangesModal from '../../components/Modal/saveChangesModal';
import Textbox from '../../components/inputs/Textbox';
import ViewOnly from '../../components/ViewOnly';
import WitnessStatementCard from '../../components/inputs/WitnessStatementCard';

export default function WitnessComponentContainer(props) {
  const dispatch = useDispatch();
  const { stage } = useParams();
  const updateReportComponent = payload =>
    dispatch(updateReportComponentRequest(payload));

  const activeIncident = useSelector(getActiveIncidentSelector);
  const activeReport = useSelector(getActiveReportsSelector);
  const incidentLoading = useSelector(getIncidentsLoadingSelector);
  const loggedInUser = useSelector(getLoggedInUser);
  const users = useSelector(getAllUsers);

  const [openModalType, setOpenModalType] = useState('');
  const [emailMessage, setEmailMessage] = useState('');
  const [report, setReport] = useState([]);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [contactUser, setContactUser] = useState(loggedInUser?._id);
  const [contactEmail, setContactEmail] = useState(loggedInUser?.email);
  const [externalWitnesses, setExternal] = useState([
    {
      witnessFirstName: null,
      witnessLastName: null,
      witnessEmail: null,
      incidentId: activeIncident._id
    }
  ]);
  const [additionalWitness, setAdditionalWitnesses] = useState([]);
  const [currentWitnesses, setCurrentWitnesses] = useState([]);
  const [witnessDueDate, setWitnessDueDate] = useState(null);
  const [missingRequired, setMissingRequired] = useState(false);

  const emptyExternalStatement = {
    incidentId: activeIncident._id,
    witnessFirstName: '',
    witnessLastName: '',
    witnessEmail: ''
  };

  const { socketDirty, lockInfo } = useSocket(
    activeIncident._id,
    activeReport?.report?.[0]?.[0]
  );

  useEffect(() => {
    if (activeReport?.report) {
      setReport([...activeReport.report]);
      let assignees = activeReport.report[0]?.[1]?.statements?.map(
        s => s.assignee
      );
      setCurrentWitnesses(assignees);
    } else {
      setReport([...activeReport]);
    }
  }, [activeReport]);

  const rc = report?.[0];
  const rcName = rc?.[0];
  const rcComplete = isComplete(report);

  const getAssigneeName = a => {
    let assignee = users.find(u => u._id === a);
    return assignee
      ? `${assignee.firstName} ${assignee.lastName}`
      : 'Unknown User';
  };

  const sendEmail = () => {
    const reportTask = activeIncident?.incidentActivity?.openTasks?.filter(
      task => report.length > 0 && task.reportComponentName === rcName
    );
    emailAssignees({
      messageContent: emailMessage,
      incidentId: activeIncident?._id,
      sentMessageToIds: reportTask?.[0]?.assignedTo
    }).then(() => {
      setOpenModalType(null);
      setEmailMessage('');
    });
  };

  const reopenReportSection = async () => {
    report[0][4].isComplete = false;
    updateReportComponent({
      stage
    });
  };

  const handleSectionSubmit = async (markAsComplete, dontLeave = false) => {
    setUnsavedChanges(true);
    const showComplete = canMarkWitnessStatementComplete(
      report[0][1].statements
    );

    if (showComplete && markAsComplete === undefined) {
      setOpenModalType('markComplete');
      return;
    }

    setOpenModalType(null);

    if (markAsComplete) {
      report[0][4].isComplete = true;
    }

    updateReportComponent({
      stage,
      dontLeavePage: dontLeave
    });

    socketDirty();
  };

  const handleDiscard = async () => {
    if (report.returnPath) {
      history.push(report.returnPath);
    } else {
      dispatch(fetchIncidentByIdRequest(activeIncident));
      history.push(`/app/incidentContainer/${props.match.params.stage}`);
    }
  };

  const getWitnessStatements = () => {
    return rc?.[1]?.statements?.map((s, index) => ({
      label:
        s.firstName && s.lastName
          ? s.firstName + ' ' + s.lastName
          : getAssigneeName(s.assignee),
      complete: false,
      id: `wsCard${index}`
    }));
  };

  const createWitnessStatements = async () => {
    let wsBody = {
      assignees: additionalWitness,
      dueDate: witnessDueDate,
      incidentId: activeIncident._id,
      companyId: activeIncident.companyId,
      groupId: activeIncident.groupId,
      projectId:
        activeIncident.projectId === '' ? null : activeIncident.projectId,
      incidentDateTime: activeIncident.dateIncidentOccured
    };

    let updatedIncident = await createWitnessStatement(wsBody);

    const reportComponent = updatedIncident.reportComponents.find(
      rc => rc[0] === 'Witness Statements'
    );
    await dispatch(
      createReportResponse({
        report: [reportComponent],
        stage: 'investigation'
      })
    );
    setOpenModalType(null);
    setAdditionalWitnesses([]);
    setWitnessDueDate(null);
  };

  const canMarkWitnessStatementComplete = statements =>
    statements?.every(s => s.isComplete || s.isNa);

  const handleWsNa = (values, index) => {
    const statements = [...report[0][1]?.statements];
    statements[index].isNa = values;
    report[0][1].statements = [...statements];
    setReport([...report]);
    setUnsavedChanges(true);
  };

  const handleWsDueDate = (values, index) => {
    const statements = [...rc?.[1]?.statements];
    statements[index].dueDate = values;
    report[0][1].statements = [...statements];
    setReport([...report]);
    setUnsavedChanges(true);
  };

  const showMarkAsComplete = canMarkWitnessStatementComplete(
    rc?.[1]?.statements
  );

  const pageActions = [
    {
      visible: showMarkAsComplete && !rcComplete,
      label: 'Mark Report as Complete',
      color: 'blueOutline',
      onClick: () => setOpenModalType('markComplete')
    },
    {
      visible: !rcComplete,
      label: 'Add Witness',
      color: 'blueOutline',
      onClick: () => setOpenModalType('addWitnesses')
    },
    {
      visible: !rcComplete,
      label: 'Add External Witness',
      color: 'blueOutline',
      onClick: () => setOpenModalType('addExternalWitnesses')
    }
  ];

  const headerCenter = (
    <ViewOnly
      canUnlock={
        !activeReport?.disabled &&
        rc?.[4]?.isComplete &&
        (loggedInUser?.accessLevel === 900 ||
          activeIncident.incidentOwner === loggedInUser?._id)
      }
      incident={activeIncident}
      loggedInUser={loggedInUser}
      onClick={() => setOpenModalType('unlockReport')}
      activeReport={activeReport}
    />
  );
  const header = (
    <Header
      title={activeIncident?.incidentNumber}
      section="Witness Statements"
      needsSaved={unsavedChanges}
      clickBack={() =>
        unsavedChanges ? setOpenModalType('unsavedChanges') : handleDiscard()
      }
      center={headerCenter}
      pageActionOptions={
        !activeReport?.disabled && pageActions?.some(pg => pg.visible)
          ? pageActions
          : []
      }
    />
  );

  const footer = (
    <SaveCancelFooter
      saveButtonClick={() => handleSectionSubmit(undefined)}
      saveButtonDisabled={!unsavedChanges}
      cancelButtonClick={() =>
        unsavedChanges ? setOpenModalType('unsavedChanges') : handleDiscard()
      }
      editing={true}
    />
  );

  const canSubmitWS =
    moment(witnessDueDate).isAfter(moment()) && additionalWitness?.length;

  const canSubmitEWS =
    externalWitnesses?.length &&
    !externalWitnesses.some(
      statement =>
        !statement.witnessEmail?.trim() ||
        !statement.witnessFirstName?.trim() ||
        !statement.witnessLastName?.trim() ||
        email(statement.witnessEmail) ||
        !contactEmail.trim() ||
        email(contactEmail)
    );

  return (
    <>
      {incidentLoading && (
        <ReactLoading
          type="spinningBubbles"
          color="#2f8738"
          className="loading"
        />
      )}
      <HeaderAndFooter
        Header={header}
        Footer={footer}
        Left={<LeftNav items={getWitnessStatements()} />}
        lockInfo={lockInfo}
        showFooter={!rcComplete && !activeReport?.disabled}
      >
        {rc?.[1]?.statements?.length === 0 ? (
          <div className="reportSectionContainer-correctiveActionText">
            No Witnesses were selected in the Initial Report. Click the Add
            Witness Button above to assign Statements.
          </div>
        ) : null}
        {rc?.[1]?.statements
          ?.sort((x, y) => x.isComplete - y.isComplete)
          ?.map((s, index) => (
            <WitnessStatementCard
              statementId={s.id}
              index={index}
              assigneeName={getAssigneeName(
                s.assignee ?? s.firstName + ' ' + s.lastName
              )}
              handleWsNa={value => {
                handleWsNa(value, index);
              }}
              handleWsDueDate={value => {
                handleWsDueDate(value, index);
              }}
              incidentArchived={activeReport?.disabled}
            />
          ))}
      </HeaderAndFooter>
      <Modal
        title="Email Assignee"
        titleClassName="blueHeader"
        isOpen={openModalType === 'emailAssignee'}
        submitButtonColor="blue"
        submitButtonText="Send Email"
        onRequestClose={() => {
          setOpenModalType(null);
          setEmailMessage('');
        }}
        submitActions={sendEmail}
      >
        <div className="reportSectionContainer-emailAssigneeText">
          This email will go through the iReportSource system for record keeping
          purposes.
        </div>
        <Textbox
          fieldLabel="Email Content"
          type="textarea"
          placeholder="Type email content here"
          currentValue={emailMessage}
          onChange={setEmailMessage}
        />
      </Modal>
      <SaveChangesModal
        isOpen={openModalType === 'unsavedChanges'}
        onRequestClose={() => setOpenModalType(null)}
        submitActions={handleDiscard}
        savingWhat="a report"
      />
      <MarkAsCompleteModal
        isOpen={openModalType === 'markComplete'}
        onRequestClose={() => setOpenModalType(null)}
        submitActions={() => handleSectionSubmit(true)}
        cancelActions={() => handleSectionSubmit(false)}
      />
      <Modal
        title="Unlock and Re-Open Report"
        titleClassName="blueHeader"
        isOpen={openModalType === 'unlockReport'}
        submitButtonColor="blue"
        submitButtonText="Re-open Report"
        onRequestClose={() => setOpenModalType(null)}
        submitActions={reopenReportSection}
      >
        <div className="reportSectionContainer-emailAssigneeText">
          Unlocking this report will allow you –and users with edit permission–
          to make changes and additions to the report.{' '}
          <span style={{ color: '#c74846', fontWeight: 'bold' }}>
            This also means that the report will no longer be considered
            completed until it is filled out, saved, and marked as complete once
            more. Re-Opening this report will also remove the currently assigned
            user.
          </span>{' '}
          You can reassign this report to the same or a different user as a new
          task.
        </div>
      </Modal>
      <Modal
        title="Add Witnesses"
        titleClassName="blueHeader"
        isOpen={openModalType === 'addWitnesses'}
        submitButtonColor="blue"
        submitButtonText="Submit"
        onRequestClose={() => {
          setAdditionalWitnesses([]);
          setWitnessDueDate(null);
          setOpenModalType(null);
          setMissingRequired(false);
        }}
        submitActions={() => (canSubmitWS ? createWitnessStatements() : null)}
        tall
        wide
        testID="addWitnessModal"
        onMouseEnter={() => setMissingRequired(!canSubmitWS)}
        submitTooltip={!canSubmitWS ? 'Missing or Invalid Fields' : null}
      >
        <EmployeeDropdown
          fieldLabel="Additional Witnesses"
          currentValue={additionalWitness}
          onChange={setAdditionalWitnesses}
          searchable
          name="dropdownEmployees"
          isRequired
          group={activeIncident.groupId}
          multi
          exclude={currentWitnesses}
          permissions={[100, 400, 500, 900]}
          touched={missingRequired && !additionalWitness?.length}
        />
        <IReportDatePicker
          pickTime
          fieldLabel="Due Date"
          isRequired
          onChange={setWitnessDueDate}
          currentValue={witnessDueDate}
          name="witnessDueDate"
          touched={
            (missingRequired && !witnessDueDate) ||
            moment().isAfter(witnessDueDate)
          }
          minDate={new Date()}
          errorMessage={
            witnessDueDate ? 'Must be a future date and time' : null
          }
        />
      </Modal>
      <Modal
        title="Add External Witnesses"
        titleClassName="blueHeader"
        submitButtonColor="blue"
        isOpen={openModalType === 'addExternalWitnesses'}
        onRequestClose={() => {
          setOpenModalType();
          setExternal([emptyExternalStatement]);
          setContactEmail(loggedInUser.email);
          setContactUser(loggedInUser._id);
          setMissingRequired(false);
        }}
        submitActions={() => {
          if (canSubmitEWS) {
            const contactUserObj = users.find(
              u => u._id === (contactUser?.value ?? contactUser)
            );
            const filledStatements = externalWitnesses.map(ws => ({
              ...ws,
              userContact: {
                name: `${contactUserObj.firstName} ${contactUserObj.lastName}`,
                email: contactEmail
              }
            }));
            createExternalWitnessStatements(filledStatements).then(
              updatedIncident => {
                const reportComponent = updatedIncident.reportComponents.find(
                  rc => rc[0] === 'Witness Statements'
                );
                dispatch(
                  createReportResponse({
                    report: [reportComponent],
                    stage: 'investigation'
                  })
                );
                setOpenModalType();
                setExternal([emptyExternalStatement]);
                setContactEmail(loggedInUser.email);
                setContactUser(loggedInUser._id);
              }
            );
          }
        }}
        taller
        wide
        onMouseEnter={() => setMissingRequired(!canSubmitEWS)}
        submitTooltip={!canSubmitEWS ? 'Missing or Invalid Fields' : null}
      >
        <span style={{ fontWeight: 'bold', lineHeight: '30px' }}>
          All External Witness Statements will have a 72 hour window to be
          answered
        </span>
        <EmployeeDropdown
          fieldLabel="Contact First and Last Name"
          currentValue={contactUser}
          bareValues={false}
          onChange={v => {
            const selectionEmail = users.find(u => u._id === v.value)?.email;
            setContactEmail(selectionEmail ?? '');
            setContactUser(v);
          }}
          permissions={[400, 500, 900]}
          isRequired
          touched={missingRequired && !contactUser}
        />
        <Textbox
          fieldLabel="Contact Email"
          currentValue={contactEmail}
          onChange={v => setContactEmail(v?.toLowerCase())}
          isRequired
          touched={(missingRequired && !contactEmail) || email(contactEmail)}
          errorMessage={email(contactEmail)}
        />
        {externalWitnesses.map((statement, i) => (
          <ExternalWitnessCard
            statement={statement}
            onChange={(value, field, index) => {
              const edited = [...externalWitnesses];
              edited[index][field] = value;
              setExternal(edited);
            }}
            statementIndex={i}
            removeStatement={() => {
              let newStatements = [...externalWitnesses];
              newStatements.splice(i, 1);
              setExternal(newStatements);
            }}
            missingRequired={missingRequired}
          />
        ))}
        <InputRow>
          <Button
            text="Add Row"
            color="blue"
            onClick={() =>
              setExternal([...externalWitnesses, emptyExternalStatement])
            }
          />
          <Button
            text="Add Five Rows"
            color="blue"
            onClick={() =>
              setExternal([
                ...externalWitnesses,
                ...Array(5)
                  .fill()
                  .map(() => emptyExternalStatement)
              ])
            }
          />
        </InputRow>
      </Modal>
    </>
  );
}
