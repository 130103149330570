import React, { useState } from 'react';
import { TRAINING_FILE_TYPES_ACCEPTED } from '../../../constants/constants';
import {
  InputRow,
  Button,
  Input,
  Textbox,
  TwoColumn
} from '../../../components/inputs';
import {
  getAttachmentSelection,
  isImage,
  isYoutube
} from '../../../utils/attachmentSelector';
import videoImg from '../../../assets/images/videoPicture.png';
import AddYouTubeLinksModal from '../../../components/Modal/addYouTubeLinksModal';
import styles from './styles.module.scss';
import history from '../../../history';
import { normalizeFloat } from '../../../utils/formValidation';
import classnames from 'classnames/bind';

const bStyles = classnames.bind(styles);

export const TrainingMaterials = ({
  training,
  fromApproveTraining,
  handleAnswer,
  trainingType,
  isCreating,
  isEditing,
  addAttachments,
  attachments,
  deleteAttachment,
  youtubeLinks,
  isTemplateCreate,
  courseTraining,
  hideTime,
  hideLabel,
  isNsc,
  missingRequired,
  performing
}) => {
  const [openYoutubeLinksModal, setYoutubeLinksModal] = useState(false);
  const hasMaterials = attachments?.length > 0 || youtubeLinks?.length > 0;

  const removeYoutubeLink = index => {
    handleAnswer({
      youtubeLinks: [
        ...youtubeLinks.slice(0, index),
        ...youtubeLinks.slice(index + 1)
      ]
    });
  };

  const materialBox = (material, index) => {
    const youtubeLink = isYoutube(material);
    const imageBoolean = !youtubeLink ? isImage(material) : false;
    const canEditAttachment =
      !!training?.trainingTemplateId ||
      material.ownerId !== training?.trainingTemplateId ||
      isTemplateCreate ||
      courseTraining;
    const disabled =
      (!isCreating && !isEditing) || !canEditAttachment || material.disabled;
    const buttonClick = e => {
      if (e.target.id === 'deleteButton')
        youtubeLink ? removeYoutubeLink(index) : deleteAttachment(material);
      else if (performing)
        history.push('/app/training/TrainingAttachmentViewer', {
          activeAttachment: material,
          activeTraining: training
        });
      else
        window.open(
          youtubeLink ? material.link : material.source_url,
          '_blank'
        );
    };

    const title = material.title ?? material.original_filename;
    return (
      <div
        title={title}
        className={styles.materials}
        key={index}
        onClick={buttonClick}
      >
        <div className={styles.training}>
          <img
            src={
              youtubeLink
                ? videoImg
                : imageBoolean
                  ? material.source_url
                  : getAttachmentSelection(material)
            }
            alt={title}
            className={styles.trainingAttachment}
          />
          <div
            style={{
              justifyContent: 'space-between',
              display: 'inline-flex',
              flexGrow: 1,
              alignItems: 'center',
              overflow: 'hidden'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1
              }}
            >
              <p className={styles.trainingFileName}>{title}</p>
              <br />
              {!hideTime ? (
                <span className={styles.timeViewed}>
                  Viewed {material.timeSpent} minutes
                </span>
              ) : null}
            </div>
            {disabled ? (
              material.timeSpent ? (
                <img
                  src={require('../../../assets/images/GreenCheck.png')}
                  alt="complete attachment"
                  className={styles.deleteButton}
                  id="attachmentCheck"
                />
              ) : (
                <></>
              )
            ) : (
              <img
                src={require('../../../assets/images/remove.png')}
                alt="delete question"
                onClick={buttonClick}
                className={styles.deleteButton}
                id="deleteButton"
                data-cy="removeButton"
              />
            )}
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="materialsContainer">
      {!fromApproveTraining && !hasMaterials ? (
        <Input
          fieldLabel={
            trainingType?.value === 1
              ? 'Either Training Materials or a Quiz are required to create a training, but you are not required to have both.'
              : trainingType?.value === 2
                ? 'Training Materials required to create a training.'
                : ''
          }
          labelStyles={styles.requiredText}
        />
      ) : null}
      <Input fieldLabel={hideLabel ? null : 'Training Materials'} isRequired />
      {isEditing && !isNsc ? (
        <InputRow className={bStyles(styles.buttons)}>
          <Button
            text="Add YouTube Links"
            color={missingRequired && !hasMaterials ? 'red' : 'blue'}
            onClick={() => setYoutubeLinksModal(true)}
            testID="Add YouTube Links"
          />
          <Button
            text="Upload Training Materials"
            color={missingRequired && !hasMaterials ? 'red' : 'blue'}
            onClick={() => document.getElementById(`myImage`).click()}
            testID="Upload Training Materials"
          />
        </InputRow>
      ) : (
        <></>
      )}

      <input
        type="file"
        id="myImage"
        className={styles.fileUpload}
        onChange={a => {
          addAttachments(a);
          a.target.value = '';
        }}
        multiple
        accept={TRAINING_FILE_TYPES_ACCEPTED.join(', ')}
      />
      <div className={styles.materialRow}>
        {[...(youtubeLinks ?? []), ...(attachments ?? [])]?.map((link, index) =>
          materialBox(link, index)
        )}
      </div>

      {hasMaterials &&
      (trainingType === 1 || isTemplateCreate || courseTraining || isNsc) ? (
        <TwoColumn>
          <Textbox
            type="number"
            step={0.1}
            disabled={
              (!isEditing ||
                (training?.trainingTemplateId &&
                  !isTemplateCreate &&
                  !training?.nscCompanyIds?.length)) &&
              !training.isCourseTemplate
            }
            inputClassname={styles.timeTextbox}
            currentValue={training.minMaterialTime}
            onChange={v => {
              handleAnswer({
                minMaterialTime:
                  normalizeFloat(v, 2) ?? training.minMaterialTime
              });
            }}
            fieldLabel="Minimum time spent on materials (minutes)"
            placeholder="00.00 minutes"
            name="minMaterialTime"
            touched={parseFloat(training.minMaterialTime) < 0}
            errorMessage="Must be greater than 0"
          />
        </TwoColumn>
      ) : null}

      <AddYouTubeLinksModal
        isOpen={openYoutubeLinksModal}
        onRequestClose={() => setYoutubeLinksModal(false)}
        submitActions={values => {
          handleAnswer({ youtubeLinks: values });
          setYoutubeLinksModal(false);
        }}
        youtubeLinks={youtubeLinks}
      />
    </div>
  );
};

export default TrainingMaterials;
