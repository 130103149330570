import React from 'react';
import './svg.css';
const toolbox = props => (
  <svg
    version="1.1"
    x={props.x || '0px'}
    y={props.y || '0px'}
    viewBox="0 0 72 72"
    width="30px"
    height="25px"
  >
    <g>
      <g>
        <rect x="41" y="31" width="2" height="2" />
        <rect x="41" y="35" width="2" height="2" />
        <path d="M58.707,25.293l-6-6C52.52,19.105,52.266,19,52,19H39v-5c0-0.552-0.447-1-1-1H26c-0.552,0-1,0.448-1,1v5H12    c-0.265,0-0.52,0.105-0.707,0.293l-6,6C5.105,25.48,5,25.735,5,26v7h14v-3c0-0.552,0.448-1,1-1h4c0.552,0,1,0.448,1,1v3h14v-3    c0-0.552,0.447-1,1-1h4c0.553,0,1,0.448,1,1v3h14v-7C59,25.735,58.895,25.48,58.707,25.293z M27,15h10v4H27V15z" />
        <rect x="21" y="35" width="2" height="2" />
        <rect x="21" y="31" width="2" height="2" />
        <path d="M45,38c0,0.553-0.447,1-1,1h-4c-0.553,0-1-0.447-1-1v-3H25v3c0,0.553-0.448,1-1,1h-4c-0.552,0-1-0.447-1-1v-3H5v15    c0,0.553,0.448,1,1,1h52c0.553,0,1-0.447,1-1V35H45V38z" />
      </g>
    </g>
  </svg>
);

export default toolbox;
