import React from 'react';
import './svg.css';

const doubleChevron = ({ className }) => (
  <svg
    width="28"
    height="32"
    viewBox="0 0 28 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    data-cy="doubleChevron"
  >
    <g filter="url(#filter0_di)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4181 23.7333C21.0608 23.2914 21.191 22.4557 20.709 21.8666L12.6363 12L20.709 2.1334C21.191 1.5443 21.0608 0.708568 20.4181 0.266743C19.7754 -0.17508 18.8637 -0.0556913 18.3818 0.533409L9 12L18.3818 23.4666C18.8637 24.0557 19.7754 24.1751 20.4181 23.7333Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4181 23.7333C12.0608 23.2914 12.191 22.4557 11.709 21.8666L3.63634 12L11.709 2.1334C12.191 1.5443 12.0608 0.708568 11.4181 0.266743C10.7754 -0.17508 9.86374 -0.0556913 9.38175 0.533409L0 12L9.38175 23.4666C9.86374 24.0557 10.7754 24.1751 11.4181 23.7333Z"
      />
    </g>
    <defs>
      <filter
        id="filter0_di"
        x="0"
        y="0"
        width="20"
        height="32"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow" />
      </filter>
    </defs>
  </svg>
);

export default doubleChevron;
