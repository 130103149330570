import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import isEqual from 'lodash/isEqual';
import { useParams } from 'react-router-dom';
import { usePrevious } from '../../../utils/hooks';
import history from '../../../history';
import { fetchPerformTraining, updateChildTraining } from '../../../api/v4';
import BlockText from '../../../components/inputs/BlockText';
import Card from '../../../components/Card';
import { IReportDatePicker } from '../../../components/inputs/DateTimePicker';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import Header from '../../../components/Header';
import LeftNav from '../../../components/LeftNav';
import MarkItemAsCompleteModal from '../../../components/Modal/markItemAsCompleteModal';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';
import SignatureCard from '../../../components/SignatureCard';
import { SubmitSaveForLaterFooter } from '../../../components/Footer';
import { Textbox } from '../../../components/inputs';
import TrainingMaterials from '../TrainingMaterials';
import RequiredTime from '../../../components/training/RequiredTime/index.js';
import { getLoggedInUser } from '../../../selectors/users.js';
import { QuizStatusCard } from '../../../components/training/Cards/QuizStatusCard/index.js';
import Modal from '../../../components/Modal/index.js';

export default function PerformTraining() {
  const user = useSelector(getLoggedInUser);
  const { trainingId } = useParams();

  const [training, setTraining] = useState({});
  const [hasUnsavedChanges, setUnsavedChanges] = useState(false);
  const [openUnsavedChanges, setUnsavedChangesModal] = useState(false);
  const [openMarkAsCompleteModal, setMarkAsCompleteModal] = useState(false);
  const [openAddtionalAttempts, setAdditionalAttempts] = useState(false);

  const defaultTrainingTitle = 'Training';
  const allMaterials =
    training?.attachments?.concat(training?.youtubeLinks ?? []) ?? [];
  const totalMaterialTime = allMaterials.reduce(
    (a, c) => a + (c?.timeSpent ?? 0),
    0
  );
  const hasOpenedAllMaterials =
    training?.trainingType === 2 ||
    allMaterials?.every(a => (a?.timeSpent ?? 0) > 0);

  useEffect(() => {
    if (trainingId) {
      fetchPerformTraining(trainingId, user._id.toString()).then(response => {
        let employeeSignature = JSON.parse(
          localStorage.getItem('employeeSignature')
        );
        setTraining({
          ...response,
          employeeSignature: response?.employeeSignature ?? employeeSignature
        });
      });
    }
  }, [trainingId, user]);

  const previous = usePrevious(training);
  useEffect(() => {
    if (previous && training && !isEqual(previous, training)) {
      setUnsavedChanges(true);
    }
  }, [training, previous]);

  const handleBack = () => {
    setTraining({});
    localStorage.removeItem('employeeSignature');
    history.goBack();
  };

  const handleSubmit = isComplete => {
    let response = { isCompleted: isComplete };

    if (isComplete) {
      response.completionDate = moment();
    }
    updateChildTraining({ ...training, ...response }).then(handleBack);
  };

  const canSign = () => {
    const hasAttachments = allMaterials?.length;
    const hasQuiz = training?.quiz;
    const quizCompleted =
      !training?.quiz ||
      training?.quiz?.isCompleted ||
      (training?.trainingType === 2 && training?.schedule === 2);
    if (training?.trainingType === 2)
      return quizCompleted && training?.collectSignatures;

    const completedAttachments = training?.minMaterialTime
      ? totalMaterialTime >= training.minMaterialTime && hasOpenedAllMaterials
      : hasOpenedAllMaterials;

    return (
      (training?.trainingType === 2 && training?.schedule === 2) ||
      (!hasAttachments && !hasQuiz) ||
      (!hasAttachments && hasQuiz && quizCompleted) ||
      (completedAttachments && hasQuiz && quizCompleted) ||
      (completedAttachments && !hasQuiz)
    );
  };

  const canSubmit = () => {
    if (training?.trainingType === 2) {
      return (
        (!training.collectSignatures || training.employeeSignature) &&
        (!training.quiz || training.quiz.isCompleted)
      );
    } else return canSign() && training.employeeSignature;
  };

  const showQuiz = !allMaterials?.length
    ? true
    : hasOpenedAllMaterials &&
      (!training?.minMaterialTime ||
        training.trainingType === 2 ||
        totalMaterialTime >= training.minMaterialTime);

  const getQuickNav = () => {
    let leftNav = [{ label: training?.title || defaultTrainingTitle, id: '0' }];

    const hasAttachments = training?.attachments?.length;
    const hasQuiz = training?.quiz;

    if (hasAttachments)
      leftNav = [...leftNav, { label: 'Training Material', id: '1' }];

    if (hasQuiz && showQuiz) leftNav = [...leftNav, { label: 'Quiz', id: '2' }];

    if (canSign()) {
      leftNav = [
        ...leftNav,
        {
          label: 'Employee Signature',
          id: 'Employee Signature'
        }
      ];
    }

    return leftNav;
  };

  const header = (
    <Header
      title="Training"
      section={training?.title}
      clickBack={() =>
        hasUnsavedChanges ? setUnsavedChangesModal(true) : handleBack()
      }
      needsSaved={hasUnsavedChanges}
    />
  );

  const footer = (
    <SubmitSaveForLaterFooter
      submitButtonDisabled={!canSubmit()}
      submitButtonClick={() =>
        !training?.quiz?.passedQuiz &&
        training?.quiz?.attemptNumber <= training?.quiz?.numberOfRetriesAllowed
          ? setAdditionalAttempts(true)
          : setMarkAsCompleteModal(true)
      }
      saveButtonClick={() => handleSubmit(false)}
    />
  );

  const canTakeQuiz =
    training?.quiz?.attemptNumber <=
      (training?.quiz?.numberOfRetriesAllowed ?? 1) &&
    !training.quizScores?.some(
      score => score >= training?.quiz.percentToPass
    ) &&
    !training?.isCompleted;

  return (
    <>
      <HeaderAndFooter
        Header={header}
        Footer={footer}
        showFooter={!training?.isCompleted}
        Left={<LeftNav items={getQuickNav()} />}
      >
        <Card
          title={training?.title || defaultTrainingTitle}
          showHeader
          name={0}
          wide
        >
          <Textbox
            currentValue={training?.instructions}
            disabled
            fieldLabel="Instructions"
            type="textarea"
          />
          {training?.dueDate && (
            <IReportDatePicker
              fieldLabel="Due Date"
              currentValue={training?.dueDate}
              disabled
              name="performTrainingDueDate"
            />
          )}
        </Card>
        {training?.attachments?.length || training?.youtubeLinks?.length ? (
          <Card title="Materials" showHeader name={1} wide>
            <BlockText blockOfText="This is where you will see all training materials. Please go through each one by one. When you have completed all the training material, sign at the bottom and submit." />
            {training.minMaterialTime && training.trainingType !== 2 ? (
              <RequiredTime
                attachments={allMaterials}
                minMaterialTime={training.minMaterialTime}
              />
            ) : null}
            <TrainingMaterials
              training={training}
              hideTime={training.trainingType === 2}
              attachments={training?.attachments}
              youtubeLinks={training.youtubeLinks}
              performing={training.trainingType === 1}
            />
          </Card>
        ) : null}
        {training?.quiz && showQuiz ? (
          <QuizStatusCard
            trainingId={training?._id}
            training={training}
            canTakeQuiz={canTakeQuiz}
            wide
          />
        ) : null}
        {getQuickNav().findIndex(nav => nav.label === 'Employee Signature') >
        -1 ? (
          <SignatureCard
            wide
            header="Employee Signature"
            currentValue={training?.employeeSignature}
            name="Employee Signature"
            onChange={v => {
              setTraining({ ...training, employeeSignature: v });
              setUnsavedChanges(true);
              localStorage.setItem('employeeSignature', JSON.stringify(v));
            }}
            ownerType="training"
            ownerId={training?._id}
            disabled={training.isCompleted}
          />
        ) : (
          ''
        )}
      </HeaderAndFooter>
      <SaveChangesModal
        isOpen={openUnsavedChanges}
        savingWhat={training?.title}
        onRequestClose={() => setUnsavedChangesModal(false)}
        submitActions={() => handleBack()}
      />
      <MarkItemAsCompleteModal
        isOpen={openMarkAsCompleteModal}
        completingWhat="Training"
        onRequestClose={() => setMarkAsCompleteModal(false)}
        submitActions={() => handleSubmit(true)}
        cancelActions={() => handleSubmit(false)}
      />
      <Modal
        title="Additional Quiz Attempts"
        titleClassName="blueHeader"
        isOpen={openAddtionalAttempts}
        submitButtonColor="blue"
        submitButtonText="Complete"
        onRequestClose={() => setAdditionalAttempts(false)}
        submitActions={() => handleSubmit(true)}
        cancelActions={() => setAdditionalAttempts(false)}
        cancelButtonText="Cancel"
        cancelButtonColor="white"
      >
        <div className="">
          You have additional attempts available to pass the training quiz. Are
          you sure you would like to submit before completing additional
          attempts?
        </div>
      </Modal>
    </>
  );
}
