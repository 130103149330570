import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { deleteAttachment, uploadAttachment } from '../../../api/v4';
import {
  // fetchEmployee,
  createClaim,
  updateClaim,
  deleteClaim,
  fetchClaimById,
  printClaim
} from '../../../api/v4';
import config from '../../../config/config';
import history from '../../../history';
import { setActiveIncidentRequest } from '../../../actions/incidents';
import { getActiveIncidentSelector } from '../../../selectors/incidents';
import { getMyUserId } from '../../../selectors/users';
import { formatFiles } from '../../../utils/attachmentSelector';
import { useActiveHeirarchy } from '../../../utils/useActiveHeirarchy';
import customToast from '../../../utils/customToast';
import AttachmentUploadCard from '../../../components/AttachmentUploadCard';
import ClaimCard from '../../../components/claims/Cards';
import DeleteItemModal from '../../../components/Modal/deleteItemModal';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import Header from '../../../components/Header';
import InstructionCard from '../../../components/claims/InstructionCard';
import Modal from '../../../components/Modal';
import LeftNav from '../../../components/LeftNav';
import { SubmitSaveForLaterCancelFooter } from '../../../components/Footer';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';

import styles from '../claimCreate.module.scss';

export default function DamageClaim() {
  const activeIncident = useSelector(getActiveIncidentSelector);
  const { activeCompany, activeGroup, activeProject } = useActiveHeirarchy();
  const myUserId = useSelector(getMyUserId);
  const employeeId =
    activeIncident?.answers?.[config.staticIds.propEmployeeInvolved];
  let { claimId } = useParams();
  const dispatch = useDispatch();

  const [id, setId] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [overviewInfo, setOverviewInfo] = useState({});
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [saveChangesModal, setSaveChangesModal] = useState(false);
  const [sumbitModal, setSubmitModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [incidentId, setIncidentId] = useState();
  const [attachments, setAttachments] = useState([]);
  const [accidentInfo, setAccidentInfo] = useState({});
  const [damageInfo, setDamageInfo] = useState({});
  const [policyInfo, setPolicyInfo] = useState({});
  const [creating, setCreating] = useState(false);
  const [accidentEditing, setAccidentEditing] = useState(false);
  const [damageEditing, setDamageEditing] = useState(false);
  const [policyEditing, setPolicyEditing] = useState(false);
  const [availablePolicies, setAvailablePolicies] = useState();

  useEffect(() => {
    if (!claimId) {
      setCreating(true);

      // if (employeeId && !employeeId.includes('Not Applicable')) {
      //   fetchEmployee(employeeId[0]?.value ?? employeeId[0]).then(employee => {
      //     setDamageInfo({
      //       employeeId: employee._id,
      //       employeeFirstName: employee?.firstName,
      //       employeeLastName: employee?.lastName
      //     });
      //   });
      // }

      if (activeProject) {
        setAccidentInfo({
          projectId: activeProject._id,
          groupId: activeGroup._id
        });
        setAvailablePolicies(activeCompany.policies);
        setPermissions(activeCompany.claimPermissions);
      } else if (activeGroup) {
        setAccidentInfo({ groupId: activeGroup._id });
        setAvailablePolicies(activeCompany.policies);
        setPermissions(activeCompany.claimPermissions);
      } else if (activeCompany) {
        setAvailablePolicies(activeCompany.policies);
        setPermissions(activeCompany.claimPermissions);
      }

      const answers = activeIncident?.answers;
      if (answers) {
        let incidentLocation =
          answers[config.staticIds.incidentLocation] &&
          !answers?.[config.staticIds.incidentLocation].includes(
            'Not Applicable'
          )
            ? answers?.[config.staticIds.incidentLocation]?.split(', ')
            : '';

        if (!incidentLocation) {
          const location = activeCompany.groups.find(
            g => g._id === activeIncident.groupId
          );
          incidentLocation = [
            location.streetAddress,
            location.city,
            location.state
          ];
        }

        setAccidentInfo({
          incidentDate: answers[config.staticIds.timeIncidentOccured],
          incidentDescription: answers[config.staticIds.incidentDescription],
          streetAddress: incidentLocation?.[0],
          city: incidentLocation?.[1],
          state: incidentLocation?.[2],
          groupId: activeIncident.groupId,
          projectId: activeIncident.projectId
        });
      }
      if (activeIncident?.incidentActivity?.attachments?.length > 0) {
        const photoEvidence =
          activeIncident?.incidentActivity?.attachments.filter(
            a => a.isPhotoEvidence
          );
        setAttachments(photoEvidence);
      }
    } else {
      fetchClaimById(claimId).then(claim => {
        setClaimInfo(claim);
      });
      if (activeCompany) {
        setAvailablePolicies(activeCompany.policies);
        setPermissions(activeCompany.claimPermissions);
      }
    }
  }, [
    claimId,
    activeProject,
    activeGroup,
    activeCompany,
    activeIncident.answers,
    activeIncident.groupId,
    activeIncident.projectId,
    employeeId,
    activeIncident.incidentActivity.attachments
  ]);

  const setClaimInfo = claim => {
    setId(claim._id);
    setIsSubmitted(claim.isSubmitted);
    setIncidentId(claim.incidentId);
    setOverviewInfo({
      claimNumber: claim.claimNumber,
      previousClaims: claim.previousClaims,
      submissionDate: claim.submissionDate
    });
    setAccidentInfo({
      groupId: claim.groupId,
      projectId: claim.projectId,
      incidentDate: claim.incidentDate,
      streetAddress: claim.incidentLocation?.incidentStreetAddress,
      city: claim.incidentLocation?.incidentCity,
      state: claim.incidentLocation?.incidentState,
      incidentDescription: claim.incidentDescription,
      claimReportedByTitle: claim.claimReportedByTitle,
      claimNotificationOther: claim.claimNotificationOther
    });
    setDamageInfo({
      lossType: claim.lossType,
      lossOccured: claim.lossOccured,
      damageEstimate: claim.damageEstimate,
      lossDamageEvent: claim.lossDamageEvent,
      propertyType: claim.propertyType
      // employeeId: claim.employeeId,
      // employeeFirstName: claim.employeeFirstName,
      // employeeLastName: claim.employeeLastName
    });
    setPolicyInfo({
      policyEffectiveDate: claim.policyId?.policyEffectiveDate,
      policyEndDate: claim.policyId?.policyExpirationDate,
      policyNumber: claim.policyId?.policyNumber,
      lineOfBusinessCode: claim.policyId?.lineOfBusinessCode
    });
    setPermissions(claim.permissions);
    setAttachments(claim.attachments);
  };

  const handleSubmit = isSubmitted => {
    const object = {
      type: 'prop',
      ...accidentInfo,
      incidentLocation: {
        incidentStreetAddress: accidentInfo.streetAddress,
        incidentCity: accidentInfo.city,
        incidentState: accidentInfo.state
      },
      ...policyInfo,
      ...damageInfo,
      incidentId: activeIncident._id,
      isSubmitted,
      permissions: activeCompany.claimPermissions,
      attachments
    };
    if (!id) {
      createClaim(object).then(claim => {
        setClaimInfo(claim);
        setId(claim._id);
        setSubmitModal(false);
        setUnsavedChanges(false);
        setCreating(false);
        if (claim.isSubmitted) {
          customToast('Submitted Successfully!', 'success');
        } else {
          customToast('Saved Successfully!', 'success');
        }
      });
    } else {
      updateClaim({
        _id: id,
        ...object
      }).then(claim => {
        setEditingFalse();
        setClaimInfo(claim);
        setSubmitModal(false);
        if (claim.isSubmitted) {
          customToast('Submitted Successfully!', 'success');
        } else {
          customToast('Saved Successfully!', 'success');
        }
      });
    }
  };

  const setEditingFalse = () => {
    setAccidentEditing(false);
    setUnsavedChanges(false);
  };

  const handleDelete = () => {
    deleteClaim(id).then(() => history.goBack());
  };

  const handleCancel = goBack => {
    if (goBack) history.goBack();
    if (id && (accidentEditing || policyEditing || damageEditing)) {
      setEditingFalse();
    } else if (unsavedChanges && !saveChangesModal) {
      setSaveChangesModal(true);
    } else {
      history.goBack();
    }
  };

  const print = () => {
    printClaim(id).then(response => window.open(response, '_blank'));
  };

  const accidentInfoComplete = !(
    !accidentInfo.groupId ||
    !accidentInfo.incidentDate ||
    !accidentInfo.incidentDescription ||
    !accidentInfo.streetAddress ||
    !accidentInfo.city ||
    !accidentInfo.state ||
    !accidentInfo.claimReportedByTitle
  );

  const damageInfoComplete = !(
    !damageInfo.lossOccured ||
    !damageInfo.lossType ||
    !damageInfo.damageEstimate ||
    !damageInfo.lossDamageEvent ||
    !damageInfo.propertyType
  );

  const policyInfoComplete = !(
    !policyInfo.policyNumber ||
    !policyInfo.policyEffectiveDate ||
    !policyInfo.policyEndDate ||
    !policyInfo.lineOfBusinessCode
  );

  const canSubmit =
    accidentInfoComplete && damageInfoComplete && policyInfoComplete;

  const isReadOnly = permissions?.find(p => p.userId === myUserId)?.readOnly;

  const addAttachments = attachment => {
    if (!attachment.length > 0) return;
    let data = formatFiles(attachment);
    uploadAttachment(data, {
      ownerType: 'claim',
      isSignature: false,
      isPhotoEvidence: false
    }).then(response => {
      setAttachments([...attachments, ...response]);
    });
  };

  const deleteAtt = attachment => {
    deleteAttachment(attachment._id).then(() => {
      let a = attachments.filter(a => a !== attachment);
      setAttachments([...a]);
    });
  };

  const header = (
    <Header
      title={activeIncident?.incidentNumber}
      section="Damage Claim"
      pageActionOptions={[
        {
          label: 'Permissions',
          visible: !creating && !isReadOnly,
          color: 'blueOutline',
          onClick: () => history.push(`/app/permissions/Claim/${id}`)
        },
        {
          label: 'Return to Incident',
          visible: true,
          color: 'blueOutline',
          onClick: () =>
            dispatch(setActiveIncidentRequest(incidentId ?? activeIncident._id))
        },
        {
          label: 'Download Claim',
          visible: isSubmitted,
          color: 'greenOutline',
          onClick: print
        },
        {
          label: 'Delete Claim',
          visible: !creating && isSubmitted && !isReadOnly,
          color: 'redOutline',
          onClick: handleDelete
        }
      ]}
      clickBack={() =>
        unsavedChanges ? setSaveChangesModal(true) : history.goBack()
      }
      needsSaved={unsavedChanges}
    />
  );

  const footer = (
    <SubmitSaveForLaterCancelFooter
      submitButtonClick={() => setSubmitModal(true)}
      saveButtonClick={() => handleSubmit(false)}
      submitButtonDisabled={!canSubmit}
      cancelButtonClick={handleCancel}
    />
  );

  const left = (
    <LeftNav
      items={[
        {
          value: 0,
          label: isSubmitted ? 'Overview' : 'Instructions',
          complete: true
        },
        {
          value: 1,
          label: 'Accident Information',
          complete: accidentInfoComplete
        },
        {
          value: 2,
          label: 'Damage Information',
          complete: damageInfoComplete
        },
        {
          value: 3,
          label: 'Policy Information',
          complete: policyInfoComplete
        },
        { value: 4, label: 'Claim Attachments' }
      ]}
    />
  );

  return (
    <HeaderAndFooter
      Header={header}
      Footer={footer}
      Left={left}
      showFooter={!isSubmitted && !isReadOnly}
    >
      {isSubmitted ? (
        <ClaimCard
          name={0}
          claimSection={overviewInfo}
          title="Overview"
          viewOnly
        />
      ) : (
        <InstructionCard name={0} />
      )}
      <ClaimCard
        type="damage"
        name={1}
        editing={creating || accidentEditing}
        viewOnly={isReadOnly}
        claimSection={accidentInfo}
        onChange={values => {
          setAccidentInfo(values);
          setUnsavedChanges(true);
        }}
        title="Accident Information"
        openEdit={setAccidentEditing}
        isSubmitted={isSubmitted}
        permissions={permissions}
      />
      <ClaimCard
        type="damage"
        name={2}
        editing={creating || damageEditing}
        viewOnly={isReadOnly}
        claimSection={damageInfo}
        onChange={values => {
          setDamageInfo(values);
          setUnsavedChanges(true);
        }}
        title="Damage Information"
        openEdit={setDamageEditing}
        isSubmitted={isSubmitted}
        permissions={permissions}
      />
      <ClaimCard
        name={3}
        editing={creating || policyEditing}
        viewOnly={isReadOnly}
        claimSection={policyInfo}
        onChange={values => {
          setPolicyInfo(values);
          setUnsavedChanges(true);
        }}
        title="Policy Information"
        openEdit={setPolicyEditing}
        isSubmitted={isSubmitted}
        availablePolicies={availablePolicies}
      />
      <AttachmentUploadCard
        name={4}
        addAttachments={addAttachments}
        deleteAttachment={deleteAtt}
        isEditing={!isSubmitted}
        addedAttachments={attachments}
        attachmentsToUpload={[]} // not sure if I need this
        ownerType="claim"
        createdBy={myUserId}
      />
      <SaveChangesModal
        onRequestClose={() => setSaveChangesModal(false)}
        isOpen={saveChangesModal}
        submitActions={() => handleCancel(true)}
      />
      <DeleteItemModal
        onRequestClose={() => setDeleteModal(false)}
        isOpen={deleteModal}
        submitActions={handleDelete}
        hideCustomTemplate
        what="Claim"
      />
      <Modal
        title="Submit Claim"
        titleClassName="blueHeader"
        isOpen={sumbitModal}
        submitButtonColor="blue"
        submitButtonText="Submit"
        onRequestClose={() => setSubmitModal(false)}
        submitActions={() => handleSubmit(true)}
      >
        <div className={styles.text}>
          Once you submit the claim the data will no longer update within the
          incident or for your insurance. Make sure you have attached all
          supporting documentation before you submit.
        </div>
      </Modal>
    </HeaderAndFooter>
  );
}
