import React from 'react';
import Modal from '.';

export default function HideLocationModal({
  isOpen,
  onRequestClose,
  submitActions,
  locationType,
  location
}) {
  const locationMessage = `${
    locationType === 'area' ? 'an' : 'a'
  } ${locationType}`;
  return (
    <Modal
      title={`${location?.isHidden ? 'Show' : 'Hide'} ${
        locationType.charAt(0).toUpperCase() + locationType.slice(1)
      }`}
      titleClassName="blueHeader"
      isOpen={isOpen}
      submitButtonColor="blue"
      onRequestClose={onRequestClose}
      submitActions={() => submitActions()}
    >
      <h2>
        Are you sure you want to {location?.isHidden ? 'show ' : 'hide '}
        {location?.name}?
      </h2>
      {location?.isHidden ? (
        <h3>
          Showing {locationMessage} means that it will show up on the home page
          and will still show on this page.
        </h3>
      ) : (
        <h3>
          Hiding {locationMessage} means that it won't show up on the home page
          but will still show on this page.
        </h3>
      )}
    </Modal>
  );
}
