import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import classnames from 'classnames/bind';
import history from '../../../history';
import {
  fetchSafetyAuditById,
  updateSafetyAuditStage,
  filterSafetyAuditSections,
  setSafetyAuditNumber,
  deleteSafetyAudit,
  updateNotes,
  deleteNoteById,
  deleteAttachment,
  updateSafetyAudit
} from '../../../api/v4';
import { useSocket } from '../../../utils/withSocket';
import AdditionalCommentsCard from '../../../components/safetyAuditCards/AdditionalCommentsCard';
import Card from '../../../components/Card';
import DeleteItemModal from '../../../components/Modal/deleteItemModal';
import {
  Dropdown,
  TwoColumn,
  Button,
  InputRow
} from '../../../components/inputs';
import Header from '../../../components/Header';
import { SaveCancelFooter } from '../../../components/Footer';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import ProgressBar from '../../../components/ProgressBar';
import QuestionCard from '../../../components/safetyAuditCards/QuestionCard';
import StageSelector from '../../../components/StageSelector';
import SummaryCard from '../../../components/safetyAuditCards/SummaryCard';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';
import { getLoggedInUser } from '../../../selectors/users';
import Modal from '../../../components/Modal';
import isEqual from 'lodash/isEqual';
import { SAFETY_AUDIT_EXPORT_OPTIONS } from '../../../constants/constants';

import styles from './summary.module.scss';
import customToast from '../../../utils/customToast';

const bStyles = classnames.bind(styles);

export default function Summary() {
  const socket = useSocket();
  const user = useSelector(getLoggedInUser);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [audit, setAudit] = useState({});
  const [sections, setSections] = useState([]);
  const [additionalNotes, setAdditionalNotes] = useState();
  const [buttonText, setButtonText] = useState('Collapse All Sections');
  const [caOptions, setCaOptions] = useState([]);
  const [responseOptions, setResponseOptions] = useState([]);
  const [collapsed, setCollapsed] = useState([]);
  const [generated, setGenerated] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [showDeleteImageModal, setShowDeleteImageModal] = useState(false);
  const [showDeleteNotesModal, setShowDeleteNotesModal] = useState(false);
  const [openSaveChangesModal, setOpenSaveChangesModal] = useState(false);
  const [generatingModal, setGeneratingModal] = useState(false);
  const [updatedNotes, setUpdatedNotes] = useState([]);
  const [noteToDelete, setNoteToDelete] = useState([]);
  const [imageToDelete, setImageToDelete] = useState({
    id: null,
    sectionNumber: null,
    questionNumber: null
  });
  const [exportOptions, setOptions] = useState({});
  const [missingRequired, setMissingRequired] = useState(false);

  const { safetyAuditId } = useParams();
  const location = useLocation();
  const auditType = location.pathname.includes('safety')
    ? 'safety'
    : location.pathname.includes('quality')
      ? 'quality'
      : 'loto';
  const auditTypeName =
    auditType === 'loto'
      ? 'Lockout/Tagout Procedure'
      : `${auditType.charAt(0).toUpperCase() + auditType.slice(1)} Audit`;
  const loggedInUser = useSelector(getLoggedInUser);

  useEffect(() => {
    if (safetyAuditId) {
      fetchSafetyAuditById(safetyAuditId).then(response => {
        const localupdatedNotes = localStorage.getItem('updatedNotes');
        const localEditing = localStorage.getItem('editing') || null;

        if (localupdatedNotes && localupdatedNotes !== 'undefined') {
          const parsedLocalNotes = JSON.parse(localupdatedNotes);
          if (parsedLocalNotes.length) {
            setUpdatedNotes(parsedLocalNotes);
            response.sections.map((section, si) => {
              return section.questions.map((question, qi) => {
                return question.notes.map((note, ni) => {
                  const foundNote = parsedLocalNotes.find(
                    localNote => localNote._id === note._id
                  );
                  if (foundNote !== undefined) {
                    response.sections[si].questions[qi].notes[ni] = foundNote;
                  }
                  return foundNote;
                });
              });
            });
          }
        }

        if (localEditing && localEditing !== 'undefined') {
          setEditing(JSON.parse(localEditing));
        }
        setAudit(response);
        setAdditionalNotes(response.additionalNotes);
        setSections(response.sections);
        const c = [];
        response.sections.map(() => c.push({ collapsed: false }));
        setCollapsed(c);
      });
    }
  }, [safetyAuditId]);

  useEffect(() => {
    if (!socket) return;
    socket.emit('subscribeRa', safetyAuditId, {});

    socket.on('raPdf', value => {
      setGenerated(value);
      setLoading(false);
    });

    socket.on('printFail', error => {
      setGenerated(null);
      customToast(`Could not generate Audit: ${error}`, 'error');
    });
  }, [safetyAuditId, socket]);

  const onFilterUpdate = (fo, type) => {
    if (type === 'response') {
      setResponseOptions(fo);
      filterSafetyAuditSections(audit._id, {
        response: fo,
        ca: caOptions
      }).then(response => setSections(response));
    } else {
      setCaOptions(fo);
      filterSafetyAuditSections(audit._id, {
        response: responseOptions,
        ca: fo
      }).then(response => setSections(response));
    }
  };

  const handleCollaspedSection = index => {
    collapsed[index].collapsed = !collapsed[index].collapsed;
    setCollapsed([...collapsed]);
  };

  const handleCollaspeAll = () => {
    let c = collapsed.map(() => {
      return { collapsed: buttonText === 'Collapse All Sections' };
    });
    setCollapsed([...c]);
    if (buttonText === 'Collapse All Sections') {
      setButtonText('Expand All Sections');
    } else {
      setButtonText('Collapse All Sections');
    }
  };

  const sectionData = sectionIndex => {
    const scorableSections = audit?.sections.filter(s => s.type === 'scorable');
    const sectionToCompare = audit?.sections[sectionIndex];
    const dataIndex = scorableSections?.findIndex(s =>
      isEqual(s, sectionToCompare)
    );

    return audit?.summary?.data[dataIndex];
  };

  const submitSafetyAuditName = newName => {
    setAudit(audit => ({ ...audit, safetyAuditNumber: newName }));
    setSafetyAuditNumber(audit._id, newName);
  };

  const initiateGoBack = () => {
    if (editing && updatedNotes.length) {
      setOpenSaveChangesModal(true);
    } else {
      handleGoBack();
    }
  };

  const handleGoBack = () => {
    localStorage.removeItem('editing');
    localStorage.removeItem('updatedNotes');
    history.goBack();
  };

  const name =
    generated === null
      ? 'Generate PDF Link'
      : generated?.url
        ? 'Regenerate PDF'
        : 'Generating PDF';

  const initiateDeleteNote = (sectionNumber, questionNumber, note) => {
    setNoteToDelete({ sectionNumber, questionNumber, note });
    setShowDeleteNotesModal(true);
  };

  const deleteNote = () => {
    const { sectionNumber, questionNumber, note } = noteToDelete;

    deleteNoteById({
      note: note,
      ownerId: safetyAuditId,
      ownerType: `${audit.type}AuditQuestion`
    });

    let newSections = [...sections];

    const index = newSections[sectionNumber].questions[
      questionNumber - 1
    ].notes.findIndex(object => object._id === note._id);

    newSections[sectionNumber].questions[questionNumber - 1].notes[
      index
    ].isRemoved = true;

    let newUpdatedNotes = [...updatedNotes];
    const updatedNotesIndex = updatedNotes.findIndex(
      updateNote => updateNote._id === note._id
    );

    if (updatedNotesIndex !== -1) {
      newUpdatedNotes[updatedNotesIndex].isRemoved = true;
    }

    setGenerated(null);
    setUpdatedNotes(newUpdatedNotes);
    setSections(newSections);
    setShowDeleteNotesModal(false);
  };

  const handleChangeNotes = (sectionNumber, questionNumber, note, value) => {
    let newUpdatedNotes = [...updatedNotes];
    const index = updatedNotes.findIndex(
      updateNote => updateNote._id === note._id
    );
    if (index === -1) {
      newUpdatedNotes.push(note);
    } else {
      newUpdatedNotes[index].value = value;
    }

    setUpdatedNotes(newUpdatedNotes);
    localStorage.setItem('updatedNotes', JSON.stringify(newUpdatedNotes));
  };

  const initiateDeletePhoto = (item, sectionNumber, questionNumber) => {
    setImageToDelete({ id: item._id, sectionNumber, questionNumber });
    setShowDeleteImageModal(true);
  };

  const completeDeletePhoto = () => {
    const { id, sectionNumber, questionNumber } = imageToDelete;
    let newSections = sections;

    newSections[sectionNumber].questions[questionNumber - 1].attachments =
      sections[sectionNumber].questions[questionNumber - 1].attachments.filter(
        image => image._id !== id
      );
    setSections(newSections);
    deleteAttachment(id);
    setShowDeleteImageModal(false);
  };

  const handleSubmit = () => {
    localStorage.removeItem('editing');
    localStorage.removeItem('updatedNotes');
    setShowDeleteNotesModal(false);
    updateNotes({
      notes: updatedNotes.filter(note => note.value !== ''),
      ownerId: safetyAuditId,
      ownerType: 'safetyAudit'
    })
      .then(() => {
        customToast(`Notes Saved Successfully!`, 'success');
      })
      .catch(err => {
        customToast(`An Error Occurred. Please try again.`, 'error');
      });

    if (updatedNotes.length) {
      setGenerated(null);
    }

    setEditing(false);
    setUpdatedNotes([]);
  };

  const handleArchive = () => {
    updateSafetyAudit({
      ...audit,
      isArchived: !audit?.isArchived
    }).then(response => {
      if (response.isArchived) history.goBack();
      else setAudit(response);
    });
  };

  const header = (
    <Header
      title={`${auditTypeName} Summary`}
      section={audit.safetyAuditNumber}
      editName={submitSafetyAuditName}
      clickBack={initiateGoBack}
      center={
        <StageSelector
          label={`${auditTypeName} Stage`}
          options={[
            { label: 'Severity Review', value: 2 },
            { label: 'Closed', value: 3 }
          ]}
          currentValue={audit?.currentWfStage}
          onChange={option =>
            updateSafetyAuditStage({
              _id: audit._id,
              currentWfStage: option
            }).then(setAudit)
          }
          disabled={audit?.isArchived}
        />
      }
      pageActionOptions={[
        {
          label: 'Edit Notes/Photos',
          visible:
            audit?.isCompleted &&
            loggedInUser.accessLevel === 900 &&
            !editing &&
            !audit?.isArchived,
          color: 'blueOutline',
          onClick: () => {
            setEditing(true);
          },
          disabled: loading || name === 'Generating PDF'
        },
        {
          label: name,
          visible: !editing && !audit?.isArchived,
          color: 'blueOutline',
          onClick: () => setGeneratingModal(true),
          disabled: loading || name === 'Generating PDF'
        },
        {
          label: 'Download PDF',
          onClick: () => window.open(generated.url, '_blank'),
          color: 'greenOutline',
          visible: generated?.url && !editing
        },
        {
          label: `Delete ${auditTypeName}`,
          visible: true,
          color: 'redOutline',
          onClick: () => setOpenDeleteModal(true)
        },
        {
          label: `${audit?.isArchived ? 'Un-' : ''}Archive ${auditTypeName}`,
          visible: loggedInUser.accessLevel > 400,
          color: 'blueOutline',
          onClick: () => handleArchive()
        }
      ]}
    />
  );

  const tooltips = audit?.summary?.data?.map(s => {
    return (
      <div className={styles.tooltip}>
        <div className={bStyles({ greenOutline: true })}>
          <span
            className={bStyles({ white: true })}
          >{`${s?.acceptable}/${s?.total}`}</span>{' '}
          Marked Acceptable
        </div>
        <div className={bStyles({ redOutline: true })}>
          <span
            className={bStyles({ white: true })}
          >{`${s?.unacceptable}/${s?.total}`}</span>{' '}
          Marked Unacceptable
        </div>
        <div className={bStyles({ greyOutline: true })}>
          <span
            className={bStyles({ white: true })}
          >{`${s?.notApplicable}/${s?.total}`}</span>{' '}
          Marked Not Applicable
        </div>
      </div>
    );
  });

  const footer = (
    <SaveCancelFooter
      editing={editing}
      saveButtonDisabled={updatedNotes?.some(
        note => note?.value?.trim() === '' && !note.isRemoved
      )}
      saveButtonClick={() => handleSubmit()}
      cancelButtonClick={() => {
        if (updatedNotes.length) {
          setOpenSaveChangesModal(true);
        } else {
          handleGoBack();
        }
      }}
    />
  );
  const canSubmitPdf =
    exportOptions?.sortType === 'response'
      ? exportOptions.questionTypes
      : exportOptions?.sortType;
  return (
    <HeaderAndFooter Header={header} showFooter={editing} Footer={footer}>
      <SummaryCard audit={audit} />
      <AdditionalCommentsCard notes={additionalNotes} disabled />
      <Card showHeader title="Filters" summaryCardWide>
        <TwoColumn>
          <Dropdown
            fieldLabel="Response"
            options={[
              { value: 1, label: 'Acceptable' },
              { value: 2, label: 'Unacceptable' },
              { value: 3, label: 'Not Applicable' }
            ]}
            multi
            currentValue={responseOptions}
            onChange={values => onFilterUpdate(values, 'response')}
          />
          <Dropdown
            fieldLabel="Corrective Action"
            options={[
              { value: 'complete', label: 'Completed' },
              { value: 'pastDue', label: 'Past Due' },
              { value: 'assigned', label: 'Assigned' },
              { value: 'notAssigned', label: 'Not Assigned' }
            ]}
            multi
            currentValue={caOptions}
            onChange={values => onFilterUpdate(values, 'correctiveAction')}
          />
        </TwoColumn>
      </Card>
      <InputRow className={styles.overallFooter} space>
        <Button
          color="blue"
          text={buttonText}
          onClick={() => handleCollaspeAll()}
        />
      </InputRow>
      {sections?.map((section, i) => (
        <Card
          key={i}
          title={`${section.title}: ${section.questions.length} Questions`}
          showHeader
          collapsible
          name={i}
          rightButton={
            <Button
              color="white"
              imageClass={bStyles({ flipChevron: collapsed?.[i]?.collapsed })}
              image={require('../../../assets/images/Chevron.png')}
              onClick={() => handleCollaspedSection(i)}
            />
          }
          collapsed={collapsed?.[i]?.collapsed}
          showSubHeader
          subHeader={
            section?.type === 'scorable' ? (
              <div>
                <div className={styles.sectionTitle}>
                  Section Score: {Math.round(sectionData(i)?.value)}%
                </div>
                <ProgressBar
                  showTooltip
                  tooltip={tooltips[i]}
                  className={styles.progressBar}
                  scale={sectionData(i)?.total}
                  bars={[
                    {
                      width: sectionData(i)?.acceptable,
                      color: 'green'
                    },
                    {
                      width: sectionData(i)?.unacceptable,
                      color: 'red'
                    },
                    {
                      width: sectionData(i)?.notApplicable,
                      color: 'grey'
                    }
                  ]}
                  name={`progressBar-${i}`}
                />
              </div>
            ) : null
          }
          summaryCardWide
        >
          {section.questions.map((question, questionIndex) => (
            <QuestionCard
              isCompleted
              section={section}
              question={question}
              questionNumber={`${questionIndex + 1}`}
              key={questionIndex}
              locationId={audit.groupId}
              editingNotes={editing}
              initiateDeleteNote={initiateDeleteNote}
              sectionNumber={i}
              initiateDeletePhoto={initiateDeletePhoto}
              handleChangeNotes={handleChangeNotes}
              audit={audit}
              updatedNotes={updatedNotes}
              isArchived={audit?.isArchived}
            />
          ))}
        </Card>
      ))}
      <DeleteItemModal
        isOpen={openDeleteModal}
        deletingWhat={`${auditTypeName}`}
        onRequestClose={() => setOpenDeleteModal(false)}
        submitActions={() =>
          deleteSafetyAudit(audit).then(() =>
            history.push(`/app/audits/${auditType}`)
          )
        }
      />
      <DeleteItemModal
        isOpen={showDeleteImageModal}
        deletingWhat={`${auditTypeName} Image`}
        onRequestClose={() => setShowDeleteImageModal(false)}
        submitActions={completeDeletePhoto}
        hideCustomTemplate
      />
      <DeleteItemModal
        isOpen={showDeleteNotesModal}
        deletingWhat="Note"
        onRequestClose={() => setShowDeleteNotesModal(false)}
        submitActions={deleteNote}
        hideCustomTemplate
      />
      <Modal
        title="Export Options"
        titleClassName="blueHeader"
        isOpen={generatingModal}
        onRequestClose={() => setGeneratingModal(false)}
        submitButtonText="Generate"
        submitButtonColor="blue"
        submitActions={() => {
          if (!missingRequired) {
            socket.emit('generateRaPdf', audit?._id, {
              user: user._id,
              ...exportOptions
            });
            setGenerated(false);
            setGeneratingModal(false);
            setLoading(true);
            setOptions();
          }
        }}
        cancelActions={() => {
          setOptions({});
          setGeneratingModal(false);
        }}
        onMouseEnter={() => setMissingRequired(!canSubmitPdf)}
        submitTooltip={missingRequired ? 'Missing or Invalid Fields' : null}
      >
        <Dropdown
          options={[
            { label: 'By Section', value: 'section' },
            { label: 'By Response', value: 'response' }
          ]}
          onChange={v => setOptions({ ...exportOptions, sortType: v })}
          currentValue={exportOptions?.sortType}
          fieldLabel="Sort Questions"
          className={styles.titleDropdown}
          searchable
          selectButtons
          isRequired
          touched={missingRequired && !exportOptions?.sortType}
        />
        {exportOptions?.sortType === 'response' ? (
          <Dropdown
            options={[
              {
                label: 'Unacceptable Findings',
                value: 'Unacceptable Findings'
              },
              { label: 'Acceptable Findings', value: 'Acceptable Findings' },
              { label: 'Not Applicable', value: 'Not Applicable' },
              { label: 'Ungraded', value: 'Ungraded' }
            ]}
            onChange={v => setOptions({ ...exportOptions, questionTypes: v })}
            currentValue={exportOptions?.questionTypes}
            fieldLabel="Filter Responses"
            className={styles.titleDropdown}
            multi
            searchable
            selectButtons
            isRequired
            touched={missingRequired && !exportOptions.questionTypes}
          />
        ) : null}
        <Dropdown
          options={SAFETY_AUDIT_EXPORT_OPTIONS}
          onChange={v => setOptions({ ...exportOptions, reportOptions: v })}
          currentValue={exportOptions?.reportOptions}
          fieldLabel="Additional Options"
          className={styles.titleDropdown}
          multi
          searchable
          selectButtons
        />
      </Modal>
      <SaveChangesModal
        savingWhat={`${auditTypeName} Comments`}
        isOpen={openSaveChangesModal}
        onRequestClose={() => setOpenSaveChangesModal(false)}
        submitActions={() => handleGoBack()}
      />
    </HeaderAndFooter>
  );
}
