import React from 'react';
import './svg.css';

const sds = props => (
  <svg
    width={props.width ?? 30}
    height={props.height ?? 25}
    viewBox="0 0 80 80"
    x={props.x || '0px'}
    y={props.y || '0px'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1224_131799)">
      <path d="M54.7813 14.7213C54.7813 16.5719 56.2957 18.1702 58.2302 18.1702H72.0262C71.7737 17.8337 71.6056 17.4971 71.269 17.245L55.7065 1.68246C55.454 1.42998 55.1178 1.17778 54.7812 0.925293L54.7813 14.7213Z" />
      <path d="M58.2309 22.7129C53.8567 22.7129 50.3234 19.1799 50.3234 14.8054V0H14.7399C11.6276 0 9.01953 2.52365 9.01953 5.72041V74.2796C9.01953 77.392 11.5432 80 14.7399 80H67.2319C70.3442 80 72.9523 77.4764 72.9523 74.2796L72.952 22.713L58.2309 22.7129Z" />
    </g>
    <defs>
      <clipPath id="clip0_1224_131799">
        <rect width="80" height="80" />
      </clipPath>
    </defs>
  </svg>
);

export default sds;
