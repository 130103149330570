import React from 'react';
import './svg.css';

const analytics = props => (
  <svg
    width={props.width ?? 30}
    height={props.height ?? 25}
    x={props.x || '0px'}
    y={props.y || '0px'}
    viewBox="0 0 80 80 "
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.9091 62.7546H25.9832C26.8221 62.7546 27.5213 62.0532 27.5213 61.2165V39.5801C27.5213 39.128 27.3572 38.7835 27.1111 38.5361C27.0967 38.5207 27.0845 38.5085 27.0701 38.4941C27.0547 38.4807 27.0435 38.4666 27.0281 38.4541C26.782 38.207 26.4376 38.042 25.9832 38.042H12.9092C12.0725 38.042 11.3711 38.7425 11.3711 39.5801V61.2165C11.3711 62.0554 12.0725 62.7546 12.9092 62.7546L12.9091 62.7546Z" />
    <path d="M33.7197 62.7548H46.7913C47.6289 62.7548 48.3294 62.0534 48.3294 61.2168V30.1632C48.3294 29.7111 48.1643 29.3666 47.9182 29.1205C47.9048 29.1052 47.8907 29.0917 47.8782 29.0776C47.8628 29.0641 47.8516 29.05 47.8362 29.0366C47.5901 28.7895 47.2457 28.6245 46.7913 28.6245H33.7197C32.8831 28.6245 32.1816 29.3237 32.1816 30.1626V61.2161C32.1816 62.0556 32.8831 62.7549 33.7197 62.7549L33.7197 62.7548Z" />
    <path d="M54.5302 62.7542H67.6018C68.4394 62.7542 69.1399 62.0527 69.1399 61.2161V19.9235C69.1399 19.4714 68.9749 19.1269 68.7288 18.8808C68.7153 18.8654 68.7022 18.852 68.6887 18.8379C68.6734 18.8244 68.6621 18.8103 68.6468 18.7968C68.4007 18.5498 68.0562 18.3848 67.6019 18.3848H54.5303C53.6926 18.3848 52.9922 19.0839 52.9922 19.9228V61.2138C52.9922 62.0543 53.6927 62.7535 54.5303 62.7535L54.5302 62.7542Z" />
    <path d="M76.946 67.8986H5.62107V9.53783C5.62107 8.6989 4.91964 7.99976 4.08299 7.99976H3.05761C2.21868 7.99976 1.51953 8.69894 1.51953 9.53783V70.462C1.51953 71.2996 2.21871 72.0001 3.05761 72.0001H76.946C77.7826 72.0001 78.484 71.2996 78.484 70.462V69.4366C78.484 68.5987 77.7858 67.8986 76.946 67.8986Z" />
  </svg>
);

export default analytics;
