import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  generateHeadcountTemplate,
  generateHoursTemplate,
  uploadOshaCsv,
  getHoursWorked
} from '../../api/v4';
import { getAddedAttachmentsSelector } from '../../selectors/attachments';
import { clearUploadedAttachments } from '../../actions/attachments';
import { setActiveIncidentRequest } from '../../actions/incidents';
import {
  createOshaLogRequest,
  fetchOshaLogsRequest,
  printOshaRequest,
  printSelectedOshaRequest
} from '../../actions/oshaLogs';
import { getUserCompaniesSelector } from '../../selectors/company';
import {
  getOsha300ALogSelector,
  getOsha300LogSelector
} from '../../selectors/oshaLogs';
import useActiveHeirarchy from '../../utils/useActiveHeirarchy';
import { getAccessLevel } from '../../selectors/users';
import yearToDateDropdown from '../../utils/yeartoDateDropdown';
import Button from '../../components/inputs/Button';
import Dropdown from '../../components/inputs/Dropdown';
import Modal from '../../components/Modal';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Header from '../../components/Header';
import Osha300List from '../../components/Osha300List';
import Osha300AForm from '../../components/Osha300AForm';
import Osha301List from '../../components/Osha301List';
import OshaLogDownload from '../../components/OshaLogDownload';
import { Checkbox, InputRow } from '../../components/inputs';
import Signature from '../../components/inputs/Signature';
import CSVUploader from '../../components/CSVUploader';
import TypeSelection from '../../components/TypeSelection';

import './index.css';

export default function OshaLog() {
  const dispatch = useDispatch();
  const params = useParams();
  const { activeCompany, activeGroup, activeProject } = useActiveHeirarchy();

  const osha300Logs = useSelector(getOsha300LogSelector);
  const osha300ALogs = useSelector(getOsha300ALogSelector);
  const userCompanies = useSelector(getUserCompaniesSelector);
  const accessLevel = useSelector(getAccessLevel);
  const addedAttachments = useSelector(getAddedAttachmentsSelector);

  const [selected, setSelected] = useState(0);
  const [year, setYear] = useState(new Date().getFullYear());
  const [selectedRow, setSelectedRow] = useState({});
  const [divisionEstInfo, setDivisionEstInfo] = useState({});
  const [openModal, setOpenModal] = useState(null);
  const [printValues, setPrintValues] = useState([]);
  const [canPrint300ACSV, setCanPrint300ACSV] = useState(true);
  const [uploadProblems, setProblems] = useState([]);
  const [missingNoInformation, setMissingNoInformation] = useState(true);
  const [includeSignature, setIncludeSignature] = useState(false);
  const [signature, setSignature] = useState(null);
  const [hours, setTotalHours] = useState(null);

  const modalTypes = ['Group/Est.', 'Areas'];
  const [modalHierarchy, setModalHierarchy] = useState(modalTypes[0]);

  useEffect(() => {
    dispatch(fetchOshaLogsRequest({ year: new Date().getFullYear() }));
    const compAddress = activeCompany?.mainAddress;
    if (activeGroup !== 0) {
      let location;
      let longerThanYear;
      let sepEst;
      const locationIndex = activeCompany.groups?.findIndex(
        location =>
          location._id === activeGroup.fromLocationId ||
          location._id === activeGroup._id
      );

      if (locationIndex > -1) {
        location = activeCompany.groups[locationIndex];

        longerThanYear = location.operationalForYearOrLonger;
        sepEst = location.isSeparateEstablishment;
      }
      if (activeProject) {
        let project = activeProject;
        const projectObject =
          project.isSeparateEstablishment && longerThanYear && sepEst
            ? {
                name: project.name,
                mainAddress: {
                  streetAddress: project.streetAddress,
                  city: project.city,
                  stateName: project.state,
                  zip: project.zip
                },
                NAICS: location.NAICS,
                SIC: location.SIC,
                entityCode: project.entityCode,
                bwcNumber: project.bwcNumber,
                reportingType: project.reportingType,
                reportingTypeNumber: project?.reportingTypeNumber,
                hasPublicEntity: project.hasPublicEntity,
                hasSchoolEntity: project.hasSchoolEntity,
                numberOfAllOtherSupportStaff:
                  project.numberOfAllOtherSupportStaff,
                numberOfFireEmtParamedic: project.numberOfFireEmtParamedic,
                numberOfFullTime: project.numberOfFullTime,
                numberOfPartTime: project.numberOfPartTime,
                numberOfPoliceFireEmt: project.numberOfPoliceFireEmt,
                numberOfTeachersInstructors:
                  project.numberOfTeachersInstructors,
                isSeparateEstablishment: location.isSeparateEstablishment,
                isSeparateProject: true,
                locationName: location.name
              }
            : project
              ? {
                  ...activeCompany,
                  name: project.name,
                  mainAddress: {
                    streetAddress: location.isSeparateEstablishment
                      ? location.streetAddress
                      : compAddress?.streetAddress,
                    city: location.isSeparateEstablishment
                      ? location.city
                      : compAddress?.city,
                    stateName: location.isSeparateEstablishment
                      ? location.state
                      : compAddress?.stateName,
                    zip: location.isSeparateEstablishment
                      ? location.zip
                      : compAddress?.zip
                  },
                  isSeparateEstablishment: location.isSeparateEstablishment,
                  isSeparateProject: false,
                  locationName: location.name,
                  reportingTypeNumber: location?.reportingTypeNumber
                }
              : {
                  ...activeCompany,
                  name: location.name,
                  isSeparateEstablishment: location.isSeparateEstablishment,
                  isSeparateProject: false,
                  locationName: location.name
                };

        setDivisionEstInfo({
          ...projectObject,
          goToLocation: longerThanYear
        });
      } else {
        const locationObject =
          longerThanYear && sepEst
            ? {
                name: location.name,
                mainAddress: {
                  streetAddress: location.streetAddress,
                  city: location.city,
                  stateName: location.state,
                  zip: location.zip
                },
                NAICS: location.NAICS,
                SIC: location.SIC,
                entityCode: location.entityCode,
                bwcNumber: location.bwcNumber,
                reportingType: location.reportingType,
                hasPublicEntity: location.hasPublicEntity,
                hasSchoolEntity: location.hasSchoolEntity,
                numberOfAllOtherSupportStaff:
                  location.numberOfAllOtherSupportStaff,
                numberOfFireEmtParamedic: location.numberOfFireEmtParamedic,
                numberOfFullTime: location.numberOfFullTime,
                numberOfPartTime: location.numberOfPartTime,
                numberOfPoliceFireEmt: location.numberOfPoliceFireEmt,
                numberOfTeachersInstructors:
                  location.numberOfTeachersInstructors,
                isSeparateEstablishment: true,
                reportingTypeNumber: location?.reportingTypeNumber
              }
            : {
                ...activeCompany,
                name: location?.name,
                isSeparateEstablishment: false
              };

        setDivisionEstInfo({
          ...locationObject,
          goToLocation: longerThanYear
        });
      }
    } else {
      setDivisionEstInfo({
        ...activeCompany,
        goToLocation: false,
        isSeparateEstablishment: true
      });
    }

    if (params.year && params.year !== '') {
      setYear(params.year);
      setSelected(1);
    }
  }, [activeCompany, activeGroup, activeProject, dispatch, params.year]);

  useEffect(() => {
    if (osha300Logs) {
      let incompleteCases = false;

      osha300Logs &&
        osha300Logs.forEach(item => {
          if (item.isIncomplete) {
            incompleteCases = true;
          }
        });

      if (incompleteCases) {
        setCanPrint300ACSV(false);
        setMissingNoInformation(false);
      } else {
        setCanPrint300ACSV(true);
        setMissingNoInformation(true);
      }
    }
  }, [osha300Logs]);
  const setHours = () => {
    let id = activeProject?._id ?? activeGroup._id;
    if (activeProject && !activeProject.isSeparateEstablishment) {
      return;
    } else {
      getHoursWorked(year).then(data => {
        let totalHours = (
          activeProject?.isSeparateEstablishment ? data.areas : data.groups
        ).reduce((acc, group) => {
          if (id && group?._id !== id) return acc;
          let hW = group.hoursWorked || [];
          return acc + hW.reduce((acc, cur) => acc + cur, 0);
        }, 0);
        setTotalHours(totalHours);
      });
    }
  };

  const handleYearSelection = year => {
    dispatch(fetchOshaLogsRequest({ year }));
    setYear(year);
  };

  const handleOpenModal = modalNumber => {
    setOpenModal(modalNumber);
  };

  const handleCloseModal = () => {
    setOpenModal(null);
    setPrintValues([]);
    setSignature('');
    setIncludeSignature(false);
    setModalHierarchy(modalTypes[0]);
    dispatch(clearUploadedAttachments());
  };

  const canPrint301 = osha300Logs?.length > 0;
  const isPerrp = divisionEstInfo.reportingType === 2;

  const header = (
    <Header
      title={`${isPerrp ? 'PERRP' : 'OSHA'} Forms`}
      pageActionOptions={
        accessLevel === 900
          ? [
              {
                label: `Upload ${isPerrp ? 'PERRP' : 'OSHA'} Headcount`,
                visible: true,
                color: 'blueOutline',
                onClick: () => setOpenModal(1)
              },
              {
                label: `Upload ${isPerrp ? 'PERRP' : 'OSHA'} Hours Worked`,
                visible: true,
                color: 'blueOutline',
                onClick: () => setOpenModal(2)
              }
            ]
          : null
      }
    />
  );

  return (
    <>
      <HeaderAndFooter Header={header} className="oshaLog">
        <div className="oshaLog-header">
          <div className="oshaLog-tabs">
            <div
              className={
                selected === 0
                  ? 'oshaLog-tab oshaLog-tab1 selected'
                  : 'oshaLog-tab oshaLog-tab1 oshaLog-tab--notSelected'
              }
              onClick={() => setSelected(0)}
            >
              <div className="oshaLog-tabs--headerTopLine">
                {isPerrp ? '300P' : 'OSHA 300'}
              </div>
              <div className="oshaLog-tabs--headerBottomLine">
                Injury & Illness Log
              </div>
              {selected === 0 && <div className="oshaLog-tab--selected" />}
            </div>
            <div
              className={
                selected === 1
                  ? 'oshaLog-tab oshaLog-tab2 selected'
                  : 'oshaLog-tab oshaLog-tab2 oshaLog-tab--notSelected'
              }
              onClick={() => setSelected(1)}
            >
              <div className="oshaLog-tabs--headerTopLine">
                {isPerrp ? '300AP' : 'OSHA 300A'}
              </div>
              <div className="oshaLog-tabs--headerBottomLine"> Summary</div>
              {selected === 1 && (
                <div className="oshaLog-tab2 oshaLog-tab--selected" />
              )}
            </div>
            <div
              className={
                selected === 2
                  ? 'oshaLog-tab oshaLog-tab3 selected'
                  : 'oshaLog-tab oshaLog-tab3 oshaLog-tab--notSelected'
              }
              onClick={() => setSelected(2)}
            >
              <div className="oshaLog-tabs--headerTopLine">
                {isPerrp ? '301P' : 'OSHA 301'}
              </div>
              <div className="oshaLog-tabs--headerBottomLine">
                Incident Reports
              </div>
              {selected === 2 && (
                <div className="oshaLog-tab3 oshaLog-tab--selected" />
              )}
            </div>
          </div>
          <InputRow className="oshaLog-selection">
            <Dropdown
              options={yearToDateDropdown()}
              currentValue={year}
              onChange={handleYearSelection}
              inputClassName="oshaLog-selection--dropdown"
            />
            <Button
              text="Download Files"
              color="green"
              onClick={() => handleOpenModal(0)}
            />
          </InputRow>
        </div>
        <div className="oshaLog-tabContent">
          {selected === 0 && osha300Logs && osha300Logs.length === 0 ? (
            <div className="oshaLogTab1-noContent">
              <div style={{ fontWeight: 600 }}>No Cases Found </div>
              <div>Check whether you have correct year</div>
            </div>
          ) : selected === 0 ? (
            <Osha300List
              isPerrp={isPerrp}
              cases={osha300Logs}
              setSelected={setSelected}
              setSelectedRow={setSelectedRow}
              setActiveIncident={id => dispatch(setActiveIncidentRequest(id))}
            />
          ) : (
            ''
          )}
          {selected === 1 ? (
            <Osha300AForm
              aggrates={osha300ALogs}
              divisonEstInfo={divisionEstInfo}
              createOshaLog={values => dispatch(createOshaLogRequest(values))}
              year={year}
              isPerrp={isPerrp}
              location={activeGroup}
              project={activeProject}
              totalHours={hours}
              setHours={setHours}
            />
          ) : (
            ''
          )}
          {selected === 2 && !osha300Logs?.length ? (
            <div className="oshaLogTab1-noContent">
              <div style={{ fontWeight: 600 }}>No Cases Found </div>
              <div>Check whether you have correct year</div>
            </div>
          ) : selected === 2 ? (
            <Osha301List
              incidentReports={osha300Logs}
              selectedRow={selectedRow}
              setActiveIncident={id => dispatch(setActiveIncidentRequest(id))}
              printSelectedOsha={values =>
                dispatch(printSelectedOshaRequest(values))
              }
              activeLocationId={activeGroup}
              activeProject={activeProject}
              isPerrp={isPerrp}
              userCompanies={userCompanies}
            />
          ) : (
            ''
          )}
        </div>
      </HeaderAndFooter>
      {JSON.stringify(divisionEstInfo) !== '{}' && (
        <Modal
          title="Download Official Files"
          titleClassName="blueHeader"
          submitButtonText="Download Files"
          isOpen={openModal === 0}
          submitButtonColor="green"
          onRequestClose={handleCloseModal}
          submitActions={() =>
            dispatch(
              printOshaRequest({
                year: year,
                print: printValues,
                signature: includeSignature ? signature?.source_url : null
              })
            ) && handleCloseModal()
          }
          disableSubmit={
            printValues.length === 0 || (includeSignature && !signature)
          }
        >
          <OshaLogDownload
            onChange={setPrintValues}
            currentValue={printValues}
            canPrint300ACSV={canPrint300ACSV}
            canPrint301={canPrint301}
            missingNoInformation={missingNoInformation}
            selected={selected}
            isShortTermEstablishment={!divisionEstInfo.isSeparateEstablishment}
            aggrates={osha300ALogs}
            divisonEstInfo={divisionEstInfo}
            activeProject={activeProject}
          />
          {printValues.includes(1) && (
            <Checkbox
              fieldLabel={`Include signature on 300A${
                isPerrp ? 'P' : ''
              } forms?`}
              onChange={setIncludeSignature}
              currentValue={includeSignature}
              selected={includeSignature}
            />
          )}
          {includeSignature && printValues.includes(1) && (
            <Signature
              fieldLabel="This signature will be added to your OSHA/PERRP form"
              currentValue={signature}
              onChange={setSignature}
            />
          )}
        </Modal>
      )}
      <Modal
        title={`Upload ${isPerrp ? 'PERRP' : 'OSHA'} ${
          openModal === 1 ? 'Headcount' : 'Hours Worked'
        }`}
        titleClassName="blueHeader"
        submitButtonText="Upload"
        isOpen={openModal === 1 || openModal === 2}
        submitButtonColor="green"
        onRequestClose={handleCloseModal}
        submitActions={() => {
          uploadOshaCsv({
            attachment: addedAttachments[0],
            type: openModal === 1 ? 'Headcount' : 'Hours Worked',
            year,
            level: modalHierarchy.includes('Group') ? 'Group' : 'Area',
            isPerrp
          }).then(
            () => {
              dispatch(fetchOshaLogsRequest({ year }));
              setHours();
            },
            e => setProblems(e.data)
          );
          handleCloseModal();
        }}
        disableSubmit={!addedAttachments.length}
      >
        <TypeSelection
          className="hierarchyTabs"
          selected={modalHierarchy}
          selectedArray={modalTypes}
          onClick={v => {
            setModalHierarchy(v);
            dispatch(clearUploadedAttachments());
          }}
        />
        <Button
          color="blue"
          onClick={() => {
            openModal === 1
              ? generateHeadcountTemplate({
                  year,
                  level: modalHierarchy.includes('Group') ? 'Group' : 'Area',
                  isPerrp
                }).then(r => {
                  var win = window.open(r, '_blank');
                  win.focus();
                })
              : generateHoursTemplate({
                  year,
                  level: modalHierarchy.includes('Group') ? 'Group' : 'Area'
                }).then(r => {
                  var win = window.open(r, '_blank');
                  win.focus();
                });
          }}
          text={`Download Template`}
        />
        <CSVUploader documentType="EmployeesUpload" className="dropzone" />
      </Modal>
      <Modal
        title="Problems with Upload"
        titleClassName="redHeader"
        isOpen={uploadProblems?.length}
        className="modalSmall"
        onRequestClose={() => {
          setProblems([]);
        }}
        hideButtons
      >
        <ul>
          {uploadProblems.map((issue, index) => (
            <li key={index}>{issue}</li>
          ))}
        </ul>
      </Modal>
    </>
  );
}
