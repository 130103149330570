import React from 'react';

const edit = ({ width, height, x, y, ...props }) => (
  <svg
    width={width || '40px'}
    height={height || '40px'}
    x={x || '0px'}
    y={y || '0px'}
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M0.68 11.56L12.24 0C12.24 0 14.28 -6.16074e-05 15.64 1.36008C17 2.72023 17 4.76 17 4.76L5.44 16.32L0 17L0.68 11.56Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.28041 12.6797C2.1938 12.5931 2.06729 12.5597 1.94924 12.5924C1.83119 12.6251 1.73986 12.7188 1.71015 12.8376L1.03015 15.5576C1.00118 15.6735 1.03513 15.7961 1.11958 15.8805C1.20403 15.965 1.32659 15.9989 1.44246 15.9699L4.16246 15.2899C4.28129 15.2602 4.37496 15.1689 4.40766 15.0508C4.44036 14.9328 4.40703 14.8063 4.32041 14.7197L2.28041 12.6797Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8404 3.83957C13.9732 3.97235 13.9732 4.18763 13.8404 4.32041L5.68042 12.4804C5.54764 12.6132 5.33237 12.6132 5.19959 12.4804C5.06681 12.3476 5.06681 12.1324 5.19959 11.9996L13.3596 3.83957C13.4924 3.7068 13.7076 3.7068 13.8404 3.83957Z"
    />
  </svg>
);

export default edit;
