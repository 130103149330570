export const getAttachmentSelection = attachment => {
  const attachmentName =
    attachment.original_filename?.toLowerCase().split('.') ??
    attachment?.name?.toLowerCase().split('.');
  if (!attachmentName) return;
  switch (attachmentName[attachmentName.length - 1]) {
    case 'doc':
      return require(`../assets/images/attachmentTypes/DOC.png`);

    case 'docx':
      return require('../assets/images/attachmentTypes/DOCX.png');

    case 'mp4':
    case 'mov':
      return require('../assets/images/attachmentTypes/MP4.png');

    case 'mpeg':
      return require('../assets/images/attachmentTypes/MPEG.png');

    case 'mpg':
      return require('../assets/images/attachmentTypes/MPG.png');

    case 'pdf':
      return require('../assets/images/attachmentTypes/PDF.png');

    case 'ppt':
      return require('../assets/images/attachmentTypes/PPT.png');

    case 'pptx':
      return require('../assets/images/attachmentTypes/PPTX.png');

    case 'rtf':
      return require('../assets/images/attachmentTypes/RTF.png');

    case 'xls':
      return require('../assets/images/attachmentTypes/XLS.png');

    case 'xlsx':
      return require('../assets/images/attachmentTypes/XLSX.png');

    default:
      return require('../assets/images/attachmentTypes/TXT.png');
  }
};
export const isFileType = (attachment, fileTypes) => {
  const attachmentName =
    attachment?.original_filename?.toLowerCase().split('.') ??
    attachment?.name?.toLowerCase().split('.') ??
    attachment.toLowerCase().split('.');
  if (!Array.isArray(fileTypes)) fileTypes = [fileTypes];
  return fileTypes.includes('.' + attachmentName[attachmentName.length - 1]);
};

export const isImage = attachment => {
  const attachmentName =
    attachment?.original_filename?.toLowerCase().split('.') ??
    attachment?.name?.toLowerCase().split('.') ??
    attachment.toLowerCase().split('.');
  return ['png', 'jpeg', 'jpg'].includes(
    attachmentName[attachmentName.length - 1]
  );
};

export const isMovie = attachment => {
  const attachmentName = attachment.original_filename?.toLowerCase().split('.');
  return ['mp4', 'mpeg', 'mpg', 'mov'].includes(
    attachmentName[attachmentName.length - 1]
  );
};

export const isMicrosoft = attachment => {
  const attachmentName = attachment.original_filename?.toLowerCase().split('.');
  return ['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx', 'txt'].includes(
    attachmentName[attachmentName.length - 1]
  );
};

export const isYoutube = attachment => {
  const source = attachment.link ?? attachment?.source_url;
  return (
    source?.toLowerCase()?.includes('youtube') ||
    source?.toLowerCase()?.includes('youtu.be')
  );
};

export const isDiagram = attachment => {
  return (
    attachment.original_filename?.toLowerCase().indexOf('bodydiagram.png') >
      -1 ||
    attachment.original_filename?.toLowerCase().indexOf('roaddiagram.png') >
      -1 ||
    attachment.original_filename?.toLowerCase().indexOf('vehiclediagram.png') >
      -1 ||
    attachment.original_filename?.toLowerCase().indexOf('aircraftdiagram.png') >
      -1
  );
};

export const isBodyDiagram = attachment => {
  return (
    attachment.original_filename?.toLowerCase().indexOf('bodydiagram') > -1
  );
};

export const isRoadDiagram = attachment => {
  return (
    attachment.original_filename?.toLowerCase().indexOf('roaddiagram') > -1
  );
};

export const isVehicleDiagram = attachment => {
  return (
    attachment.original_filename?.toLowerCase().indexOf('vehiclediagram') > -1
  );
};

export const isAircraftDiagram = attachment => {
  return (
    attachment.original_filename?.toLowerCase().indexOf('aircraftdiagram') > -1
  );
};

export const isInputCSV = file => {
  const fileName = file.name.toLowerCase().split('.');
  return ['csv'].includes(fileName[fileName.length - 1]);
};

export const isPDF = attachment => {
  const attachmentName =
    attachment.original_filename?.toLowerCase().split('.') ??
    attachment?.toLowerCase().split('.');
  return ['pdf'].includes(attachmentName[attachmentName.length - 1]);
};

export const formatFiles = attachments => {
  let data = new FormData();
  const files = attachments;

  for (let i = 0; i < files.length; i++) {
    const FileSize = files[i].size / 1024 / 1024; // in MB
    if (FileSize > 2000) {
      alert('File size exceeds 2000 MB');
      return;
    }
  }

  Object.entries(files).forEach(async file => {
    data.append('attachments', file[1], file[1].name);
  });

  return data;
};

export const checkIfLinkIsYoutube = url => {
  if (!url) return false;
  var p =
    /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|live\/|v\/)?)([\w-]+)(\S+)?$/;
  return url.match(p);
};
