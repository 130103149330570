import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import history from '../../history';
import {
  deleteSafetyWalk,
  setSafetyWalkNumber,
  updateSafetyWalk
} from '../../api/v4';
import { deleteUserTaskRequest } from '../../actions/tasks';
import { getActiveSafetyWalkSelector } from '../../selectors/safetyWalks';
import {
  setActiveSafetyWalk,
  addSafetyWalkRequest
} from '../../actions/safetyWalks';
import { clearUploadedAttachments } from '../../actions/attachments';
import { SAFETY_WALKS_STAGES } from '../../constants/constants';
import { getLoggedInUser } from '../../selectors/users';
import useActiveHeirarchy from '../../utils/useActiveHeirarchy';
import { useSocket } from '../../utils/withSocket';

import AssignCorrectiveActionButton from '../../components/inputs/AssignCorrectiveActionButton';
import AttachmentCarousel from './AttachmentCarousel';
import BravoCard from './BravoCard';
import Card from '../../components/Card';
import HazardCard from './HazardCard';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Header from '../../components/Header';
import HierarchySelector from '../../components/HierarchySelector';
import ItemSummaryCard from '../../components/safetyWalkCards/ItemSummaryCard';
import LeftNav from '../../components/LeftNav';
import MissingRequiredModal from '../../components/Modal/missingRequiredModal';
import Modal from '../../components/Modal';
import { SaveCancelFooter } from '../../components/Footer';
import SaveChangesModal from '../../components/Modal/saveChangesModal';
import SignatureCard from '../../components/SignatureCard';
import SummaryCard from '../../components/safetyWalkCards/SummaryCard/index';
import Textbox from '../../components/inputs/Textbox';

import StageSelector from '../../components/StageSelector';

import styles from './itemCard.module.scss';

function SafetyWalkContainer() {
  const { activeCompany, activeGroup, activeProject } = useActiveHeirarchy();
  const dispatch = useDispatch();

  const activeSw = useSelector(getActiveSafetyWalkSelector);
  const loggedInUser = useSelector(getLoggedInUser);
  const setSafetyWalk = payload => dispatch(setActiveSafetyWalk(payload));
  const addSafetyWalk = payload => dispatch(addSafetyWalkRequest(payload));
  const deleteUserTask = payload => dispatch(deleteUserTaskRequest(payload));
  const clearAttachments = () => dispatch(clearUploadedAttachments());
  const [creating, setCreating] = useState(true);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [openModalType, setOpenModalType] = useState();
  const [sw, setSw] = useState({});
  const [missingRequired, setMissingRequired] = useState(false);
  const [tempLocation, setTempLocation] = useState();

  const {
    groupId,
    projectId,
    inspectionLocation,
    signature,
    isArchived,
    stage,
    items = []
  } = sw;

  const { socketDirty, socketLock, locked } = useSocket({ id: activeSw?._id });

  const group = activeCompany.groups?.find(g => g._id === groupId);
  const area = group?.projects?.find(p => p._id === projectId);
  const disabled = group?.isHidden || area?.isHidden || sw?.isArchived;
  const myName = activeCompany.isDash ? 'DASH Report' : 'Safety Walk';
  const createdBy = sw?.auditLog?.[0]?.user ?? sw?.createdBy;

  const validHazardInfo = h =>
    h?.description?.trim() &&
    (h?.hazardClassification === 'Other'
      ? h?.otherHazardClassification?.trim()
      : h?.hazardClassification);

  const validBravoInfo = b =>
    b?.description?.trim() &&
    (b?.bravoClassification === 'Other'
      ? b?.otherBravoClassification?.trim()
      : b?.bravoClassification);

  const validItems = items?.every(i =>
    i.type === 'bravo' ? validBravoInfo(i) : validHazardInfo(i)
  );
  const validSwInfo =
    groupId && (sw?.useLegacy ? inspectionLocation?.trim()?.length : true);
  const canSubmit = validSwInfo && signature && (sw?.useLegacy || validItems);

  useEffect(() => {
    setSw({
      companyId: activeCompany?._id,
      groupId: activeGroup?._id ?? null,
      projectId: activeProject?._id ?? null,
      items: [],
      useLegacy: !!activeCompany?.isDash,
      ...activeSw
    });
    setHasUnsavedChanges(activeSw?.hasUnsavedChanges ?? false);
    setCreating(!activeSw?._id);
  }, [activeGroup, activeProject, activeCompany, activeSw]);

  const handleAnswer = values => {
    setHasUnsavedChanges(true);
    setSw({ ...sw, ...values });
  };

  const handleAddItem = type => {
    handleAnswer({
      items: [
        ...(sw?.items ?? []),
        { type, overallOrder: sw?.items?.length ?? 0 }
      ]
    });
    if (sw?.useLegacy) {
      setSafetyWalk(sw);
      clearAttachments();
      history.push({
        pathname: `/app/safetyWalkContainer/${type}Card`,
        state: { myName }
      });
    }
  };

  const updateStage = value => {
    socketLock();
    updateSafetyWalk({
      ...sw,
      ...value
    }).then(response => {
      let items = response?.bravos
        ?.concat(response?.hazards)
        ?.sort((a, b) => a.overallOrder - b.overallOrder);
      socketDirty();
      setSw({ ...response, items });
    });
  };

  const handleArchive = () => {
    updateSafetyWalk({
      ...sw,
      isArchived: !isArchived
    }).then(response => {
      let items = response?.bravos
        ?.concat(response?.hazards)
        ?.sort((a, b) => a.overallOrder - b.overallOrder);
      if (response.isArchived) history.push('/app/safetyWalkStages');
      else setSw({ ...response, items });
    });
  };

  const submitSafetyWalkName = newName => {
    setSafetyWalk({
      ...sw,
      safetyWalkNumber: newName
    });
    setSafetyWalkNumber(sw._id, newName);
  };

  const handleDiscardItem = index => {
    let hazardTask = items[index]?.tasks?.[0];
    handleAnswer({
      items: items
        .toSpliced(index, 1)
        ?.map((item, index) => ({ ...item, overallOrder: index }))
    });
    if (hazardTask) {
      setSafetyWalk({
        ...sw,
        items: items
          .toSpliced(index, 1)
          ?.map((item, index) => ({ ...item, overallOrder: index })),
        hasUnsavedChanges
      });
      deleteUserTask({ ...hazardTask, goBack: false });
    }
  };

  const handleDiscardSw = () => {
    const tasks =
      sw?.items
        ?.map(h => h.tasks ?? [])
        .reduce((acc, x) => acc.concat(x), []) ?? [];
    if (tasks?.length) {
      tasks?.forEach(t => deleteUserTask({ ...t }));
    } else {
      setSafetyWalk();
      history.push('/app/dashboard');
    }
  };

  const handleSWSubmit = () => {
    addSafetyWalk(sw);
    setCreating(false);
    setOpenModalType();
  };
  const header = (
    <Header
      title={`${creating ? 'Create' : 'View'} ${myName}`}
      section={sw?.safetyWalkNumber}
      editName={v => (disabled ? null : submitSafetyWalkName(v))}
      clickBack={() =>
        hasUnsavedChanges
          ? setOpenModalType('unsavedChanges')
          : history.push('/app/safetyWalkStages')
      }
      needsSaved={hasUnsavedChanges}
      center={
        !creating && (
          <StageSelector
            fieldLabel={`${myName} Stage:`}
            options={SAFETY_WALKS_STAGES}
            currentValue={stage}
            onChange={value => updateStage({ stage: value })}
            disabled={true}
            bareValues={false}
            displayTooltip={true}
            name="safetyWalkStage"
            tooltip="Safety walk stage is system generated"
            testID="swStage"
          />
        )
      }
      pageActionOptions={
        !creating && [
          {
            label: 'Delete Safety Walk',
            visible:
              loggedInUser?.accessLevel !== 100 &&
              (loggedInUser?.accessLevel === 900 ||
                createdBy === loggedInUser?._id),
            color: 'redOutline',
            onClick: () => setOpenModalType('deleteSw')
          },
          {
            label: 'Close Safety Walk',
            onClick: () => {
              updateStage({
                stage: {
                  value: 'closed',
                  label: 'Closed'
                }
              });
            },
            color: 'blueOutline',
            visible: sw?.stage?.label !== 'Closed' && !disabled
          },
          {
            label: 'Edit Location',
            onClick: () => {
              setOpenModalType('editLocation');
              setTempLocation(sw?.inspectionLocation);
            },
            color: 'blueOutline',
            visible:
              sw?.useLegacy && loggedInUser?.accessLevel === 900 && !disabled
          },
          {
            label: 'Reopen Safety Walk',
            onClick: () => {
              updateStage({
                stage: { value: 'severityReview', label: 'Severity Review' }
              });
            },
            color: 'blueOutline',
            visible:
              sw?.stage?.label === 'Closed' && sw?.canBeReopened && !disabled
          },
          {
            label: `${sw?.isArchived ? 'Un-' : ''}Archive Safety Walk`,
            onClick: handleArchive,
            color: 'blueOutline',
            visible: loggedInUser.accessLevel > 400
          }
        ]
      }
    />
  );

  const footer = (
    <SaveCancelFooter
      saveButtonClick={() =>
        !canSubmit
          ? setOpenModalType('missingRequired')
          : !items?.length
            ? setOpenModalType('noHazards')
            : handleSWSubmit()
      }
      cancelButtonClick={() =>
        hasUnsavedChanges
          ? setOpenModalType('unsavedChanges')
          : handleDiscardSw()
      }
      onMouseEnter={() => setMissingRequired(true)}
    />
  );

  let leftNav = items?.map((h, i) => {
    if (h.bravosGiven != null || h.type === 'bravo') {
      return {
        label: `#${i + 1} ${
          h?.title?.length
            ? h.title
            : h?.bravoClassification === 'Other'
              ? h.otherBravoClassification
              : (h.bravoClassification ?? '')
        }`,
        id: `b-${i}`
      };
    }
    return {
      label: `#${i + 1} ${
        h?.title?.length
          ? h.title
          : h?.hazardClassification === 'Other'
            ? (h.otherHazardClassification ?? '')
            : (h.hazardClassification ?? '')
      }`,
      id: `h-${i}`
    };
  });

  leftNav.push({ label: 'Signature', id: 'signature' });
  if (!creating) leftNav.unshift({ label: 'Summary', id: 'summary' });
  leftNav.unshift({ label: `${myName} Info`, id: 'info' });

  const left = (
    <LeftNav
      items={leftNav}
      showSwButton={creating && validSwInfo}
      onAddHazard={() => handleAddItem('hazard')}
      onAddBravos={() => handleAddItem('bravo')}
    />
  );

  const renderCngLegacySummary = () =>
    items?.map((hazard, index) => (
      <ItemSummaryCard
        item={hazard}
        key={index}
        history={history}
        index={index}
        groupId={groupId}
        projectId={projectId}
        name={hazard.bravosGiven != null ? `b-${index}` : `h-${index}`}
        type={hazard.bravosGiven != null ? 'Bravo' : 'Hazard'}
        hazardNumber={index}
        disabled={locked || disabled}
        ownerId={sw?._id}
      />
    ));

  const renderItems = () => {
    return sw?.items?.map((item, index) => {
      let type =
        item.bravosGiven != null || item?.type === 'bravo' ? 'Bravo' : 'Hazard';
      return (
        <Card
          wide
          title={type}
          name={type === 'Bravo' ? `b-${index}` : `h-${index}`}
          showHeader
          rightButton={
            creating ? (
              <img
                src={require('../../assets/images/remove.png')}
                alt="remove row"
                onClick={() => handleDiscardItem(index)}
                style={{ cursor: 'pointer' }}
              />
            ) : null
          }
        >
          <AttachmentCarousel
            isEditing={creating}
            pictures={item?.pictures ?? []}
            loggedInUser={loggedInUser}
            ownerType={type === 'Bravo' ? 'bravo' : 'hazard'}
            handlePhotoUpdate={values => {
              let item = { ...sw?.items[index], ...values };
              let updatedItems = sw?.items.toSpliced(index, 1, item);
              setSw({ ...sw, items: updatedItems });
            }}
            name={`${type === 'Bravo' ? 'bravo' : 'hazard'}${index}`}
          />
          {type === 'Bravo' ? (
            <BravoCard
              {...item}
              updateBravo={values => {
                let bravo = { ...sw?.items[index], ...values };
                let updatedItems = sw?.items.toSpliced(index, 1, bravo);
                setSw({ ...sw, items: updatedItems });
              }}
              editing={creating}
              loggedInUser={loggedInUser}
              missingRequired={missingRequired}
            />
          ) : (
            <>
              <HazardCard
                {...item}
                updateHazard={values => {
                  let hazard = { ...sw?.items[index], ...values };
                  let updatedItems = sw?.items.toSpliced(index, 1, hazard);
                  setSw({ ...sw, items: updatedItems });
                }}
                editing={creating}
                loggedInUser={loggedInUser}
                missingRequired={missingRequired}
                index={index}
              />
              <AssignCorrectiveActionButton
                groupId={groupId}
                task={item?.tasks?.[0]}
                ownerType="safetyWalks"
                label={
                  item?.title?.length
                    ? item.title
                    : item?.hazardClassification === 'Other'
                      ? (item.otherHazardClassification ?? '')
                      : (item.hazardClassification ?? '')
                }
                description={item?.description}
                notes={
                  sw?.useLegacy
                    ? `Solution - \n ${item.solution} \n Exact Location - \n ${item.exactLocation}`
                    : ''
                }
                schedule="immediate"
                projectId={projectId}
                immediatelyFixed={item?.immediatelyFixed?.length > 0}
                ownerId={sw?._id}
                pictures={item.pictures}
                type="correctiveAction"
                disabled={disabled || !validHazardInfo(item)}
                hazardNumber={index}
                onClick={() => setSafetyWalk({ ...sw, hasUnsavedChanges })}
              />
            </>
          )}
        </Card>
      );
    });
  };

  return (
    <>
      <HeaderAndFooter
        Header={header}
        Footer={sw?.isArchived ? null : footer}
        Left={left}
        editName={disabled ? null : submitSafetyWalkName}
        showFooter={creating}
      >
        <Card showHeader title={`${myName} Info`} name="info" wide>
          {validSwInfo ? null : (
            <p className={styles.infoStyle}>
              Fill out the required fields to add Hazards and Bravos.
            </p>
          )}
          <HierarchySelector
            onGroupChange={o => handleAnswer({ groupId: o })}
            groupId={groupId}
            groupDisabled={!creating}
            onProjectChange={o => handleAnswer({ projectId: o })}
            projectId={projectId}
            projectDisabled={!creating || !groupId}
            groupTouched={missingRequired && !groupId}
          />
          {sw?.useLegacy ? (
            <Textbox
              fieldLabel="Inspection Location"
              isRequired={true}
              placeholder="Where is the inspection taking place?"
              onChange={v => handleAnswer({ inspectionLocation: v })}
              currentValue={inspectionLocation}
              disabled={!creating}
              touched={missingRequired && !inspectionLocation}
            />
          ) : null}
        </Card>
        {!creating && (
          <SummaryCard
            safetyWalk={sw}
            loggedInUser={loggedInUser}
            name="summary"
            isDash={activeCompany.isDash}
          />
        )}
        {sw?.useLegacy ? renderCngLegacySummary() : renderItems()}
        <SignatureCard
          wide
          currentValue={signature}
          name="signature"
          disabled={!creating}
          header="Signature"
          onChange={signature => handleAnswer({ signature })}
          missingRequired={missingRequired}
        />
      </HeaderAndFooter>
      <Modal
        title={`Delete ${myName}`}
        titleClassName="redHeader"
        isOpen={openModalType === 'deleteSw'}
        submitButtonColor="red"
        submitButtonText={`Delete ${myName}`}
        onRequestClose={() => setOpenModalType()}
        submitActions={() => {
          if (!creating) {
            deleteSafetyWalk(sw?._id);
          }
          setSafetyWalk(undefined);
          history.goBack();
        }}
      >
        <span style={{ color: '#c74846', fontWeight: 'bold' }}>
          Discarding the {myName} will delete all information that has been
          gathered, pictures taken, and corrective actions assigned as tasks.
        </span>
      </Modal>
      <SaveChangesModal
        isOpen={openModalType === 'unsavedChanges'}
        onRequestClose={() => setOpenModalType()}
        submitActions={handleDiscardSw}
      />
      <Modal
        title="Edit Inspection Location"
        titleClassName="blueHeader"
        isOpen={openModalType === 'editLocation'}
        submitButtonColor="blue"
        submitButtonText="Save"
        disableSubmit={
          !inspectionLocation?.match(/^(?:[^a-zA-Z0-9]*[a-zA-Z0-9]){3}.*/)
        }
        onRequestClose={() => {
          setHasUnsavedChanges(false);
          setOpenModalType();
        }}
        submitActions={() => {
          if (tempLocation !== '') {
            socketLock();
            updateSafetyWalk({
              ...sw,
              inspectionLocation: tempLocation
            }).then(response => {
              setSw({ ...response });
              socketDirty();
            });
            setHasUnsavedChanges(false);
            setTempLocation();
            setOpenModalType();
          }
        }}
      >
        <Textbox
          fieldLabel="Inspection Location"
          placeholder="Where is the inspection taking place?"
          onChange={setTempLocation}
          currentValue={tempLocation}
        />
      </Modal>
      <Modal
        title="No Hazards"
        titleClassName="blueHeader"
        isOpen={openModalType === 'noHazards'}
        submitButtonColor="blue"
        submitButtonText="Continue"
        onRequestClose={() => setOpenModalType()}
        submitActions={handleSWSubmit}
      >
        <div className="reportSectionContainer-emailAssigneeText">
          No Hazards or Bravos have been added to this {myName}. If this is
          correct, please continue. Otherwise, cancel and add the Hazards before
          Submitting.
        </div>
      </Modal>
      <MissingRequiredModal
        isOpen={openModalType === 'missingRequired'}
        onRequestClose={() => setOpenModalType()}
        subject={myName}
      />
    </>
  );
}

export default SafetyWalkContainer;
