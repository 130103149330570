import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import ReactLoading from 'react-loading';
import history from '../../history';
import { deleteEmployee } from '../../api/v4';
import {
  addUploadedAttachmentsRequest,
  clearUploadedAttachments,
  deleteAttachmentRequest
} from '../../actions/attachments';
import {
  setActiveEmployeeResponse,
  updateLocationEmployeeRequest
} from '../../actions/personnel';
import { loadSafetyWalk } from '../../actions/safetyWalks';
import {
  getAddedAttachmentsSelector,
  getAttachmentLoadingSelector
} from '../../selectors/attachments';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject,
  getUserCompaniesSelector
} from '../../selectors/company';
import {
  getActiveEmployeeSelector,
  getPersonnelLoadingSelector
} from '../../selectors/personnel';
import { getLoggedInUser } from '../../selectors/users';
import { commonName, validZip, email } from '../../utils/formValidation';
import ContractorForm from '../../components/ContractorForm';
import EmployeeDocumentRepo from '../../components/EmployeeDocumentRepo';
import EmployeeExpectations from '../../components/EmployeeExpectations';
import EmployeeForm from '../../components/EmployeeForm';
import EmployeeInfoList from '../../components/EmployeeInfoLists';
import EmployeeSnapshot from '../../components/EmployeeSnapshot';
import IncidentsList from '../../components/IncidentsList';
import Modal from '../../components/Modal';
import QualificationList from '../../components/QualificationList';
import SafetyTrainingList from '../../components/training/SafetyTrainingList';
import UserForm from '../../forms/UserForm';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Card from '../../components/Card';
import MissingRequiredModal from '../../components/Modal/missingRequiredModal';

import './index.css';

export class ViewEditEmployee extends Component {
  state = {
    modalOpen: false,
    selected: 0,
    contractor: {},
    employee: {}
  };

  componentDidMount = () => {
    this.props.clearAttachment();
    this.setState({ contractor: this.props.activeEmployee });
    this.setState({ employee: this.props.activeEmployee });
  };

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.activeEmployee, prevProps.activeEmployee)) {
      this.setState({ contractor: this.props.activeEmployee });
      this.setState({ employee: this.props.activeEmployee });
    }
  }

  handleDeleteEmployee = () => {
    deleteEmployee(this.props.activeEmployee._id).then(() =>
      history.push('/app/settings/Employees')
    );
  };

  render() {
    const { selected, modalOpen, contractor, employee } = this.state;

    const {
      activeEmployee,
      loggedInUser,
      userCompanies,
      personnelLoading,
      setActiveEmployee
    } = this.props;

    const header = (
      <Header
        title="Employee Profile"
        clickBack={() => {
          setActiveEmployee();
          history.push('/app/settings/Employees');
        }}
      />
    );

    const tabs = {
      selected: this.state.selected,
      onClick: i => this.setState({ selected: i }),
      list: [
        { name: 'Snapshot' },
        { name: 'Personal Info' },
        { name: 'Trainings' },
        { name: 'Training Expectation(s)' },
        { name: 'Reports' },
        {
          name: 'Behavior Observation'
        },
        { name: 'Bravos' },
        {
          name: 'Qualifications'
        },
        {
          name: 'User Info',
          visible: loggedInUser.accessLevel === 900
        },
        { name: 'Doc Repo' }
      ]
    };

    const canSubmitEmployee =
      employee?.companyIds?.length &&
      employee.groupIds?.length &&
      employee.employeeNumber &&
      employee.firstName &&
      !commonName(employee.firstName) &&
      employee.lastName &&
      !commonName(employee.lastName) &&
      employee.dateOfHire &&
      employee.jobTitle &&
      employee.status &&
      employee.isMicrosoftUser !== undefined &&
      employee.isDeactivated !== undefined &&
      employee.accessLevel &&
      (employee.zip ? !validZip(employee.zip) : true) &&
      (employee.email ? !email(employee.email) : true) &&
      (employee.middleName ? !commonName(employee.middleName) : true);

    return (
      <HeaderAndFooter Header={header}>
        {personnelLoading ? (
          <ReactLoading
            type="spinningBubbles"
            color="#58a15b"
            className="loading"
          />
        ) : (
          <Card wide tabs={tabs}>
            {selected === 0 && (
              <EmployeeSnapshot
                activeEmployee={activeEmployee}
                userCompanies={userCompanies}
                accessLevel={loggedInUser.accessLevel}
                systemInteractions={activeEmployee.systemInteractions}
              />
            )}
            {selected === 1 && !activeEmployee?.usedContractorForm && (
              <EmployeeForm
                employee={employee}
                onSubmit={() =>
                  canSubmitEmployee
                    ? this.props.updateLocationEmployee(employee)
                    : this.setState({ missingModalOpen: true })
                }
                onDelete={() => this.setState({ modalOpen: true })}
                isUpdate={true}
                userCompanies={userCompanies}
                isAccountOwner={activeEmployee.isAccountOwner}
                accessLevel={activeEmployee.accessLevel}
                limitCollabEdits={
                  loggedInUser.accessLevel === 500 &&
                  (loggedInUser._id === activeEmployee._id ||
                    activeEmployee.accessLevel === 900)
                }
                updateEmployee={values =>
                  this.setState({ employee: { ...employee, ...values } })
                }
              />
            )}
            {selected === 1 && activeEmployee?.usedContractorForm && (
              <ContractorForm
                contractor={this.state.contractor}
                updateContractor={values =>
                  this.setState({ contractor: { ...contractor, ...values } })
                }
                isEditing
                updateEmployeeApi={() =>
                  this.props.updateLocationEmployee(this.state.contractor)
                }
                deleteEmployeeApi={this.handleDeleteEmployee}
              />
            )}
            {selected === 2 && (
              <SafetyTrainingList
                fromEmployee
                saveKey="empListTrainingList"
                userId={activeEmployee._id}
              />
            )}
            {selected === 3 && (
              <EmployeeExpectations employeeId={activeEmployee?._id} />
            )}
            {selected === 4 && (
              <IncidentsList
                content={activeEmployee?.incidents ?? []}
                saveKey={`incidents-${activeEmployee._id}`}
                fromEmployee
                user={activeEmployee}
              />
            )}
            {selected === 5 && (
              <EmployeeInfoList
                type="observations"
                userId={activeEmployee._id}
              />
            )}
            {selected === 6 && (
              <EmployeeInfoList type="bravos" userId={activeEmployee._id} />
            )}
            {selected === 7 && (
              <QualificationList
                isFromEmployee={true}
                employeeId={activeEmployee?._id}
              />
            )}
            {selected === 8 && loggedInUser.accessLevel === 900 && (
              <UserForm
                user={activeEmployee}
                updateActiveUser={value =>
                  this.props.updateLocationEmployee({
                    ...activeEmployee,
                    ...value
                  })
                }
              />
            )}
            {selected === 9 && (
              <EmployeeDocumentRepo employeeId={activeEmployee?._id} />
            )}

            <Modal
              title={`Are you sure you want to delete ${activeEmployee.firstName} ${activeEmployee.lastName}?`}
              titleClassName="redHeader"
              isOpen={modalOpen}
              onRequestClose={() => this.setState({ modalOpen: false })}
              submitActions={this.handleDeleteEmployee}
              submitButtonColor="red"
              testID="deleteEmployee"
            />
            <MissingRequiredModal
              isOpen={this.state.missingModalOpen}
              onRequestClose={() => this.setState({ missingModalOpen: false })}
              subject="Employee"
              isEditing
            />
          </Card>
        )}
      </HeaderAndFooter>
    );
  }
}

export const mapStateToProps = state => ({
  activeEmployee: getActiveEmployeeSelector(state),
  activeProject: getActiveProject(state),
  activeLocationId: getActiveLocationId(state),
  activeCompany: getActiveCompany(state),
  attachmentLoading: getAttachmentLoadingSelector(state),
  addedAttachments: getAddedAttachmentsSelector(state),
  loggedInUser: getLoggedInUser(state),
  userCompanies: getUserCompaniesSelector(state),
  personnelLoading: getPersonnelLoadingSelector(state)
});

export const mapDispatchToProps = dispatch => ({
  addAttachment: attachment =>
    dispatch(addUploadedAttachmentsRequest(attachment)),
  clearAttachment: () => dispatch(clearUploadedAttachments()),
  updateLocationEmployee: employee =>
    dispatch(updateLocationEmployeeRequest(employee)),
  deleteAttachment: values => dispatch(deleteAttachmentRequest(values)),
  setActiveEmployee: () =>
    dispatch(
      setActiveEmployeeResponse({
        safetyTrainings: { expiring3Months: [] },
        incidents: [],
        qualifications: { expiring3Months: [], upcomingQuals: [] }
      })
    ),
  loadSafetyWalk: id => dispatch(loadSafetyWalk(id))
});

// eslint-disable-next-line import/no-anonymous-default-export
export default connect(mapStateToProps, mapDispatchToProps)(ViewEditEmployee);
