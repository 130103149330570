import React from 'react';
import './svg.css';

const list = props => (
  <svg width="25px" height="30px" viewBox="0 0 68 67">
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 11C9.433 11 11 9.433 11 7.5C11 5.567 9.433 4 7.5 4C5.567 4 4 5.567 4 7.5C4 9.433 5.567 11 7.5 11ZM15 5C13.8954 5 13 5.89543 13 7V8C13 9.10457 13.8954 10 15 10H51C52.1046 10 53 9.10457 53 8V7C53 5.89543 52.1046 5 51 5H15ZM15 18C13.8954 18 13 18.8954 13 20V21C13 22.1046 13.8954 23 15 23H62C63.1046 23 64 22.1046 64 21V20C64 18.8954 63.1046 18 62 18H15ZM13 33C13 31.8954 13.8954 31 15 31H46C47.1046 31 48 31.8954 48 33V34C48 35.1046 47.1046 36 46 36H15C13.8954 36 13 35.1046 13 34V33ZM15 44C13.8954 44 13 44.8954 13 46V47C13 48.1046 13.8954 49 15 49H61C62.1046 49 63 48.1046 63 47V46C63 44.8954 62.1046 44 61 44H15ZM13 59C13 57.8954 13.8954 57 15 57H54C55.1046 57 56 57.8954 56 59V60C56 61.1046 55.1046 62 54 62H15C13.8954 62 13 61.1046 13 60V59ZM11 20.5C11 22.433 9.433 24 7.5 24C5.567 24 4 22.433 4 20.5C4 18.567 5.567 17 7.5 17C9.433 17 11 18.567 11 20.5ZM7.5 37C9.433 37 11 35.433 11 33.5C11 31.567 9.433 30 7.5 30C5.567 30 4 31.567 4 33.5C4 35.433 5.567 37 7.5 37ZM11 46.5C11 48.433 9.433 50 7.5 50C5.567 50 4 48.433 4 46.5C4 44.567 5.567 43 7.5 43C9.433 43 11 44.567 11 46.5ZM7.5 63C9.433 63 11 61.433 11 59.5C11 57.567 9.433 56 7.5 56C5.567 56 4 57.567 4 59.5C4 61.433 5.567 63 7.5 63Z"
      />
    </g>
  </svg>
);

export default list;
