import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  addEmployee,
  listEmployees,
  exportEmployeesToCSV,
  importEmployees,
  doesUsernameExist,
  fetchAllUsers
} from '../../api/v4';
import { clearUploadedAttachments } from '../../actions/attachments';
import {
  downloadEmployeeQRcodeRequest,
  setActiveEmployeeRequest
} from '../../actions/personnel';
import { loadAllUsersResponse } from '../../actions/user';
import history from '../../history';
import { getLoggedInUser } from '../../selectors/users';
import {
  getUserCompaniesSelector,
  getActiveLocationId
} from '../../selectors/company';
import {
  getAttachmentLoadingSelector,
  getAddedAttachmentsSelector
} from '../../selectors/attachments';
import customToast from '../../utils/customToast';
import { commonName, validZip, email } from '../../utils/formValidation';
import {
  EMPLOYEE_BULK_UPLOAD_TEMPLATE,
  USER_ROLES
} from '../../constants/constants';
import Button from '../inputs/Button';
import ContractorForm from '../ContractorForm';
import CSVUploader from '../CSVUploader';
import EmployeeForm from '../EmployeeForm';
import Modal from '../Modal';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import HeaderAndFooter from '../HeaderAndFooter';
import Header from '../Header';
import List from '../List';

export default function EmployeeList() {
  const dispatch = useDispatch();
  const loggedInUser = useSelector(getLoggedInUser);
  const userCompanies = useSelector(getUserCompaniesSelector);
  const attachmentLoading = useSelector(getAttachmentLoadingSelector);
  const attachments = useSelector(getAddedAttachmentsSelector);
  const activeLocation = useSelector(getActiveLocationId);
  const setEmployees = e => dispatch(loadAllUsersResponse(e));

  const [modalOpen, setModalOpen] = useState(0);
  const [userExists, setUserExists] = useState();
  const [data, setData] = useState();
  const [messages, setMessages] = useState();
  const [openContractorModal, setOpenContractorModal] = useState();
  const [contractor, setContractor] = useState({
    accessLevel: 100,
    usedContractorForm: true
  });
  const [missingRequired, setMissingRequired] = useState(false);
  const [employee, setEmployee] = useState();

  useEffect(() => {
    listEmployees().then(setData);
  }, []);

  const handleOpenModal = modalId => {
    setModalOpen(modalId);
  };

  const handleCloseModal = () => {
    setModalOpen(0);
    setMissingRequired(false);
    setEmployee(null);
  };

  const exportEmployees = async () => {
    const src = await exportEmployeesToCSV();
    window.location = src;
  };

  const handleImport = () => {
    try {
      if (!attachments[0]) customToast('File required!', 'error');
      else {
        importEmployees(attachments[0]?.source_url).then(
          data => {
            customToast('Imported Successfully!', 'success');
            dispatch(clearUploadedAttachments());
          },
          error => {
            setMessages(error.data);
            handleOpenModal(3);
            dispatch(clearUploadedAttachments());
          }
        );
      }
    } catch (e) {
      if (e.status !== 400) throw e;
      let messages = e.data;
      if (!Array.isArray(messages)) messages = [messages];
      setMessages(messages);
    }
    handleCloseModal();
  };

  const handleAddEmployeeSubmit = values => {
    addEmployee(values).then(() => {
      customToast('Employee created successfully!');
      listEmployees().then(setData);
      fetchAllUsers().then(setEmployees);
      setOpenContractorModal(false);
      setEmployee(null);
      setModalOpen();
      setContractor({
        accessLevel: 100,
        usedContractorForm: true,
        firstName: '',
        lastName: '',
        jobTitle: '',
        email: '',
        username: '',
        contractorCompanyName: '',
        phoneNumber: ''
      });
    });
  };

  const checkUsernameAvailability = username => {
    if (!username) {
      setUserExists(null);
      return;
    }

    doesUsernameExist(username).then(response => {
      setUserExists(response?.userExists ?? null);
    });
  };

  const header = (
    <Header
      title="Employees"
      pageActionOptions={[
        {
          label: 'Add Employee',
          visible: true,
          color: 'greenOutline',
          onClick: () => handleOpenModal(1)
        },
        {
          label: 'Add Contractor',
          visible: true,
          color: 'greenOutline',
          onClick: () => setOpenContractorModal(true)
        },
        {
          label: 'Upload Employee Template',
          visible: true,
          color: 'blueOutline',
          onClick: () => handleOpenModal(2)
        },
        {
          label: 'Export Employees To CSV',
          visible: true,
          color: 'blueOutline',
          onClick: exportEmployees
        }
      ]}
    />
  );

  const columns = [
    { key: 'firstName' },
    { key: 'lastName' },
    {
      key: 'employeeNumber',
      label: 'Employee Id'
    },
    { label: 'Hire Date', key: 'dateOfHire', datatype: 'date' },
    { key: 'username' },
    { key: 'jobTitle' },
    {
      key: 'userType',
      accessor: row => (row.isDeactivated ? 0 : row.accessLevel),
      enum: USER_ROLES
    }
  ];

  if (!activeLocation) {
    columns.unshift({
      key: 'groupIds',
      label: 'Group/Est.',
      datatype: 'groups'
    });
  }

  const canSubmitContractor =
    contractor.companyIds?.length &&
    contractor.groupIds?.length &&
    contractor.contractorCompanyName &&
    contractor.firstName &&
    contractor.lastName &&
    contractor.jobTitle &&
    contractor.status &&
    contractor.username &&
    !userExists;

  const canSubmitEmployee =
    employee?.companyIds?.length &&
    employee.groupIds?.length &&
    employee.employeeNumber &&
    employee.firstName &&
    !commonName(employee.firstName) &&
    employee.lastName &&
    !commonName(employee.lastName) &&
    employee.dateOfHire &&
    employee.jobTitle &&
    employee.status &&
    employee.username &&
    !userExists &&
    employee.isMicrosoftUser !== undefined &&
    employee.isDeactivated !== undefined &&
    employee.accessLevel &&
    (employee.zip ? !validZip(employee.zip) : true) &&
    (employee.email ? !email(employee.email) : true) &&
    (employee.middleName ? !commonName(employee.middleName) : true);

  return (
    <>
      <HeaderAndFooter Header={header}>
        <ScrollToTopOnMount />
        <List
          settings={columns}
          actions={[
            {
              color: 'blue',
              label: 'Download QR Codes',
              onClick: ids => dispatch(downloadEmployeeQRcodeRequest({ ids }))
            }
          ]}
          dataIsHash
          getRowId={r => r._id}
          data={data}
          rowClick={row => {
            dispatch(setActiveEmployeeRequest({ ...row }));
            history.push('/app/viewEditPersonnel');
          }}
          saveKey="empList"
        />
      </HeaderAndFooter>
      {modalOpen === 1 && (
        <Modal
          title="Add New Employee"
          titleClassName="greenHeader"
          wide
          isOpen={modalOpen === 1}
          onRequestClose={handleCloseModal}
          submitButtonColor="green"
          testID="modal"
          onMouseEnter={() => setMissingRequired(!canSubmitEmployee)}
          submitActions={() =>
            canSubmitEmployee ? handleAddEmployeeSubmit(employee) : null
          }
          submitTooltip={missingRequired ? 'Missing or Invalid Fields' : null}
        >
          <EmployeeForm
            employee={employee}
            onRequestClose={handleCloseModal}
            onSubmit={values => handleAddEmployeeSubmit({ ...values })}
            userCompanies={userCompanies}
            accessLevel={loggedInUser.accessLevel}
            missingRequired={missingRequired}
            updateEmployee={values => setEmployee({ ...employee, ...values })}
            checkUsernameAvailability={value => {
              checkUsernameAvailability(value);
              setEmployee({ ...employee, username: value });
            }}
            userExists={userExists}
          />
        </Modal>
      )}
      <Modal
        title="Import Employee"
        titleClassName="blueHeader"
        isOpen={modalOpen === 2}
        className="modalSmall"
        onRequestClose={handleCloseModal}
        submitButtonColor="blue"
        submitActions={handleImport}
        disableSubmit={attachmentLoading}
      >
        <Button
          color="blue"
          onClick={() => {
            var win = window.open(EMPLOYEE_BULK_UPLOAD_TEMPLATE, '_blank');
            win.focus();
          }}
          text="Download CSV Template"
        />
        <CSVUploader documentType="EmployeesUpload" className="dropzone" />
      </Modal>
      <Modal
        title="Issues with Employee Upload"
        titleClassName="redHeader"
        isOpen={modalOpen === 3}
        className="modalMedium"
        onRequestClose={handleCloseModal}
        hideCancelButton={true}
        submitActions={handleCloseModal}
        submitButtonText="Close"
        showXButton={true}
      >
        <ul>
          {messages?.map((issue, index) => (
            <li key={index}>{issue}</li>
          ))}
        </ul>
      </Modal>
      <Modal
        title="Add New Contractor"
        titleClassName="greenHeader"
        isOpen={openContractorModal}
        className="modalWide"
        onRequestClose={() => setOpenContractorModal(false)}
        submitActions={() =>
          canSubmitContractor ? handleAddEmployeeSubmit(contractor) : null
        }
        submitButtonText="Submit"
        submitButtonColor="green"
        onMouseEnter={() => setMissingRequired(!canSubmitContractor)}
        submitTooltip={missingRequired ? 'Missing or Invalid Fields' : null}
      >
        <ContractorForm
          contractor={contractor}
          updateContractor={values =>
            setContractor({ ...contractor, ...values })
          }
          checkUsernameAvailability={value => {
            checkUsernameAvailability(value);
            setContractor({ ...contractor, username: value });
          }}
          missingRequired={missingRequired}
          userExists={userExists}
        />
      </Modal>
    </>
  );
}
