import React from 'react';
import './svg.css';

const search = props => (
  <svg
    version="1.1"
    x={props.x || '0px'}
    y={props.y || '0px'}
    width="30px"
    height="25px"
    viewBox="0 0 100 100"
    enableBackground="new 0 0 100 100"
  >
    <g>
      <path d="M45.3,68.8c5.7,0,11-2,15-5.4l14.8,14.8l3-3L63.4,60.4c3.4-4.1,5.4-9.3,5.4-15c0-13-10.5-23.5-23.5-23.5   S21.8,32.4,21.8,45.4S32.4,68.8,45.3,68.8z M45.3,26.2c10.6,0,19.2,8.6,19.2,19.2s-8.6,19.2-19.2,19.2s-19.2-8.6-19.2-19.2   S34.8,26.2,45.3,26.2z" />
    </g>
  </svg>
);

export default search;
