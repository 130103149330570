import React from 'react';
import { Dropdown, TwoColumn } from '../inputs';
import useActiveHeirarchy from '../../utils/useActiveHeirarchy';
import { getSingleCompanyGroupDropdown } from '../../utils/employeeDGPHelper';

export const HierarchySelector = ({
  onGroupChange,
  groupId,
  groupDisabled,
  onProjectChange,
  projectId,
  projectDisabled,
  alwaysShowGroupDropdown = false,
  alwaysShowProjectDropdown = false,
  groupRequired = true,
  alphabetize = false,
  multi = false,
  filteredGroupDropdown,
  filteredProjectDropdown,
  showFilteredMessage,
  noTwoColumn,
  groupTooltip,
  displayGroupTooltip,
  projectTooltip,
  displayProjectTooltip,
  showProject = true,
  excludeGroups,
  fromIncidentBasics = false,
  projectRequired = false,
  allowProjectClear = true,
  groupTouched,
  projectTouched
}) => {
  const { activeCompany, activeGroup, activeProject } = useActiveHeirarchy();

  let groupDropdown =
    filteredGroupDropdown ?? getSingleCompanyGroupDropdown(activeCompany);

  groupDropdown = groupDropdown?.filter(
    gd => !excludeGroups?.includes(gd.value)
  );

  let projectDropdown = [];

  if (multi) {
    let groups = [];
    if (activeGroup) {
      groups = [activeGroup._id];
    } else {
      groups = groupId ?? [];
    }

    groups.forEach(g => {
      let group = activeCompany?.groups?.find(l => l._id === g);
      if (!group) return;

      group.projects.forEach(project => {
        if (!project.isHidden)
          projectDropdown.push({
            value: project._id,
            label: `${group.name} - ${project.name}`
          });
      });
    });
  } else {
    const selectedGroup = activeCompany.groups.filter(
      group => group._id === activeGroup._id || group._id === groupId
    );

    if (selectedGroup.length > 0) {
      projectDropdown = selectedGroup[0].projects
        ?.filter(p => !p.isHidden)
        ?.map(project => {
          return {
            value: project._id,
            label: project.name
          };
        });
      if (projectDropdown.length && allowProjectClear)
        projectDropdown.unshift({ value: '', label: '      ' });
    }
  }

  const ddInfo = (
    <>
      {(!activeGroup || alwaysShowGroupDropdown) && (
        <Dropdown
          searchable
          options={groupDropdown}
          fieldLabel="Group or Establishment:"
          currentValue={groupId}
          onChange={onGroupChange}
          isRequired={groupRequired}
          disabled={groupDisabled}
          alphabetize={alphabetize}
          multi={multi}
          selectButtons={multi}
          touched={groupTouched}
          tooltip={groupTooltip}
          displayTooltip={displayGroupTooltip}
        />
      )}
      {fromIncidentBasics &&
      !projectDropdown.length &&
      groupId ? null : (!activeProject || alwaysShowProjectDropdown) &&
        showProject ? (
        <Dropdown
          searchable
          options={filteredProjectDropdown ?? projectDropdown}
          fieldLabel="Area:"
          currentValue={projectId?.length ? projectId : null}
          onChange={onProjectChange}
          disabled={
            projectDisabled ||
            !projectDropdown.length ||
            (filteredProjectDropdown && !filteredProjectDropdown?.length)
          }
          placeholder={
            activeGroup
              ? ''
              : !groupId?.length
                ? 'Select a Group/Establishment'
                : !filteredProjectDropdown?.length && showFilteredMessage
                  ? 'No Areas available for selection'
                  : !projectDropdown.length
                    ? 'No Areas in Group/Establishment'
                    : ''
          }
          alphabetize={alphabetize}
          multi={multi}
          selectButtons={multi}
          tooltip={projectTooltip}
          displayTooltip={displayProjectTooltip}
          isRequired={projectRequired}
          touched={projectTouched}
        />
      ) : null}
    </>
  );

  return noTwoColumn ? ddInfo : <TwoColumn>{ddInfo}</TwoColumn>;
};

export default HierarchySelector;
