import React from 'react';
import './svg.css';

const violence = props => (
  <svg
    width={props.width ?? 30}
    height={props.height ?? 25}
    viewBox="0 0 80 80"
    x={props.x || '0px'}
    y={props.y || '0px'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2257_89458)">
      <path d="M59.7685 7.32595C60.8634 7.32595 61.7896 6.39974 61.7896 5.30491V2.02104C61.7896 0.926208 60.8634 0 59.7685 0C58.6737 0 57.7475 0.926208 57.7475 2.02104V5.30519C57.6633 6.39974 58.5896 7.32595 59.7685 7.32595Z" />
      <path d="M50.4187 8.33574C50.7556 8.92508 51.429 9.34609 52.187 9.34609C52.5239 9.34609 52.8607 9.26194 53.1973 9.09336C54.1235 8.50402 54.4604 7.32508 53.9552 6.31438L52.3552 3.45127C52.1025 2.94608 51.6815 2.6092 51.0921 2.52506C50.5869 2.35676 49.9973 2.44091 49.5763 2.69336C49.0711 2.9461 48.7342 3.36713 48.6501 3.95647C48.4818 4.46166 48.5659 5.0513 48.8184 5.47234L50.4187 8.33574Z" />
      <path d="M66.251 9.01183C66.5879 9.18013 66.9248 9.26456 67.2613 9.26456C68.0193 9.26456 68.6086 8.84353 69.0296 8.25422L70.6299 5.47524C71.2192 4.54904 70.8826 3.28593 69.872 2.69627C68.9458 2.10693 67.6827 2.44354 67.093 3.4542L65.4933 6.31732C64.9037 7.24352 65.2405 8.50663 66.2509 9.0118L66.251 9.01183Z" />
      <path d="M27.9351 16.3347C27.9351 20.5668 24.5043 23.9976 20.2722 23.9976C16.0402 23.9976 12.6094 20.5668 12.6094 16.3347C12.6094 12.1027 16.0402 8.67188 20.2722 8.67188C24.5043 8.67188 27.9351 12.1027 27.9351 16.3347Z" />
      <path d="M48.0601 27.2835C49.8284 26.6941 50.8391 24.7572 50.2495 22.9048C49.6601 21.1366 47.7232 20.1259 45.8708 20.7155C45.7025 20.7997 29.7031 25.9362 20.1877 25.9362C8.56708 25.9362 1.07265 34.5253 0.82003 34.8622C0.0620955 35.7042 -0.190315 36.9674 0.14626 38.0622L2.1673 45.1356C2.58834 46.6514 3.93558 47.6618 5.45145 47.6618C5.78833 47.6618 6.04079 47.6618 6.37766 47.4935C8.23007 46.9883 9.24077 45.0514 8.7356 43.2831L7.30422 38.1466C8.31456 37.2204 9.9987 35.9573 12.0197 34.8624L11.9356 49.7671L2.42019 74.7766C1.66226 76.7135 2.67292 78.987 4.6095 79.7449C5.03054 79.9132 5.53571 79.9976 5.95675 79.9976C7.47262 79.9976 8.98813 79.0714 9.49331 77.5555L19.2613 51.9566H20.8613L21.1141 76.2082C21.1141 78.3134 22.8823 80.0817 24.9875 79.9976C27.0927 79.9976 28.7768 78.2293 28.7768 76.1241L28.44 32.0838C37.7027 30.5682 47.4708 27.4524 48.0602 27.2841L48.0601 27.2835Z" />
      <path d="M67.3413 18.2722C67.3413 22.5045 63.9105 25.9351 59.6785 25.9351C55.4464 25.9351 52.0156 22.5043 52.0156 18.2722C52.0156 14.0402 55.4464 10.6094 59.6785 10.6094C63.9105 10.6094 67.3413 14.0402 67.3413 18.2722Z" />
      <path d="M79.0515 36.7128C78.7146 36.376 70.715 27.8711 59.768 27.8711C51.9368 27.8711 47.2212 32.5025 44.9476 35.7024L38.8848 33.092C37.1165 32.3341 35.0955 33.1762 34.3376 34.9444C33.5796 36.7127 34.4217 38.7337 36.19 39.4917L45.0317 43.1969C46.7159 43.9548 48.7369 43.1127 49.4948 41.4286C49.579 41.2603 50.3369 39.5762 52.0211 37.9762L53.0314 50.944L45.7054 58.5227C45.0316 59.1965 44.6106 60.2068 44.6106 61.2172V76.206C44.6106 78.3112 46.2947 79.9954 48.3999 79.9954C50.5051 79.9954 52.1892 78.3112 52.1892 76.206V62.7329L59.3468 55.4069L58.1678 60.2066C57.9151 61.1328 57.9995 62.1435 58.5047 62.9855L66.5886 77.8902C67.2624 79.1533 68.6096 79.9112 69.9569 79.9112C70.5462 79.9112 71.22 79.7429 71.7252 79.4902C73.5776 78.4798 74.2514 76.206 73.241 74.3536L65.915 60.7121L67.8519 53.3861C67.9361 53.0492 68.0202 52.7123 68.0202 52.3757L67.9361 36.9658C70.0412 38.0606 71.7254 39.3238 72.7357 40.25L71.2199 46.7336C70.7988 48.586 71.9778 50.4387 73.8302 50.8598C74.083 50.9439 74.3354 50.9439 74.5881 50.9439C76.1881 50.9439 77.5354 49.8491 77.9564 48.2494L79.8933 39.8287C80.1458 38.7339 79.8092 37.555 79.0513 36.7129L79.0515 36.7128Z" />
    </g>
    <defs>
      <clipPath id="clip0_2257_89458">
        <rect width="80" height="80" />
      </clipPath>
    </defs>
  </svg>
);

export default violence;
