import React from 'react';
import './svg.css';

const leaderboard = props => (
  <svg
    width={props.width ?? 30}
    height={props.height ?? 25}
    viewBox="0 0 80 80"
    x={props.x || '0px'}
    y={props.y || '0px'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M74.5353 6.30595H64.478V2.3351C64.4788 0.657648 63.8202 0 62.1437 0H16.055C14.3784 0 13.7199 0.657715 13.7199 2.3351V6.30595H3.97441C2.36474 6.30595 1.73242 6.93791 1.73242 8.54794V8.73371C2.24806 21.743 10.8423 32.7625 23.8612 37.4227C26.6683 41.5712 30.1024 45.2192 34.2718 48.2141C33.7519 56.0535 30.9856 61.5159 26.6124 66.6109C26.2764 67.0012 26.2602 67.314 26.9315 67.314H51.2685C51.9387 67.314 51.9228 67.0021 51.5868 66.6109C47.2125 61.5156 44.4462 56.055 43.9266 48.2141C48.0503 45.2539 51.4535 41.652 54.2424 37.5619C67.4873 32.9874 76.2556 21.877 76.7771 8.73371V8.54794C76.778 6.93797 76.1452 6.30595 74.5355 6.30595H74.5353ZM6.67708 11.4383C6.63802 11.1831 6.7092 10.9762 7.07785 10.9762H14.23C15.3287 17.9382 17.1537 24.5292 19.9165 30.4382C12.7184 26.1751 7.88579 19.3476 6.67687 11.4384L6.67708 11.4383ZM71.8322 11.4383C70.5992 19.5008 65.6009 26.4387 58.1704 30.6833C60.9971 24.7132 62.856 18.0348 63.968 10.9768H71.4312C71.8007 10.9768 71.8719 11.184 71.8319 11.4381L71.8322 11.4383Z" />
    <path d="M57.7817 69.6465H20.4132C18.8649 69.6465 18.0781 70.4358 18.0781 71.9816V77.6652C18.0781 79.2127 18.8658 80.0003 20.4132 80.0003H57.7817C59.33 80.0003 60.1168 79.2127 60.1168 77.6652V71.9816C60.1168 70.435 59.3291 69.6465 57.7817 69.6465Z" />
  </svg>
);

export default leaderboard;
