import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import history from '../../history';
import {
  createDocument,
  deleteDocument,
  fetchDocumentById,
  fetchDocumentFoldersByType,
  updateDocument,
  deleteAttachment
} from '../../api/v4';
import {
  clearUploadedAttachments,
  addUploadedAttachmentsRequest,
  addUploadedAttachmentsResponse
} from '../../actions/attachments';
import { getAddedAttachmentsSelector } from '../../selectors/attachments';
import { getActiveCompany, getAllLocations } from '../../selectors/company';
import { getAccessLevel } from '../../selectors/users';
import customToast from '../../utils/customToast';
import Card from '../../components/Card';
import { IReportDatePicker } from '../../components/inputs/DateTimePicker';
import { Dropdown, Textbox } from '../../components/inputs';
import FileDownloader from '../../components/FileDownloader';
import FileUploader from '../../components/FileUploader';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import HierarchySelector from '../../components/HierarchySelector';
import SaveChangesModal from '../../components/Modal/saveChangesModal';
import { SaveCancelFooter } from '../../components/Footer';
import SdsButtons from '../../components/inputs/SdsButtons';
import MissingRequiredModal from '../../components/Modal/missingRequiredModal';

export default function SdsContainer() {
  const { docId } = useParams();
  const dispatch = useDispatch();

  const allGroups = useSelector(getAllLocations);
  const accessLevel = useSelector(getAccessLevel);
  const addedAttachments = useSelector(getAddedAttachmentsSelector);
  const company = useSelector(getActiveCompany);

  const [document, setDocument] = useState();
  const [documentData, setDocumentData] = useState();
  const [attachments, setAttachments] = useState([]);
  const [folders, setFolders] = useState([]);
  const [isCreating, setCreating] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [openUnsavedChangesModal, setOpenUnsavedChangesModal] = useState(false);
  const [missingRequired, setMissingRequired] = useState(false);
  const [missingModalOpen, setMissingModalOpen] = useState(false);
  const allAttachments = [...attachments, ...addedAttachments];

  useEffect(() => {
    if (docId) {
      fetchDocumentById(docId).then(response => {
        setDocument({
          label: response.label,
          expires: response.expires,
          attachments: response.attachments,
          groupIds: response?.groupIds,
          projectIds: response?.projectIds,
          documentFolderId: response?.documentFolderId,
          isArchived: response?.isArchived
        });
        setDocumentData({ ...response?.documentData });
        setAttachments(response?.attachments);
      });
    } else {
      setCreating(true);
      setDocument({
        companyId: company?._id
      });
    }
    fetchDocumentFoldersByType('SDS').then(response =>
      setFolders(response?.map(df => ({ value: df?._id, label: df?.name })))
    );
  }, [docId, company]);

  const handleSubmit = () => {
    const attachmentIds = allAttachments?.map(a => a._id);
    let payload = {
      ...document,
      documentData: { ...documentData },
      documentType: 'SDS',
      attachments: attachmentIds
    };
    if (isEditing) {
      updateDocument({ ...payload, _id: docId }).then(() => {
        dispatch(clearUploadedAttachments());
        history.goBack();
      });
    } else {
      createDocument(payload).then(() => {
        dispatch(clearUploadedAttachments());
        customToast(`Successfully Created!`, 'success');
        history.goBack();
      });
    }
  };

  const handleDelete = () => {
    deleteDocument(docId).then(() => history.goBack());
  };

  const handleDeleteAttachment = attachment => {
    let updatedAttachments = attachments?.filter(
      a => a._id !== attachment?._id
    );

    let updatedAddedAttachments = addedAttachments?.filter(
      a => a._id !== attachment?._id
    );

    dispatch(addUploadedAttachmentsResponse(updatedAddedAttachments));

    deleteAttachment(attachment?._id).then(response => {
      customToast(`Successfully Deleted!`, 'success');
    });
    setAttachments(updatedAttachments);
  };

  const handleAnswer = (values, isDocumentData = true) => {
    isDocumentData
      ? setDocumentData({ ...documentData, ...values })
      : setDocument({ ...document, ...values });
    setHasUnsavedChanges(true);
  };

  const handleBack = () => {
    dispatch(clearUploadedAttachments());
    history.goBack();
  };

  const handleArchive = () => {
    updateDocument({
      ...document,
      _id: docId,
      isArchived: !document?.isArchived
    }).then(() => {
      if (!document.isArchived) handleBack();
      else
        fetchDocumentById(docId).then(response =>
          setDocument({
            label: response.label,
            expires: response.expires,
            attachments: response.attachments,
            groupIds: response?.groupIds,
            projectIds: response?.projectIds,
            documentFolderId: response?.documentFolderId,
            isArchived: response?.isArchived
          })
        );
    });
  };

  const header = (
    <Header
      title="SDS"
      section={
        !isEditing && !isCreating
          ? 'View Document'
          : isCreating
            ? 'Create Document'
            : 'Edit Document'
      }
      clickBack={() =>
        hasUnsavedChanges ? setOpenUnsavedChangesModal(true) : handleBack()
      }
      needsSaved={hasUnsavedChanges}
      rightButtons={[
        {
          visible: !isCreating && !isEditing && accessLevel !== 100,
          text: 'Edit',
          color: 'blue',
          onClick: () => setEditing(true)
        },
        {
          visible: isEditing,
          text: 'Delete',
          color: 'red',
          onClick: () => handleDelete()
        }
      ]}
      pageActionOptions={
        accessLevel > 400 && !isCreating
          ? [
              {
                label: 'Edit SDS',
                color: 'blueOutline',
                onClick: () => setEditing(true),
                visible: !isEditing && !document?.isArchived
              },
              {
                label: 'Delete SDS',
                color: 'redOutline',
                onClick: () => handleDelete(),
                visible: isEditing || document?.isArchived
              },
              {
                label: `${document?.isArchived ? 'Un-' : ''}Archive SDS`,
                color: 'blueOutline',
                onClick: () => handleArchive(),
                visible: true
              }
            ]
          : null
      }
    />
  );

  const footer =
    isEditing || isCreating ? (
      <SaveCancelFooter
        saveButtonClick={() =>
          !document?.label ? setMissingModalOpen(true) : handleSubmit()
        }
        saveButtonDisabled={!hasUnsavedChanges && isEditing}
        cancelButtonClick={() =>
          hasUnsavedChanges ? setOpenUnsavedChangesModal(true) : handleBack()
        }
        editing={isEditing}
        onMouseEnter={() => setMissingRequired(!document?.label)}
      />
    ) : null;

  const getProjectDropdown = groupIds => {
    const selectedGroupProjects = allGroups
      ?.filter(g => groupIds?.includes(g._id))
      .reduce((projects, g) => projects.concat(g.projects), []);
    let projects = selectedGroupProjects?.map(p => ({
      value: p._id,
      label: p.name
    }));
    return projects;
  };

  return (
    <>
      <HeaderAndFooter Header={header} Footer={footer}>
        <Card>
          <HierarchySelector
            multi
            groupDisabled={!isCreating && !isEditing}
            projectDisabled={!isCreating && !isEditing}
            onGroupChange={v => {
              if (document?.projectIds) {
                const availableProjects = getProjectDropdown(v);
                const projectIds = availableProjects
                  ?.filter(ap => document?.projectIds?.includes(ap?.value))
                  ?.map(ap => ap?.value);
                handleAnswer({ groupIds: v, projectIds }, false);
              } else {
                handleAnswer({ groupIds: v }, false);
              }
            }}
            onProjectChange={v => handleAnswer({ projectIds: v }, false)}
            groupId={document?.groupIds}
            projectId={document?.projectIds}
            alwaysShowGroupDropdown
            alwaysShowProjectDropdown
            noTwoColumn
            groupTooltip={
              <p>
                You can share with one or more Groups / Establishments and that
                will make it available to them as well
              </p>
            }
            displayGroupTooltip
            projectTooltip={
              <p>
                You can share this document with one or more Areas and that will
                make it available to them as well
              </p>
            }
            displayProjectTooltip
            groupRequired={accessLevel === 500}
          />
          <Textbox
            currentValue={document?.label}
            fieldLabel="Title:"
            isRequired
            onChange={v => handleAnswer({ label: v }, false)}
            disabled={!isCreating && !isEditing}
            touched={missingRequired && !document?.label}
          />
          <IReportDatePicker
            fieldLabel="Expires:"
            onChange={v => handleAnswer({ expires: v }, false)}
            currentValue={document?.expires}
            name="spExpirationDate"
            disabled={!isCreating && !isEditing}
          />
          <Dropdown
            options={folders}
            fieldLabel="Add to Folder:"
            currentValue={document?.documentFolderId}
            onChange={v => handleAnswer({ documentFolderId: v }, false)}
            disabled={!isCreating && !isEditing}
            searchable
            allowUnset
          />
          <SdsButtons
            handleButtonClick={value => handleAnswer({ ghsLabels: value })}
            currentValue={
              documentData?.ghsLabels?.length ? documentData?.ghsLabels : []
            }
            disabled={!isCreating && !isEditing}
          />
          <h4> Attachments</h4>
          {allAttachments?.map((attachment, attachmentIndex) => (
            <FileDownloader
              attachment={attachment}
              key={attachmentIndex}
              attachmentIndex={attachmentIndex}
              ownerType="documents"
              deleteAttachment={handleDeleteAttachment}
              disabled={!isCreating && !isEditing}
            />
          ))}
          {isEditing || isCreating ? (
            <FileUploader
              addAttachment={attachment =>
                dispatch(addUploadedAttachmentsRequest(attachment))
              }
              ownerId={docId ?? 'pending'}
              ownerType="documents"
            />
          ) : null}
        </Card>
        <SaveChangesModal
          isOpen={openUnsavedChangesModal}
          onRequestClose={() => setOpenUnsavedChangesModal(false)}
          submitActions={() => handleBack()}
          savingWhat="a SDS"
        />
        <MissingRequiredModal
          isOpen={missingModalOpen}
          onRequestClose={() => setMissingModalOpen(false)}
          subject="SDS"
          isEditing={isEditing}
        />
      </HeaderAndFooter>
    </>
  );
}
