import React from 'react';
import classnames from 'classnames/bind';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import styles from './progress.module.scss';
const bStyles = classnames.bind(styles);

export const ProgressBar = ({
  complete,
  bars = [],
  className,
  scale = 1,
  showTooltip,
  tooltip,
  name
}) => (
  <div className={bStyles(className, 'container')}>
    {showTooltip && (
      <ReactTooltip className="whiteTheme" id={name}>
        {tooltip}
      </ReactTooltip>
    )}
    <div className={styles.bar} data-tip="whiteTheme" data-tooltip-id={name}>
      {bars.map((bar, i) => {
        if (!bar.width) {
          return <React.Fragment key={i} />;
        }
        return (
          <div
            key={i}
            className={bStyles('barPiece', bar.color)}
            style={{ width: `${(bar.width / scale) * 100}%` }}
          />
        );
      })}
    </div>
    {typeof complete === 'boolean' ? (
      complete ? (
        <img
          src={require('../../assets/images/CompleteStar.png')}
          alt="complete"
        />
      ) : (
        <img
          src={require('../../assets/images/IncompleteStar.png')}
          alt="incomplete"
        />
      )
    ) : (
      <></>
    )}
  </div>
);

export default ProgressBar;
