import React from 'react';
import './svg.css';

const general = props => (
  <svg
    width={props.width ?? 30}
    height={props.height ?? 25}
    viewBox="0 0 80 80"
    x={props.x || '0px'}
    y={props.y || '0px'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M68.5626 27.6802H78.0026V50.2402H68.5626V27.6802Z" />
    <path d="M76.1626 17.76H70.4027C69.3626 17.76 68.5626 18.4801 68.5626 19.36V24.7199H78.0026V19.36C78.0026 18.4801 77.2027 17.76 76.1626 17.76Z" />
    <path d="M71.0385 59.8401L72.1585 61.6803C72.6385 62.4803 73.9184 62.4803 74.3987 61.6803L75.5187 59.8401C76.7987 57.7602 77.5987 55.5201 77.8388 53.2H68.7188C68.9587 55.6 69.7583 57.8401 71.0385 59.8401Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57.3599 8.79987H49.5199V11.5999C49.5199 14.1597 47.44 16.1597 44.9601 16.1597H18.1601C15.6002 16.1597 13.6002 14.0798 13.6002 11.5999L13.5999 8.79987H5.67993C3.67993 8.79987 2 10.4798 2 12.4798V74.3198C2 76.3198 3.67993 77.9997 5.67993 77.9997H57.2799C59.2799 77.9997 60.9599 76.3198 60.9599 74.3198L60.9601 12.4798C60.9601 10.4798 59.3599 8.79987 57.3599 8.79987ZM46.7999 66.3199H16.2399C15.1199 66.3199 14.2399 65.4399 14.2399 64.3199C14.2399 63.1998 15.1199 62.3199 16.2399 62.3199H46.7199C47.84 62.3199 48.7199 63.1998 48.7199 64.3199C48.7199 65.4399 47.92 66.3199 46.7999 66.3199ZM46.7999 53.9999H16.2399C15.1199 53.9999 14.2399 53.1199 14.2399 51.9999C14.2399 50.8798 15.1199 49.9999 16.2399 49.9999H46.7199C47.84 49.9999 48.7199 50.8798 48.7199 51.9999C48.7199 53.1199 47.92 53.9999 46.7999 53.9999ZM46.7999 41.7599H16.2399C15.1199 41.7599 14.2399 40.8799 14.2399 39.7599C14.2399 38.6398 15.1199 37.7599 16.2399 37.7599H46.7199C47.84 37.7599 48.7199 38.6398 48.7199 39.7599C48.7199 40.8799 47.92 41.7599 46.7999 41.7599ZM46.7999 29.5199H16.2399C15.1199 29.5199 14.2399 28.6399 14.2399 27.5199C14.2399 26.3998 15.1199 25.5199 16.2399 25.5199H46.7199C47.84 25.5199 48.7199 26.3998 48.7199 27.5199C48.7199 28.6399 47.92 29.5199 46.7999 29.5199Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.1629 12.5599H44.8829C45.3629 12.5599 45.7629 12.1599 45.7629 11.6799V6.95987C45.7629 4.2398 43.5227 2 40.803 2H22.163C19.4429 2 17.2031 4.24013 17.2031 6.95987V11.6799C17.2828 12.1599 17.6827 12.5599 18.1629 12.5599Z"
    />
  </svg>
);

export default general;
