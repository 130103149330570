import './logrocketSetup.js';
import './wdyr.js';
import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import Bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import LogRocket from 'logrocket';

import Root from './containers/Root';
import config from './config/config';
import store from './store';
import GitInfo from './git.macro';
// import localforage from 'localforage';
import 'react-toastify/dist/ReactToastify.css';
let ErrorBoundary = React.Fragment;

if (process.env.REACT_APP_STAGE !== 'development ') {
  const gitInfo = new GitInfo();
  const bugsnagClient = Bugsnag({
    apiKey: '819bc47acce31fb33c49bb88d30e1090',
    releaseStage: config.name,
    appVersion: gitInfo.describe
  });
  bugsnagClient.use(bugsnagReact, React);
  ErrorBoundary = bugsnagClient.getPlugin('react');
  Bugsnag.beforeNotify = function (data) {
    data.metaData.sessionURL = LogRocket.sessionURL;
    return data;
  };
}

const root = createRoot(document.getElementById('root'));
root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <ToastContainer />
      <Root />
    </Provider>
  </ErrorBoundary>
);
