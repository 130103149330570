import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {
  dropDownRequired,
  renderDropdownField,
  renderNumberField,
  required
} from '../../utils/formValidation';

import '../form.css';

// this needs to be brought out of redux-form

const optionList = [
  { label: 'Under 20', value: 0 },
  { label: '20 - 49', value: 1 },
  { label: '50 - 99', value: 2 },
  { label: '100 - 249', value: 3 },
  { label: '250 - 499', value: 4 },
  { label: '500 - 999', value: 5 },
  { label: '1000+', value: 6 }
];

const OshaEmploymentForm = props => {
  const { handleSubmit, totalHours, year } = props;

  return (
    <form className="oshaEmploymentForm" onSubmit={handleSubmit}>
      <div className="form-fields">
        <Field
          name="annualAverageNumberOfWorkers"
          component={renderNumberField}
          label="Annual average number of workers."
          placeholder={0}
          requiredLabel={true}
          validate={[required]}
          displayToolTip={true}
        >
          <p>
            Total number of worker the establishment paid that year (include
            all, not just full-time).
          </p>
          <p>Divided By:</p>
          <p>The number of pay periods the establishment has that year.</p>
          <p>Then round to the nearest whole number.</p>
        </Field>
        <Field
          label="Maximum number of workers at any given time last year."
          name="maximumNumberOfWorkersEmployed"
          component={renderDropdownField}
          placeholder="Select an option"
          options={optionList}
          validate={[dropDownRequired]}
          className="form-select"
          displayToolTip={true}
          requiredLabel={true}
        >
          <p>NOT the total number of workers at the establishment that year.</p>
          <p>
            {' '}
            This is the highest number of workers working at the establishment
            at one time.
          </p>
        </Field>
        <p>
          <b>Total Hours worked by all workers in {year}</b>: {totalHours}
        </p>
        <p>
          <b>Total Hours worked by all workers</b> has been moved to{' '}
          <b>Settings</b> &rarr; <b>Company Division</b>.
        </p>
      </div>
    </form>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default reduxForm({
  form: 'OshaEmploymentForm'
})(OshaEmploymentForm);
