import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import history from '../../history';
import UserBadge from '../UserBadge';
import { noBubble } from '../../utils/events';

import './index.css';

const CompanyLogo = ({
  company,
  setActiveCompany,
  setActiveLocation,
  allLocations,
  setActiveGroups,
  setActiveProject,
  user,
  assignIncidentOwner,
  companyName
}) => {
  const restricted = user.accessLevel < 500;
  const handleLogoClick = () => {
    setActiveCompany(company);
    setActiveLocation(0);
    setActiveGroups(company.groups);
    allLocations(company.allLocations);
    setActiveProject();
    history.push({
      pathname: '/app/dashboard',
      state: {
        showToasts: true,
        showNotificationModal: !user.notifications
      }
    });
  };

  return (
    <div className="companyLogo">
      {company.logoUrl && (
        <img
          src={company.logoUrl}
          alt={`${company.name}'s Logo`}
          className={
            restricted ? 'companyLogoImage-noAccess' : 'companyLogoImage'
          }
          onClick={!restricted ? handleLogoClick : null}
        />
      )}
      <div className="companyLogo-company">
        <UserBadge
          size="medium"
          userId={company?.defaultIncidentOwner?.userId}
          showImage
          hoverName
          className={
            restricted ? 'companyLogoHover-noAccess' : 'companyLogoHover'
          }
          onClick={e => {
            noBubble(e);
            if (!restricted)
              assignIncidentOwner(company.defaultIncidentOwner?.userId ?? '');
          }}
        />
        <div
          className={
            restricted
              ? 'companyLogo-companyDivWithoutHover'
              : 'companyLogo-companyDiv'
          }
          onClick={!restricted ? handleLogoClick : null}
          data-tooltip-id={`${company._id}`}
          data-tooltip-class-name="toolTip"
        >
          {companyName}
        </div>
        {!restricted && (
          <div className="companyLogo-companyDivHover">
            {company.defaultIncidentOwner ? 'Reassign' : 'Assign'} Incident
            Owner
          </div>
        )}
      </div>
      {restricted && (
        <ReactTooltip id={`${company._id}`} className="tooltip">
          *Permission restricted for lower levels
        </ReactTooltip>
      )}
    </div>
  );
};
export default CompanyLogo;
