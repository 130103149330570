import React, { Component } from 'react';

import { isComplete } from '../../utils/reportHelper';
import { hasAccess } from '../../utils/incidentHelper';
import classnames from 'classnames/bind';

import styles from './viewOnly.module.scss';
const bStyles = classnames.bind(styles);

export class ViewOnly extends Component {
  render() {
    const { loggedInUser, activeReport, canUnlock, incident, className } =
      this.props;

    const complete = isComplete(activeReport.report);
    if (!complete && hasAccess(loggedInUser, activeReport?.stage, incident))
      return <></>;

    return (
      <div
        className={bStyles(className, 'viewOnly', {
          complete: complete && canUnlock
        })}
        onClick={canUnlock ? this.props.onClick : null}
      >
        <img
          alt="Report is locked"
          src={require('../../assets/images/reportLock.png')}
        />
        <img
          className={styles.hover}
          alt="Report is locked"
          src={require('../../assets/images/reportLockHover.png')}
        />
        <div>
          {complete ? (
            <div className={styles.completed}>Report Complete</div>
          ) : (
            <></>
          )}
          <div className={styles.vo}>VIEW ONLY ACCESS</div>
          <div className={styles.unlock}>UNLOCK?</div>
        </div>
      </div>
    );
  }
}

export default ViewOnly;
