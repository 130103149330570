import React from 'react';
import './svg.css';

const infoIcon = props => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <circle cx="8" cy="8" r="7.5" stroke="black" />
    <path
      d="M6.42 12.16L7.442 11.88V7.036L6.392 6.756V6H8.772V11.88L9.822 12.16V13H6.42V12.16ZM8.058 4.614C7.79667 4.614 7.582 4.53 7.414 4.362C7.246 4.194 7.162 3.97933 7.162 3.718C7.162 3.45667 7.246 3.24667 7.414 3.088C7.582 2.92 7.79667 2.836 8.058 2.836C8.31933 2.836 8.534 2.92 8.702 3.088C8.87 3.24667 8.954 3.45667 8.954 3.718C8.954 3.97933 8.87 4.194 8.702 4.362C8.534 4.53 8.31933 4.614 8.058 4.614Z"
      fill="black"
    />
  </svg>
);

export default infoIcon;
