import React from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';

import { getActiveCompany, getIsPerrp } from '../../selectors/company';
import { setActiveIncidentRequest } from '../../actions/incidents';
import classnames from 'classnames/bind';
import Loading from '../Loading';
import Card from '../Card';
import Pagination from '../Pagination';
import { Donut } from '../Donut';
import SvgWithTooltip from '../SvgWithTooltip';
import {
  CLAIM_STAGES,
  HAZARD_STAGES,
  REACTIVE_KEYS
} from '../../constants/constants';
import { useCardData } from './useCardData';
import Badge from '../Badge';

import styles from './headerCards.module.scss';
const perPage = 2;

const statusColor = status => {
  switch (status) {
    default:
    case 'notStarted':
      return 'black';
    case 'complete':
      return 'green';
    case 'pastDue':
      return 'red';
    case 'started':
      return 'blue';
  }
};

const statusText = status => {
  switch (status) {
    default:
    case 'notStarted':
      return 'Not Started';
    case 'complete':
      return 'Completed';
    case 'pastDue':
      return 'Past Due';
    case 'started':
      return 'Started';
  }
};

let IncidentCard = ({ card, className }) => {
  const [page, setPage] = React.useState(0);
  const data = useCardData(card);
  const isPerrp = useSelector(getIsPerrp);
  const company = useSelector(getActiveCompany);
  const dispatch = useDispatch();
  const loadIncident = incidentId =>
    dispatch(setActiveIncidentRequest(incidentId));
  const word = isPerrp ? 'PERRP' : 'OSHA';

  const sliced = data?.slice(page * perPage, (page + 1) * perPage);
  return (
    <Card
      className={classnames(styles.card, className)}
      title={card?.name}
      showHeader
      wide
    >
      {data === undefined ? (
        <Loading />
      ) : data.length === 0 ? (
        <div className={styles.empty}>No Incidents Available</div>
      ) : (
        sliced.map(incident => {
          let group = company?.groups?.find(
            g => g._id.toString() === incident.locationId
          );
          let project = group?.projects?.find(
            p => p._id.toString() === incident.projectId
          );
          let name = group?.name;
          if (project) {
            name += ` - ${project.name}`;
          }
          let claimStage =
            REACTIVE_KEYS.indexOf(parseInt(incident.reportTemplateIds[0], 10)) >
            -1
              ? CLAIM_STAGES[incident.reportStage || 0]
              : HAZARD_STAGES[incident.reportStage || 0];

          let oshaStatus =
            incident.oshaStatus === 'notStarted'
              ? `${word} Compliance Not Started`
              : incident.oshaStatus === 'incomplete'
                ? `${word} Compliance Incomplete`
                : incident.oshaStatus === 'recordable'
                  ? `${word} Recordable`
                  : incident.oshaStatus === 'notApplicable'
                    ? `Not ${word} Recordable`
                    : undefined;

          return (
            <div
              className={styles.incident}
              onClick={() => loadIncident(incident._id)}
              key={incident._id}
            >
              <div className={styles.incidentInfo}>
                <span className={styles.number}>{incident.incidentNumber}</span>
                {name && <span className={styles.group}>{name}</span>}
                <span className={styles.reported}>
                  Reported {moment(incident.createdAt).format('l LT')}
                </span>
                <span className={styles.reported}>
                  Occurred {moment(incident.dateIncidentOccured).format('l LT')}
                </span>
                <div className={styles.categories}>
                  {incident.reportTemplateIds.map((t, i) => (
                    <SvgWithTooltip type={t} key={i} />
                  ))}
                </div>
                <span className={styles.claimStage}>
                  <b>Report Status:</b> {claimStage}
                </span>
                {oshaStatus && (
                  <Badge
                    color={
                      incident.oshaStatus === 'notStarted' ||
                      incident.oshaStatus === 'incomplete'
                        ? 'redOutline'
                        : incident.oshaStatus === 'notApplicable'
                          ? 'green'
                          : 'red'
                    }
                    text={oshaStatus}
                    square
                  />
                )}
              </div>
              <div className={styles.donutContainer}>
                <div className={styles.donutStage}>
                  <span className={styles.donutText}>Investigation</span>
                  <Donut
                    className={[
                      styles.donut,
                      styles[incident.status.investigation]
                    ]}
                    progress={Math.round(
                      incident.status.investigationComplete * 100
                    )}
                    color={statusColor(incident.status.investigation)}
                    text={statusText(incident.status.investigation)}
                  />
                </div>
                <div className={styles.donutStage}>
                  <span className={styles.donutText}>Action</span>
                  <Donut
                    className={[styles.donut, styles[incident.status.action]]}
                    progress={Math.round(incident.status.actionComplete * 100)}
                    color={statusColor(incident.status.action)}
                    text={statusText(incident.status.action)}
                  />
                </div>
                <div className={styles.donutStage}>
                  <span className={styles.donutText}>Closure</span>
                  <Donut
                    className={[styles.donut, styles[incident.status.closure]]}
                    progress={Math.round(incident.status.closureComplete * 100)}
                    color={statusColor(incident.status.closure)}
                    text={statusText(incident.status.closure)}
                  />
                </div>
              </div>
            </div>
          );
        })
      )}
      <Pagination
        page={page}
        setPage={setPage}
        perPage={perPage}
        count={data?.length}
        className={styles.pagination}
      />
    </Card>
  );
};

export { IncidentCard };
export default IncidentCard;
