import React, { Component } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { stateAbbr } from '../../constants/states';
import Checkbox from '../inputs/Checkbox';
import CheckboxGroup from '../inputs/CheckboxGroup';
import { IReportDatePicker, TimePicker } from '../inputs/DateTimePicker';
import RadioButtons from '../inputs/RadioButtons';
import { InputRow, Textbox, EmployeeDropdown, Dropdown } from '../inputs';
import { parseTime, renderTime } from '../../utils/dateHelpers';
import MultipleDateRangePicker from '../inputs/MultipleDateRangePicker';
import { numberOfDays } from '../../utils/oshaDbMapper';
import { fetchEmployee } from '../../api/v4';

import './index.css';

export default class OshaInjuredWorker extends Component {
  handleDatesSubmit(
    { startDate, endDate, editing, previousEndDate, previousStartDate },
    type
  ) {
    let datesAwayArray;
    if (type === 'daysAway') {
      if (editing) {
        datesAwayArray = this.props.oshaForm.daysAwayFromWorkArray.filter(
          d =>
            d.startDate !== previousStartDate && d.endDate !== previousEndDate
        );
      } else {
        datesAwayArray = this.props.oshaForm.daysAwayFromWorkArray || [];
      }
      datesAwayArray.push({
        startDate: startDate,
        endDate: endDate
      });
      this.props.handleInjuredWorkerChange({
        daysAwayFromWorkArray: datesAwayArray,
        daysJobRestriction: numberOfDays(
          this.props.oshaForm.daysJobRestrictionArray,
          this.props.oshaForm.daysJobRestrictionCheckbox
        ),
        daysAwayFromWork: numberOfDays(
          datesAwayArray,
          this.props.oshaForm.daysAwayFromWorkCheckbox
        )
      });
    } else {
      if (editing) {
        datesAwayArray = this.props.oshaForm.daysJobRestrictionArray.filter(
          d =>
            d.startDate !== previousStartDate && d.endDate !== previousEndDate
        );
      } else {
        datesAwayArray = this.props.oshaForm.daysJobRestrictionArray || [];
      }

      datesAwayArray.push({
        startDate: startDate,
        endDate: endDate
      });
      this.props.handleInjuredWorkerChange({
        daysJobRestrictionArray: datesAwayArray,
        daysJobRestriction: numberOfDays(
          datesAwayArray,
          this.props.oshaForm.daysJobRestrictionCheckbox
        ),
        daysAwayFromWork: numberOfDays(
          this.props.oshaForm.daysAwayFromWorkArray,
          this.props.oshaForm.daysAwayFromWorkCheckbox
        )
      });
    }
  }

  deleteDate({ startDate, endDate }, type) {
    let filtered;
    if (type === 'daysAway') {
      filtered = this.props.oshaForm.daysAwayFromWorkArray.filter(
        d =>
          d.startDate !== startDate &&
          (d.endDate !== endDate || d.endDate === null)
      );
      this.props.handleInjuredWorkerChange({
        daysAwayFromWorkArray: filtered,
        daysJobRestriction: numberOfDays(
          this.props.oshaForm.daysJobRestrictionArray,
          this.props.oshaForm.daysJobRestrictionCheckbox
        ),
        daysAwayFromWork: numberOfDays(
          filtered,
          this.props.oshaForm.daysAwayFromWorkCheckbox
        )
      });
    } else {
      filtered = this.props.oshaForm.daysJobRestrictionArray.filter(
        d =>
          d.startDate !== startDate &&
          (d.endDate !== endDate || d.endDate === null)
      );
      this.props.handleInjuredWorkerChange({
        daysJobRestrictionArray: filtered,
        daysJobRestriction: numberOfDays(
          filtered,
          this.props.oshaForm.daysJobRestrictionCheckbox
        ),
        daysAwayFromWork: numberOfDays(
          this.props.oshaForm.daysAwayFromWorkArray,
          this.props.oshaForm.daysAwayFromWorkCheckbox
        )
      });
    }
  }

  handleEmployeeChange = userId => {
    fetchEmployee(userId).then(employee => {
      let employeeInfo = {
        workerDateOfBirth: employee.dateOfBirth,
        workerGender:
          employee.gender === 1
            ? 'Male'
            : employee.gender === 2
              ? 'Female'
              : '',
        workerDateOfHire: employee.dateOfHire,
        employeeName: `${employee.firstName} ${employee.lastName}`,
        jobTitle: employee.jobTitle,
        employeeId: employee.employeeNumber,
        employeeUserId: userId
      };

      if (employee.address?.streetAddress) {
        employeeInfo = {
          ...employeeInfo,
          workerStreetAddress: employee.address.streetAddress,
          workerCity: employee.address.city,
          workerState: employee.address.stateName,
          workerZip: employee.address.zip
        };
      }

      this.props.handleInjuredWorkerChange(employeeInfo);
    });
  };

  render() {
    const { isPerrp, disabled, groupId, missingRequired } = this.props;

    const {
      happenToThisWorker,
      facilityTreatedWorksite,
      injuryIllness,
      jobTitle,
      workerDoing,
      howInjuryOccurred,
      incidentInjuryIllness,
      bodyParts,
      incidentFrom,
      physicianName,
      facilityName,
      facilityAddress,
      facilityCity,
      facilityState,
      facilityZip,
      treatedInEmergency,
      hospitalizedInpatient,
      timeOfDayWorkStarted,
      dateOfDeath,
      privacyConcern,
      employeeFirstAid,
      employeeUserId
    } = this.props.oshaForm;

    const options = [
      { value: 0, label: 'Death' },
      {
        value: 1,
        label: 'Days away from work, restricted work, or on job transfer'
      },
      { value: 2, label: 'Loss of Consciousness' },
      { value: 3, label: 'Medical treatment beyond first aid' },
      {
        value: 4,
        label: `Other ${
          isPerrp ? 'PERRP' : 'OSHA'
        } recordable injuries or illnesses`
      }
    ];

    const injuryIllnessOptions = [
      { value: 0, label: 'Injury' },
      { value: 1, label: 'Skin Disorder' },
      { value: 2, label: 'Respiratory Condition' },
      { value: 3, label: 'Poisoning' },
      { value: 4, label: 'Hearing Loss' },
      { value: 5, label: 'Other Illness' }
    ];

    const yesNoOptions = [
      { value: 1, label: 'Yes' },
      { value: 2, label: 'No' }
    ];

    const shouldShowIncidents = happenToThisWorker?.length > 0;
    const shouldShowTreatment = happenToThisWorker?.length > 0;
    const shouldShowDeathDate = happenToThisWorker?.some(
      option => option.value === 0
    );
    const shouldShowDayAwayFromWork = happenToThisWorker?.some(
      option => option.value === 1
    );
    const shouldShowResults = shouldShowDayAwayFromWork || shouldShowDeathDate;
    const [hours, minutes] = parseTime(timeOfDayWorkStarted);

    return (
      <div className="oshaInjuredWorker">
        <EmployeeDropdown
          fieldLabel="Injured or ill worker's name. "
          isRequired
          placeholder="Worker must already be entered in system."
          currentValue={employeeUserId}
          onChange={this.handleEmployeeChange}
          searchable
          disabled={disabled}
          group={groupId}
          touched={missingRequired && !employeeUserId}
        />
        <CheckboxGroup
          options={[{ value: 0, label: 'Privacy Concern Case' }]}
          isRequired={true}
          className="oshaQuestionaire-checkbox"
          name="privacyConcern"
          onChange={values =>
            this.props.handleInjuredWorkerChange({ privacyConcern: values })
          }
          bareValues={false}
          currentValue={privacyConcern}
          displayTooltip={true}
          disabled={disabled}
          tooltips={[
            <>
              <p>- Injury to intimate body part or reproductive system</p>
              <p>- Injury resulting from sexual assault</p>
              <p>- Mental illness</p>
              <p>- HIV, hepatitis, or tuberculosis</p>
              <p>
                - Needlestick injury or cut from object contaminated with blood
                or potentially infectious material
              </p>
              <p>
                - Worker voluntarily requests their name not be entered on the
                OSHA log
              </p>
            </>
          ]}
        />
        <Textbox
          currentValue={jobTitle}
          fieldLabel="Job title "
          onChange={v => this.props.handleInjuredWorkerChange({ jobTitle: v })}
          isRequired={true}
          type="textarea"
          placeholder="e.g. Welder"
          disabled={disabled}
          rows={2}
          touched={missingRequired && !jobTitle}
          error
        />
        <Dropdown
          fieldLabel="Classify injury or illness "
          isRequired={true}
          options={injuryIllnessOptions}
          placeholder="Select an option"
          currentValue={injuryIllness}
          onChange={value =>
            this.props.handleInjuredWorkerChange({ injuryIllness: value })
          }
          disabled={disabled}
          bareValues={false}
          touched={missingRequired && !injuryIllness}
        />
        <CheckboxGroup
          fieldLabel="Did any of these happen to this worker? (Check all that apply)"
          options={options}
          className="oshaQuestionaire-checkbox"
          name="happenToThisWorker"
          onChange={values =>
            this.props.handleInjuredWorkerChange({ happenToThisWorker: values })
          }
          bareValues={false}
          currentValue={happenToThisWorker}
          displayTooltip={true}
          disabled={disabled}
          tooltips={[
            null,
            'Not including day of the incident',
            null,
            <p>
              First aid refers to medical attention that is usually administered
              immediately after the injury occurs and at the location where it
              occurred. It often consists of a one-time, short-term treatment
              and requires little technology or training to administer.
            </p>,
            <>
              <p>
                - Diagnosed work-related cancer, chronic irreversible diseases,
                broken bones or teeth, and punctured eardrums
              </p>
              <p>
                - Needlestick injury or cut from object contaminated with blood
                or potentially infectious material
              </p>
              <p>- Medical removal</p>
              <p>- Hearing loss</p>
              <p>- Tuberculosis</p>
            </>
          ]}
        />
        {shouldShowIncidents && (
          <section className="oshaInjuredWorker-incident">
            <div className="formHeading">
              <h2>Incident</h2>
            </div>
            <TimePicker
              fieldLabel="Time of day worker started. "
              isRequired
              onChange={(hours, minutes) =>
                this.props.handleInjuredWorkerChange({
                  timeOfDayWorkStarted: renderTime(hours, minutes, true)
                })
              }
              hours={hours}
              minutes={minutes}
              disabled={disabled}
              touched={missingRequired && !timeOfDayWorkStarted}
              onlyTime
            />
            <Textbox
              fieldLabel="What was the worker doing and using (i.e. tools and materials) just before the incident occurred?"
              onChange={v =>
                this.props.handleInjuredWorkerChange({ workerDoing: v })
              }
              isRequired={true}
              type="textarea"
              placeholder="e.g. Climbing a ladder while carrying roofing materials"
              currentValue={workerDoing}
              disabled={disabled}
              touched={missingRequired && !workerDoing}
              error
            />
            <Textbox
              fieldLabel="How did the injury / incident occur?"
              onChange={v =>
                this.props.handleInjuredWorkerChange({ howInjuryOccurred: v })
              }
              isRequired={true}
              type="textarea"
              placeholder="e.g. When ladder slipped on wet floor, worker fell 20 feet"
              currentValue={howInjuryOccurred}
              disabled={disabled}
              touched={missingRequired && !howInjuryOccurred}
              error
            />
            <ReactTooltip className="customTheme" id="injuryIllnessBlanks">
              Leave object or substance blank if it does not apply to this
              injury.
            </ReactTooltip>
            <label
              className="label"
              data-tip="customTheme"
              data-tooltip-id="injuryIllnessBlanks"
            >
              <span className="isRequired">*</span>
              Type N/A if object or substance does not apply to this injury
              <img
                className="label-info"
                src={require(`../../assets/images/Info Icon.png`)}
                alt="Info Icon"
              />
            </label>
            <div className="oshaInjuredWorker-fillInTheBlanks">
              <Textbox
                testID="injuryIllnessTextbox"
                onChange={v =>
                  this.props.handleInjuredWorkerChange({
                    incidentInjuryIllness: v
                  })
                }
                isRequired={true}
                type="textarea"
                placeholder="Injury or Illness"
                currentValue={incidentInjuryIllness}
                disabled={disabled}
                rows={2}
                touched={missingRequired && !incidentInjuryIllness}
                error
              />
              <span> Affecting</span>
              <Textbox
                testID="bodyPartsTextbox"
                onChange={v =>
                  this.props.handleInjuredWorkerChange({ bodyParts: v })
                }
                isRequired={true}
                type="textarea"
                placeholder="Body Part(s)"
                currentValue={bodyParts}
                disabled={disabled}
                rows={2}
                touched={missingRequired && !bodyParts}
                error
              />
              <span>From</span>
              <Textbox
                testID="objectSubstanceTextbox"
                onChange={v =>
                  this.props.handleInjuredWorkerChange({ incidentFrom: v })
                }
                isRequired={true}
                type="textarea"
                placeholder="Object or Substance"
                currentValue={incidentFrom}
                disabled={disabled}
                rows={2}
                touched={missingRequired && !incidentFrom}
                error
              />
            </div>
          </section>
        )}
        {shouldShowTreatment && (
          <section className="oshaInjuredWorker-treatment">
            <div className="formHeading">
              <h2>Treatment</h2>
            </div>
            <Textbox
              fieldLabel="Name of physician or healthcare professional."
              onChange={v =>
                this.props.handleInjuredWorkerChange({ physicianName: v })
              }
              isRequired={true}
              placeholder="e.g. Dr. Jane Doe"
              currentValue={physicianName}
              disabled={disabled}
              touched={missingRequired && !physicianName}
              error
            />
            <label className="label">
              <span className="isRequired">*</span>
              Where was treatment given? (Must enter ALL address information for
              given facility.)
            </label>
            <div className="oshaInjuredWorker-combinationQuestion">
              <div className="oshaInjuredWorker-combinationQuestion--leftGroup">
                <Textbox
                  onChange={v =>
                    this.props.handleInjuredWorkerChange({ facilityName: v })
                  }
                  isRequired={true}
                  placeholder="Facility Name"
                  disabled={facilityTreatedWorksite || disabled}
                  currentValue={facilityTreatedWorksite ? '' : facilityName}
                  touched={missingRequired && !facilityName}
                  error
                />
                <Textbox
                  onChange={v =>
                    this.props.handleInjuredWorkerChange({ facilityAddress: v })
                  }
                  isRequired={true}
                  placeholder="Street Address"
                  currentValue={facilityTreatedWorksite ? '' : facilityAddress}
                  disabled={facilityTreatedWorksite || disabled}
                  touched={
                    missingRequired &&
                    !facilityAddress &&
                    !facilityTreatedWorksite
                  }
                  error
                />
                <InputRow className="oshaInjuredWorker-csz">
                  <Textbox
                    onChange={v =>
                      this.props.handleInjuredWorkerChange({ facilityCity: v })
                    }
                    isRequired={true}
                    placeholder="City"
                    className="city"
                    disabled={facilityTreatedWorksite || disabled}
                    currentValue={facilityTreatedWorksite ? '' : facilityCity}
                    touched={
                      missingRequired &&
                      !facilityCity &&
                      !facilityTreatedWorksite
                    }
                    error
                  />
                  <Dropdown
                    isRequired
                    options={stateAbbr}
                    placeholder="State"
                    currentValue={facilityTreatedWorksite ? '' : facilityState}
                    onChange={value =>
                      this.props.handleInjuredWorkerChange({
                        facilityState: value
                      })
                    }
                    disabled={facilityTreatedWorksite || disabled}
                    bareValues={false}
                    touched={
                      missingRequired &&
                      !facilityState &&
                      !facilityTreatedWorksite
                    }
                  />
                  <Textbox
                    onChange={v =>
                      this.props.handleInjuredWorkerChange({ facilityZip: v })
                    }
                    isRequired={true}
                    placeholder="ZIP Code"
                    className="zip"
                    disabled={facilityTreatedWorksite || disabled}
                    currentValue={facilityTreatedWorksite ? '' : facilityZip}
                    touched={
                      missingRequired &&
                      !facilityZip &&
                      !facilityTreatedWorksite
                    }
                    error
                  />
                </InputRow>
              </div>
              <Checkbox
                fieldLabel="Treated at worksite"
                className="oshaQuestionaire-checkbox"
                name="facilityTreatedWorksite"
                onChange={values => {
                  this.props.handleInjuredWorkerChange({
                    facilityTreatedWorksite: values
                  });
                }}
                currentValue={facilityTreatedWorksite}
                disabled={disabled}
              />
            </div>
            <RadioButtons
              fieldLabel="Treated in emergency room?"
              options={yesNoOptions}
              isRequired={true}
              onChange={values =>
                this.props.handleInjuredWorkerChange({
                  treatedInEmergency: values
                })
              }
              currentValue={treatedInEmergency}
              disabled={disabled}
              bareValues={false}
              touched={missingRequired && !treatedInEmergency}
            />
            <RadioButtons
              fieldLabel="Hospitalized overnight as inpatient?"
              options={yesNoOptions}
              isRequired={true}
              onChange={values =>
                this.props.handleInjuredWorkerChange({
                  hospitalizedInpatient: values
                })
              }
              currentValue={hospitalizedInpatient}
              disabled={disabled}
              bareValues={false}
              touched={missingRequired && !hospitalizedInpatient}
            />
            {isPerrp && (
              <RadioButtons
                fieldLabel="Did the employee receive treatment classified as first aid at the worksite or hospital?"
                options={yesNoOptions}
                isRequired={true}
                onChange={values =>
                  this.props.handleInjuredWorkerChange({
                    employeeFirstAid: values
                  })
                }
                currentValue={employeeFirstAid}
                disabled={disabled}
                bareValues={false}
                touched={missingRequired && !employeeFirstAid}
              />
            )}
          </section>
        )}
        {shouldShowResults && (
          <section className="oshaInjuredWorker-result">
            <div className="formHeading">
              <h2>Result</h2>
            </div>
            {shouldShowDayAwayFromWork ? (
              this.props.oshaForm.isLegacy ? (
                <div className="oshaInjuredWorker-combinationQuestion">
                  <div className="oshaInjuredWorker-combinationQuestion--leftGroup">
                    <Textbox
                      fieldLabel="Days away from work."
                      onChange={value =>
                        this.props.handleInjuredWorkerChange({
                          daysAwayFromWork: value
                        })
                      }
                      isRequired={true}
                      placeholder="0"
                      currentValue={this.props.oshaForm.daysAwayFromWork}
                      displayTooltip={true}
                      tooltip="Not including day of the incident."
                      name="daysAway"
                      disabled={disabled}
                    />
                    <Textbox
                      fieldLabel="Days on job restriction or transfer."
                      onChange={value =>
                        this.props.handleInjuredWorkerChange({
                          daysJobRestriction: value
                        })
                      }
                      isRequired={true}
                      placeholder="0"
                      currentValue={this.props.oshaForm.daysJobRestriction}
                      displayTooltip={true}
                      tooltip="Not including day of the incident."
                      name="daysJob"
                      disabled={disabled}
                    />
                  </div>
                  <Checkbox
                    fieldLabel="Ongoing"
                    className="oshaQuestionaire-checkbox"
                    name="ongoing"
                    onChange={values =>
                      this.props.handleInjuredWorkerChange({ ongoing: values })
                    }
                    currentValue={this.props.oshaForm.ongoing}
                    disabled={disabled}
                  />
                </div>
              ) : (
                <React.Fragment>
                  <MultipleDateRangePicker
                    onChange={e =>
                      this.props.handleInjuredWorkerChange({
                        daysAwayFromWorkCheckbox: e,
                        daysAwayFromWork: numberOfDays(
                          this.props.oshaForm.daysAwayFromWorkArray,
                          e
                        )
                      })
                    }
                    fieldLabel="Days away from work"
                    datesArray={this.props.oshaForm.daysAwayFromWorkArray}
                    datesCheckbox={this.props.oshaForm.daysAwayFromWorkCheckbox}
                    disabled={disabled}
                    daysAway={this.props.oshaForm.daysAwayFromWork}
                    name="daysAway"
                    handleDatesSubmit={values =>
                      this.handleDatesSubmit(values, 'daysAway')
                    }
                    deleteDate={values =>
                      this.deleteDate(values.date, 'daysAway')
                    }
                    minDate={this.props.oshaForm.dateTime}
                    isRequired
                    modalLabel="Days away from work, not including the date of the incident"
                    missingRequired={missingRequired}
                  />
                  <MultipleDateRangePicker
                    onChange={e => {
                      this.props.handleInjuredWorkerChange({
                        daysJobRestrictionCheckbox: e,
                        daysJobRestriction: numberOfDays(
                          this.props.oshaForm.daysJobRestrictionArray,
                          e
                        )
                      });
                    }}
                    fieldLabel="Days on job restriction or transfer"
                    datesArray={this.props.oshaForm.daysJobRestrictionArray}
                    datesCheckbox={
                      this.props.oshaForm.daysJobRestrictionCheckbox
                    }
                    disabled={disabled}
                    daysAway={this.props.oshaForm.daysJobRestriction}
                    name="jobRest"
                    handleDatesSubmit={values =>
                      this.handleDatesSubmit(values, 'jobRest')
                    }
                    deleteDate={values =>
                      this.deleteDate(values.date, 'jobRest')
                    }
                    minDate={this.props.oshaForm.dateTime}
                    isRequired
                    modalLabel="Days on job restriction or transfer, not including the date of the
                  incident"
                    missingRequired={missingRequired}
                  />
                </React.Fragment>
              )
            ) : null}
            {shouldShowDeathDate && (
              <IReportDatePicker
                fieldLabel="Date of Death"
                isRequired
                onChange={value =>
                  this.props.handleInjuredWorkerChange({ dateOfDeath: value })
                }
                currentValue={dateOfDeath}
                disabled={disabled}
                name="dateOfDeath"
                touched={missingRequired && !dateOfDeath}
              />
            )}
          </section>
        )}
      </div>
    );
  }
}
