import React from 'react';
import moment from 'moment';
import classnames from 'classnames/bind';

import styles from './dueDate.module.scss';

export default function dueDateText({ date, label, left, isComplete }) {
  let today = moment();
  let overdue = today.isAfter(moment(date)) && !label;

  let classes = classnames.bind(styles)({
    overdue: overdue && !isComplete,
    complete: isComplete,
    dueDate: true
  });

  return date ? (
    <div style={{ display: 'flex', justifyContent: left ? 'start' : 'center' }}>
      <span className={classes}>
        <span className={styles.bold}>
          {label
            ? label
            : isComplete
              ? 'Completed'
              : overdue
                ? 'Past Due'
                : 'Due'}
          :
        </span>{' '}
        {moment(date).format('L')}
      </span>
    </div>
  ) : null;
}
