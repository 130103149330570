import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import history from '../../history';
import {
  createDocumentQRCodeSheet,
  fetchDocumentsByType,
  archiveMany,
  fetchUserListFilter,
  updateUserListFilter
} from '../../api/v4';
import {
  getActiveFolderSelector,
  getDocumentFoldersSelector
} from '../../selectors/documents';
import { getActiveLocationId, getActiveProject } from '../../selectors/company';
import { getLoggedInUser } from '../../selectors/users';
import { addDocumentsToFolderRequest } from '../../actions/documents';
import { usePrevious } from '../../utils/hooks';
import { validListDateRange } from '../../utils/formHelpers';
import AddToFolderModal from '../../components/Modal/addToFolderModal';
import DocumentFolder from '../../components/DocumentFolder';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Header from '../../components/Header';
import List from '../../components/List';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import TypeSelection from '../../components/TypeSelection';
import AnalyticsPeriodPicker from '../../components/AnalyticsPeriodPicker';

export default function SdsList(props) {
  const dispatch = useDispatch();
  const [documents, setDocuments] = useState([]);
  const [addToFolderModal, setAddToFolderModal] = useState(false);
  const [folderId, setFolderId] = useState('');
  const [documentIds, setDocumentIds] = useState([]);
  const [listType, setListType] = useState('Active');
  const [empPeriod, setEmpPeriod] = useState();
  const [currentPeriod, setCurrentPeriod] = useState();

  const user = useSelector(getLoggedInUser);
  const activeFolder = useSelector(getActiveFolderSelector);
  const location = useSelector(getActiveLocationId);
  const project = useSelector(getActiveProject);
  const folderList = useSelector(getDocumentFoldersSelector);
  const prevPeriod = usePrevious(currentPeriod);
  const prevFolder = usePrevious(activeFolder);
  const prevType = usePrevious(listType);

  useEffect(() => {
    fetchUserListFilter(user._id, 'SDS').then(r => {
      setCurrentPeriod(r);
      setEmpPeriod(r);
    });
  }, [user._id]);

  useEffect(() => {
    const hasStateChange =
      !isEqual(prevPeriod, currentPeriod) ||
      !isEqual(prevFolder, activeFolder) ||
      !isEqual(prevType, listType);

    if (currentPeriod && hasStateChange && validListDateRange(currentPeriod))
      fetchDocumentsByType('SDS', activeFolder?._id, {
        ...currentPeriod,
        selectedType: listType
      }).then(setDocuments);
  }, [activeFolder, prevPeriod, prevFolder, currentPeriod, prevType, listType]);

  const createQRCodes = ids => {
    if (!ids.length) return;
    createDocumentQRCodeSheet(ids).then(response =>
      window.open(response, '_blank')
    );
  };

  const handleAddMultipleToFolders = () => {
    dispatch(
      addDocumentsToFolderRequest({
        documents: documentIds,
        documentFolderId: folderId,
        type: 'documents'
      })
    );
    setAddToFolderModal(false);
    setDocumentIds([]);
  };

  const handleArchive = ids => {
    if (!ids.length) return;
    archiveMany({
      type: 'SDS',
      ids: ids,
      isArchived: listType === 'Active'
    }).then(() =>
      fetchDocumentsByType('SDS', activeFolder?._id, {
        ...currentPeriod,
        selectedType: listType
      }).then(setDocuments)
    );
  };

  const header = (
    <Header
      title="SDS"
      rightButtons={[
        {
          color: 'green',
          text: 'Add SDS',
          visible:
            user.accessLevel !== 100 &&
            !location?.isHidden &&
            !project?.isHidden,
          onClick: () => history.push(`/app/sdsContainer`)
        }
      ]}
    />
  );

  const columns = [
    { key: 'label', label: 'Title' },
    { key: 'expires', label: 'Expiration Date', datatype: 'futureDate' },
    { key: 'attachments', datatype: 'attachments' }
  ];

  if (!project) {
    columns.splice(1, 0, {
      key: 'projectIds',
      label: 'Area',
      datatype: 'projects'
    });
  }

  if (!location) {
    columns.splice(1, 0, {
      key: 'groupIds',
      label: 'Group/Est.',
      datatype: 'groups'
    });
  }

  let saveKey = `${listType}sdsList`;
  const newSaveKey = documents?.length
    ? !location
      ? `${saveKey}Groups`
      : location && !project
        ? `${saveKey}Areas`
        : saveKey
    : null;

  return (
    <HeaderAndFooter Header={header}>
      <ScrollToTopOnMount />
      {user.accessLevel > 400 ? (
        <TypeSelection
          selectedArray={['Active', 'Archived']}
          onClick={setListType}
          selected={listType}
        />
      ) : null}
      <AnalyticsPeriodPicker
        period={currentPeriod}
        onChange={setCurrentPeriod}
        onSave={v =>
          updateUserListFilter({
            empId: user._id,
            type: 'SDS',
            period: v
          }).then(() => setEmpPeriod(v))
        }
        allowAll
        savedPeriod={empPeriod}
        listPicker
      />
      <DocumentFolder content={documents} docType="SDS" />
      <List
        data={documents}
        dataIsHash
        settings={columns}
        getRowId={r => r._id}
        rowClick={row => history.push(`/app/sdsContainer/${row._id}`)}
        saveKey={newSaveKey}
        actions={[
          {
            color: 'blue',
            label: 'Add to Folder',
            visible: user.accessLevel !== 100,
            onClick: ids => {
              setAddToFolderModal(true);
              setDocumentIds(ids);
            }
          },
          {
            label: 'Download QR Codes',
            color: 'green',
            onClick: ids => createQRCodes(ids),
            visible: listType === 'Active'
          },
          {
            color: 'blue',
            label: `${listType === 'Archived' ? 'Un-' : ''}Archive Documents`,
            visible: user.accessLevel > 400,
            onClick: handleArchive
          }
        ]}
      />
      <AddToFolderModal
        isOpen={addToFolderModal}
        folderId={folderId}
        onRequestClose={() => setAddToFolderModal(false)}
        submitActions={() => handleAddMultipleToFolders()}
        folderOptions={folderList?.map(f => ({ value: f._id, label: f?.name }))}
        onChange={setFolderId}
      />
    </HeaderAndFooter>
  );
}
