import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import history from '../../../history';
import {
  fetchBehaviorObservationTemplates,
  fetchBehaviorObservationTemplateById,
  createBehaviorObservationTemplate,
  updateBehaviorObservationTemplate,
  deleteBehaviorObservationTemplate
} from '../../../api/v4';
import BehaviorObservationCard from '../../../components/behaviorObservations/TemplateCard';
import DeleteTemplateModal from '../../../components/Modal/deleteTemplateModal';
import Header from '../../../components/Header';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import { SaveCancelFooter } from '../../../components/Footer';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';
import { isValidTableQuestion } from '../../../utils/formHelpers';
import MissingRequiredModal from '../../../components/Modal/missingRequiredModal';

export default function BehaviorObservationTemplate() {
  let { templateId, duplicate } = useParams();
  const [behaviorObservation, setBehaviorObservation] = useState({});
  const [templates, setTemplates] = useState([]);
  const [id, setId] = useState(null);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [editing, setEditing] = useState(false);
  const [creating, setCreating] = useState(false);
  const [openSaveChangesModal, setOpenSaveChangesModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [missingModalOpen, setMissingModalOpen] = useState(false);
  const [missingRequired, setMissingRequired] = useState(false);
  const [invalidTitle, setInvalidTitle] = useState(false);
  useEffect(() => {
    fetchBehaviorObservationTemplates().then(setTemplates);

    if (templateId) {
      fetchBehaviorObservationTemplateById(templateId).then(response => {
        let temp = { ...response };

        if (duplicate) {
          delete temp._id;
          temp.fields.forEach(f => delete f._id);
          temp.title = `${temp.title} - Copy`;
          setCreating(true);
        }

        setBehaviorObservation(temp);
      });
      setId(templateId);
    } else {
      setCreating(true);
    }
  }, [templateId, duplicate]);

  const canSubmit = () => {
    const valid = isTitleValid(behaviorObservation?.title);
    const questionsMissingParts = behaviorObservation?.fields?.filter(
      question =>
        !question.type ||
        !question.label?.replace(/\u200B/g, '')?.trim() ||
        (question.type &&
          ['dropdown', 'multiselect', 'radiobuttons', 'checkbox'].includes(
            question.type
          ) &&
          question.options.length === 0) ||
        (question.type === 'table' && !isValidTableQuestion(question))
    );

    if (
      behaviorObservation?.fields?.length > 0 &&
      questionsMissingParts.length === 0 &&
      behaviorObservation?.title?.length &&
      valid
    ) {
      return true;
    }
    return false;
  };

  const handleSubmit = () => {
    if (id && !duplicate) {
      let updatedFields = behaviorObservation?.fields.map(field => {
        field.options =
          field.type !== 'table'
            ? field?.options?.map(option => option.option ?? option)
            : field.options;
        return field;
      });
      updateBehaviorObservationTemplate({
        ...behaviorObservation,
        fields: updatedFields
      }).then(response => {
        setBehaviorObservation(response);
        setEditing(false);
        setUnsavedChanges(false);
      });
    } else {
      let updatedFields = behaviorObservation?.fields.map(field => {
        field.options =
          field.type !== 'table'
            ? field?.options?.map(option => option.option ?? option)
            : field.options;
        return field;
      });
      createBehaviorObservationTemplate({
        ...behaviorObservation,
        fields: updatedFields
      }).then(response => {
        setBehaviorObservation(response);
        setCreating(false);
        setUnsavedChanges(false);
        setId(response._id);
      });
    }
  };

  const deleteTemplate = () => {
    deleteBehaviorObservationTemplate(behaviorObservation._id).then(() => {
      history.push('/app/behaviorObservation/templates');
    });
  };

  const isTitleValid = title => {
    let formattedTitle = title
      ?.split(' ')
      ?.filter(s => s)
      ?.join(' ');

    let valid = true;
    if (creating && formattedTitle?.length !== 0) {
      valid =
        templates.filter(
          t => t.title.toLowerCase() === formattedTitle?.toLowerCase()
        ).length === 0;
    } else if (editing) {
      let template = templates.filter(
        t => t.title.toLowerCase() === formattedTitle?.toLowerCase()
      )?.[0];
      if (template) {
        valid = template?._id === templateId;
      }
    }
    return valid;
  };

  const handleArchive = () => {
    updateBehaviorObservationTemplate({
      ...behaviorObservation,
      isArchived: !behaviorObservation.isArchived
    }).then(response => {
      if (response.isArchived)
        history.push('/app/behaviorObservations/templates');
      else {
        setBehaviorObservation(response);
        setUnsavedChanges(false);
      }
    });
  };

  const header = (
    <Header
      title="Custom Behavior Observation"
      section={
        !creating && !editing
          ? 'View Template'
          : creating
            ? 'Create Template'
            : 'Edit Template'
      }
      needsSaved={unsavedChanges}
      clickBack={() =>
        unsavedChanges ? setOpenSaveChangesModal(true) : history.goBack()
      }
      pageActionOptions={
        !creating
          ? [
              {
                label:
                  editing || behaviorObservation?.isArchived
                    ? 'Delete Template'
                    : 'Edit Template',
                onClick: () =>
                  editing ? setOpenDeleteModal(true) : setEditing(true),
                visible: true,
                color:
                  editing || behaviorObservation?.isArchived
                    ? 'redOutline'
                    : 'blueOutline'
              },
              {
                label: `${
                  behaviorObservation?.isArchived ? 'Un-' : ''
                }Archive Template`,
                onClick: () => handleArchive(),
                visible: true,
                color: 'blueOutline'
              }
            ]
          : null
      }
    />
  );

  const footer = (
    <SaveCancelFooter
      editing={editing}
      saveButtonClick={() =>
        canSubmit() ? handleSubmit() : setMissingModalOpen(true)
      }
      cancelButtonClick={() =>
        editing && unsavedChanges
          ? setOpenSaveChangesModal(true)
          : editing && !unsavedChanges
            ? setEditing(false)
            : setOpenSaveChangesModal(true)
      }
      onMouseEnter={() => setMissingRequired(true)}
    />
  );

  return (
    <HeaderAndFooter
      Header={header}
      Footer={footer}
      showFooter={creating || editing}
    >
      <BehaviorObservationCard
        onChange={(values, e) => {
          if (values?.title) {
            setInvalidTitle(!isTitleValid(e.target.value));
          }

          setBehaviorObservation({ ...behaviorObservation, ...values });
          setUnsavedChanges(true);
        }}
        disabled={!editing && !creating}
        editing={editing}
        duplicating={behaviorObservation.duplicated}
        document={behaviorObservation}
        missingRequired={missingRequired}
        invalidTitle={invalidTitle}
      />
      <DeleteTemplateModal
        deletingWhat="Behavior Observation"
        isOpen={openDeleteModal}
        onRequestClose={() => setOpenDeleteModal(false)}
        submitActions={deleteTemplate}
      />
      <SaveChangesModal
        savingWhat="Behavior Observation"
        isOpen={openSaveChangesModal}
        onRequestClose={() => setOpenSaveChangesModal(false)}
        submitActions={() => history.goBack()}
      />
      <MissingRequiredModal
        isOpen={missingModalOpen}
        onRequestClose={() => setMissingModalOpen(false)}
        subject="Behavior Observation Template"
      />
    </HeaderAndFooter>
  );
}
