import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { isEqual } from 'lodash';
import moment from 'moment';
import {
  fetchSafetyAudits,
  fetchSafetyAuditRepeatables,
  fetchSafetyAuditScanOnDemands,
  reassignSafetyAudits,
  sendAuditReminder,
  archiveMany,
  fetchUserListFilter,
  updateUserListFilter,
  fetchSafetyAuditTemplates
} from '../../api/v4';
import history from '../../history';
import { SAFETY_AUDIT_EXPORT_OPTIONS } from '../../constants/constants';
import {
  getActiveLocationId,
  getActiveProject,
  getActiveCompany
} from '../../selectors/company';
import { getLoggedInUser } from '../../selectors/users';
import { usePrevious } from '../../utils/hooks';
import { useSocket } from '../../utils/withSocket';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Header from '../../components/Header';
import HierarchySelector from '../../components/HierarchySelector';
import List from '../../components/List';
import ReassignUserModal from '../../components/Modal/reassignUserModal';
import RepeatableList from '../../components/RepeatableList';
import TypeSelection from '../../components/TypeSelection';
import ImmediateList from '../../components/ImmediateList';
import AnalyticsPeriodPicker from '../../components/AnalyticsPeriodPicker';
import { Dropdown } from '../../components/inputs';
import Modal from '../../components/Modal';

import styles from './safetyAudits.module.scss';

export default function Audits() {
  const location = useLocation().pathname;
  const auditType = location.split('/').pop();
  const auditName =
    auditType === 'loto'
      ? 'Lockout/Tagout Procedure'
      : `${auditType.charAt(0).toUpperCase() + auditType.slice(1)} Audit`;
  const activeGroup = useSelector(getActiveLocationId);
  const activeProject = useSelector(getActiveProject);
  const activeCompany = useSelector(getActiveCompany);
  const user = useSelector(getLoggedInUser);
  const [audits, setAudits] = useState([]);
  const [repeatables, setRepeatables] = useState([]);
  const [scans, setScans] = useState([]);
  const [schedule, setSchedule] = useState('Active');
  const [selectedTab, setSelectedTab] = useState(0);
  const [ids, setIds] = useState([]);
  const [openReassignModal, setOpenReassignModal] = useState(false);
  const [reassignedUser, setReassignedUser] = useState(null);
  const [groups, setGroups] = useState([]);
  const [empPeriod, setEmpPeriod] = useState();
  const [currentPeriod, setCurrentPeriod] = useState();
  const [bulkDLModalOpen, setBulkDLModalOpen] = useState(false);
  const [bulkOptions, setBulkOptions] = useState({});
  const [templates, setTemplates] = useState([]);
  const [missingRequired, setMissingRequired] = useState(false);
  const [bulkReport, setBulkReport] = useState(null);
  const [loading, setLoading] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const prevPeriod = usePrevious(currentPeriod);
  const prevType = usePrevious(schedule);
  const socket = useSocket();

  useEffect(() => {
    fetchUserListFilter(user._id, 'safetyAudit').then(r => {
      setCurrentPeriod(r);
      setEmpPeriod(r);
    });

    fetchSafetyAuditTemplates(auditType).then(setTemplates);

    if (!socket) return;
    socket.emit(
      `subscribe${auditType}BulkAudit`,
      user._id +
        activeCompany._id +
        activeGroup?._id +
        (activeProject?._id ?? '')
    );
    socket.on(`bulk${auditType}Pdf`, value => {
      if (!value) return;
      if (moment().isAfter(moment(value?.createdAtTime).add(7, 'days')))
        socket.emit('deletePdf', {
          id:
            user._id +
            activeCompany._id +
            activeGroup?._id +
            (activeProject?._id ?? ''),
          type: `bulk${auditType}`
        });
      else {
        setBulkReport(value);
        setLoading(value && !value?.url);
      }
    });
    socket.on('printFail', error => {
      setBulkReport(null);
      console.log(error);
    });
  }, [
    user._id,
    auditType,
    socket,
    activeCompany._id,
    activeGroup._id,
    activeProject
  ]);

  useEffect(() => {
    if (
      currentPeriod &&
      (!isEqual(prevPeriod, currentPeriod) ||
        (!isEqual(prevType, schedule) &&
          (schedule === 'Active' || schedule === 'Archived')))
    ) {
      setListLoading(true);
      if (schedule === 'Active' || schedule === 'Archived')
        fetchSafetyAudits(auditType, {
          ...currentPeriod,
          selectedType: schedule
        }).then(v => {
          setAudits(v);
          setListLoading(false);
        });
      if (user.accessLevel > 100) {
        fetchSafetyAuditRepeatables(auditType).then(setRepeatables);
        fetchSafetyAuditScanOnDemands(auditType).then(setScans);
      }
    }
  }, [
    user.accessLevel,
    auditType,
    currentPeriod,
    prevPeriod,
    schedule,
    prevType
  ]);

  const reassignAudits = () => {
    reassignSafetyAudits({ ids, reassignedUser }).then(() => {
      setOpenReassignModal(false);
      setReassignedUser(null);
      fetchSafetyAudits(auditType, { ...currentPeriod }).then(setAudits);
    });
  };

  const getGroupIds = ids => {
    const selectedTasks = audits.filter(t => ids.includes(t._id));

    return selectedTasks.map(t => t.groupId);
  };

  const handleRowClick = ids => {
    if (!ids?.length) return;
    setIds(ids);

    setGroups(getGroupIds(ids));
    setOpenReassignModal(true);
  };

  const handleArchive = ids => {
    if (!ids?.length) return;
    archiveMany({
      type: 'safetyAudit',
      ids: ids,
      isArchived: schedule === 'Active'
    }).then(() =>
      fetchSafetyAudits(auditType, {
        ...currentPeriod,
        selectedType: schedule
      }).then(setAudits)
    );
  };

  const titleOptions = templates.map(t => {
    return {
      ...templates,
      value: t._id,
      label: t.title
    };
  });

  const header = (
    <Header
      title={auditName + 's'}
      pageActionOptions={[
        {
          label: `Create ${auditName}`,
          color: 'greenOutline',
          onClick: () => history.push(`/app/audits/${auditType}/create/`),
          visible: !activeGroup?.isHidden && !activeProject?.isHidden
        },
        {
          label: `Export ${auditName}s`,
          color: 'blueOutline',
          onClick: () => {
            setBulkDLModalOpen(true);
            setBulkOptions({
              group: activeGroup?._id,
              project: activeProject?._id
            });
          },
          visible: true
        },
        {
          label: `${loading ? 'Generating' : 'Download'} Bulk Report`,
          color: loading ? 'blueOutline' : 'greenOutline',
          onClick: () =>
            !loading && bulkReport?.url
              ? window.open(bulkReport.url, '_blank')
              : null,
          visible: loading || bulkReport?.url,
          disabled: loading
        }
      ]}
    />
  );

  const repeatingColumns = [
    {
      key: 'title',
      label: `${auditName}(s)`
    },
    {
      key: 'assignedTo',
      datatype: 'users'
    },
    {
      key: 'lastUpdatedAt',
      label: 'Last Updated',
      datatype: 'date'
    },
    {
      key: 'repeatingOn',
      label: 'Repeat On',
      datatype: 'dow',
      frequency: 'frequency'
    }
  ];

  const onDemandColumns = [
    {
      key: 'title',
      label: `${auditName}(s)`
    },
    {
      key: 'lastUpdatedAt',
      label: 'Last Updated',
      datatype: 'date'
    },
    {
      key: 'qrCodeUrl',
      label: 'QR Code',
      datatype: 'url',
      text: 'Download QR Code'
    }
  ];

  const activeColumns = [
    {
      key: 'safetyAuditNumber',
      label: `${auditType === 'loto' ? 'Procedure' : 'Audit'} Number`
    },
    {
      key: 'title',
      label: `${auditName}(s)`
    },
    {
      key: 'assignedTo',
      datatype: 'users'
    },
    {
      key: 'timeSpent',
      label: 'Time Spent'
    },
    {
      key: 'dueDate',
      label: 'Due By',
      datatype: 'futureDate'
    },
    {
      key: 'lastUpdatedAt',
      label: 'Last Updated',
      datatype: 'date'
    }
  ];

  if (!activeProject) {
    activeColumns.unshift({
      key: 'projectId',
      label: 'Area',
      datatype: 'project'
    });
    repeatingColumns.unshift({
      key: 'projectId',
      label: 'Area',
      datatype: 'project'
    });
  }

  if (!activeGroup) {
    activeColumns.unshift({
      key: 'groupId',
      label: 'Group/Est.',
      datatype: 'group'
    });
    repeatingColumns.unshift({
      key: 'groupId',
      label: 'Group/Est.',
      datatype: 'group'
    });
  }

  const statuses = ['New', 'In Progress', 'Severity Review', 'Closed'];

  const reportContent = statuses.map((status, index) => {
    return audits.filter(
      jsa => jsa.currentWfStage === index && !jsa.isArchived
    );
  });

  const tabs = {
    selected: selectedTab,
    onClick: i => setSelectedTab(i),
    list: statuses.map((name, i) => ({
      color: 'blue',
      name,
      count: reportContent[i]?.length
    }))
  };

  const archivedAudits = audits?.filter(audit => audit.isArchived);

  const missingRequiredOptions =
    !bulkOptions.period?.mode ||
    !bulkOptions?.sortType ||
    (bulkOptions?.sortType === 'response' &&
      !bulkOptions?.questionTypes?.length);

  return (
    <HeaderAndFooter Header={header}>
      {user.accessLevel > 100 ? (
        <TypeSelection
          selected={schedule}
          selectedArray={[
            'Active',
            'Repeating',
            'On Demand',
            user.accessLevel > 400 ? 'Archived' : null
          ]}
          onClick={v => {
            if (v !== schedule) setAudits([]);
            setSchedule(v);
          }}
        />
      ) : null}
      {schedule !== 'Repeating' && schedule !== 'On Demand' ? (
        <AnalyticsPeriodPicker
          period={currentPeriod}
          onChange={v => {
            setAudits([]);
            setCurrentPeriod(v);
          }}
          onSave={v =>
            updateUserListFilter({
              empId: user._id,
              type: 'safetyAudit',
              period: v
            }).then(() => setEmpPeriod(v))
          }
          allowAll
          savedPeriod={empPeriod}
          listPicker
        />
      ) : null}
      {schedule === 'Repeating' ? (
        <RepeatableList
          repeatables={repeatables}
          repeatingColumns={repeatingColumns}
          handleRowClick={row =>
            history.push(
              `/app/audits/${auditType}/create/${row._id}/repeatable`
            )
          }
          saveKey="repeatingRas"
          type={`${auditName}s`}
          updateData={() =>
            fetchSafetyAuditRepeatables(auditType).then(response =>
              setRepeatables(response)
            )
          }
          showErrorFor={['isTemplateArchived']}
          errorMessage="Template is archived."
        />
      ) : schedule === 'On Demand' ? (
        <List
          saveKey="scanRas"
          rowClick={row =>
            history.push(`/app/audits/${auditType}/create/${row._id}/scan`)
          }
          dataIsHash
          data={scans}
          settings={onDemandColumns}
        />
      ) : (
        <ImmediateList
          actions={
            user.accessLevel >= 500
              ? [
                  ...((tabs.selected === 0 || tabs.selected === 1) &&
                  schedule !== 'Archived'
                    ? [
                        {
                          color: 'blue',
                          label: `Reassign ${
                            auditType === 'loto' ? 'Procedure' : 'Audit'
                          }`,
                          onClick: handleRowClick
                        },
                        {
                          color: 'blue',
                          label: 'Send Reminder',
                          onClick: sendAuditReminder
                        }
                      ]
                    : []),
                  {
                    color: 'blue',
                    label: `${schedule === 'Archived' ? 'Un-' : ''}Archive ${
                      auditType === 'loto' ? 'Procedure' : 'Audit'
                    }s`,
                    onClick: handleArchive
                  }
                ]
              : null
          }
          saveKey={`${schedule === 'Archived' ? 'archived' : ''}immediateRas`}
          rowClick={row =>
            row.isCompleted
              ? history.push(`/app/audits/${auditType}/summary/${row._id}`)
              : history.push(`/app/audits/${auditType}/perform/${row._id}`)
          }
          data={
            schedule === 'Active' ? reportContent[selectedTab] : archivedAudits
          }
          dataIsHash
          settings={activeColumns}
          tabs={schedule === 'Active' ? tabs : null}
          getRowId={r => r._id}
          loading={listLoading}
        />
      )}
      <ReassignUserModal
        title={`Reassign ${auditName}s`}
        reassignUser={reassignedUser}
        disableSubmit={!reassignedUser}
        message={
          <div className={styles.reassignText}>
            {`All of the selected ${auditName}s will be reassigned to the user
            below.`}
          </div>
        }
        onRequestClose={() => {
          setOpenReassignModal(false);
          setReassignedUser(null);
        }}
        isOpen={openReassignModal}
        employeeFieldLabel="New Assignee"
        onChange={setReassignedUser}
        submitActions={reassignAudits}
        groups={groups}
        permissions={[100, 400, 500, 900]}
      />
      <Modal
        isOpen={bulkDLModalOpen}
        onRequestClose={() => {
          setBulkDLModalOpen(false);
          setMissingRequired(false);
        }}
        title={`Export ${auditName}s`}
        titleClassName="blueHeader"
        submitButtonText="Generate"
        submitActions={() => {
          if (!missingRequiredOptions) {
            setLoading(true);
            socket.emit('generateBulkReport', {
              ...bulkOptions,
              user: user._id,
              company: activeCompany._id,
              type: auditType,
              activeGroup: activeGroup?._id,
              activeProject: activeProject?._id
            });
            setBulkDLModalOpen(false);
            setBulkReport(null);
          }
        }}
        submitButtonColor="blue"
        onMouseEnter={() => setMissingRequired(missingRequiredOptions)}
        submitTooltip={missingRequired ? 'Missing or Invalid Fields' : null}
        wide
      >
        <AnalyticsPeriodPicker
          period={bulkOptions.period}
          onChange={v => {
            setBulkOptions({ ...bulkOptions, period: v });
            setMissingRequired(false);
          }}
          error={missingRequired && !bulkOptions?.period}
          isBulkPicker
        />
        <HierarchySelector
          groupRequired={false}
          groupId={bulkOptions?.group}
          projectId={bulkOptions?.project}
          onGroupChange={value =>
            setBulkOptions({ ...bulkOptions, group: value })
          }
          onProjectChange={value =>
            setBulkOptions({ ...bulkOptions, project: value })
          }
          projectDisabled={!bulkOptions.group}
        />
        <Dropdown
          options={[
            {
              value: 2,
              label: 'Severity Review'
            },
            {
              value: 3,
              label: 'Closed'
            }
          ]}
          onChange={v => {
            setBulkOptions({ ...bulkOptions, stage: v });
          }}
          currentValue={bulkOptions.stage}
          fieldLabel={`${auditName} Stage`}
          className={styles.stageDropdown}
          multi
          searchable
          selectButtons
        />
        <Dropdown
          options={titleOptions}
          onChange={v => setBulkOptions({ ...bulkOptions, templates: v })}
          currentValue={bulkOptions.templates}
          fieldLabel={`${auditName} Title`}
          className={styles.titleDropdown}
          multi
          searchable
          selectButtons
        />
        <Dropdown
          options={[
            { label: 'By Section', value: 'section' },
            { label: 'By Response', value: 'response' }
          ]}
          onChange={v => setBulkOptions({ ...bulkOptions, sortType: v })}
          currentValue={bulkOptions.sortType}
          fieldLabel="Sort Questions"
          className={styles.titleDropdown}
          searchable
          selectButtons
          isRequired
          touched={missingRequired && !bulkOptions.sortType}
        />
        {bulkOptions?.sortType === 'response' ? (
          <Dropdown
            options={[
              {
                label: 'Unacceptable Findings',
                value: 'Unacceptable Findings'
              },
              { label: 'Acceptable Findings', value: 'Acceptable Findings' },
              { label: 'Not Applicable', value: 'Not Applicable' },
              { label: 'Ungraded', value: 'Ungraded' }
            ]}
            onChange={v => setBulkOptions({ ...bulkOptions, questionTypes: v })}
            currentValue={bulkOptions.questionTypes}
            fieldLabel="Filter Responses"
            className={styles.titleDropdown}
            multi
            searchable
            selectButtons
            isRequired
            touched={missingRequired && !bulkOptions.questionTypes}
          />
        ) : null}
        <Dropdown
          options={SAFETY_AUDIT_EXPORT_OPTIONS}
          onChange={v => setBulkOptions({ ...bulkOptions, reportOptions: v })}
          currentValue={bulkOptions.reportOptions}
          fieldLabel="Additional Options"
          className={styles.titleDropdown}
          multi
          searchable
          selectButtons
        />
        <p className={styles.infoText}>
          Once the report has finished generating, it can be accessed for one
          week via the Download Report option in the {auditName} List Page
          Actions menu.
        </p>
      </Modal>
    </HeaderAndFooter>
  );
}
