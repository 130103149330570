import { all, call, put } from 'redux-saga/effects';

import history from '../history';
import {
  fetchSafetyWalksResponse,
  setActiveSafetyWalk
} from '../actions/safetyWalks';

export function* loadSafetyWalk(apiV4, { payload }) {
  try {
    const response = yield call(apiV4.fetchSafetyWalkById, payload);
    let items = response?.bravos
      ?.concat(response?.hazards)
      ?.sort((a, b) => a.overallOrder - b.overallOrder);

    yield put(setActiveSafetyWalk({ ...response, items }));
    history.push('/app/safetyWalkContainer');
  } catch (e) {
    console.log(e);
  }
}

export function* fetchSafetyWalks(apiV4, { payload }) {
  try {
    let response = yield call(apiV4.fetchSafetyWalks);

    yield put(fetchSafetyWalksResponse(response));
  } catch (e) {
    console.log(e);
  }
}

export function* addSafetyWalk(apiV4, { payload }) {
  try {
    let response = yield call(apiV4.createSafetyWalk, payload);
    const tasks = (response?.hazards ?? []).reduce(
      (acc, hazard) => [...acc, ...(hazard.tasks ?? []).map(t => t._id)],
      []
    );

    yield all(
      tasks.map(task =>
        call(apiV4.updateTask, {
          _id: task,
          ownerType: 'safetyWalks',
          ownerId: response._id
        })
      )
    );

    response = yield call(apiV4.fetchSafetyWalkById, response._id);
    let items = response?.bravos
      ?.concat(response?.hazards)
      ?.sort((a, b) => a.overallOrder - b.overallOrder);

    yield put(setActiveSafetyWalk({ ...response, items }));
    history.push('/app/safetyWalkContainer');
  } catch (e) {
    console.log(e);
  }
}
