import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { setActiveIncidentRequest } from '../../actions/incidents';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject
} from '../../selectors/company';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import {
  REACTIVE_REPORT_TYPES,
  PROACTIVE_REPORT_TYPES,
  CLAIM_STAGES
} from '../../constants/constants';
import List from '../List';
import { fetchEmployeeIncidents } from '../../api/v4';

const reportTypes = [
  ...REACTIVE_REPORT_TYPES,
  ...PROACTIVE_REPORT_TYPES.filter(r => r.value !== 8 && r.value !== 10)
];

export const IncidentsList = ({
  content,
  tabs,
  saveKey,
  user,
  fromEmployee,
  handleArchive
}) => {
  const dispatch = useDispatch();
  const loadIncident = payload => dispatch(setActiveIncidentRequest(payload));
  const company = useSelector(state => getActiveCompany(state), shallowEqual);
  const activeLocation = useSelector(state => getActiveLocationId(state));
  const activeProject = useSelector(state => getActiveProject(state));

  const [data, setData] = useState(content);

  useEffect(() => {
    if (fromEmployee) fetchEmployeeIncidents(user._id, 'all').then(setData);
    else setData(content);
  }, [fromEmployee, user._id, content]);

  const columns = [
    {
      key: 'reportStage',
      label: 'Status',
      accessor: r => (r.reportStage?.label === 'Closed' ? 'Completed' : 'Open'),
      enum: ['Open', 'Completed']
    },
    {
      key: 'claimStatus',
      label: 'Report Status',
      accessor: r => r.reportStage?.label,
      enum: CLAIM_STAGES
    },
    {
      key: 'reportTemplateIds',
      label: 'Report Types',
      accessor: r => r.reportTemplateIds.map(v => parseInt(v, 10)),
      datatype: 'svgs',
      disableSortBy: true,
      enum: company?.showAircraft
        ? reportTypes.filter(r => !r.label !== 'Spill')
        : reportTypes.filter(r => !r.isAircraft)
    },
    {
      key: 'incidentNumber',
      label: 'Incident Title'
    },
    {
      key: 'involvedEmployee',
      datatype: 'users'
    },
    {
      key: 'createdByUserId',
      label: 'Reporter',
      datatype: 'user'
    },
    {
      key: 'dateIncidentOccured',
      label: 'Date Occurred',
      datatype: 'date'
    },
    {
      key: 'severity',
      enum: [
        { value: 'Minor', label: 'Minor' },
        { value: 'Moderate', label: 'Moderate' },
        { value: 'Major', label: 'Major' }
      ]
    }
  ].concat(
    fromEmployee
      ? [
          {
            key: 'isArchived',
            label: 'Archived',
            accessor: i => (i.isArchived ? 'Yes' : 'No'),
            enum: ['Yes', 'No']
          }
        ]
      : []
  );
  if (!activeProject) {
    columns.unshift({
      key: 'projectId',
      label: 'Area',
      datatype: 'project'
    });
  }

  if (!activeLocation) {
    columns.unshift({
      key: 'locationId',
      label: 'Group/Est.',
      datatype: 'group'
    });
  }

  const newSaveKey =
    content?.length > 0
      ? !activeLocation
        ? `${saveKey}Groups`
        : activeLocation && !activeProject
          ? `${saveKey}Areas`
          : saveKey
      : null;

  return (
    <>
      <ScrollToTopOnMount />
      <List
        tabs={tabs}
        dataIsHash
        data={data?.sort((a, b) => {
          return (
            new Date(b.dateIncidentOccured) - new Date(a.dateIncidentOccured)
          );
        })}
        getRowId={r => r._id}
        settings={columns}
        rowClick={row =>
          loadIncident({
            _id: row._id,
            returnPath: `/app/Incidents`
          })
        }
        saveKey={newSaveKey}
        actions={
          user?.accessLevel > 400
            ? [
                {
                  label: `${
                    saveKey === 'incidents' ? '' : 'Un-'
                  }Archive Incidents`,
                  onClick: handleArchive,
                  visible: user?.accessLevel > 400
                }
              ]
            : null
        }
      />
    </>
  );
};

export default IncidentsList;
