/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import AssignCorrectiveActionButton from '../../inputs/AssignCorrectiveActionButton';
import {
  Button,
  InputRow,
  Checkbox,
  ButtonGroup,
  FieldRenderer
} from '../../inputs';
import Card from '../../Card';
import Carousel from '../../Carousel';
import AuditNote from '../../AuditNote';

import styles from './styles.module.scss';
const bStyles = classnames.bind(styles);

export default function QuestionCard({
  question,
  questionNumber,
  sectionNumber,
  updateAnswer,
  isCompleted,
  addAttachment,
  addNote,
  locationId,
  projectId,
  deleteJsaQuestionAttachment,
  section,
  requirePhotoForUnacceptableItems,
  requireNoteForUnacceptableItems,
  requireCAForUnacceptableItems,
  setQuestionId,
  audit,
  updateImmediatelyCorrected,
  editing,
  editingNotes,
  initiateDeleteNote,
  initiateDeletePhoto,
  handleChangeNotes,
  sectionCompleted,
  updatedNotes,
  disabled
}) {
  const color =
    question.answer === 1 ? 'green' : question.answer === 2 ? 'red' : 'grey';
  const hasLineItemNotes = question?.notes?.some(note => !note.isRemoved);
  const hasLineItemImages = question?.attachments?.length > 0;
  const totalQuestions = section?.questions?.length;
  const sectionName = section?.title;
  const isYesNoQuestion = !question.type || question.type === 'yesNo';
  const [localQuestion, setLocalQuestion] = useState(question);

  useEffect(() => {
    setLocalQuestion(question);
  }, [question, question.notes]);

  const handleDelete = item => {
    initiateDeletePhoto(item, sectionNumber, questionNumber);
  };

  const updateLocalQuestion = (e, note) => {
    let newLocalQuestion = { ...localQuestion };
    let index = newLocalQuestion.notes.findIndex(
      object => object._id === note._id
    );
    newLocalQuestion.notes[index].value = e;
    setLocalQuestion(newLocalQuestion);
  };

  return (
    <Card
      smallCard
      key={questionNumber}
      testID={`card${questionNumber}`}
      title={`${questionNumber}/${totalQuestions} \n ${question.label}`}
      showHeader={true}
      sectionCompleted={sectionCompleted}
      richTitle
    >
      <div className={styles.questionCard}>
        {!question?.type || question.type === 'yesNo' ? (
          <>
            {isCompleted ? (
              <div
                className={bStyles({
                  selectedButton: true,
                  [color]: true
                })}
              >
                <img
                  className={bStyles({ image: true, white: true })}
                  src={
                    question.answer === 1
                      ? require('../../../assets/images/checkmark.png')
                      : question.answer === 2
                        ? require('../../../assets/images/Remove Icon.png')
                        : require('../../../assets/images/NA.png')
                  }
                  alt={
                    question.answer === 1
                      ? 'marked acceptable'
                      : question.answer === 2
                        ? 'marked unacceptable'
                        : 'marked not applicable'
                  }
                />
              </div>
            ) : (
              <ButtonGroup
                leftImage={require('../../../assets/images/checkmark.png')}
                centerImage={require('../../../assets/images/Remove Icon.png')}
                rightImage={require('../../../assets/images/NA.png')}
                selected={question.answer}
                onClick={updateAnswer}
                disabled={disabled}
              />
            )}
            {editing || !isCompleted || question.immediatelyFixed ? (
              <Checkbox
                currentValue={question.immediatelyFixed}
                name={`immediatelyFixed-${section.title}-${questionNumber}`}
                disabled={isCompleted || disabled}
                fieldLabel="Unacceptable Item was immediately corrected"
                onChange={value => {
                  updateImmediatelyCorrected(value);
                }}
                id={`immediatelyFixed-${section.title}-${questionNumber}`}
              />
            ) : null}
          </>
        ) : (
          <FieldRenderer
            options={question.options}
            onChange={updateAnswer}
            currentValue={question.answer}
            bareValues
            group={audit.groupId}
            disabled={disabled || !(editing || !isCompleted)}
            type={question.type}
            ownerType={`${audit.type}AuditQuestion`}
            ownerId={question._id}
          />
        )}

        {requireNoteForUnacceptableItems &&
        !question?.notes?.filter(n => !n.isRemoved).length &&
        isYesNoQuestion &&
        question.answer == 2 ? (
          <div className={styles.error}>
            *Note required for unacceptable items.
          </div>
        ) : null}
        {requirePhotoForUnacceptableItems &&
        !question?.attachments?.length &&
        isYesNoQuestion &&
        question.answer == 2 ? (
          <div className={styles.error}>
            *Photo required for unacceptable items.
          </div>
        ) : null}
        {!isCompleted && question.type !== 'signature' && (
          <InputRow>
            <Button
              image={require(
                hasLineItemImages
                  ? '../../../assets/images/cameraWithAttachments.png'
                  : '../../../assets/images/camera.png'
              )}
              text="Photo"
              color="blue"
              imageClass={bStyles(styles.image, { disabled })}
              onClick={() => {
                setQuestionId(question._id);
                document.getElementById('myPictureFile').click();
              }}
              testID="safetyAuditPhotoButton"
              disabled={disabled}
            />
            <Button
              image={require(
                hasLineItemNotes
                  ? '../../../assets/images/notesWithAttachments.png'
                  : '../../../assets/images/notes.png'
              )}
              text="Note"
              color="blue"
              imageClass={bStyles(styles.image, { disabled })}
              onClick={addNote}
              testID="safetyAuditNoteButton"
              disabled={disabled}
            />
            <input
              type="file"
              id="myPictureFile"
              onChange={e => {
                addAttachment(e, sectionNumber, questionNumber);
              }}
              accept="image/png, image/jpeg, image/jpg"
              multiple
              data-cy={`addPicture${questionNumber}`}
            />
          </InputRow>
        )}
      </div>
      {hasLineItemNotes &&
        question?.notes
          .filter(note => !note.isRemoved)
          .map(note => (
            <AuditNote
              note={note}
              canEdit={editingNotes}
              updateQuestion={updateLocalQuestion}
              onChange={handleChangeNotes}
              deleteNote={initiateDeleteNote}
              sectionCompleted={sectionCompleted}
              sectionNumber={sectionNumber}
              questionNumber={questionNumber}
              disabled={disabled}
            />
          ))}
      {hasLineItemImages && (
        <Carousel
          items={question?.attachments}
          title="Attachments"
          deleteAction={
            editingNotes && !editing
              ? handleDelete
              : deleteJsaQuestionAttachment
          }
          canDelete={!isCompleted || editingNotes}
          deleteFromComplete={!isCompleted || editingNotes}
        />
      )}
      {question.type !== 'signature' ? (
        <>
          <AssignCorrectiveActionButton
            task={question.task}
            description={`Please correct the following which was marked as unsafe: \n ${question.label.replace(
              /[*_]/g
            )}\n${question?.notes
              ?.filter(n => !n.isRemoved)
              .map(n => n.value)
              .join(',\n')}`}
            label={sectionName}
            ownerType={`${audit.type}AuditQuestion`}
            ownerId={question._id}
            groupId={locationId}
            projectId={projectId}
            schedule="immediate"
            pictures={question?.attachments}
            onClick={() => {
              localStorage.setItem('activeAudit', JSON.stringify(audit));
              localStorage.setItem(
                'updatedNotes',
                JSON.stringify(updatedNotes)
              );
              localStorage.setItem('editing', JSON.stringify(editingNotes));
            }}
            type="correctiveAction"
            disabled={disabled}
          />
          {requireCAForUnacceptableItems &&
          !question?.task &&
          question.answer == 2 &&
          question.type === 'yesNo' ? (
            <div className={styles.error}>
              *Corrective Action required for unacceptable items.
            </div>
          ) : null}
        </>
      ) : (
        <></>
      )}
    </Card>
  );
}
