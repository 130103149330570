import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { REHYDRATE } from 'redux-persist';

import attachments from './attachments';
import auth from './auth';
import company from './company';
import documents from './documents';
import incidents from './incidents';
import oshaLogs from './oshaLogs';
import personnel from './personnel';
import reports from './reports';
import safetyWalks from './safetyWalks';
import tasks from './tasks';
import user from './user';
import loading from './loading';
import { AUTH_SIGNOUT } from '../constants/actionTypes';

const appReducer = combineReducers({
  attachments,
  auth,
  company,
  documents,
  form: formReducer,
  incidents,
  oshaLogs,
  personnel,
  reports,
  safetyWalks,
  tasks,
  user,
  loading
});

const rootReducer = (state, action) => {
  if (action.type === AUTH_SIGNOUT) {
    state = undefined;
  }
  if (action.type === REHYDRATE) {
    if (action?.payload?.user) {
      action.payload.user.users = {};
      action.payload.user.allUsers = [];
      action.payload.user.allLoaded = false;
    }
  }

  return appReducer(state, action);
};

export default rootReducer;
