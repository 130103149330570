import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { EQUIPMENT_BULK_UPLOAD_TEMPLATE } from '../../constants/constants';
import {
  fetchEquipmentListAll,
  downloadEquipmentQrCodes,
  uploadEquipment,
  archiveMany,
  fetchUserListFilter,
  updateUserListFilter
} from '../../api/v4';
import { clearUploadedAttachments } from '../../actions/attachments';
import history from '../../history';
import { getAddedAttachmentsSelector } from '../../selectors/attachments';
import { useSocket } from '../../utils/withSocket';
import useActiveHeirarchy from '../../utils/useActiveHeirarchy';
import customToast from '../../utils/customToast';
import { getLoggedInUser } from '../../selectors/users';
import { usePrevious } from '../../utils/hooks';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Header from '../../components/Header';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import List from '../../components/List';
import Modal from '../../components/Modal';
import CSVUploader from '../../components/CSVUploader';
import { Button } from '../../components/inputs';
import TypeSelection from '../../components/TypeSelection';
import AnalyticsPeriodPicker from '../../components/AnalyticsPeriodPicker';
import ImportProblemsModal from '../../components/Modal/importProblemsModal';

export default function EquipmentList() {
  const dispatch = useDispatch();
  const socket = useSocket();
  const { activeCompany, activeGroup, activeProject } = useActiveHeirarchy();
  const [equipmentList, setList] = useState([]);
  const [uploadModal, setUploadModal] = useState(false);
  const [problems, setProblems] = useState([]);
  const [generated, setGenerated] = useState({});
  const [loading, setLoading] = useState(false);
  const [listType, setListType] = useState('Active');
  const [empPeriod, setEmpPeriod] = useState();
  const [currentPeriod, setCurrentPeriod] = useState();
  const prevPeriod = usePrevious(currentPeriod);
  const prevType = usePrevious(listType);
  const user = useSelector(getLoggedInUser);
  const addedAttachments = useSelector(getAddedAttachmentsSelector);

  useEffect(() => {
    fetchUserListFilter(user._id, 'equipment').then(r => {
      setCurrentPeriod(r);
      setEmpPeriod(r);
    });
  }, [user._id]);

  useEffect(() => {
    if (
      currentPeriod &&
      (!isEqual(currentPeriod, prevPeriod) || !isEqual(prevType, listType))
    )
      fetchEquipmentListAll({ ...currentPeriod, selectedType: listType }).then(
        setList
      );
  }, [currentPeriod, prevPeriod, listType, prevType]);

  useEffect(() => {
    if (!socket) return;

    socket.emit('subscribeEquipmentList', {
      company: activeCompany?._id,
      group: activeGroup?._id,
      project: activeProject?._id
    });

    socket.on('equipmentCSV', value => {
      setGenerated(value);
      if (value?.url?.length) setLoading(false);
    });

    socket.on('printFail', error => {
      setGenerated(null);
      customToast(`Error Creating Equipment List: ${error}`, 'error');
    });
  }, [activeCompany, activeGroup, activeProject, socket, dispatch]);

  const handleExportEquipmentList = () => {
    socket.emit('generateEquipmentListCSV', {
      company: activeCompany?._id,
      group: activeGroup?._id,
      project: activeProject?._id
    });
    setGenerated({});
    setLoading(true);
  };

  const downloadQR = ids => {
    if (!ids.length)
      customToast(
        'No QR codes selected to download. Please select QR codes and try again',
        'error'
      );
    else
      downloadEquipmentQrCodes({ ids }).then(response =>
        window.open(response, '_blank')
      );
  };

  const handleUpload = () => {
    uploadEquipment(addedAttachments).then(
      r => {
        fetchEquipmentListAll().then(setList);
        customToast('Equipment Successfully Uploaded!');
      },
      e => {
        setProblems(e.data);
        fetchEquipmentListAll().then(setList);
      }
    );

    dispatch(clearUploadedAttachments());
    setUploadModal(false);
  };

  const handleArchive = ids => {
    if (!ids.length) return;
    archiveMany({
      type: 'equipment',
      ids: ids,
      isArchived: listType === 'Active'
    }).then(() => {
      fetchEquipmentListAll({ ...currentPeriod, selectedType: listType }).then(
        setList
      );
    });
  };

  const header = (
    <Header
      title="Equipment"
      pageActionOptions={[
        {
          label: 'Create Equipment',
          visible: !activeGroup?.isHidden && !activeProject?.isHidden,
          color: 'blueOutline',
          onClick: () => history.push('/app/equipmentContainer/')
        },
        {
          label: 'Upload Template',
          visible: true,
          color: 'blueOutline',
          onClick: () => setUploadModal(true)
        },
        {
          label: loading
            ? 'Generating Equipment List CSV'
            : generated?.url
              ? 'Regenerate Equipment List CSV'
              : 'Generate Equipment List CSV',
          visible: true,
          color: 'blueOutline',
          onClick: handleExportEquipmentList,
          disabled: loading
        },
        {
          label: 'Download Equipment List CSV',
          color: 'greenOutline',
          visible: generated?.url,
          onClick: () => window.open(generated.url, '_blank')
        }
      ]}
    />
  );

  const columns = [
    {
      key: 'title',
      label: 'Name'
    },
    {
      key: 'status',
      label: 'Status',
      enum: [
        { value: 1, label: 'Operational' },
        { value: 2, label: 'Service' },
        { value: 3, label: 'Not Operational' }
      ]
    },
    {
      key: 'location',
      label: 'Location'
    },
    {
      key: 'numAssocTasks',
      label: 'Number of Associated Tasks'
    }
  ];

  return (
    <HeaderAndFooter Header={header}>
      <ScrollToTopOnMount />
      <TypeSelection
        selectedArray={['Active', 'Archived']}
        onClick={v => setListType(v)}
        selected={listType}
      />
      <AnalyticsPeriodPicker
        period={currentPeriod}
        onChange={v => {
          setList([]);
          setCurrentPeriod(v);
        }}
        onSave={v =>
          updateUserListFilter({
            empId: user._id,
            type: 'equipment',
            period: v
          }).then(() => setEmpPeriod(v))
        }
        allowAll
        savedPeriod={empPeriod}
        listPicker
      />
      <List
        data={equipmentList}
        dataIsHash
        settings={columns}
        getRowId={r => r._id}
        rowClick={row => history.push(`/app/equipmentContainer/${row._id}`)}
        saveKey={`${listType === 'Archived' ? 'archived' : ''}EquipmentList`}
        actions={[
          {
            color: 'blue',
            label: 'Download QR Codes',
            onClick: downloadQR
          },
          {
            color: 'blue',
            label: `${listType === 'Archived' ? 'Un-' : ''}Archive Equipment`,
            onClick: handleArchive
          }
        ]}
      />
      <Modal
        title="Upload Equipment Template"
        titleClassName="blueHeader"
        isOpen={uploadModal}
        className="modalSmall"
        onRequestClose={() => {
          dispatch(clearUploadedAttachments());
          setUploadModal(false);
        }}
        submitButtonColor="blue"
        submitActions={handleUpload}
        disableSubmit={!addedAttachments?.length}
      >
        <Button
          color="blue"
          onClick={() => {
            var win = window.open(EQUIPMENT_BULK_UPLOAD_TEMPLATE, '_blank');
            win.focus();
          }}
          text="Download CSV Template"
        />
        <CSVUploader documentType="equipmentUpload" className="dropzone" />
      </Modal>
      <ImportProblemsModal
        type="Equipment"
        isOpen={problems?.length}
        onRequestClose={() => {
          setProblems([]);
        }}
        messages={problems}
      />
    </HeaderAndFooter>
  );
}
