import React from 'react';

const clone = props => (
  <svg
    width={props.width || '40px'}
    height={props.height || '40px'}
    x={props.x || '0px'}
    y={props.y || '0px'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.97727 1.81818H16.4773H18.1818V3.52273V11.0227V12.7273H16.3636V14.5455H18.1818C19.186 14.5455 20 13.7294 20 12.7273V11.0227V3.52273V1.81818C20 0.814028 19.1839 0 18.1818 0H16.4773H8.97727H7.27273C6.26857 0 5.45455 0.816071 5.45455 1.81818V3.63636H7.27273V1.81818H8.97727ZM0 7.27466C0 6.26944 0.813194 5.45455 1.82012 5.45455H12.7253C13.7306 5.45455 14.5455 6.26774 14.5455 7.27466V18.1799C14.5455 19.1851 13.7323 20 12.7253 20H1.82012C0.814894 20 0 19.1868 0 18.1799V7.27466Z"
    />
  </svg>
);

export default clone;
