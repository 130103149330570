import React, { useState } from 'react';
import { Portal } from 'react-portal';
import { usePopper } from 'react-popper';
import classnames from 'classnames/bind';
import moment from 'moment';

import { Input, nameInput } from '../';
import { IReportDatePicker } from '../DateTimePicker';
import { zIndex } from '../../../utils/popperModifiers';
import { noBubble } from '../../../utils/events';

import styles from '../Dropdown/dropdown.module.scss';
const bStyles = classnames.bind(styles);

const CalendarDropdown = ({
  options,
  name,
  currentValue,
  placeholder,
  disabled,
  onChange,
  testID,
  inputClassName,
  startTop,
  ...props
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [menuRef, setMenuRef] = useState(null);
  const [ddRef, setDdRef] = useState(null);
  const popper = usePopper(menuRef, ddRef, {
    placement: startTop ? 'top-start' : 'bottom-start',
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: [startTop ? 'bottom-start' : 'top-start']
        }
      },
      zIndex
    ]
  });

  const handleChange = selection => {
    setShowOptions(false);
    onChange(selection);
    return;
  };

  const open = e => {
    noBubble(e);
    setShowOptions(disabled ? false : !showOptions);
  };

  const close = e => {
    noBubble(e);
    let node = e?.relatedTarget;
    while (node) {
      if (node === e?.currentTarget || node === ddRef) {
        return;
      }
      node = node.parentNode;
    }
    setShowOptions(false);
  };

  let ulStyles = bStyles(inputClassName, {
    disabled,
    showOptions,
    multiSelect: true,
    openUp: popper.attributes?.popper?.['data-popper-placement'] === 'top-start'
  });

  let dateString = currentValue;
  if (moment.isMoment(dateString)) dateString = dateString.format('MM/DD/YYYY');

  return (
    <Input name={name} disabled={disabled} {...props}>
      <div
        data-cy={testID}
        className={ulStyles}
        onBlur={close}
        onClick={open}
        tabIndex={0}
        ref={setMenuRef}
      >
        {dateString ?? placeholder ?? 'Select an option'}
        <div className={styles.right}>
          <img
            src={
              showOptions
                ? require('../../../assets/images/Chevron.png')
                : require('../../../assets/images/downChevron.png')
            }
            alt={showOptions ? 'multiselect open' : 'multiselect closed'}
          />
        </div>
      </div>
      {showOptions && (
        <Portal>
          <div
            ref={setDdRef}
            style={popper.styles.popper}
            {...popper.attributes.popper}
            tabIndex={0}
            className={styles.calendar}
          >
            <IReportDatePicker
              currentValue={currentValue}
              onChange={handleChange}
              name="lastDayOfWork"
            />
          </div>
        </Portal>
      )}
    </Input>
  );
};

const d = nameInput(CalendarDropdown);
export { d as CalendarDropdown };
export default d;
