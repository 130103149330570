import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  REACTIVE_REPORT_TYPES,
  PROACTIVE_REPORT_TYPES,
  GENDER_DROPDOWN
} from '../../../constants/constants';
import {
  PART_OF_BODY_OPTIONS,
  NATURE_OF_INJURY_OPTIONS,
  INJURY_CAUSE_CODE_OPTIONS,
  MARITAL_STATUS,
  EMPLOYEMENT_STATUS,
  WAGES_PAID_FOR_DATE_OF_INJURY_OPTIONS,
  YES_NO_UNKNOWN,
  INITIAL_TREATMENT_OPTIONS,
  EVENT_LOCATION_DESCRIPTION,
  CLAIM_REPORTED_BY_TITLE,
  CLAIM_REPORTED_BY_RELATIONSHIP_TO_INSURED_CODE,
  VEHICLE_OWNERSHIP_TYPE_CODE,
  TYPE_OF_VEHICLE,
  POINT_OF_IMPACT_CODE,
  ESTIMATED_DAMAGE_SEVERITY_CODE,
  RELATIONSHIP_VEHICLE_OWNER,
  AUTO_EVENT_DESCRIPTION,
  EXTENT_OF_INJURY_CODE,
  PROPERTY_DAMAGE_LOSS_OCCURRED,
  PROPERTY_DAMAGE_TYPE_OF_LOSS,
  PROPERTY_DAMAGE_ESTIMATE,
  PROPERTY_DAMAGE_EVENT,
  PROPERTY_DAMAGE_PROPERTY_TYPE,
  RETURN_TO_WORK_DUTIES,
  AUTO_THIRD_PARTY_TYPES,
  AUTO_BICYCLIST_PEDESTRIAN_ROLE_CODE,
  AUTO_OTHER_PROPERTY_DAMAGE_ROLE_CODE
} from '../../../constants/libertyMutualMapping';
import { states } from '../../../constants/states';
import { getActiveCompany, getAllLocations } from '../../../selectors/company';
import { getAllUsers, getMyUserId } from '../../../selectors/users';
import {
  normalizeLibertyMutualPhone,
  normalizeSSN,
  notFutureDate
} from '../../../utils/formValidation';
import Card from '../../Card';
import { IReportDatePicker } from '../../inputs/DateTimePicker';
import HierarchySelector from '../../HierarchySelector';
import List from '../../List';
import { Textbox, Dropdown, EmployeeDropdown, TwoColumn } from '../../inputs';
import styles from './claimCard.module.scss';

const formatDate = date => (date ? moment(date).format('MM/DD/YYYY') : null);

const getDropdownAnswer = (dropdown, value) =>
  dropdown?.find(d => d.value === value)?.label;

const getMultiselectAnswer = (dropdown, value) =>
  dropdown
    ?.filter(d => value.includes(d.value))
    ?.map(d => d.label)
    .join(', ');

export default function ClaimCard({
  name,
  onChange,
  editing,
  claimSection,
  title,
  openEdit,
  isSubmitted,
  viewOnly,
  missingRequired,
  ...props
}) {
  return (
    <Card
      showHeader
      title={title}
      name={name}
      rightButton={{
        text: 'Edit',
        visible: !editing && !isSubmitted && !viewOnly,
        color: 'blue',
        onClick: () => openEdit(true)
      }}
    >
      {title === 'Employee Information' ? (
        <EmployeeCard
          onChange={onChange}
          claimSection={claimSection}
          editing={editing}
          missingRequired={missingRequired}
        />
      ) : title === 'Injury Information' ? (
        <InjuryCard
          onChange={onChange}
          claimSection={claimSection}
          editing={editing}
          missingRequired={missingRequired}
        />
      ) : title === 'Accident Information' ? (
        <AccidentCard
          onChange={onChange}
          claimSection={claimSection}
          editing={editing}
          missingRequired={missingRequired}
          {...props}
        />
      ) : title === 'Policy Information' ? (
        <PolicyCard
          onChange={onChange}
          claimSection={claimSection}
          editing={editing}
          missingRequired={missingRequired}
          {...props}
        />
      ) : title === 'First Party Vehicle Information' ? (
        <VehicleCard
          onChange={onChange}
          claimSection={claimSection}
          editing={editing}
          missingRequired={missingRequired}
        />
      ) : title === 'First Party Driver Information' ? (
        <DriverCard
          onChange={onChange}
          claimSection={claimSection}
          editing={editing}
          missingRequired={missingRequired}
        />
      ) : title === 'Damage Information' ? (
        <DamageCard
          onChange={onChange}
          claimSection={claimSection}
          editing={editing}
          missingRequired={missingRequired}
        />
      ) : title === 'Third Party Vehicle Information' ? (
        <ThirdPartyVehicleCard
          onChange={onChange}
          claimSection={claimSection}
          editing={editing}
          missingRequired={missingRequired}
        />
      ) : title === 'Third Party Pedestrian Information' ? (
        <ThirdPartyPedestrianCard
          onChange={onChange}
          claimSection={claimSection}
          editing={editing}
          missingRequired={missingRequired}
        />
      ) : title === 'Third Party Building Information' ? (
        <ThirdPartyBuildingCard
          onChange={onChange}
          claimSection={claimSection}
          editing={editing}
          missingRequired={missingRequired}
        />
      ) : title === 'Overview' ? (
        <OverviewCard claimSection={claimSection} />
      ) : (
        <></>
      )}
    </Card>
  );
}

function EmployeeCard({ claimSection, onChange, editing, missingRequired }) {
  return (
    <>
      {editing ? (
        <div>
          <EmployeeDropdown
            fieldLabel="Injured Employee Name"
            currentValue={claimSection?.employeeId}
            onChange={value => onChange({ ...claimSection, employeeId: value })}
            searchable
            name="employeeInformation"
            isRequired
            disabled
            touched={missingRequired && !claimSection?.employeeId}
          />
          <TwoColumn>
            <Dropdown
              options={[
                { label: 'Male', value: 1 },
                { label: 'Female', value: 2 }
              ]}
              currentValue={claimSection?.employeeGender}
              onChange={value =>
                onChange({ ...claimSection, employeeGender: value })
              }
              fieldLabel="Gender"
              isRequired
              touched={missingRequired && !claimSection?.employeeGender}
            />
            <Textbox
              currentValue={claimSection?.employeeSSN}
              fieldLabel="Social Security Number"
              onChange={value =>
                onChange({ ...claimSection, employeeSSN: normalizeSSN(value) })
              }
              placeholder="XXX-XX-XXXX"
              isRequired
              tooltip="Full SSN will not be saved in our system, just last four. Don't add until you are ready to submit the claim."
              displayTooltip
              touched={missingRequired && !claimSection?.employeeSSN}
            />
            <Textbox
              currentValue={claimSection?.streetAddress}
              fieldLabel="Street Address"
              onChange={value =>
                onChange({ ...claimSection, streetAddress: value })
              }
              isRequired
              touched={missingRequired && !claimSection?.streetAddress}
            />
            <Textbox
              currentValue={claimSection?.city}
              fieldLabel="City"
              onChange={value => onChange({ ...claimSection, city: value })}
              isRequired
              touched={missingRequired && !claimSection?.city}
            />
            <Dropdown
              fieldLabel="State"
              options={states}
              currentValue={claimSection?.state}
              onChange={value => onChange({ ...claimSection, state: value })}
              isRequired
              touched={missingRequired && !claimSection?.state}
            />
            <Textbox
              fieldLabel="Zip Code"
              currentValue={claimSection?.zip}
              onChange={value => onChange({ ...claimSection, zip: value })}
              isRequired
              error={
                claimSection.zip?.length !== 5
                  ? 'Zip Code must be 5 numbers'
                  : null
              }
              touched={!claimSection.zip && missingRequired}
              type="number"
            />
            <Textbox
              currentValue={claimSection?.employeePhone}
              fieldLabel="Phone Number"
              onChange={value =>
                onChange({
                  ...claimSection,
                  employeePhone: normalizeLibertyMutualPhone(value)
                })
              }
              placeholder="XXX-XXXXXXX"
              isRequired
              touched={missingRequired && !claimSection?.employeePhone}
            />
            <Dropdown
              fieldLabel="Marital Status"
              options={MARITAL_STATUS}
              isRequired
              onChange={value =>
                onChange({ ...claimSection, employeeMaritalStatus: value })
              }
              currentValue={claimSection?.employeeMaritalStatus}
              touched={missingRequired && !claimSection?.employeeMaritalStatus}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <IReportDatePicker
                fieldLabel="Death Date"
                currentValue={claimSection?.employeeDeathDate}
                onChange={value =>
                  onChange({ ...claimSection, employeeDeathDate: value })
                }
                className={styles.deathDate}
                touched={claimSection?.employeeDeathDate}
                warning={notFutureDate(claimSection?.employeeDeathDate)}
                name="deathDate"
              />
              <IReportDatePicker
                fieldLabel="Hire Date"
                currentValue={claimSection?.employeeDateOfHire}
                onChange={value =>
                  onChange({ ...claimSection, employeeDateOfHire: value })
                }
                isRequired
                name="hireDate"
                touched={missingRequired && !claimSection?.employeeDateOfHire}
                warning={notFutureDate(claimSection?.employeeDateOfHire)}
              />
              <IReportDatePicker
                fieldLabel="Date of Birth"
                currentValue={claimSection?.employeeDateOfBirth}
                onChange={value =>
                  onChange({ ...claimSection, employeeDateOfBirth: value })
                }
                name="birthDate"
                touched={claimSection?.employeeDateOfBirth}
                warning={notFutureDate(claimSection?.employeeDateOfBirth)}
              />
            </div>
            <Textbox
              fieldLabel="Occupation"
              currentValue={claimSection?.employeeOccupation}
              onChange={value =>
                onChange({ ...claimSection, employeeOccupation: value })
              }
              isRequired
              touched={!claimSection?.employeeOccupation && missingRequired}
              error={
                claimSection.employeeOccupation?.length > 35 ||
                claimSection.employeeOccupation?.length < 2
                  ? 'Occupation must be between 2 and 35 characters'
                  : null
              }
            />
            <Dropdown
              fieldLabel="Employment Status"
              options={EMPLOYEMENT_STATUS}
              isRequired
              onChange={value =>
                onChange({ ...claimSection, employmentStatus: value })
              }
              currentValue={claimSection?.employmentStatus}
              touched={missingRequired && !claimSection?.employmentStatus}
            />
            <Textbox
              fieldLabel="Number of Days Worked Per Week"
              currentValue={claimSection?.numberOfDaysWorkedPerWeek}
              onChange={value =>
                onChange({ ...claimSection, numberOfDaysWorkedPerWeek: value })
              }
              isRequired
              type="number"
              touched={
                !claimSection.numberOfDaysWorkedPerWeek && missingRequired
              }
              error={
                claimSection.numberOfDaysWorkedPerWeek > 7 ||
                claimSection.numberOfDaysWorkedPerWeek < 1
                  ? 'Number of Days must be between 1 and 7'
                  : null
              }
            />
            <Textbox
              fieldLabel="Number of Hours Worked Per Week"
              currentValue={claimSection?.numberOfHoursWorkedPerWeek}
              onChange={value =>
                onChange({ ...claimSection, numberOfHoursWorkedPerWeek: value })
              }
              isRequired
              type="number"
              touched={
                !claimSection.numberOfHoursWorkedPerWeek && missingRequired
              }
              error={
                claimSection.numberOfHoursWorkedPerWeek > 168 ||
                claimSection.numberOfHoursWorkedPerWeek < 1
                  ? 'Number of Hours must be between 1 and 168'
                  : null
              }
            />
          </TwoColumn>
        </div>
      ) : (
        <TwoColumn className={styles.claimCard}>
          <div className={styles.claimLabel}>Injured Employee Name</div>
          <div>{`${claimSection?.employeeFirstName} ${claimSection?.employeeLastName}`}</div>
          <div className={styles.claimLabel}>Gender</div>
          <div>
            {claimSection?.employeeGender === 1
              ? 'Male'
              : claimSection?.employeeGender === 2
                ? 'Female'
                : 'N/A'}
          </div>
          <div className={styles.claimLabel}>Social Security Number</div>
          <div>{claimSection?.employeeSSN}</div>
          <div className={styles.claimLabel}>Employee Address</div>
          <div className={styles.stackedText}>
            <div>{claimSection?.streetAddress}</div>
            <div>{`${claimSection?.city}, ${claimSection?.state} ${claimSection?.zip}`}</div>
          </div>
          <div className={styles.claimLabel}>Phone Number</div>
          <div>{claimSection?.employeePhone}</div>
          <div className={styles.claimLabel}>Marital Status</div>
          <div>
            {getDropdownAnswer(
              MARITAL_STATUS,
              claimSection.employeeMaritalStatus
            )}
          </div>
          <div className={styles.claimLabel}>Date of Death</div>
          <div>{formatDate(claimSection?.employeeDeathDate) ?? 'N/A'}</div>
          <div className={styles.claimLabel}>Date of Hire</div>
          <div>{formatDate(claimSection?.employeeDateOfHire)}</div>
          <div className={styles.claimLabel}>Date of Birth</div>
          <div>{formatDate(claimSection?.employeeDateOfBirth)}</div>
          <div className={styles.claimLabel}>Occupation</div>
          <div>{claimSection?.employeeOccupation}</div>
          <div className={styles.claimLabel}>Employement Status</div>
          <div>
            {getDropdownAnswer(
              EMPLOYEMENT_STATUS,
              claimSection.employmentStatus
            )}
          </div>
          <div className={styles.claimLabel}>
            Number of Days Worked Per Week
          </div>
          <div>{claimSection?.numberOfDaysWorkedPerWeek}</div>
          <div className={styles.claimLabel}>
            Number of Hours Worked Per Day
          </div>
          <div>{claimSection?.numberOfHoursWorkedPerWeek}</div>
        </TwoColumn>
      )}
    </>
  );
}

function InjuryCard({ claimSection, onChange, editing, missingRequired }) {
  return (
    <>
      {editing ? (
        <TwoColumn>
          <Dropdown
            searchable
            fieldLabel="Body Part Affected"
            options={PART_OF_BODY_OPTIONS}
            currentValue={parseInt(claimSection?.bodyPartAffected)}
            alphabetize
            onChange={values =>
              onChange({ ...claimSection, bodyPartAffected: values })
            }
            isRequired
            touched={missingRequired && !claimSection?.bodyPartAffected}
          />
          <Dropdown
            searchable
            fieldLabel="Nature Of Injury"
            options={
              claimSection.bodyPartAffected
                ? NATURE_OF_INJURY_OPTIONS.filter(o =>
                    o.applicableBodyParts.includes(
                      parseInt(claimSection?.bodyPartAffected)
                    )
                  )
                : NATURE_OF_INJURY_OPTIONS
            }
            currentValue={claimSection?.natureOfInjury}
            alphabetize
            onChange={values =>
              onChange({ ...claimSection, natureOfInjury: values })
            }
            isRequired
            touched={missingRequired && !claimSection?.natureOfInjury}
          />
          <Dropdown
            searchable
            fieldLabel="Injury Cause"
            options={INJURY_CAUSE_CODE_OPTIONS}
            currentValue={claimSection?.injuryCauseCode}
            alphabetize
            onChange={values =>
              onChange({ ...claimSection, injuryCauseCode: values })
            }
            isRequired
            touched={missingRequired && !claimSection?.injuryCauseCode}
          />
          <Textbox
            type="text"
            fieldLabel="Source of injury"
            onChange={value =>
              onChange({ ...claimSection, sourceOfInjury: value })
            }
            currentValue={claimSection.sourceOfInjury}
            isRequired
            touched={!claimSection.sourceOfInjury && missingRequired}
            error={
              claimSection.sourceOfInjury?.length > 15 ||
              claimSection.sourceOfInjury?.length < 2
                ? 'Source of Injury must be between 2 and 15 Characters'
                : null
            }
          />
          <Textbox
            type="text"
            fieldLabel="Injury Description"
            onChange={value =>
              onChange({ ...claimSection, injuryDescription: value })
            }
            currentValue={claimSection.injuryDescription}
            isRequired
            touched={!claimSection.injuryDescription && missingRequired}
            error={
              claimSection.injuryDescription?.length > 121 ||
              claimSection.injuryDescription?.length < 2
                ? 'Injury Description must be between 2 and 120 Characters'
                : null
            }
          />
          <Dropdown
            searchable
            fieldLabel="Initial Treatment"
            options={INITIAL_TREATMENT_OPTIONS}
            currentValue={claimSection?.initialTreatment}
            onChange={values =>
              onChange({ ...claimSection, initialTreatment: values })
            }
            isRequired
            touched={missingRequired && !claimSection?.initialTreatment}
          />
          <Dropdown
            fieldLabel="Wages Paid for Date of Injury"
            options={WAGES_PAID_FOR_DATE_OF_INJURY_OPTIONS}
            currentValue={claimSection?.wagesPaidForDateOfInjury}
            onChange={values =>
              onChange({ ...claimSection, wagesPaidForDateOfInjury: values })
            }
            isRequired
            touched={missingRequired && !claimSection?.wagesPaidForDateOfInjury}
          />
          <Dropdown
            fieldLabel="Absence from Work Due to Injury"
            options={YES_NO_UNKNOWN}
            currentValue={claimSection?.absenceFromWorkDueToInjury}
            onChange={values =>
              onChange({
                ...claimSection,
                absenceFromWorkDueToInjury: values
              })
            }
            isRequired
            touched={
              missingRequired && !claimSection?.absenceFromWorkDueToInjury
            }
          />
          {claimSection?.absenceFromWorkDueToInjury === 'yes' ? (
            <>
              <IReportDatePicker
                fieldLabel="Last Day of Work"
                displayTooltip
                tooltip="When the injured worker loses more than one day from work due to their injury or occupational illness, enter their last day on the job before being absent."
                currentValue={claimSection?.lastDayWorkedDate}
                onChange={value =>
                  onChange({ ...claimSection, lastDayWorkedDate: value })
                }
                touched={!claimSection?.lastDayWorkedDate && missingRequired}
                error={notFutureDate(claimSection?.lastDayWorkedDate)}
                name="lastDayOfWork"
                isRequired
              />
              <IReportDatePicker
                fieldLabel="First Day Absent"
                displayTooltip
                tooltip="When the injured worker loses more than one day from work due to their injury or occupational illness, enter their first day being absent from their job."
                currentValue={claimSection?.firstDayAbsentDate}
                onChange={value =>
                  onChange({ ...claimSection, firstDayAbsentDate: value })
                }
                touched={!claimSection?.firstDayAbsentDate && missingRequired}
                error={
                  moment(claimSection?.firstDayAbsentDate).isBefore(
                    claimSection?.lastDayWorkedDate
                  )
                    ? 'First Day Absent must be after Last Day Worked'
                    : null
                }
                name="firstDayAbsent"
                isRequired
              />
              <Dropdown
                displayTooltip
                tooltip="Specifies whether the injured worker continued to receive normal wages while absent from their job due to their injury or occupational illness. If unknown or unsure, enter (No)."
                fieldLabel="Wages Continued while Absent"
                options={YES_NO_UNKNOWN.filter(o => o.label !== 'Unknown')}
                currentValue={claimSection?.wagesContinuedWhileAbsent}
                onChange={values =>
                  onChange({
                    ...claimSection,
                    wagesContinuedWhileAbsent: values
                  })
                }
                isRequired
                touched={
                  missingRequired && !claimSection?.wagesContinuedWhileAbsent
                }
              />
              <Textbox
                fieldLabel="Average Weekly Wage"
                currentValue={claimSection?.averageWeeklyWage}
                onChange={value =>
                  onChange({ ...claimSection, averageWeeklyWage: value })
                }
                isRequired
                type="number"
                touched={!claimSection?.averageWeeklyWage && missingRequired}
                error={
                  parseFloat(claimSection?.averageWeeklyWage) > 99999.99 ||
                  parseFloat(claimSection?.averageWeeklyWage) < 0.01
                    ? 'Wage must be between $.01 and $99999.99'
                    : null
                }
              />
              <IReportDatePicker
                fieldLabel="Returned to Work Date"
                displayTooltip
                tooltip="The injured worker''s first day back on the job after being absent due to their injury or occupational illness. If unknown or unsure, do not populate this field."
                currentValue={claimSection?.returnToWorkDate}
                onChange={value =>
                  onChange({ ...claimSection, returnToWorkDate: value })
                }
                touched={!claimSection?.returnToWorkDate && missingRequired}
                error={
                  moment(claimSection.returnToWorkDate).isBefore(
                    claimSection.firstDayAbsentDate
                  ) ||
                  moment(claimSection?.returnToWorkDate).isBefore(
                    claimSection.lastDayWorkedDate
                  )
                    ? 'Return to work Date must be after First Day Absent and Last Day Worked'
                    : null
                }
                name="returnedToWorkDate"
              />
              <Dropdown
                displayTooltip
                tooltip="Specifies if the type of duties assigned to the injured worker when they returned from absence due to their injury or occupational illness (i.e. alternate or light duties assigned vs. regular job duties)."
                fieldLabel="Return to Work Duties"
                options={RETURN_TO_WORK_DUTIES}
                currentValue={claimSection?.returnToWorkDuties}
                onChange={values =>
                  onChange({
                    ...claimSection,
                    returnToWorkDuties: values
                  })
                }
              />
            </>
          ) : null}
        </TwoColumn>
      ) : (
        <TwoColumn className={styles.claimCard}>
          <div className={styles.claimLabel}>Body Part Affected</div>
          <div>
            {getDropdownAnswer(
              PART_OF_BODY_OPTIONS,
              parseInt(claimSection?.bodyPartAffected)
            )}
          </div>
          <div className={styles.claimLabel}>Nature Of Injury</div>
          <div>
            {getDropdownAnswer(
              NATURE_OF_INJURY_OPTIONS,
              claimSection.natureOfInjury
            )}
          </div>
          <div className={styles.claimLabel}>Injury Cause</div>
          <div>
            {getDropdownAnswer(
              INJURY_CAUSE_CODE_OPTIONS,
              claimSection.injuryCauseCode
            )}
          </div>
          <div className={styles.claimLabel}>Source Of Injury</div>
          <div>{claimSection.sourceOfInjury}</div>
          <div className={styles.claimLabel}>Injury Description</div>
          <div>{claimSection.injuryDescription}</div>
          <div className={styles.claimLabel}>Initial Treatment</div>
          <div>
            {getDropdownAnswer(
              INITIAL_TREATMENT_OPTIONS,
              claimSection.initialTreatment
            )}
          </div>
          <div className={styles.claimLabel}>Wages Paid for Date of Injury</div>
          <div>
            {getDropdownAnswer(
              WAGES_PAID_FOR_DATE_OF_INJURY_OPTIONS,
              claimSection.wagesPaidForDateOfInjury
            )}
          </div>
          <div className={styles.claimLabel}>
            Absence from Work Due to Injury
          </div>
          <div>
            {getDropdownAnswer(
              YES_NO_UNKNOWN,
              claimSection.absenceFromWorkDueToInjury
            )}
          </div>
          {claimSection.absenceFromWorkDueToInjury === 'yes' ? (
            <>
              <div className={styles.claimLabel}>Last Day Worked</div>
              <div>
                {moment(claimSection.lastDayWorkedDate).format('MM/DD/YYYY')}
              </div>
              <div className={styles.claimLabel}>First Day Absent</div>
              <div>
                {moment(claimSection.firstDayAbsentDate).format('MM/DD/YYYY')}
              </div>
              <div className={styles.claimLabel}>
                Wages Continued while Absent
              </div>
              <div>
                {getDropdownAnswer(
                  YES_NO_UNKNOWN,
                  claimSection.wagesContinuedWhileAbsent
                )}
              </div>
              <div className={styles.claimLabel}>Return to Work Date</div>
              <div>
                {moment(claimSection.returnToWorkDate).format('MM/DD/YYYY')}
              </div>
              <div className={styles.claimLabel}>Return to Work Duties</div>
              <div>
                {getDropdownAnswer(
                  RETURN_TO_WORK_DUTIES,
                  claimSection.returnToWorkDuties
                )}
              </div>
            </>
          ) : null}
        </TwoColumn>
      )}
    </>
  );
}

function AccidentCard({
  claimSection,
  onChange,
  editing,
  permissions,
  type,
  missingRequired
}) {
  const allGroups = useSelector(getAllLocations);
  const myUserId = useSelector(getMyUserId);
  const users = useSelector(getAllUsers);

  const findGroupName = groupId =>
    allGroups?.find(g => g._id === groupId)?.name;

  const findProjectName = (groupId, projectId) =>
    allGroups
      ?.find(g => g._id === groupId)
      ?.projects?.find(p => p._id === projectId)?.name;

  return (
    <>
      {editing ? (
        <div>
          <HierarchySelector
            groupId={claimSection.groupId}
            projectId={claimSection.projectId}
            onGroupChange={value =>
              onChange({ ...claimSection, groupId: value })
            }
            onProjectChange={value =>
              onChange({ ...claimSection, projectId: value })
            }
          />
          <TwoColumn>
            <IReportDatePicker
              fieldLabel="Incident Date"
              pickTime
              currentValue={claimSection.incidentDate}
              onChange={values =>
                onChange({ ...claimSection, incidentDate: values })
              }
              isRequired
              name="incidentDate"
              touched={!claimSection?.incidentDate && missingRequired}
              error={notFutureDate(claimSection?.incidentDate)}
            />
            <Textbox
              currentValue={claimSection?.streetAddress}
              fieldLabel="Incident Street Address"
              onChange={value =>
                onChange({ ...claimSection, streetAddress: value })
              }
              touched={!claimSection?.streetAddress && missingRequired}
              isRequired
            />
            <Textbox
              currentValue={claimSection?.city}
              fieldLabel="Incident City"
              onChange={value => onChange({ ...claimSection, city: value })}
              touched={!claimSection?.city && missingRequired}
              isRequired
            />
            <Dropdown
              fieldLabel="Incident State"
              options={states}
              currentValue={claimSection?.state}
              touched={!claimSection?.state && missingRequired}
              onChange={value => onChange({ ...claimSection, state: value })}
              isRequired
            />
            {type === 'wc' ? (
              <Textbox
                fieldLabel="Incident Zip Code"
                currentValue={claimSection?.zip}
                onChange={value => onChange({ ...claimSection, zip: value })}
                isRequired
                error={
                  claimSection.zip?.length !== 5
                    ? 'Zip Code must be 5 numbers'
                    : null
                }
                touched={!claimSection.zip?.length > 0 && missingRequired}
                type="number"
              />
            ) : null}
            {type === 'wc' ? (
              <Textbox
                type="text"
                fieldLabel="Incident Location County"
                currentValue={claimSection.incidentLocationCounty}
                onChange={values =>
                  onChange({ ...claimSection, incidentLocationCounty: values })
                }
                isRequired
                touched={
                  !claimSection?.incidentLocationCounty && missingRequired
                }
              />
            ) : null}
            {type === 'wc' ? (
              <Dropdown
                searchable
                fieldLabel="Incident Location Description"
                options={EVENT_LOCATION_DESCRIPTION}
                currentValue={claimSection?.incidentLocationDescription}
                onChange={value =>
                  onChange({
                    ...claimSection,
                    incidentLocationDescription: value
                  })
                }
                touched={
                  !claimSection?.incidentLocationDescription && missingRequired
                }
                isRequired
              />
            ) : null}
            {type === 'wc' ? (
              <Dropdown
                fieldLabel="Claim Jurisdiction"
                options={states}
                currentValue={claimSection?.claimJurisdictionCode}
                onChange={value =>
                  onChange({ ...claimSection, claimJurisdictionCode: value })
                }
                touched={
                  !claimSection?.claimJurisdictionCode && missingRequired
                }
                isRequired
                displayTooltip
                tooltip="The code representing which State's workers compensation rules may apply to this claim. Please select the majority state for the following; state the incident occurred, state in which the injured worker is paid, state the injured work performs their job, state the injured worker resides, state from which the injured worker is supervised, state in which the injured worker was hired. When unknown or unsure, select the same state in which the incident occurred."
              />
            ) : null}
            <EmployeeDropdown
              fieldLabel="Should also Receive Communication from Liberty Mutual?"
              currentValue={claimSection?.claimNotificationOther}
              onChange={value =>
                onChange({ ...claimSection, claimNotificationOther: value })
              }
              searchable
              name="claimNotificationOther"
              limit={permissions?.map(p => p.userId)}
              exclude={[myUserId]}
            />
            {type === 'wc' ? (
              <Dropdown
                searchable
                fieldLabel={
                  type === 'wc'
                    ? 'Claim Reported by Title'
                    : 'Claim Reported By Relationship to Insured'
                }
                options={
                  type === 'wc'
                    ? CLAIM_REPORTED_BY_TITLE
                    : CLAIM_REPORTED_BY_RELATIONSHIP_TO_INSURED_CODE
                }
                currentValue={claimSection?.claimReportedByTitle}
                onChange={value =>
                  onChange({
                    ...claimSection,
                    claimReportedByTitle: value
                  })
                }
                touched={!claimSection?.claimReportedByTitle && missingRequired}
                isRequired
              />
            ) : null}
            {type === 'auto' ? (
              <Dropdown
                searchable
                fieldLabel="Incident Description Code"
                options={AUTO_EVENT_DESCRIPTION}
                currentValue={claimSection?.eventDescriptionCode}
                onChange={value =>
                  onChange({ ...claimSection, eventDescriptionCode: value })
                }
                isRequired
                touched={!claimSection?.eventDescriptionCode && missingRequired}
              />
            ) : null}
            {type === 'auto' ? (
              <Dropdown
                fieldLabel="Are there any Third Parties Involved?"
                options={YES_NO_UNKNOWN.filter(o => o.label !== 'Unknown')}
                onChange={value =>
                  onChange({ ...claimSection, thirdPartyInvolved: value })
                }
                currentValue={claimSection?.thirdPartyInvolved}
                isRequired
                touched={!claimSection?.thirdPartyInvolved && missingRequired}
              />
            ) : null}
            {claimSection?.thirdPartyInvolved === 'yes' ? (
              <Dropdown
                multi
                fieldLabel="Type of Third Party Invovled"
                options={AUTO_THIRD_PARTY_TYPES}
                onChange={value =>
                  onChange({ ...claimSection, typeOfThirdParty: value })
                }
                currentValue={claimSection?.typeOfThirdParty}
              />
            ) : null}
            <Dropdown
              fieldLabel="Are you reporting this claim as RECORD ONLY?"
              displayTooltip
              tooltip="An indicator the policyholder is reporting the incident for reference or 'record only' and feels that either damages will not be paid out or will be handled by a 3rd party's insurance carrier."
              options={YES_NO_UNKNOWN.filter(o => o.label !== 'Unknown')}
              onChange={value =>
                onChange({
                  ...claimSection,
                  reportingClaimAsRecordOnlyIndicator: value
                })
              }
              currentValue={claimSection?.reportingClaimAsRecordOnlyIndicator}
            />
          </TwoColumn>
          <Textbox
            type="textarea"
            fieldLabel="Incident Description"
            currentValue={claimSection.incidentDescription}
            onChange={values =>
              onChange({ ...claimSection, incidentDescription: values })
            }
            isRequired
            touched={!claimSection.incidentDescription && missingRequired}
            error={
              claimSection?.incidentDescription?.length > 200 ||
              claimSection?.incidentDescription?.length < 2
                ? 'Incident Description must be between 2 and 200 Characters'
                : null
            }
          />
          {type === 'auto' ? (
            <Textbox
              type="textarea"
              fieldLabel="Additional Remarks"
              currentValue={claimSection.claimsOccurrenceAdditionalRemarks}
              onChange={values =>
                onChange({
                  ...claimSection,
                  claimsOccurrenceAdditionalRemarks: values
                })
              }
              warning={
                claimSection?.claimsOccurrenceAdditionalRemarks?.length >
                  1500 ||
                claimSection?.claimsOccurrenceAdditionalRemarks?.length < 2
                  ? 'Additional Remarks must be between 2 and 1500 Characters'
                  : null
              }
            />
          ) : null}
        </div>
      ) : (
        <TwoColumn className={styles.claimCard}>
          <div className={styles.claimLabel}>Group/Est.</div>
          <div>{findGroupName(claimSection.groupId)}</div>
          <div className={styles.claimLabel}>Area</div>
          <div>
            {findProjectName(claimSection.groupId, claimSection.projectId)}
          </div>
          <div className={styles.claimLabel}>Incident Date</div>
          <div>{formatDate(claimSection.incidentDate)}</div>
          <div className={styles.claimLabel}>Incident Address</div>
          <div className={styles.stackedText}>
            <div>{claimSection?.streetAddress}</div>
            <div>{`${claimSection?.city}, ${claimSection?.state} ${
              type === 'wc' ? claimSection?.zip : ''
            }`}</div>
          </div>
          {type === 'wc' ? (
            <>
              <div className={styles.claimLabel}>Incident Location County</div>
              <div>{claimSection?.incidentLocationCounty}</div>
            </>
          ) : null}
          {type === 'wc' ? (
            <>
              <div className={styles.claimLabel}>
                Incident Location Description
              </div>
              <div>
                {getDropdownAnswer(
                  EVENT_LOCATION_DESCRIPTION,
                  claimSection.incidentLocationDescription
                )}
              </div>
            </>
          ) : null}
          {type === 'wc' ? (
            <>
              <div className={styles.claimLabel}>Claim Jurisdiction Code</div>
              <div>
                {getDropdownAnswer(states, claimSection.claimJurisdictionCode)}
              </div>
            </>
          ) : null}
          <div className={styles.claimLabel}>
            Should also Receive Communication from Liberty Mutual?
          </div>
          <div>
            {getDropdownAnswer(
              users.map(u => ({
                label: `${u.firstName} ${u.lastName}`,
                value: u._id
              })),
              claimSection.claimNotificationOther
            )}
          </div>
          {type === 'wc' ? (
            <>
              <div className={styles.claimLabel}>{`${
                type === 'wc'
                  ? 'Claim Reported by Title'
                  : 'Claim Reported By Relationship to Insured'
              }`}</div>
              <div>
                {getDropdownAnswer(
                  type === 'wc'
                    ? CLAIM_REPORTED_BY_TITLE
                    : CLAIM_REPORTED_BY_RELATIONSHIP_TO_INSURED_CODE,
                  claimSection.claimReportedByTitle
                )}
              </div>
            </>
          ) : null}
          {type === 'auto' ? (
            <>
              <div className={styles.claimLabel}>Incident Description Code</div>
              <div>
                {getDropdownAnswer(
                  AUTO_EVENT_DESCRIPTION,
                  claimSection?.eventDescriptionCode
                )}
              </div>
            </>
          ) : null}
          {type === 'auto' ? (
            <>
              <div className={styles.claimLabel}>
                Are there any Third Parties Involved?
              </div>
              <div>
                {getDropdownAnswer(
                  YES_NO_UNKNOWN,
                  claimSection?.thirdPartyInvolved
                )}
              </div>
            </>
          ) : null}
          {type === 'auto' && claimSection?.thirdPartyInvolved === 'yes' ? (
            <>
              <div className={styles.claimLabel}>
                Type of Third Party Involved
              </div>
              <div>
                {getMultiselectAnswer(
                  AUTO_THIRD_PARTY_TYPES,
                  claimSection?.typeOfThirdParty
                )}
              </div>
            </>
          ) : null}
          <div className={styles.claimLabel}>
            Are you reporting this claim as RECORD ONLY?
          </div>
          <div>
            {getDropdownAnswer(
              YES_NO_UNKNOWN,
              claimSection?.reportingClaimAsRecordOnlyIndicator
            )}
          </div>
          {type === 'auto' && claimSection?.insuredLocationCode ? (
            <>
              <div className={styles.claimLabel}>Insured Location Code</div>
              <div>{claimSection?.insuredLocationCode}</div>
            </>
          ) : null}
          <div className={styles.claimLabel}>Incident Description</div>
          <div>{claimSection?.incidentDescription}</div>
          {type === 'auto' ? (
            <>
              <div className={styles.claimLabel}>Additional Remarks</div>
              <div>{claimSection?.claimsOccurrenceAdditionalRemarks}</div>
            </>
          ) : null}
        </TwoColumn>
      )}
    </>
  );
}

function PolicyCard({
  claimSection,
  onChange,
  editing,
  availablePolicies,
  incidentDate,
  type,
  missingRequired
}) {
  const handlePolicyNumberChange = value => {
    const policy = availablePolicies.find(a => a._id === value);

    onChange({
      lineOfBusinessCode: policy.lineOfBusinessCode,
      policyEffectiveDate: policy.policyEffectiveDate,
      policyEndDate: policy.policyExpirationDate,
      displayPolicyNumber: value
    });
  };

  const AVAILABLE_POLICIES_OPTIONS = availablePolicies
    ?.filter(
      p =>
        moment(incidentDate).isBetween(
          p.policyEffectiveDate,
          p.policyExpirationDate
        ) && type?.includes(p.lineOfBusinessCode)
    )
    .map(p => ({
      label: p.displayPolicyNumber,
      value: p._id
    }));

  return (
    <>
      {editing ? (
        <TwoColumn>
          <Dropdown
            searchable
            fieldLabel="Policy Number"
            options={AVAILABLE_POLICIES_OPTIONS}
            currentValue={claimSection?.displayPolicyNumber}
            onChange={handlePolicyNumberChange}
            isRequired
            alphabetize
            touched={missingRequired && !claimSection?.displayPolicyNumber}
          />
          <Textbox
            fieldLabel="Line of Business Code"
            currentValue={claimSection.lineOfBusinessCode}
            onChange={values =>
              onChange({ ...claimSection, lineOfBusinessCode: values })
            }
            isRequired
            disabled
            touched={missingRequired && !claimSection?.lineOfBusinessCode}
          />
          <IReportDatePicker
            fieldLabel="Policy Effective Date"
            currentValue={claimSection.policyEffectiveDate}
            onChange={values =>
              onChange({ ...claimSection, policyEffectiveDate: values })
            }
            isRequired
            name="policyEffectiveDate"
            disabled
            touched={missingRequired && !claimSection?.policyEffectiveDate}
          />
          <IReportDatePicker
            fieldLabel="Policy End Date"
            currentValue={claimSection.policyEndDate}
            onChange={values =>
              onChange({ ...claimSection, policyEndDate: values })
            }
            isRequired
            name="policyEndDate"
            disabled
            touched={missingRequired && !claimSection?.policyEndDate}
          />
        </TwoColumn>
      ) : (
        <TwoColumn className={styles.claimCard}>
          <div className={styles.claimLabel}>Policy Number</div>
          <div>{claimSection?.displayPolicyNumber}</div>
          <div className={styles.claimLabel}>Line Of Business Code</div>
          <div>{claimSection?.lineOfBusinessCode}</div>
          <div className={styles.claimLabel}>Policy Period Effective Date</div>
          <div>{formatDate(claimSection?.policyEffectiveDate)}</div>
          <div className={styles.claimLabel}>Policy Period End Date</div>
          <div>{formatDate(claimSection?.policyEndDate)}</div>
        </TwoColumn>
      )}
    </>
  );
}

function OverviewCard({ claimSection }) {
  const company = useSelector(getActiveCompany);
  const reportTypes = [
    ...REACTIVE_REPORT_TYPES,
    ...PROACTIVE_REPORT_TYPES.filter(r => r.value !== 8 && r.value !== 10)
  ];
  const columns = [
    {
      key: 'groupId',
      label: 'Group/Est',
      datatype: 'group'
    },
    {
      key: 'reportTemplateIds',
      label: 'Report Types',
      accessor: r => r.incidentId.reportTemplateIds.map(v => parseInt(v, 10)),
      datatype: 'svgs',
      disableSortBy: true,
      enum: company?.showAircraft
        ? reportTypes.filter(r => !r.label !== 'Spill')
        : reportTypes.filter(r => !r.isAircraft)
    },
    {
      key: 'incidentNumber',
      label: 'Incident Number',
      accessor: r => r.incidentId.incidentNumber
    },
    {
      key: 'incidentDate',
      label: 'Incident Date',
      datatype: 'date',
      accessor: r => r.incidentDate
    },
    {
      key: 'claimNumber',
      label: 'Claim Number'
    },
    { key: 'lastUpdatedAt', datatype: 'date' }
  ];
  return (
    <>
      <TwoColumn className={styles.claimCard}>
        <div className={styles.claimLabel}>Claim Number</div>
        <div>{claimSection?.claimNumber}</div>
        <div className={styles.claimLabel}>iReportSource Number</div>
        <div>{claimSection?.insuredReportNumber}</div>
        <div className={styles.claimLabel}>Claim Sent</div>
        <div>{formatDate(claimSection?.submissionDate)}</div>
        <div className={styles.claimLabel}>Employee Other Claims</div>
      </TwoColumn>
      <List
        data={claimSection.previousClaims}
        settings={columns}
        dataIsHash
        saveKey="previousClaims"
      />
    </>
  );
}

function VehicleCard({ claimSection, onChange, editing, missingRequired }) {
  return (
    <>
      {editing ? (
        <TwoColumn>
          <Dropdown
            searchable
            fieldLabel="Vehicle Ownership"
            options={VEHICLE_OWNERSHIP_TYPE_CODE}
            currentValue={claimSection?.vehicleOwnershipTypeCode}
            onChange={value =>
              onChange({ ...claimSection, vehicleOwnershipTypeCode: value })
            }
            isRequired
            touched={missingRequired && !claimSection?.vehicleOwnershipTypeCode}
          />
          <Dropdown
            searchable
            fieldLabel="Type of Vehicle"
            options={TYPE_OF_VEHICLE}
            currentValue={claimSection?.typeOfVehicle}
            onChange={value =>
              onChange({ ...claimSection, typeOfVehicle: value })
            }
            isRequired
            touched={missingRequired && !claimSection?.typeOfVehicle}
          />
          <Textbox
            fieldLabel="Year"
            currentValue={claimSection.year}
            onChange={values => onChange({ ...claimSection, year: values })}
            isRequired
            type="number"
            placeholder="eg 2022"
            touched={!claimSection?.year && missingRequired}
            error={
              claimSection?.year?.length !== 4 ? 'Year must be 4 numbers' : null
            }
          />
          <Textbox
            fieldLabel="Make"
            currentValue={claimSection.make}
            onChange={values => onChange({ ...claimSection, make: values })}
            isRequired
            touched={!claimSection?.make && missingRequired}
            error={
              claimSection?.make?.length < 1 || claimSection?.make?.length > 40
                ? 'Make must be between 1 and 40 characters'
                : null
            }
          />
          <Textbox
            fieldLabel="Model"
            currentValue={claimSection.model}
            onChange={values => onChange({ ...claimSection, model: values })}
            isRequired
            touched={!claimSection?.model && missingRequired}
            error={
              claimSection?.model?.length < 1 ||
              claimSection?.model?.length > 40
                ? 'Model must be between 1 and 40 characters'
                : null
            }
          />
          <Textbox
            fieldLabel="VIN"
            currentValue={claimSection.VIN}
            onChange={values => onChange({ ...claimSection, VIN: values })}
            touched={!claimSection?.VIN && missingRequired}
            error={
              claimSection?.VIN?.length < 1 || claimSection?.VIN?.length > 20
                ? 'VIN must be between 1 and 20 characters'
                : null
            }
          />
          <Textbox
            fieldLabel="License Plate Number"
            currentValue={claimSection.licensePlateNumber}
            onChange={values =>
              onChange({ ...claimSection, licensePlateNumber: values })
            }
            touched={!claimSection?.licensePlateNumber && missingRequired}
            error={
              claimSection?.licensePlateNumber?.length < 1 ||
              claimSection?.licensePlateNumber?.length > 10
                ? 'Liscense Plate Number must be between 1 and 10 characters'
                : null
            }
          />
          <Dropdown
            searchable
            fieldLabel="Was Vehicle Stopped"
            options={YES_NO_UNKNOWN}
            currentValue={claimSection?.wasVehicleStopped}
            onChange={value =>
              onChange({ ...claimSection, wasVehicleStopped: value })
            }
            isRequired
            touched={missingRequired && !claimSection?.wasVehicleStopped}
          />
          <Dropdown
            searchable
            fieldLabel="Point of Impact"
            options={POINT_OF_IMPACT_CODE}
            currentValue={claimSection?.pointOfImpactCode}
            onChange={value =>
              onChange({ ...claimSection, pointOfImpactCode: value })
            }
            isRequired
            touched={missingRequired && !claimSection?.pointOfImpactCode}
          />
          <Dropdown
            searchable
            fieldLabel="Estimated Damage Severity"
            options={ESTIMATED_DAMAGE_SEVERITY_CODE}
            currentValue={claimSection?.estimatedDamageSeverityCode}
            onChange={value =>
              onChange({ ...claimSection, estimatedDamageSeverityCode: value })
            }
            isRequired
            touched={
              missingRequired && !claimSection?.estimatedDamageSeverityCode
            }
          />
        </TwoColumn>
      ) : (
        <TwoColumn className={styles.claimCard}>
          <div className={styles.claimLabel}>Vehicle Ownership</div>
          <div>
            {getDropdownAnswer(
              VEHICLE_OWNERSHIP_TYPE_CODE,
              claimSection?.vehicleOwnershipTypeCode
            )}
          </div>
          <div className={styles.claimLabel}>Type of Vehicle</div>
          <div>
            {getDropdownAnswer(TYPE_OF_VEHICLE, claimSection?.typeOfVehicle)}
          </div>
          <div className={styles.claimLabel}>Year</div>
          <div>{claimSection?.year}</div>
          <div className={styles.claimLabel}>Make</div>
          <div>{claimSection?.make}</div>
          <div className={styles.claimLabel}>Model</div>
          <div>{claimSection?.model}</div>
          <div className={styles.claimLabel}>VIN</div>
          <div>{claimSection?.VIN}</div>
          <div className={styles.claimLabel}>License Plate Number</div>
          <div>{claimSection?.licensePlateNumber}</div>
          <div className={styles.claimLabel}>Was Vehicle Stopped</div>
          <div>
            {getDropdownAnswer(YES_NO_UNKNOWN, claimSection?.wasVehicleStopped)}
          </div>
          <div className={styles.claimLabel}>Point of Impact</div>
          <div>
            {getDropdownAnswer(
              POINT_OF_IMPACT_CODE,
              claimSection?.pointOfImpactCode
            )}
          </div>
          <div className={styles.claimLabel}>Estimated Damage Severity</div>
          <div>
            {getDropdownAnswer(
              ESTIMATED_DAMAGE_SEVERITY_CODE,
              claimSection?.estimatedDamageSeverityCode
            )}
          </div>
        </TwoColumn>
      )}
    </>
  );
}

function DriverCard({ claimSection, onChange, editing, missingRequired }) {
  return (
    <>
      {editing ? (
        <TwoColumn>
          <EmployeeDropdown
            fieldLabel="Driver Name"
            currentValue={claimSection?.employeeId}
            onChange={value => onChange({ ...claimSection, employeeId: value })}
            name="employeeInformation"
            isRequired
            disabled
            touched={missingRequired && !claimSection?.employeeId}
          />
          {claimSection.employeeId === '606b49379c029d0073ab0458' ? (
            <div>
              <Textbox
                fieldLabel="Employee First Name"
                currentValue={claimSection.employeeFirstName}
                onChange={values =>
                  onChange({ ...claimSection, employeeFirstName: values })
                }
              />
              <Textbox
                fieldLabel="Employee Last Name"
                currentValue={claimSection.employeeLastName}
                onChange={values =>
                  onChange({ ...claimSection, employeeLastName: values })
                }
              />
            </div>
          ) : null}
          <Dropdown
            options={[
              { label: 'Male', value: 1 },
              { label: 'Female', value: 2 }
            ]}
            currentValue={claimSection?.employeeGender}
            onChange={value =>
              onChange({ ...claimSection, employeeGender: value })
            }
            fieldLabel="Gender"
            isRequired
            touched={missingRequired && !claimSection?.employeeGender}
          />
          <Dropdown
            fieldLabel="Suffered Bodily Injury"
            options={YES_NO_UNKNOWN.filter(o => o.label !== 'Unknown')}
            currentValue={claimSection?.sufferedBodilyInjuryIndicator}
            onChange={value =>
              onChange({
                ...claimSection,
                sufferedBodilyInjuryIndicator: value
              })
            }
            isRequired
            touched={
              missingRequired && !claimSection?.sufferedBodilyInjuryIndicator
            }
          />
          <Dropdown
            fieldLabel="Extent of Injury"
            options={EXTENT_OF_INJURY_CODE}
            currentValue={claimSection?.extentOfInjuryCode}
            onChange={value =>
              onChange({
                ...claimSection,
                extentOfInjuryCode: value
              })
            }
            disabled={claimSection?.sufferedBodilyInjuryIndicator !== 'yes'}
          />
          <Textbox
            displayTooltip
            tooltip="When Extent of Injury is Other, use this to describe the injury."
            fieldLabel="Extent of Injury Description"
            currentValue={claimSection.extentOfInjuryOtherText}
            onChange={values =>
              onChange({ ...claimSection, extentOfInjuryOtherText: values })
            }
            touched={!claimSection?.extentOfInjuryOtherText?.length > 0}
            error={
              claimSection?.extentOfInjuryOtherText?.length < 2 ||
              claimSection?.extentOfInjuryOtherText?.length > 30
                ? 'Extent of Injury Description must be between 2 and 30 characters'
                : null
            }
            disabled={claimSection?.extentOfInjuryCode !== 'Other'}
          />
          <Dropdown
            fieldLabel="Was Personal Property Damaged"
            options={YES_NO_UNKNOWN.filter(o => o.label !== 'Unknown')}
            currentValue={claimSection?.personalPropertyDamagedIndicator}
            onChange={value =>
              onChange({
                ...claimSection,
                personalPropertyDamagedIndicator: value
              })
            }
            isRequired
            touched={
              missingRequired && !claimSection?.personalPropertyDamagedIndicator
            }
          />
          <Textbox
            displayTooltip
            tooltip="What was the personal property damaged (i.e. loose items in the vehicle, GPS, laptop, cell phone, bicycle, etc)?"
            fieldLabel="Personal Property Description"
            currentValue={claimSection.personalPropertyDescription}
            onChange={values =>
              onChange({ ...claimSection, personalPropertyDescription: values })
            }
            touched={claimSection?.personalPropertyDescription?.length > 0}
            error={
              claimSection?.personalPropertyDescription?.length < 2 ||
              claimSection?.personalPropertyDescription?.length > 50
                ? 'What was the personal property damaged  must be between 2 and 50 characters'
                : null
            }
            disabled={claimSection?.personalPropertyDamagedIndicator !== 'yes'}
          />
          <Textbox
            displayTooltip
            tooltip="Details about the damage the personal property sustained due to the incident (i.e. severity, estimated cost to repair or replace, etc)."
            fieldLabel="Personal Property Damage Description"
            currentValue={claimSection.personalPropertyDamageDescription}
            onChange={values =>
              onChange({
                ...claimSection,
                personalPropertyDamageDescription: values
              })
            }
            touched={
              claimSection?.personalPropertyDamageDescription?.length > 0
            }
            error={
              claimSection?.personalPropertyDamageDescription?.length < 2 ||
              claimSection?.personalPropertyDamageDescription?.length > 1000
                ? 'Personal Property Damage Description must be between 2 and 1000 characters'
                : null
            }
            type="textarea"
            disabled={claimSection?.personalPropertyDamagedIndicator !== 'yes'}
          />
          <Dropdown
            fieldLabel="Was Driver or Custodian Present"
            options={YES_NO_UNKNOWN}
            currentValue={claimSection?.wasDriverOrCustodianPresent}
            onChange={value =>
              onChange({
                ...claimSection,
                wasDriverOrCustodianPresent: value
              })
            }
            isRequired
            touched={
              missingRequired && !claimSection?.wasDriverOrCustodianPresent
            }
          />
          <Dropdown
            fieldLabel="Relationship to Vehicle Owner"
            options={RELATIONSHIP_VEHICLE_OWNER}
            currentValue={claimSection?.relationshipToVehicleOwner}
            onChange={value =>
              onChange({
                ...claimSection,
                relationshipToVehicleOwner: value
              })
            }
            isRequired
            touched={
              missingRequired && !claimSection?.relationshipToVehicleOwner
            }
          />
        </TwoColumn>
      ) : (
        <TwoColumn className={styles.claimCard}>
          <div className={styles.claimLabel}>Driver Name</div>
          <div>
            {`${claimSection?.employeeFirstName} ${claimSection?.employeeLastName}`}
          </div>
          <div className={styles.claimLabel}>Gender</div>
          <div>
            {getDropdownAnswer(GENDER_DROPDOWN, claimSection?.employeeGender)}
          </div>
          <div className={styles.claimLabel}>Suffered Bodily Injury</div>
          <div>
            {getDropdownAnswer(
              YES_NO_UNKNOWN,
              claimSection?.sufferedBodilyInjuryIndicator
            )}
          </div>
          {claimSection?.sufferedBodilyInjuryIndicator === 'yes' ? (
            <>
              <div className={styles.claimLabel}>Extent of Injury</div>
              <div>
                {getDropdownAnswer(
                  EXTENT_OF_INJURY_CODE,
                  claimSection?.extentOfInjuryCode
                )}
              </div>
            </>
          ) : null}
          {claimSection?.extentOfInjuryCode === 'other' ? (
            <>
              <div className={styles.claimLabel}>
                Extent of Injury Description
              </div>
              <div>{claimSection?.extentOfInjuryOtherText}</div>
            </>
          ) : null}
          <div className={styles.claimLabel}>Was Personal Property Damaged</div>
          <div>
            {getDropdownAnswer(
              YES_NO_UNKNOWN,
              claimSection?.personalPropertyDamagedIndicator
            )}
          </div>
          {claimSection?.personalPropertyDamagedIndicator === 'yes' ? (
            <>
              <div className={styles.claimLabel}>
                Personal Property Description
              </div>
              <div>{claimSection?.personalPropertyDescription}</div>
            </>
          ) : null}
          {claimSection?.personalPropertyDamagedIndicator === 'yes' ? (
            <>
              <div className={styles.claimLabel}>
                Personal Property Damage Description
              </div>
              <div>{claimSection?.personalPropertyDamageDescription}</div>
            </>
          ) : null}
          <div className={styles.claimLabel}>
            Was Driver or Custodian Present
          </div>
          <div>
            {getDropdownAnswer(
              YES_NO_UNKNOWN,
              claimSection?.wasDriverOrCustodianPresent
            )}
          </div>
          <div className={styles.claimLabel}>Relationship to Vehicle Owner</div>
          <div>
            {getDropdownAnswer(
              RELATIONSHIP_VEHICLE_OWNER,
              claimSection?.relationshipToVehicleOwner
            )}
          </div>
        </TwoColumn>
      )}
    </>
  );
}

function DamageCard({ claimSection, onChange, editing, missingRequired }) {
  return (
    <>
      {editing ? (
        <>
          <TwoColumn>
            <Dropdown
              fieldLabel="Where the Loss Occurred"
              options={PROPERTY_DAMAGE_LOSS_OCCURRED}
              currentValue={claimSection?.lossOccured}
              onChange={value =>
                onChange({
                  ...claimSection,
                  lossOccured: value
                })
              }
              isRequired
              touched={missingRequired && !claimSection?.lossOccured}
            />
            <Dropdown
              fieldLabel="Type of Loss"
              options={PROPERTY_DAMAGE_TYPE_OF_LOSS}
              currentValue={claimSection?.lossType}
              onChange={value =>
                onChange({
                  ...claimSection,
                  lossType: value
                })
              }
              isRequired
              touched={missingRequired && !claimSection?.lossType}
            />
            <Dropdown
              fieldLabel="Estimated Damage Amount"
              options={PROPERTY_DAMAGE_ESTIMATE}
              currentValue={claimSection?.damageEstimate}
              onChange={value =>
                onChange({
                  ...claimSection,
                  damageEstimate: value
                })
              }
              isRequired
              touched={missingRequired && !claimSection?.damageEstimate}
            />
            <Dropdown
              fieldLabel="Event Causing Loss or Damage"
              options={PROPERTY_DAMAGE_EVENT}
              currentValue={claimSection?.lossDamageEvent}
              onChange={value =>
                onChange({
                  ...claimSection,
                  lossDamageEvent: value
                })
              }
              isRequired
              touched={missingRequired && !claimSection?.lossDamageEvent}
            />
            <Dropdown
              fieldLabel="Type of Property Damaged"
              options={PROPERTY_DAMAGE_PROPERTY_TYPE}
              currentValue={claimSection?.propertyType}
              onChange={value =>
                onChange({
                  ...claimSection,
                  propertyType: value
                })
              }
              isRequired
              touched={missingRequired && !claimSection?.propertyType}
            />
          </TwoColumn>
          {/* <EmployeeDropdown
            fieldLabel="Involved Employee"
            currentValue={claimSection?.employeeId}
            onChange={value => onChange({ ...claimSection, employeeId: value })}
            name="employeeInformation"
            disabled
          /> */}
        </>
      ) : (
        <TwoColumn className={styles.claimCard}>
          <div className={styles.claimLabel}>Where the Loss Occurred</div>
          <div>
            {getDropdownAnswer(
              PROPERTY_DAMAGE_LOSS_OCCURRED,
              claimSection.lossOccured
            )}
          </div>
          <div className={styles.claimLabel}>Type of Loss</div>
          <div>
            {getDropdownAnswer(
              PROPERTY_DAMAGE_TYPE_OF_LOSS,
              claimSection.lossType
            )}
          </div>
          <div className={styles.claimLabel}>Estimated Damage Amount</div>
          <div>
            {getDropdownAnswer(
              PROPERTY_DAMAGE_ESTIMATE,
              claimSection.damageEstimate
            )}
          </div>
          <div className={styles.claimLabel}>Event Causing Loss or Damage</div>
          <div>
            {getDropdownAnswer(
              PROPERTY_DAMAGE_EVENT,
              claimSection.lossDamageEvent
            )}
          </div>
          <div className={styles.claimLabel}>Type of Property Damage</div>
          <div>
            {getDropdownAnswer(
              PROPERTY_DAMAGE_PROPERTY_TYPE,
              claimSection.propertyType
            )}
          </div>
        </TwoColumn>
      )}
    </>
  );
}

function ThirdPartyVehicleCard({
  claimSection,
  onChange,
  editing,
  missingRequired
}) {
  const handleVehicleChange = (vehicleNumber, value) => {
    let thirdPartyVehicles = claimSection?.thirdPartyVehicles;
    thirdPartyVehicles[vehicleNumber] = {
      ...thirdPartyVehicles[vehicleNumber],
      ...value
    };
    onChange({ ...claimSection, thirdPartyVehicles });
  };

  return (
    <>
      {editing ? (
        <>
          <TwoColumn>
            <Textbox
              fieldLabel="How many third party vehicles are involved?"
              type="number"
              currentValue={claimSection?.numberOfThirdPartyVehiclesInvolved}
              onChange={value =>
                onChange({
                  ...claimSection,
                  numberOfThirdPartyVehiclesInvolved: value,
                  thirdPartyVehicles: value
                    ? Array(parseInt(value)).fill({
                        otherInsurancePolicyNumber: '',
                        firstName: '',
                        lastName: '',
                        licensePlateNumber: '',
                        phoneNumber: '',
                        sufferedBodilyInjuryIndicator: null,
                        personalPropertyDamagedIndicator: null
                      })
                    : []
                })
              }
              isRequired
              touched={
                missingRequired &&
                !claimSection?.numberOfThirdPartyVehiclesInvolved
              }
            />
          </TwoColumn>
          {claimSection?.thirdPartyVehicles?.map((vehicle, i) => (
            <div key={i}>
              <h3
                className={styles.thirdPartyHeader}
              >{`Third Party Vehicle #${i + 1}`}</h3>
              <TwoColumn>
                <Textbox
                  fieldLabel="Insurance Policy Number"
                  currentValue={vehicle?.otherInsurancePolicyNumber}
                  onChange={value =>
                    handleVehicleChange(i, {
                      otherInsurancePolicyNumber: value
                    })
                  }
                  isRequired
                  touched={
                    missingRequired && !vehicle?.otherInsurancePolicyNumber
                  }
                />
                <Textbox
                  fieldLabel="Driver First Name"
                  currentValue={vehicle?.firstName}
                  onChange={value =>
                    handleVehicleChange(i, {
                      firstName: value
                    })
                  }
                  isRequired
                  touched={missingRequired && !vehicle?.firstName}
                />
                <Textbox
                  fieldLabel="Driver Last Name"
                  currentValue={vehicle?.lastName}
                  onChange={value =>
                    handleVehicleChange(i, {
                      lastName: value
                    })
                  }
                  isRequired
                  touched={missingRequired && !vehicle?.lastName}
                />
                <Textbox
                  currentValue={vehicle?.phoneNumber}
                  fieldLabel="Phone Number"
                  onChange={value =>
                    handleVehicleChange(i, {
                      phoneNumber: normalizeLibertyMutualPhone(value)
                    })
                  }
                  placeholder="XXX-XXXXXXX"
                  isRequired
                  touched={missingRequired && !vehicle?.phoneNumber}
                />
                <Textbox
                  fieldLabel="License Plate Number"
                  currentValue={vehicle?.licensePlateNumber}
                  onChange={value =>
                    handleVehicleChange(i, {
                      licensePlateNumber: value
                    })
                  }
                />
                <Dropdown
                  fieldLabel="Suffered Bodily Injury"
                  options={YES_NO_UNKNOWN.filter(o => o.label !== 'Unknown')}
                  currentValue={vehicle?.sufferedBodilyInjuryIndicator}
                  onChange={value =>
                    handleVehicleChange(i, {
                      sufferedBodilyInjuryIndicator: value
                    })
                  }
                  isRequired
                  touched={
                    missingRequired && !vehicle?.sufferedBodilyInjuryIndicator
                  }
                />
                <Dropdown
                  fieldLabel="Personal Property Damaged"
                  options={YES_NO_UNKNOWN.filter(o => o.label !== 'Unknown')}
                  currentValue={vehicle?.personalPropertyDamagedIndicator}
                  onChange={value =>
                    handleVehicleChange(i, {
                      personalPropertyDamagedIndicator: value
                    })
                  }
                  isRequired
                  touched={
                    missingRequired && !vehicle.personalPropertyDamagedIndicator
                  }
                />
              </TwoColumn>
              <Textbox
                type="textarea"
                fieldLabel="Involved Party Additional Remarks"
                currentValue={vehicle.involvedPartyAdditionalRemarks}
                onChange={values =>
                  handleVehicleChange(i, {
                    involvedPartyAdditionalRemarks: values
                  })
                }
                touched={vehicle.involvedPartyAdditionalRemarks?.length > 0}
                error={
                  vehicle?.involvedPartyAdditionalRemarks?.length > 1000 ||
                  vehicle?.involvedPartyAdditionalRemarks?.length < 2
                    ? 'Involved Party Additional Remarks must be between 2 and 1000 Characters'
                    : null
                }
              />
            </div>
          ))}
        </>
      ) : (
        <>
          <TwoColumn className={styles.claimCard}>
            <div className={styles.claimLabel}>
              How many third party vehicles are involved?
            </div>
          </TwoColumn>
          <div>{claimSection.numberOfThirdPartyVehiclesInvolved}</div>
          {claimSection?.thirdPartyVehicles?.map((v, i) => (
            <div key={i}>
              <h3
                className={styles.thirdPartyHeader}
              >{`Third Party Vehicle #${i + 1}`}</h3>
              <TwoColumn className={styles.claimCard}>
                <div className={styles.claimLabel}>Insurance Policy Number</div>
                <div>{v.otherInsurancePolicyNumber}</div>
                <div className={styles.claimLabel}>Driver First Name</div>
                <div>{v.firstName}</div>
                <div className={styles.claimLabel}>Driver Last Name</div>
                <div>{v.lastName}</div>
                <div className={styles.claimLabel}>Phone Number</div>
                <div>{v.phoneNumber}</div>
                <div className={styles.claimLabel}>License Plate Number</div>
                <div>{v.licensePlateNumber}</div>
                <div className={styles.claimLabel}>Suffered Bodily Injury</div>
                <div>
                  {getDropdownAnswer(
                    YES_NO_UNKNOWN,
                    v?.sufferedBodilyInjuryIndicator
                  )}
                </div>
                <div className={styles.claimLabel}>
                  Was Personal Property Damaged
                </div>
                <div>
                  {getDropdownAnswer(
                    YES_NO_UNKNOWN,
                    v?.personalPropertyDamagedIndicator
                  )}
                </div>
                <div className={styles.claimLabel}>Additional Remarks</div>
                <div>{claimSection?.involvedPartyAdditionalRemarks}</div>
              </TwoColumn>
            </div>
          ))}
        </>
      )}
    </>
  );
}

function ThirdPartyPedestrianCard({
  claimSection,
  onChange,
  editing,
  missingRequired
}) {
  const handlePedestrianChange = (index, value) => {
    let thirdPartyPedestrians = claimSection?.thirdPartyPedestrians;
    thirdPartyPedestrians[index] = {
      ...thirdPartyPedestrians[index],
      ...value
    };
    onChange({ ...claimSection, thirdPartyPedestrians });
  };
  return (
    <>
      {editing ? (
        <>
          <TwoColumn>
            <Textbox
              fieldLabel="How many third party pedestrians/bicyclists are involved?"
              type="number"
              currentValue={claimSection?.numberOfThirdPartyPedestriansInvolved}
              onChange={value =>
                onChange({
                  ...claimSection,
                  numberOfThirdPartyPedestriansInvolved: value,
                  thirdPartyPedestrians: value
                    ? Array(parseInt(value)).fill({
                        bicyclistOrPedestrianRoleCode: null,
                        firstName: '',
                        lastName: '',
                        phoneNumber: '',
                        sufferedBodilyInjuryIndicator: null,
                        personalPropertyDamagedIndicator: null
                      })
                    : []
                })
              }
              isRequired
              touched={
                missingRequired &&
                !claimSection?.numberOfThirdPartyPedestriansInvolved
              }
            />
          </TwoColumn>
          {claimSection?.thirdPartyPedestrians?.map((pedestrian, i) => (
            <div key={i}>
              <h3
                className={styles.thirdPartyHeader}
              >{`Third Party Vehicle #${i + 1}`}</h3>
              <TwoColumn>
                <Dropdown
                  fieldLabel="Was Third Party a Bicyclist or Pedestrian?"
                  options={AUTO_BICYCLIST_PEDESTRIAN_ROLE_CODE}
                  currentValue={pedestrian?.bicyclistOrPedestrianRoleCode}
                  onChange={value =>
                    handlePedestrianChange(i, {
                      bicyclistOrPedestrianRoleCode: value
                    })
                  }
                  isRequired
                  touched={
                    missingRequired && !pedestrian.bicyclistOrPedestrianRoleCode
                  }
                />
                <Textbox
                  fieldLabel="Pedestrian First Name"
                  currentValue={pedestrian?.firstName}
                  onChange={value =>
                    handlePedestrianChange(i, {
                      firstName: value
                    })
                  }
                  isRequired
                  touched={missingRequired && !pedestrian.firstName}
                />
                <Textbox
                  fieldLabel="Pedestrian Last Name"
                  currentValue={pedestrian?.lastName}
                  onChange={value =>
                    handlePedestrianChange(i, {
                      lastName: value
                    })
                  }
                  isRequired
                  touched={missingRequired && !pedestrian?.lastName}
                />
                <Textbox
                  currentValue={pedestrian?.phoneNumber}
                  fieldLabel="Phone Number"
                  onChange={value =>
                    handlePedestrianChange(i, {
                      phoneNumber: normalizeLibertyMutualPhone(value)
                    })
                  }
                  placeholder="XXX-XXXXXXX"
                  isRequired
                  touched={missingRequired && !pedestrian?.phoneNumber}
                />
                <Dropdown
                  fieldLabel="Suffered Bodily Injury"
                  options={YES_NO_UNKNOWN.filter(o => o.label !== 'Unknown')}
                  currentValue={pedestrian?.sufferedBodilyInjuryIndicator}
                  onChange={value =>
                    handlePedestrianChange(i, {
                      sufferedBodilyInjuryIndicator: value
                    })
                  }
                  isRequired
                  touched={
                    missingRequired &&
                    !pedestrian?.sufferedBodilyInjuryIndicator
                  }
                />
                <Dropdown
                  fieldLabel="Personal Property Damaged"
                  options={YES_NO_UNKNOWN.filter(o => o.label !== 'Unknown')}
                  currentValue={pedestrian?.personalPropertyDamagedIndicator}
                  onChange={value =>
                    handlePedestrianChange(i, {
                      personalPropertyDamagedIndicator: value
                    })
                  }
                  touched={
                    missingRequired &&
                    !pedestrian?.personalPropertyDamagedIndicator
                  }
                  isRequired
                />
              </TwoColumn>
              <Textbox
                type="textarea"
                fieldLabel="Involved Party Additional Remarks"
                currentValue={pedestrian.involvedPartyAdditionalRemarks}
                onChange={values =>
                  handlePedestrianChange(i, {
                    involvedPartyAdditionalRemarks: values
                  })
                }
                touched={pedestrian?.involvedPartyAdditionalRemarks?.length > 0}
                error={
                  pedestrian?.involvedPartyAdditionalRemarks?.length > 1000 ||
                  pedestrian?.involvedPartyAdditionalRemarks?.length < 2
                    ? 'Involved Party Additional Remarks must be between 2 and 1000 Characters'
                    : null
                }
              />
            </div>
          ))}
        </>
      ) : (
        <>
          <TwoColumn className={styles.claimCard}>
            <div className={styles.claimLabel}>
              How many third party pedestrians/bicyclists are involved?
            </div>
          </TwoColumn>
          <div>{claimSection.numberOfThirdPartyPedestriansInvolved}</div>
          {claimSection?.thirdPartyPedestrians?.map((v, i) => (
            <div key={i}>
              <h3
                className={styles.thirdPartyHeader}
              >{`Third Party Pedestrian/Bicyclist #${i + 1}`}</h3>
              <TwoColumn className={styles.claimCard}>
                <div className={styles.claimLabel}>
                  Was Third Party a Bicyclist or Pedestrian?
                </div>
                <div>
                  {getDropdownAnswer(
                    AUTO_BICYCLIST_PEDESTRIAN_ROLE_CODE,
                    v?.bicyclistOrPedestrianRoleCode
                  )}
                </div>
                <div className={styles.claimLabel}>Pedestrian Name</div>
                <div>{`${v.firstName} ${v.lastName}`}</div>
                <div className={styles.claimLabel}>Phone Number</div>
                <div>{v.phoneNumber}</div>
                <div className={styles.claimLabel}>Suffered Bodily Injury</div>
                <div>
                  {getDropdownAnswer(
                    YES_NO_UNKNOWN,
                    v?.sufferedBodilyInjuryIndicator
                  )}
                </div>
                <div className={styles.claimLabel}>
                  Was Personal Property Damaged
                </div>
                <div>
                  {getDropdownAnswer(
                    YES_NO_UNKNOWN,
                    v?.personalPropertyDamagedIndicator
                  )}
                </div>
                <div className={styles.claimLabel}>Additional Remarks</div>
                <div>{claimSection?.involvedPartyAdditionalRemarks}</div>
              </TwoColumn>
            </div>
          ))}
        </>
      )}
    </>
  );
}

function ThirdPartyBuildingCard({
  claimSection,
  onChange,
  editing,
  missingRequired
}) {
  const handleBuildingChange = (vehicleNumber, value) => {
    let thirdPartyBuildings = claimSection?.thirdPartyBuildings;
    thirdPartyBuildings[vehicleNumber] = {
      ...thirdPartyBuildings[vehicleNumber],
      ...value
    };
    onChange({ ...claimSection, thirdPartyBuildings });
  };
  return (
    <>
      {editing ? (
        <>
          <TwoColumn>
            <Textbox
              fieldLabel="How many third party other properties are involved?"
              type="number"
              currentValue={claimSection?.numberOfThirdPartyBuildingsInvolved}
              onChange={value =>
                onChange({
                  ...claimSection,
                  numberOfThirdPartyBuildingsInvolved: value,
                  thirdPartyBuildings: value
                    ? Array(parseInt(value)).fill({
                        otherPropertyDescription: '',
                        firstName: '',
                        lastName: '',
                        phoneNumber: '',
                        otherDamagedPropertiesRoleCode: null
                      })
                    : []
                })
              }
              isRequired
              touched={
                missingRequired &&
                !claimSection?.numberOfThirdPartyBuildingsInvolved
              }
            />
          </TwoColumn>
          {claimSection?.thirdPartyBuildings?.map((building, i) => (
            <div key={i}>
              <h3
                className={styles.thirdPartyHeader}
              >{`Third Party Other Property #${i + 1}`}</h3>
              <TwoColumn>
                <Dropdown
                  fieldLabel="What Type of Party was Involved?"
                  options={AUTO_OTHER_PROPERTY_DAMAGE_ROLE_CODE}
                  currentValue={building?.otherDamagedPropertiesRoleCode}
                  onChange={value =>
                    handleBuildingChange(i, {
                      otherDamagedPropertiesRoleCode: value
                    })
                  }
                  isRequired
                  touched={
                    missingRequired && !building?.otherDamagedPropertiesRoleCode
                  }
                />
                <Textbox
                  tooltip="A phrase to classify the type of property that was damaged due to the incident (i.e. building, house, bridge, utility pole, guard rail, mailbox, fence, etc.)"
                  displayTooltip
                  fieldLabel="Other Property Description"
                  currentValue={building?.otherPropertyDescription}
                  onChange={value =>
                    handleBuildingChange(i, {
                      otherPropertyDescription: value
                    })
                  }
                  isRequired
                  touched={
                    missingRequired && !building?.otherPropertyDescription
                  }
                  error={
                    building?.otherPropertyDescription?.length > 51 ||
                    building?.otherPropertyDescription?.length < 2
                      ? 'Other Property Description must be between 2 and 50 characters'
                      : null
                  }
                />
                <Textbox
                  fieldLabel="Property Owner First Name"
                  currentValue={building?.firstName}
                  onChange={value =>
                    handleBuildingChange(i, {
                      firstName: value
                    })
                  }
                  isRequired
                  touched={missingRequired && !building?.firstName}
                />
                <Textbox
                  fieldLabel="Property Owner Last Name"
                  currentValue={building?.lastName}
                  onChange={value =>
                    handleBuildingChange(i, {
                      lastName: value
                    })
                  }
                  isRequired
                  touched={missingRequired && !building?.lastName}
                />
                <Textbox
                  currentValue={building?.phoneNumber}
                  fieldLabel="Phone Number"
                  onChange={value =>
                    handleBuildingChange(i, {
                      phoneNumber: normalizeLibertyMutualPhone(value)
                    })
                  }
                  placeholder="XXX-XXXXXXX"
                  isRequired
                  touched={missingRequired && !building?.phoneNumber}
                />
              </TwoColumn>
              <Textbox
                type="textarea"
                fieldLabel="Involved Party Additional Remarks"
                currentValue={building.involvedPartyAdditionalRemarks}
                onChange={values =>
                  handleBuildingChange(i, {
                    involvedPartyAdditionalRemarks: values
                  })
                }
                touched={building.involvedPartyAdditionalRemarks?.length > 0}
                error={
                  building?.involvedPartyAdditionalRemarks?.length > 1000 ||
                  building?.involvedPartyAdditionalRemarks?.length < 2
                    ? 'Involved Party Additional Remarks must be between 2 and 1000 Characters'
                    : null
                }
              />
            </div>
          ))}
        </>
      ) : (
        <>
          <TwoColumn className={styles.claimCard}>
            <div className={styles.claimLabel}>
              How many third party other properties are involved?
            </div>
          </TwoColumn>
          <div>{claimSection.numberOfThirdPartyBuildingsInvolved}</div>
          {claimSection?.thirdPartyBuildings?.map((v, i) => (
            <div key={i}>
              <h3
                className={styles.thirdPartyHeader}
              >{`Third Party Pedestrian/Bicyclist #${i + 1}`}</h3>
              <TwoColumn className={styles.claimCard}>
                <div className={styles.claimLabel}>
                  What Type of Party was Involved?
                </div>
                <div>
                  {getDropdownAnswer(
                    AUTO_OTHER_PROPERTY_DAMAGE_ROLE_CODE,
                    v?.otherDamagedPropertiesRoleCode
                  )}
                </div>
                <div className={styles.claimLabel}>
                  Other Property Description
                </div>
                <div>{v.otherPropertyDescription}</div>
                <div className={styles.claimLabel}>Property Owner Name</div>
                <div>{`${v.firstName} ${v.lastName}`}</div>
                <div className={styles.claimLabel}>Phone Number</div>
                <div>{v.phoneNumber}</div>
                <div className={styles.claimLabel}>Additional Remarks</div>
                <div>{claimSection?.involvedPartyAdditionalRemarks}</div>
              </TwoColumn>
            </div>
          ))}
        </>
      )}
    </>
  );
}
