import React from 'react';
import './svg.css';

const employeeUsers = props => (
  <svg
    width={props.width ?? 30}
    height={props.height ?? 25}
    viewBox="0 0 80 80"
    x={props.x || '0px'}
    y={props.y || '0px'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1224_131802)">
      <path d="M51.1915 54.5201C51.1915 54.5201 44.9661 52.6597 44.9661 51.5298C44.9661 49.6035 44.8441 47.0509 45.1882 46.7215C45.2295 46.6821 45.2679 46.6635 45.315 46.6318C49.2136 44.0046 52.8559 38.6619 52.8559 32.4763C52.8559 23.6953 46.9916 15.7395 40.0179 15.7395H39.3426C32.3688 15.7395 26.5046 23.7014 26.5046 32.482C26.5046 38.669 30.146 44.0154 34.0455 46.6433C34.0926 46.6747 34.131 46.7054 34.1723 46.7444C34.5154 47.0738 34.3944 49.6038 34.3944 51.53C34.3944 52.9941 28.169 54.5203 28.169 54.5203C21.33 56.7522 15.5859 60.8577 15.5859 67.8081C15.5859 68.462 16.116 69.696 16.7709 69.696H62.5915C63.2455 69.696 63.7765 68.462 63.7765 67.8081C63.7762 60.8567 58.1673 56.8475 51.1918 54.5203L51.1915 54.5201Z" />
      <path d="M24.5765 52.6639C27.0789 52.0465 28.5903 51.0936 28.9914 50.5705C28.9924 50.2175 28.9962 49.8458 29.0014 49.4732C29.0123 48.545 29.0299 47.1839 28.9434 46.6855C25.4801 44.3018 22.3839 39.2144 22.3839 32.5701C22.3839 26.0233 24.7301 19.1447 29.2177 16.4201C27.1931 12.6629 24.7957 11 21.0965 11H20.5133C14.4915 11 9.42807 17.8742 9.42807 25.4571C9.42807 30.7994 12.5726 35.4164 15.9396 37.6854C15.98 37.7129 16.0142 37.7385 16.0488 37.7728C16.3458 38.057 16.2405 40.242 16.2405 41.9058C16.2405 43.1705 10.8656 44.488 10.8656 44.488C4.95998 46.4153 0 49.9602 0 55.9616C0 56.5268 0.45837 57.5918 1.02264 57.5918H15.733C17.8289 55.5644 20.7845 53.9025 24.5762 52.6647L24.5765 52.6639Z" />
      <path d="M69.1325 44.4874C69.1325 44.4874 63.7576 42.8806 63.7576 41.9053C63.7576 40.2424 63.6523 38.038 63.9493 37.7537C63.9848 37.7205 64.0181 37.7035 64.0585 37.676C67.4255 35.4066 70.57 30.7947 70.57 25.4526C70.57 17.8704 65.5059 11.0012 59.4848 11.0012H58.9016C55.1089 11.0012 52.633 12.7966 50.6263 16.7113C54.8789 19.5521 57.0059 26.2445 57.0059 32.5664C57.0059 39.2094 53.9055 44.2972 50.4444 46.6777C50.3609 47.1901 50.3766 48.546 50.3884 49.4733C50.3923 49.8145 50.3964 50.1557 50.3983 50.4803C50.9617 51.0094 52.6227 52.0093 54.7918 52.6572C58.6297 53.9375 61.5845 55.5885 63.6711 57.5904H78.9772C79.5425 57.5904 79.9998 56.5254 79.9998 55.9601C79.9989 49.96 75.1559 46.499 69.1326 44.4882L69.1325 44.4874Z" />
    </g>
    <defs>
      <clipPath id="clip0_1224_131802">
        <rect width="80" height="80" />
      </clipPath>
    </defs>
  </svg>
);

export default employeeUsers;
