import React from 'react';

const favorite = ({ width, height, x, y, ...props }) => (
  <svg
    width={width || '40px'}
    height={height || '40px'}
    x={x || '0px'}
    y={y || '0px'}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M12.54 1.35455C12.7128 0.949265 13.2872 0.949263 13.46 1.35455L16.3345 8.09928C16.5513 8.60809 17.0306 8.95628 17.5815 9.00529L24.8844 9.65489C25.3232 9.69392 25.5008 10.2403 25.1687 10.5298L19.6423 15.3479C19.2254 15.7113 19.0424 16.2747 19.166 16.8138L20.8049 23.96C20.9034 24.3894 20.4387 24.7271 20.0607 24.5007L13.7707 20.7337C13.2962 20.4496 12.7038 20.4496 12.2293 20.7337L5.93931 24.5007C5.56135 24.7271 5.09659 24.3894 5.19507 23.96L6.83398 16.8138C6.95762 16.2747 6.77456 15.7113 6.35767 15.3479L0.831326 10.5298C0.499251 10.2403 0.676772 9.69392 1.1156 9.65489L8.4185 9.00529C8.96941 8.95628 9.44866 8.60809 9.6655 8.09928L12.54 1.35455Z" />
  </svg>
);

export default favorite;
