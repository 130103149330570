import React from 'react';
import Modal from '.';

const ImportProblemsModal = ({ isOpen, onRequestClose, messages, type }) => {
  return (
    <Modal
      title={`Issues with ${type} Upload`}
      titleClassName="redHeader"
      isOpen={isOpen}
      className="modalMedium"
      onRequestClose={onRequestClose}
      showXButton
      hideButtons
    >
      <ul>
        {messages?.map((issue, index) => (
          <li key={index}>{issue}</li>
        ))}
      </ul>
    </Modal>
  );
};

export default ImportProblemsModal;
