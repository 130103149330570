import {
  take,
  fork,
  takeEvery,
  takeLatest,
  takeLeading
} from 'redux-saga/effects';
import * as apiV4 from '../api/v4';
import * as actionTypes from '../constants/actionTypes';
import {
  deleteAttachment,
  uploadDocument,
  updateAttachment
} from './attachment';
import {
  forgotPasswordReset,
  logInUser,
  updatePassword,
  updateUser,
  validateSecurityQuestions
} from './auth';
import {
  addCompany,
  addCompanyLocation,
  addGroupProject,
  deleteCompanyLocation,
  deleteGroupProject,
  setIncidentOwner,
  updateCompany,
  updateCompanyLocation,
  updateGroupProject
} from './company';
import {
  addDocumentFolder,
  addDocumentsToFolder,
  deleteDocumentFolder,
  fetchDocumentFolders,
  fetchDocumentsByFolder,
  updateDocumentFolder
} from './documents';
import {
  createIncident,
  deleteIncidentAttachment,
  fetchIncidentBasics,
  fetchIncidentById,
  saveIncidentBasics,
  setActiveIncident,
  updateIncidentBasics,
  updateReportComponent
} from './incidents';
import {
  createOshaForm,
  createOshaLog,
  fetchOshaLogs,
  printOshaFiles,
  printSelectedOsha301,
  updateOshaForm
} from './oshaLogs';
import {
  downloadPersonnelQRCode,
  setActiveEmployee,
  updateLocationEmployee
} from './personnel';
import {
  createCustomReportQuestions,
  deleteCustomReportQuestions,
  fetchCustomReportQuestions,
  updateCustomReportQuestions
} from './reports';
import { addSafetyWalk, fetchSafetyWalks, loadSafetyWalk } from './safetyWalks';
import {
  addUserTask,
  deleteUserTask,
  loadTask,
  setActiveTask,
  updateUserTask,
  sendReminder
} from './tasks';
import { loadUser, loadAllUsers, loadAllCompanyUsers } from './user';

const takeLeadingUserId = (patternOrChannel, saga, ...args) =>
  fork(function* () {
    const loadingIds = {};
    while (true) {
      const action = yield take(patternOrChannel);
      const userId = action.payload;

      if (!(loadingIds[userId] && loadingIds[userId].isRunning())) {
        loadingIds[userId] = yield fork(saga, ...args.concat(action));
      }
    }
  });

export default function* rootSaga() {
  yield takeLatest(
    actionTypes.SUBMIT_SECURITY_QUESTIONS,
    validateSecurityQuestions,
    apiV4
  );

  yield takeLatest(actionTypes.AUTH_SIGNIN, logInUser, apiV4);

  yield takeLatest(
    actionTypes.FORGOT_PASSWORD_REQUEST,
    forgotPasswordReset,
    apiV4
  );

  yield takeLatest(actionTypes.SET_INCIDENT_OWNER, setIncidentOwner, apiV4);

  yield takeLatest(actionTypes.ADD_COMPANY_REQUEST, addCompany, apiV4);

  yield takeLatest(
    actionTypes.ADD_COMPANY_LOCATION_REQUEST,
    addCompanyLocation,
    apiV4
  );

  yield takeLatest(
    actionTypes.UPDATE_COMPANY_LOCATION_REQUEST,
    updateCompanyLocation,
    apiV4
  );

  yield takeLatest(
    actionTypes.DELETE_COMPANY_LOCATION_REQUEST,
    deleteCompanyLocation,
    apiV4
  );

  yield takeLatest(actionTypes.LOAD_TASK, loadTask, apiV4);

  yield takeLatest(actionTypes.SEND_REMINDER_REQUEST, sendReminder, apiV4);

  yield takeLatest(actionTypes.SET_ACTIVE_TASK_REQUEST, setActiveTask, apiV4);

  yield takeLatest(actionTypes.ADD_USER_TASK_REQUEST, addUserTask, apiV4);

  yield takeLatest(actionTypes.UPDATE_USER_TASK_REQUEST, updateUserTask, apiV4);

  yield takeLatest(actionTypes.DELETE_USER_TASK_REQUEST, deleteUserTask, apiV4);

  yield takeLatest(
    actionTypes.UPDATE_LOCATION_EMPLOYEE_REQUEST,
    updateLocationEmployee,
    apiV4
  );

  yield takeLatest(actionTypes.UPDATE_COMPANY_REQUEST, updateCompany, apiV4);

  yield takeLatest(
    actionTypes.FETCH_INCIDENT_BASICS_REQUEST,
    fetchIncidentBasics,
    apiV4
  );

  yield takeLatest(
    actionTypes.SAVE_INCIDENT_BASICS_REQUEST,
    saveIncidentBasics,
    apiV4
  );

  yield takeLatest(
    actionTypes.UPDATE_INCIDENT_BASICS_REQUEST,
    updateIncidentBasics,
    apiV4
  );

  yield takeLatest(
    actionTypes.UPDATE_REPORT_COMPONENT_REQUEST,
    updateReportComponent,
    apiV4
  );

  yield takeLatest(actionTypes.CREATE_INCIDENT_REQUEST, createIncident, apiV4);

  yield takeLatest(actionTypes.UPDATE_USER_REQUEST, updateUser, apiV4);

  yield takeLatest(actionTypes.UPDATE_PASSWORD_REQUEST, updatePassword, apiV4);

  yield takeLatest(
    actionTypes.SET_ACTIVE_INCIDENT_REQUEST,
    setActiveIncident,
    apiV4
  );

  yield takeLatest(
    actionTypes.SET_ACTIVE_EMPLOYEE_REQUEST,
    setActiveEmployee,
    apiV4
  );

  yield takeEvery(
    actionTypes.ADD_UPLOADED_ATTACHMENTS_REQUEST,
    uploadDocument,
    apiV4
  );

  yield takeLatest(
    actionTypes.DELETE_INCIDENT_ATTACHMENT,
    deleteIncidentAttachment,
    apiV4
  );

  yield takeLatest(actionTypes.CREATE_OSHA_FORM_REQUEST, createOshaForm, apiV4);

  yield takeLatest(actionTypes.UPDATE_OSHA_FORM_REQUEST, updateOshaForm, apiV4);

  yield takeLatest(actionTypes.FETCH_OSHA_LOGS_REQUEST, fetchOshaLogs, apiV4);

  yield takeLatest(actionTypes.CREATE_OSHA_LOG_REQUEST, createOshaLog, apiV4);

  yield takeLatest(actionTypes.PRINT_OSHA_REQUEST, printOshaFiles, apiV4);

  yield takeLatest(
    actionTypes.PRINT_SELECTED_OSHA_REQUEST,
    printSelectedOsha301,
    apiV4
  );

  yield takeLatest(
    actionTypes.DELETE_ATTACHMENT_REQUEST,
    deleteAttachment,
    apiV4
  );

  yield takeLatest(
    actionTypes.DOWNLOAD_EMPLOYEE_QRCODE_REQUEST,
    downloadPersonnelQRCode,
    apiV4
  );

  yield takeLatest(actionTypes.ADD_PROJECT_REQUEST, addGroupProject, apiV4);

  yield takeLatest(
    actionTypes.UPDATE_PROJECT_REQUEST,
    updateGroupProject,
    apiV4
  );

  yield takeLatest(
    actionTypes.DELETE_PROJECT_REQUEST,
    deleteGroupProject,
    apiV4
  );

  yield takeLatest(
    actionTypes.ADD_DOCUMENT_FOLDER_REQUEST,
    addDocumentFolder,
    apiV4
  );

  yield takeLatest(
    actionTypes.FETCH_DOCUMENT_FOLDERS_REQUEST,
    fetchDocumentFolders,
    apiV4
  );

  yield takeLatest(
    actionTypes.UPDATE_DOCUMENT_FOLDER_REQUEST,
    updateDocumentFolder,
    apiV4
  );

  yield takeLatest(
    actionTypes.DELETE_DOCUMENT_FOLDER_REQUEST,
    deleteDocumentFolder,
    apiV4
  );

  yield takeLatest(
    actionTypes.SET_ACTIVE_DOCUMENT_FOLDER_REQUEST,
    fetchDocumentsByFolder,
    apiV4
  );

  yield takeLatest(
    actionTypes.ADD_DOCUMENTS_TO_FOLDER_REQUEST,
    addDocumentsToFolder,
    apiV4
  );

  yield takeLatest(
    actionTypes.FETCH_INCIDENT_BY_ID_REQUEST,
    fetchIncidentById,
    apiV4
  );

  yield takeLatest(
    actionTypes.FETCH_SAFETY_WALKS_REQUEST,
    fetchSafetyWalks,
    apiV4
  );
  yield takeLatest(actionTypes.ADD_SAFETY_WALK_REQUEST, addSafetyWalk, apiV4);
  yield takeLatest(actionTypes.LOAD_SAFETY_WALK, loadSafetyWalk, apiV4);

  yield takeLatest(
    actionTypes.FETCH_CUSTOM_REPORT_QUESTIONS_REQUEST,
    fetchCustomReportQuestions,
    apiV4
  );

  yield takeLatest(
    actionTypes.CREATE_CUSTOM_REPORT_QUESTIONS_REQUEST,
    createCustomReportQuestions,
    apiV4
  );

  yield takeLatest(
    actionTypes.UPDATE_CUSTOM_REPORT_QUESTIONS_REQUEST,
    updateCustomReportQuestions,
    apiV4
  );

  yield takeLatest(
    actionTypes.DELETE_CUSTOM_REPORT_QUESTIONS_REQUEST,
    deleteCustomReportQuestions,
    apiV4
  );

  yield takeLatest(
    actionTypes.UPDATE_ATTACHMENT_REQUEST,
    updateAttachment,
    apiV4
  );

  yield takeLeadingUserId(actionTypes.LOAD_USER_REQUEST, loadUser, apiV4);
  yield takeLeading(actionTypes.LOAD_ALL_USERS_REQUEST, loadAllUsers, apiV4);
  yield takeLeading(
    actionTypes.LOAD_ALL_COMPANY_USERS_REQUEST,
    loadAllCompanyUsers,
    apiV4
  );
}
