import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import history from '../../history';
import { fetchEmployeeBravos, fetchEmployeeObservations } from '../../api/v4';
import { getActiveCompany } from '../../selectors/company';
import { loadSafetyWalk } from '../../sagas/safetyWalks';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import HeaderAndFooter from '../HeaderAndFooter';
import Header from '../Header';
import List from '../List';

export default function EmployeeInfoList({ type, userId }) {
  const dispatch = useDispatch();
  const getSafetyWalk = payload => dispatch(loadSafetyWalk(payload));

  const activeCompany = useSelector(getActiveCompany);

  const [data, setData] = useState([]);

  const isBravo = type === 'bravo';

  useEffect(() => {
    if (type === 'bravo') fetchEmployeeBravos(userId).then(setData);
    else fetchEmployeeObservations(userId).then(setData);
  }, [type, userId]);

  const bravoColumns = [
    {
      key: 'safetyWalkNumber',
      label: activeCompany.isDash ? 'DASH title' : 'SW Title'
    },
    {
      key: 'createdByUser',
      label: 'Reporter',
      datatype: 'user',
      accessor: r => r.createdBy?._id || r.createdBy
    },
    {
      key: 'createdAt',
      label: 'Created Date',
      datatype: 'date'
    }
  ];

  const observationColumns = [
    {
      label: 'Behavior Observations',
      key: 'title'
    },
    {
      label: 'Employee Observed',
      key: 'employeeObserved',
      datatype: 'user'
    },
    {
      label: 'Employee Observing',
      key: 'supervisorObserving',
      datatype: 'user'
    },
    { label: 'Last Updated', key: 'updatedAt', datatype: 'date' },
    {
      label: 'Completion Date',
      key: 'completionDate',
      datatype: 'date'
    },
    {
      label: 'Archived',
      key: 'isArchived',
      accessor: o => (o.isArchived ? 'Yes' : 'No'),
      enum: ['Yes', 'No']
    }
  ];

  const header = <Header title="Employees" />;

  return (
    <HeaderAndFooter Header={header}>
      <ScrollToTopOnMount />
      <List
        settings={isBravo ? bravoColumns : observationColumns}
        dataIsHash
        getRowId={r => r._id}
        data={data}
        rowClick={row => {
          isBravo
            ? getSafetyWalk(row._id)
            : history.push(`/app/behaviorObservation/container/${row._id}`);
        }}
        saveKey={`emp${type}List`}
      />
    </HeaderAndFooter>
  );
}
