import React, { useState } from 'react';
import { deleteAttachment, uploadAttachment } from '../../../api/v4';
import customToast from '../../../utils/customToast';
import Button from '../../../components/inputs/Button';
import Chevron from '../../../svgs/chevron';
import { DeleteItemModal } from '../../../components/Modal/deleteItemModal';
import { InputRow } from '../../../components/inputs';

import styles from '../itemCard.module.scss';

function AttachmentCarousel({
  pictures,
  isEditing,
  loggedInUser,
  ownerType,
  handlePhotoUpdate,
  name
}) {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selected, setSelected] = useState(0);

  const handlePhotoUpload = async e => {
    const files = e.target.files;
    if (files?.length === 0) return;

    let data = new FormData();

    Object.entries(files).forEach(async file => {
      data.append('attachments', file[1], file[1].name);
    });

    uploadAttachment(data, {
      ownerId: loggedInUser._id,
      ownerType
    }).then(r => handlePhotoUpdate({ pictures: [...(pictures ?? []), ...r] }));
  };

  const handleDeletePicture = () => {
    let updated = pictures.toSpliced(selected, 1);
    deleteAttachment(pictures[selected]._id).then(r => {
      handlePhotoUpdate({ pictures: updated });
      customToast('Deleted Successfully');
    });
    setOpenDeleteModal(false);
  };
  return (
    <>
      {pictures?.length ? (
        <div
          className={
            pictures.length === 1 ? styles.onePicture : styles.pictureContainer
          }
        >
          {pictures.length > 1 && (
            <Chevron
              className={styles.arrows}
              onClick={() => setSelected(selected > 0 ? selected - 1 : 0)}
            />
          )}

          <img
            src={pictures[selected].source_url}
            alt={pictures[selected].original_filename}
            className={styles.photoImage}
          />

          {pictures.length > 1 && (
            <Chevron
              className={styles.arrows}
              onClick={() =>
                setSelected(
                  selected === pictures.length - 1 ? selected : selected + 1
                )
              }
            />
          )}
        </div>
      ) : null}
      {isEditing ? (
        <div className={styles.padding}>
          <InputRow>
            <Button
              text="Add Pictures"
              color="blue"
              onClick={() =>
                document.getElementById(`${name}myPictureFile`).click()
              }
            />
            {pictures.length ? (
              <Button
                text="Delete Picture"
                color="red"
                onClick={() => setOpenDeleteModal(true)}
              />
            ) : (
              <></>
            )}
          </InputRow>
          <input
            type="file"
            id={`${name}myPictureFile`}
            onChange={e => {
              handlePhotoUpload(e);
              e.target.value = '';
            }}
            accept="image/png, image/jpeg, image/jpg"
            multiple
            data-cy="addPicture"
          />
        </div>
      ) : null}
      <DeleteItemModal
        deletingWhat="picture"
        isOpen={openDeleteModal}
        onRequestClose={() => setOpenDeleteModal(false)}
        submitActions={handleDeletePicture}
        hideCustomTemplate
      />
    </>
  );
}

export default AttachmentCarousel;
