import { call, put, select } from 'redux-saga/effects';
import {
  addUploadedAttachmentsResponse,
  deleteAttachmentResponse
} from '../actions/attachments';
import { getAddedAttachmentsSelector } from '../selectors/attachments';
import customToast from '../utils/customToast';

export function* uploadDocument(apiV4, { payload }) {
  try {
    const addedAttachments = yield select(getAddedAttachmentsSelector);

    let response = [];

    response = yield call(apiV4.uploadAttachment, payload.data, {
      ownerId: payload.ownerId,
      ownerType: payload.ownerType,
      isSignature: payload.isSiganture ?? false,
      isPhotoEvidence: payload.isPhotoEvidence ?? false,
      photoType: payload?.photoType
    });

    yield put(
      addUploadedAttachmentsResponse([...addedAttachments, ...response])
    );

    customToast(`Attachment Uploaded!`, 'success');
  } catch (e) {
    customToast(
      e?.data ??
        'Attachment cannot be uploaded at this time. Please try again later.',
      'error'
    );
  }
}

export function* deleteAttachment(apiV4, { payload }) {
  try {
    yield call(apiV4.deleteAttachment, payload._id, payload?.fromOnTheSpot);
    yield put(deleteAttachmentResponse(payload));
  } catch (e) {
    customToast(`Could not delete attachment`, 'error');
  }
}

export function* updateAttachment(apiV4, { payload }) {
  try {
    yield call(apiV4.updateAttachment, payload);
  } catch (e) {
    customToast(`Could not delete attachment`, 'error');
  }
}
