import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import {
  fetchAllSafetySuggestions,
  archiveMany,
  fetchUserListFilter,
  updateUserListFilter
} from '../../../api/v4';
import history from '../../../history';
import { getLoggedInUser, getMyUserId } from '../../../selectors/users';
import { usePrevious } from '../../../utils/hooks';
import useActiveHeirarchy from '../../../utils/useActiveHeirarchy';
import AnalyticsPeriodPicker from '../../../components/AnalyticsPeriodPicker';
import Header from '../../../components/Header';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import List from '../../../components/List';
import TypeSelection from '../../../components/TypeSelection';
function SafetySuggestionsList() {
  const user = useSelector(getLoggedInUser);
  const { activeGroup, activeProject } = useActiveHeirarchy();
  const [suggestionsList, setSuggestionsList] = useState([]);
  const [listType, setListType] = useState(
    user.accessLevel >= 500 ? 'All Suggestions' : 'My Suggestions'
  );
  const [empPeriod, setEmpPeriod] = useState();
  const [currentPeriod, setCurrentPeriod] = useState();
  const prevPeriod = usePrevious(currentPeriod);
  const prevType = usePrevious(listType);
  const uId = useSelector(getMyUserId);

  useEffect(() => {
    fetchUserListFilter(user._id, 'safetySuggestions').then(r => {
      setCurrentPeriod(r);
      setEmpPeriod(r);
    });
  }, [user._id]);

  useEffect(() => {
    if (
      currentPeriod &&
      (!isEqual(prevPeriod, currentPeriod) || !isEqual(prevType, listType))
    )
      fetchAllSafetySuggestions({
        ...currentPeriod,
        selectedType: listType
      }).then(setSuggestionsList);
  }, [prevPeriod, currentPeriod, listType, prevType]);

  const handleArchive = ids => {
    if (!ids.length) return;
    archiveMany({
      type: 'safetySuggestion',
      ids: ids,
      isArchived: listType !== 'Archived'
    }).then(() =>
      fetchAllSafetySuggestions({
        ...currentPeriod,
        selectedType: listType
      }).then(setSuggestionsList)
    );
  };

  const header = (
    <Header
      section="Safety Suggestions"
      rightButtons={{
        text: 'Create Safety Suggestion',
        onClick: () => history.push('/app/safetySuggestions'),
        color: 'blue',
        visible: !activeGroup?.isHidden && !activeProject?.isHidden
      }}
    />
  );

  const columns = [
    { key: 'safetySuggestionId', label: 'Suggestion Number' },
    {
      key: 'stage',
      label: 'Status',
      enum: ['Open', 'Under Review', 'Closed']
    },
    { key: 'createdAt', label: 'Date Received', datatype: 'date' },
    { key: 'dateClosed', label: 'Date Closed', datatype: 'date' }
  ];

  const listTypeFilter =
    listType === 'My Suggestions'
      ? suggestionsList?.filter(s => s.creatorId === uId)
      : suggestionsList;

  return (
    <HeaderAndFooter Header={header}>
      {user.accessLevel >= 500 && (
        <TypeSelection
          selectedArray={[
            'All Suggestions',
            'My Suggestions',
            user.accessLevel > 400 ? 'Archived' : null
          ]}
          selected={listType}
          onClick={v => {
            if (v !== listType) setSuggestionsList([]);
            setListType(v);
          }}
        />
      )}
      <AnalyticsPeriodPicker
        period={currentPeriod}
        onChange={v => {
          setSuggestionsList([]);
          setCurrentPeriod(v);
        }}
        onSave={v =>
          updateUserListFilter({
            empId: user._id,
            type: 'safetySuggestions',
            period: v
          }).then(() => setEmpPeriod(v))
        }
        allowAll
        savedPeriod={empPeriod}
        listPicker
      />
      <List
        data={listTypeFilter}
        settings={columns}
        dataIsHash
        saveKey={`${listType}SafetySuggestionList`}
        name="SafetySuggestionList"
        getRowId={r => r._id}
        rowClick={row => history.push(`/app/safetySuggestions/${row._id}`)}
        actions={
          user.accessLevel > 400
            ? [
                {
                  color: 'blue',
                  label: `${
                    listType === 'Archived' ? 'Un-' : ''
                  }Archive Suggestions`,
                  onClick: handleArchive
                }
              ]
            : null
        }
      />
    </HeaderAndFooter>
  );
}

export default SafetySuggestionsList;
