import React from 'react';
import './svg.css';
const replyArrow = ({ width, height, x, y, ...props }) => (
  <svg
    width={props.width || '14px'}
    height={props.height || '14px'}
    x={props.x || '0px'}
    y={props.y || '0px'}
    viewBox="0 0 14 11"
    fill="#000000"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M13.1731 10.9804C13.0647 11.0122 12.9485 11.0051 12.8449 10.9604C12.7413 10.9156 12.6571 10.836 12.6071 10.7357C11.463 8.91286 9.70828 8.00244 7.64926 7.56728C7.06188 7.45304 6.4676 7.37668 5.87019 7.33867C5.66733 7.32263 5.60242 7.37075 5.60647 7.58131C5.62067 8.22102 5.60647 8.85872 5.60647 9.49842C5.60647 9.74909 5.54561 9.95564 5.29001 10.0499C5.03441 10.1441 4.86806 10.0098 4.70983 9.83532C3.37502 8.36474 2.03818 6.89415 0.699309 5.42356C0.433564 5.13279 0.433564 4.94228 0.699309 4.6495C2.04224 3.17891 3.38584 1.70432 4.73012 0.225709C4.89038 0.0472329 5.07092 -0.0630611 5.31435 0.0392115C5.41719 0.0831379 5.502 0.160121 5.55503 0.257674C5.60805 0.355227 5.62618 0.467628 5.60647 0.576644C5.60647 0.891483 5.60647 1.20632 5.60647 1.52116C5.60647 1.84402 5.61864 2.16889 5.60647 2.49375C5.60647 2.69429 5.65313 2.74041 5.85599 2.76046C9.18017 3.07062 11.5225 4.73105 12.883 7.74174C13.2572 8.60362 13.4637 9.52751 13.4916 10.465C13.5133 10.5747 13.4926 10.6885 13.4337 10.7839C13.3747 10.8793 13.2818 10.9494 13.1731 10.9804Z" />
  </svg>
);

export default replyArrow;
