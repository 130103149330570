import React from 'react';
import './svg.css';

const email = props => (
  <svg
    width={props.width ?? 30}
    height={props.height ?? 25}
    viewBox="0 0 80 80"
    x={props.x || '0'}
    y={props.y || '0'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1508_31245)">
      <path d="M35.5742 41.4889C38.1032 43.7648 41.9809 43.7648 44.51 41.4889L74.6048 13.8387C73.7618 13.5015 72.8346 13.333 71.8228 13.333H8.26114C7.24969 13.333 6.32212 13.5015 5.47913 13.8387L35.5742 41.4889Z" />
      <path d="M49.0621 46.4623C46.5331 48.8228 43.3296 49.9185 40.042 49.9185C36.8385 49.9185 33.5511 48.7382 31.022 46.4623L0.589982 18.3906C0.168486 19.4021 0 20.4139 0 21.5941V59.1916C0 63.7438 3.70924 67.4529 8.26133 67.4529H71.7387C76.2909 67.4529 80 63.7437 80 59.1916L79.9997 21.5941C79.9997 20.4981 79.7467 19.4024 79.4097 18.3906L49.0621 46.4623Z" />
    </g>
    <defs>
      <clipPath id="clip0_1508_31245">
        <rect width="80" height="80" />
      </clipPath>
    </defs>
  </svg>
);

export default email;
