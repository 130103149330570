import React, { Component } from 'react';

import { InputRow } from '../inputs';
import Button from '../inputs/Button';
import Modal from '../Modal';
import Textbox from '../inputs/Textbox';
import classnames from 'classnames/bind';

import styles from './viewEdit.module.scss';

export default class ViewEditList extends Component {
  state = {
    deleteModalOpen: false,
    newName: '',
    newError: undefined,
    editRowId: undefined,
    rowName: {},
    rowError: {}
  };

  componentDidUpdate(prevProps) {
    if (prevProps.showAdd !== this.props.showAdd && !this.props.showAdd)
      this.setState({ newName: '' });
  }

  confirmDelete(row) {
    this.setState({
      deleteModalOpen: true,
      deleteRow: row
    });
  }

  onDelete = () => {
    const row = this.state.deleteRow;
    this.setState(
      {
        deleteModalOpen: false,
        deleteRow: undefined
      },
      () => this.props.onDelete(row)
    );
  };

  onInlineEdit = row => {
    this.setState((state, props) => {
      let newState = { ...state };
      newState.rowName[row._id] = row.name;
      newState.editRowId = row._id;
      return newState;
    });
  };

  renderRow = (row, columns) => {
    if (row._id === this.state.editRowId)
      return this.renderEditRow(columns, row._id);
    const edit = this.props.inlineEdit
      ? () => this.onInlineEdit(row)
      : this.props.onEdit
        ? () => this.props.onEdit(row)
        : undefined;
    return (
      <tr key={row._id}>
        <td>{row.name}</td>
        {this.props.onView && (
          <td>
            <Button
              text="View"
              color="blue"
              inputClassName={styles.input}
              onClick={() => this.props.onView(row)}
              inline
              testID={'View ' + row.name}
            />
          </td>
        )}
        {this.props.onHide && (
          <td>
            <Button
              text={row.isHidden ? 'Show' : 'Hide'}
              color="blue"
              inputClassName={styles.input}
              onClick={() => this.props.onHide(row)}
              inline
              testID={row.isHidden ? 'Show ' + row.name : 'Hide ' + row.name}
            />
          </td>
        )}
        {edit && (
          <td>
            <Button
              text="Edit"
              color="blue"
              inputClassName={styles.input}
              onClick={edit}
              inline
              testID={'Edit ' + row.name}
            />
          </td>
        )}
        {this.props.onDelete && (
          <td>
            <Button
              text="Delete"
              color="red"
              inputClassName={styles.input}
              onClick={() => this.confirmDelete(row)}
              inline
              testID={'Delete ' + row.name}
            />
          </td>
        )}
      </tr>
    );
  };

  renderEditRow = (columns, id) => {
    const value = id ? this.state.rowName[id] : this.state.newName;
    const error = id ? this.state.rowError[id] : this.state.newError;
    const change = v => {
      this.setState((state, props) => {
        let newState = { ...state };
        if (id) {
          newState.rowName[id] = v;
          newState.rowError[id] = undefined;
        } else {
          newState.newName = v;
          newState.newError = undefined;
        }
        return newState;
      });
    };

    const cancel = () => {
      if (id) {
        this.setState({ editRowId: undefined });
      } else {
        this.props.cancelAdd && this.props.cancelAdd();
      }
    };

    const submit = () => {
      const { minLength } = this.props;
      if (id) {
        if (minLength && this.state.rowName[id].length < minLength) {
          this.setState((state, props) => {
            let newState = { ...state };
            newState.rowError[id] =
              `The name must be ${minLength} characters long.`;
            return newState;
          });
        } else {
          this.props.submitEdit(id, this.state.rowName[id], () =>
            this.setState({ editRowId: undefined })
          );
        }
      } else {
        if (minLength && this.state.newName.length < minLength) {
          this.setState({
            newError: `The name must be ${minLength} characters long.`
          });
        } else {
          this.props.submitAdd(this.state.newName);
        }
      }
    };

    return (
      <tr key={-1} className={styles.newRow}>
        <td>
          <Textbox
            currentValue={value}
            onChange={change}
            className={styles.newName}
            autoFocus
            escape={cancel}
            enter={submit}
            error={error}
            touched
          />
        </td>
        <td colSpan={columns - 1}>
          <InputRow>
            <Button
              color="green"
              text={id ? 'Edit' : 'Create'}
              onClick={submit}
              inputClassName={styles.input}
            />
            <Button
              color="red"
              text="Cancel"
              onClick={cancel}
              inputClassName={styles.input}
            />
          </InputRow>
        </td>
      </tr>
    );
  };

  render() {
    let headers = ['Name'];
    if (this.props.onView) headers.push('View');
    if (this.props.onHide) headers.push('Hide');
    if (this.props.onEdit || this.props.inlineEdit) headers.push('Edit');
    if (this.props.onDelete) headers.push('Delete');
    const { className, subCat } = this.props;

    const data = this.props.data;

    return (
      <div className={classnames(className, styles.container)}>
        <table className={styles.table}>
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => {
              if (!subCat) return this.renderRow(item, headers.length);
              return (
                <>
                  <tr>
                    <th colSpan={headers.length}>{item.name}</th>
                  </tr>
                  {item[subCat].map(i => this.renderRow(i, headers.length))}
                </>
              );
            })}
            {this.props.showAdd && this.renderEditRow(headers.length)}
          </tbody>
        </table>
        <Modal
          title="Confirm Delete"
          titleClassName="redHeader"
          isOpen={this.state.deleteModalOpen}
          submitButtonColor="red"
          submitButtonText="Delete"
          onRequestClose={() => this.setState({ deleteModalOpen: false })}
          submitActions={this.onDelete}
        >
          <h2>Are you sure you want to delete {this.state.deleteRow?.name}?</h2>
        </Modal>
      </div>
    );
  }
}
