import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import history from '../../history';
import { formatFiles } from '../../utils/attachmentSelector';
import useActiveHeirarchy from '../../utils/useActiveHeirarchy';
import { useSocket } from '../../utils/withSocket';
import { addComment } from '../../api/v4';
import {
  addUserTaskRequest,
  deleteUserTaskRequest,
  updateUserTaskRequst
} from '../../actions/tasks';
import {
  deleteAttachmentRequest,
  clearUploadedAttachments
} from '../../actions/attachments';
import { getActiveTaskSelector } from '../../selectors/tasks';
import { getActiveSafetyWalkSelector } from '../../selectors/safetyWalks';
import { getLoggedInUser } from '../../selectors/users';
import { getAddedAttachmentsSelector } from '../../selectors/attachments';
import customToast from '../../utils/customToast';
import AttachmentUploadCard from '../../components/AttachmentUploadCard';
import Card from '../../components/Card';
import CommentCard from './comments';
import { IReportDatePicker } from '../../components/inputs/DateTimePicker';
import DeleteItemModal from '../../components/Modal/deleteItemModal';
import { EmployeeDropdown, Textbox } from '../../components/inputs';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import HierarchySelector from '../../components/HierarchySelector';
import LeftNav from '../../components/LeftNav';
import MarkItemAsCompleteModal from '../../components/Modal/markItemAsCompleteModal';
import ReassignUserModal from '../../components/Modal/reassignUserModal';
import {
  SaveCancelFooter,
  SubmitSaveForLaterCancelFooter
} from '../../components/Footer';
import SaveChangesModal from '../../components/Modal/saveChangesModal';
import MissingRequiredModal from '../../components/Modal/missingRequiredModal';

export default function CorrectiveActionContainer(props) {
  const dispatch = useDispatch();
  const clearAttachments = () => dispatch(clearUploadedAttachments());
  const createCa = ca => dispatch(addUserTaskRequest(ca));
  const updateCa = ca => dispatch(updateUserTaskRequst(ca));

  const activeTask = useSelector(getActiveTaskSelector);
  const activeSafetyWalk = useSelector(getActiveSafetyWalkSelector);
  const loggedInUser = useSelector(getLoggedInUser);
  const addedAttachments = useSelector(getAddedAttachmentsSelector);
  const { activeCompany, activeGroup, activeProject } = useActiveHeirarchy();

  const { socketDirty, socketLock, locked } = useSocket({
    id: activeSafetyWalk?._id
  });

  const [openModalType, setOpenModalType] = useState(null);
  const [creating, setCreating] = useState(false);
  const [editing, setEditing] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [ca, setCa] = useState({});
  const [comments, setComments] = useState([]);
  const [attachmentsToUpload, setAttachmentsToUpload] = useState([]);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [attachmentsAdded, setAttachmentsAdded] = useState([]);
  const [newOwnerId, setNewOwnerId] = useState();
  const [missingRequired, setMissingRequired] = useState(false);
  const [missingModalOpen, setMissingModalOpen] = useState(false);

  const {
    assignedTo,
    createdBy,
    description,
    dueDate,
    groupId,
    isCompleted,
    label,
    ownerType,
    projectId,
    taskOwner,
    measures,
    isArchived
  } = ca;

  const group = activeCompany.groups?.find(g => g._id === groupId);
  const area = group?.projects?.find(p => p._id === projectId);

  const hiddenOrArchived = isArchived || group?.isHidden || area?.isHidden;

  const disabled = (!editing && !creating) || locked || hiddenOrArchived;
  const isCreator = !createdBy || createdBy === loggedInUser._id;
  const adminOrCreator =
    isCreator ||
    [500, 900].indexOf(loggedInUser.accessLevel) > -1 ||
    loggedInUser._id === taskOwner;

  const validDueDate =
    dueDate && creating ? moment(dueDate).isAfter(moment()) : true;

  const isFromObservation = ownerType === 'observation';

  useEffect(() => {
    setCa({ ...activeTask });
    if (!activeTask?._id) {
      setCreating(true);
      setCa({
        groupId: activeGroup?._id,
        projectId: activeProject?._id,
        ...activeTask
      });
    }

    if (
      !activeTask.isCompleted &&
      activeTask.assignedTo?.some(assignee => assignee === loggedInUser._id)
    )
      setEditing(true);

    setComments(activeTask?.comments ?? []);
    setAttachmentsAdded(addedAttachments);
  }, [
    activeTask,
    activeSafetyWalk,
    activeGroup,
    activeProject,
    addedAttachments,
    loggedInUser
  ]);

  const handleAnswer = values => {
    socketLock();
    setHasUnsavedChanges(true);
    setCa({ ...ca, ...values });
  };

  const canSubmit =
    (groupId || activeGroup._id) &&
    label &&
    description &&
    assignedTo?.length > 0 &&
    !assignedTo.every(a => a === null) &&
    dueDate &&
    validDueDate;

  const handleTaskSubmit = async (isCompleted = false) => {
    if (creating) {
      if (filesToUpload?.length) {
        let data = formatFiles(filesToUpload);
        await createCa({
          ...ca,
          attachmentData: {
            data: data,
            ownerType: 'task',
            photoType: 'defective'
          }
        });
      } else {
        await createCa({ ...ca, isCompleted });
      }
      setCreating(false);
      setHasUnsavedChanges(false);
    } else {
      await updateCa({ ...ca, isCompleted });
      history.goBack();
    }
    socketDirty();
  };

  const handleDeleteTask = () => {
    const allAttachments = [
      ...(addedAttachments || []),
      ...(activeTask.photosToUpload || [])
    ];
    socketLock();
    dispatch(deleteUserTaskRequest({ ...ca, attachments: allAttachments }));
    socketDirty();
  };

  const addNewComment = async (comment, parentId) => {
    let fullComment = {
      value: comment,
      ownerId: ca._id,
      ownerType: 'task',
      parentId
    };
    addComment(fullComment).then(newComment => {
      setComments([...comments, newComment]);
    });
  };

  const handleDiscardTask = () => {
    clearAttachments();
    activeTask.returnPath
      ? history.push(activeTask.returnPath)
      : history.goBack();
  };

  const deleteAttachment = attachment => {
    dispatch(deleteAttachmentRequest(attachment));
    customToast(`Deleted Updated!`, 'success');
    let updatedAttachments = activeTask?.attachments?.filter(
      a => a !== attachment
    );
    setHasUnsavedChanges(creating || editing);
    if (!creating && !editing)
      updateCa({
        ...this.state,
        attachments: updatedAttachments
      });
  };

  const addAttachments = async (attachments, photoType = '') => {
    let data = formatFiles(attachments);
    let attachmentData = {
      data,
      ownerId: activeTask._id ?? 'pending',
      ownerType: 'task',
      photoType: photoType
    };
    // need to update the task so the attachment array has the new photo ids
    await updateCa({
      ...ca,
      _id: activeTask._id,
      attachmentData
    });
  };
  const header = (
    <Header
      title="Corrective Actions"
      section={`${
        creating ? 'Create' : editing ? 'Edit' : 'View'
      } Corrective Action`}
      needsSaved={hasUnsavedChanges}
      clickBack={() =>
        hasUnsavedChanges
          ? setOpenModalType('unsavedChanges')
          : handleDiscardTask()
      }
      pageActionOptions={
        adminOrCreator && !creating
          ? [
              {
                label: 'Edit Corrective Action',
                visible: !editing && !ca.isCompleted && !hiddenOrArchived,
                color: 'blueOutline',
                onClick: () => setEditing(true)
              },
              {
                label: 'Delete Corrective Action',
                visible: true,
                color: 'redOutline',
                onClick: () => setOpenModalType('deleteCa')
              },
              {
                label: 'Reopen Corrective Action',
                visible: isCompleted,
                color: 'greenOutline',
                onClick: () => {
                  socketLock();
                  updateCa({
                    ...ca,
                    isCompleted: !isCompleted
                  });
                  socketDirty();
                }
              },
              {
                label: 'Transfer Ownership',
                visible: !isCompleted && !hiddenOrArchived,
                color: 'blueOutline',
                onClick: () => setOpenModalType('transferOwner')
              },
              {
                label: `${ca.isArchived ? 'Un-' : ''}Archive Corrective Action`,
                visible: loggedInUser.accessLevel > 400 && activeTask.canEdit,
                color: 'blueOutline',
                onClick: () => {
                  updateCa({
                    ...ca,
                    isArchived: !ca.isArchived
                  });
                  if (!ca.isArchived)
                    history.push('/app/tasks/correctiveAction');
                }
              }
            ]
          : null
      }
    />
  );

  const footer = creating ? (
    <SaveCancelFooter
      saveButtonClick={() =>
        !canSubmit ? setMissingModalOpen(true) : handleTaskSubmit()
      }
      saveButtonText={editing ? 'Update' : 'Create'}
      cancelButtonClick={() =>
        hasUnsavedChanges
          ? setOpenModalType('unsavedChanges')
          : handleDiscardTask()
      }
      editing={editing}
      onMouseEnter={() => setMissingRequired(true)}
    />
  ) : editing ? (
    <SubmitSaveForLaterCancelFooter
      saveButtonClick={() => handleTaskSubmit()}
      saveButtonDisabled={!hasUnsavedChanges || !canSubmit}
      saveButtonText="Save"
      submitButtonText="Complete"
      cancelButtonClick={() =>
        hasUnsavedChanges
          ? setOpenModalType('unsavedChanges')
          : handleDiscardTask()
      }
      deleteButtonClick={() => setOpenModalType('delete')}
      submitButtonClick={() => setOpenModalType('complete')}
      submitButtonDisabled={isCompleted || !canSubmit || hiddenOrArchived}
    />
  ) : null;

  let leftNav = [{ label: 'Corrective Action Details', id: '0' }];
  if (ownerType !== 'incident') leftNav.push({ label: 'Attachments', id: '1' });
  if (!creating) leftNav.push({ label: 'Comments', id: '2' });

  return (
    <>
      <HeaderAndFooter
        Header={header}
        Footer={footer}
        showFooter
        lockInfo={props.lockInfo}
        Left={<LeftNav items={leftNav} />}
      >
        <Card showHeader title="Corrective Action Details" name={0}>
          <HierarchySelector
            onGroupChange={v => {
              let newProjectId = v === groupId ? projectId : '';
              handleAnswer({ groupId: v, projectId: newProjectId });
            }}
            groupId={groupId}
            groupDisabled
            onProjectChange={v => handleAnswer({ projectId: v })}
            projectId={projectId}
            projectDisabled
          />
          <Textbox
            currentValue={label}
            fieldLabel="Title"
            onChange={label => handleAnswer({ label })}
            placeholder="Type a clear and concise title."
            isRequired
            disabled
          />
          <Textbox
            currentValue={description}
            fieldLabel="Description"
            onChange={description => handleAnswer({ description })}
            type="textarea"
            placeholder="Describe what needs to be done to complete this Task. Be sure to include any important details."
            isRequired
            disabled={disabled || !adminOrCreator}
            touched={missingRequired && !description}
          />
          <Textbox
            currentValue={measures}
            fieldLabel="Measures"
            onChange={measures => handleAnswer({ measures })}
            type="textarea"
            disabled={disabled || !adminOrCreator}
          />
          <IReportDatePicker
            fieldLabel="Due By"
            isRequired
            onChange={value => handleAnswer({ dueDate: value })}
            pickTime
            currentValue={dueDate}
            disabled={disabled || !adminOrCreator}
            name="taskDueBy"
            touched={missingRequired && (!dueDate || !validDueDate)}
            minDate={Date.now()}
            errorMessage="Must be a future date and time"
          />
          <EmployeeDropdown
            fieldLabel="Assignee(s)"
            currentValue={assignedTo}
            onChange={v => handleAnswer({ assignedTo: v })}
            isRequired
            disabled={
              disabled ||
              !adminOrCreator ||
              (isFromObservation &&
                !assignedTo.some(x => x === null || x === undefined))
            }
            searchable
            name="employees"
            multi
            permissions={[100, 400, 500, 900]}
            group={groupId}
            touched={missingRequired && assignedTo === undefined}
          />
        </Card>
        <AttachmentUploadCard
          addAttachments={addAttachments}
          deleteAttachment={deleteAttachment}
          isCreating={creating}
          isEditing={
            adminOrCreator
              ? editing
              : ca?.assignedTo?.includes(loggedInUser._id)
          }
          addedAttachments={attachmentsAdded}
          attachmentsToUpload={attachmentsToUpload}
          name={1}
          handleAnswer={(currentAttachments, currentFiles) => {
            setAttachmentsToUpload(currentAttachments);
            setFilesToUpload(currentFiles);
            setHasUnsavedChanges(true);
          }}
          taskOwnedBy={ownerType}
          ownerType="task"
          formattedAttachmentsToUpload={filesToUpload}
          keepAttachmentsAnonymous={ownerType === 'safetySuggestion'}
          disabled={hiddenOrArchived}
        />

        {creating ? null : (
          <CommentCard
            name={2}
            addComment={addNewComment}
            comments={comments}
            disabled={hiddenOrArchived}
          />
        )}
      </HeaderAndFooter>
      <SaveChangesModal
        isOpen={openModalType === 'unsavedChanges'}
        onRequestClose={() => setOpenModalType()}
        submitActions={handleDiscardTask}
        savingWhat="corrective action"
      />
      <DeleteItemModal
        isOpen={openModalType === 'deleteCa'}
        onRequestClose={() => setOpenModalType()}
        deletingWhat="Corrective Action"
        hideCustomTemplate
        submitActions={handleDeleteTask}
      />
      <MarkItemAsCompleteModal
        isOpen={openModalType === 'complete'}
        completingWhat="Corrective Action"
        onRequestClose={() => setOpenModalType()}
        submitActions={() => handleTaskSubmit(true)}
        cancelActions={() => setOpenModalType()}
        message={
          <div>
            Are you sure you would like to mark this task complete? This will
            remove the task from the assignee(s) to-do list.
          </div>
        }
        submitButtonColor="blue"
        submitButtonText="Complete Corrective Action"
        cancelButtonText="Cancel"
        cancelButtonColor="white"
        headerColor="blueHeader"
      />
      <ReassignUserModal
        title="Transfer Ownership"
        previousUser={taskOwner}
        reassignUser={newOwnerId}
        disableSubmit={!newOwnerId}
        message={
          <div>
            From here, you can assign a different user to be the owner for this
            corrective action. Corrective Action Ownership can only be
            reassigned to an Admin or Collaborator User with access to the
            Group/Establishment this corrective action is in.
          </div>
        }
        onRequestClose={() => {
          setOpenModalType();
          setNewOwnerId();
        }}
        isOpen={openModalType === 'transferOwner'}
        employeeFieldLabel="New Corrective Action Owner"
        onChange={setNewOwnerId}
        submitActions={() => {
          setOpenModalType();
          setNewOwnerId();
          updateCa({
            ...ca,
            taskOwner: newOwnerId
          });
        }}
        permissions={[500, 900]}
        groups={groupId}
        submitButtonText="Reassign"
      />
      <MissingRequiredModal
        isOpen={missingModalOpen}
        onRequestClose={() => setMissingModalOpen(false)}
        subject="Corrective Action"
      />
    </>
  );
}
