import React from 'react';
import Card from '../../Card';
import { Textbox } from '../../inputs';

export default function AdditionalCommentsCard({
  name,
  notes,
  onChange,
  submit,
  disabled
}) {
  return (
    <Card
      title="Additional Comments & Suggestions"
      showHeader
      name={name}
      summaryCardWide
    >
      <Textbox
        type="textarea"
        currentValue={notes}
        onChange={onChange}
        placeholder="Comments"
        onBlur={submit}
        disabled={disabled}
      />
    </Card>
  );
}
