import React from 'react';
import ReactGA from 'react-ga';
import { useSelector } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { PersistGate } from 'redux-persist/lib/integration/react';
import history from '../../history';
import config from '../../config/config';
import { isLoading } from '../../selectors/loading';
import { persistor } from '../../store';
import { unregister } from '../../registerServiceWorker';
import App from '../App';
import Card from '../../components/Card';
import AuthenticatedRoute from '../AuthenticatedRoute';
import ErrorPage from '../../components/ErrorPage';
import Home from '../Home';
import Loading from '../../components/Loading';
import Login from '../Login';
import TopHeader from '../TopHeader';
import TemporaryLogin from '../TemporaryLogin';

// Running test to see if this stops the browser performance issues we've been seeing from CS and customers
// Using the default react-loading component instead of our ireport gif
// import loadingGif from '../../assets/images/iReportSourceLoading.gif';
import styles from './root.module.scss';

unregister();

ReactGA.initialize(config.gaTrackingID);

const Root = props => {
  const loading = useSelector(isLoading);
  const message = config?.maintenanceMessage;
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <PersistGate loading={<Loading />} persistor={persistor}>
      {message ? (
        <Card showHeader title={message?.title}>
          {message.message}
        </Card>
      ) : (
        <Router history={history}>
          <div id="app" className="root">
            <TopHeader />
            <Switch>
              <AuthenticatedRoute exact path="/home" component={Home} />
              <AuthenticatedRoute path="/app" component={App} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/error" component={ErrorPage} />
              <Route
                exact
                path="/useraccounts/microsoftlogin"
                component={Home}
              />
              <Route
                path="/externalLogin/:statementId"
                component={TemporaryLogin}
              />
              <Redirect from="/" to="/login" />
            </Switch>
            <div
              className={`${styles.loading} ${loading ? '' : styles.hidden}`}
            >
              {/* <div>
                  <img src={loadingGif} alt="Loading..." />
                </div> */}
              <Loading />
            </div>
          </div>
        </Router>
      )}
    </PersistGate>
  );
};

export default Root;
