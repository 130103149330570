import React from 'react';

import classnames from 'classnames/bind';

import Button from '../inputs/Button';
import Badge from '../Badge';

import styles from './styles.module.scss';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
const bStyles = classnames.bind(styles);

export default function Card({
  title,
  footer,
  showFooter,
  children,
  showHeader,
  smallCard,
  name,
  rightButton,
  className,
  wide,
  tabs,
  cardTitle,
  testID,
  collapsed,
  showSubHeader,
  subHeader,
  border,
  sectionCompleted = false,
  richTitle,
  summaryCardWide
}) {
  const cardClasses = bStyles(className, {
    card: true,
    smallCard,
    wide,
    noPadding: cardTitle,
    border,
    completedCard: sectionCompleted,
    summaryCardWide
  });

  let buttonJSX;
  if (!rightButton) {
    buttonJSX = <></>;
  } else if (React.isValidElement(rightButton)) {
    buttonJSX = (
      <div className={styles.rightButtonContainer}>{rightButton}</div>
    );
  } else if ('visible' in rightButton && !rightButton.visible) {
    buttonJSX = <></>;
  } else {
    buttonJSX = <Button {...rightButton} inputClassName={styles.input} />;
  }

  return (
    <>
      {tabs?.list?.length ? (
        <div className={styles.tabs}>
          {tabs.list.map((t, i) =>
            (t.visible ?? true) ? (
              <div
                key={i}
                onClick={() => tabs.onClick?.(i)}
                className={bStyles({
                  [t.color ?? 'blue']: true,
                  selected: tabs.selected === i
                })}
              >
                {t.count || t.count === 0 ? (
                  <Badge color={t.color ?? 'blue'} text={t.count} />
                ) : (
                  <></>
                )}
                <span className={styles.title}>{t.name}</span>
              </div>
            ) : (
              <></>
            )
          )}
        </div>
      ) : (
        <></>
      )}
      <div data-cy={testID} className={cardClasses} id={name}>
        {cardTitle && <h1 className={styles.title}>{cardTitle}</h1>}
        {showHeader && (
          <div className={styles.header}>
            <div>
              {richTitle ? (
                <ReactMarkdown className={styles.richTitle} children={title} />
              ) : (
                <span>{title}</span>
              )}
            </div>

            {buttonJSX}
          </div>
        )}
        {showSubHeader && subHeader}
        {!collapsed && children}
        {!collapsed && showFooter && (
          <footer className={styles.footer}>{footer}</footer>
        )}
      </div>
    </>
  );
}
