import React from 'react';
import './svg.css';

const collab = props => (
  <svg width="100" height="100" viewBox="0 0 100 100">
    <g>
      <g transform="translate(50 50) scale(0.69 0.69) rotate(0) translate(-50 -50)">
        <svg
          version="1.1"
          x={props.x || '0px'}
          y={props.y || '0px'}
          viewBox="0 0 100 100"
          enableBackground="new 0 0 100 100"
        >
          <path d="M90,75.7V83H66v-4.7v-2.6c0-0.5,0-0.9,0.1-1.3c0-0.2,0-0.4,0.1-0.5c0-0.2,0.1-0.4,0.1-0.6  c0.7-3.4,2.8-6.2,5.7-7.9c-1.7-1.6-2.8-3.9-2.8-6.5c0-4.7,3.7-8.5,8.3-8.8l0,0c0.2,0,0.3,0,0.5,0c0.4,0,0.7,0,1.1,0.1  c0.1,0,0.2,0,0.3,0c0.3,0,0.6,0.1,0.9,0.2c0.1,0,0.1,0,0.2,0c0.7,0.2,1.4,0.5,2.1,0.9l0,0v0c2.6,1.5,4.4,4.4,4.4,7.6  c0,2.6-1.1,4.8-2.8,6.5C87.6,67.4,90,71.2,90,75.7z M33.8,73.8c0-0.2-0.1-0.4-0.1-0.6C33,69.8,30.9,67,28,65.3  c1.7-1.6,2.8-3.9,2.8-6.5c0-4.7-3.7-8.5-8.3-8.8l0,0c-0.2,0-0.3,0-0.5,0c-0.4,0-0.7,0-1.1,0.1c-0.1,0-0.2,0-0.3,0  c-0.3,0-0.6,0.1-0.9,0.2c-0.1,0-0.1,0-0.2,0c-0.7,0.2-1.4,0.5-2.1,0.9l0,0l0,0c-2.6,1.5-4.4,4.4-4.4,7.6c0,2.6,1.1,4.8,2.8,6.5  c-3.6,2.1-6,5.9-6,10.4V83h24v-4.7v-2.6c0-0.5,0-0.9-0.1-1.3C33.9,74.1,33.9,74,33.8,73.8z M42.5,21c0.2,0.3,0.4,0.6,0.6,0.8  c0.1,0.1,0.1,0.1,0.2,0.2c0.2,0.3,0.5,0.6,0.8,0.8c-3.6,2.1-6,5.9-6,10.4v7.3h24v-7.3c0-4.1-2-7.6-5.1-9.8h0  c-0.3-0.2-0.6-0.4-0.9-0.6c0.3-0.3,0.5-0.5,0.8-0.8c0.1-0.1,0.1-0.1,0.2-0.2c0.2-0.3,0.4-0.5,0.6-0.8c0,0,0,0,0-0.1  c0.4-0.7,0.7-1.4,1-2.2l0,0l0,0c0.2-0.7,0.3-1.5,0.3-2.3c0-4.9-4-8.8-8.8-8.8c-4.9,0-8.8,4-8.8,8.8C41.2,18,41.6,19.6,42.5,21  C42.5,20.9,42.5,20.9,42.5,21z M22.7,47c1-9.2,6.5-17,14.3-21.2c0.7-1.3,1.7-2.5,2.8-3.6c-0.5-0.8-0.9-1.7-1.1-2.7  c-11.7,4.4-20.2,15.3-21.1,28.3c1.4-0.6,2.9-0.9,4.4-0.9C22.2,47,22.4,47,22.7,47z M36.9,74.2c0,0.5,0.1,1,0.1,1.5v4.1  c4,1.7,8.4,2.7,13,2.7s9-1,13-2.7v-4.1c0-0.5,0-1,0.1-1.5c-3.9,2.1-8.3,3.3-13.1,3.3S40.8,76.3,36.9,74.2z M77.3,47  c0.2,0,0.4,0,0.7,0c1.6,0,3.1,0.3,4.4,0.9c-0.8-13-9.4-23.9-21.1-28.3c-0.3,0.9-0.6,1.8-1.1,2.7c1.1,1,2.1,2.3,2.8,3.6  C70.8,30,76.3,37.9,77.3,47z" />
        </svg>
      </g>
    </g>
  </svg>
);

export default collab;
