import React from 'react';
import './svg.css';

const qualityAudit = props => (
  <svg
    width={props.width ?? 30}
    height={props.height ?? 25}
    viewBox="0 0 80 80"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M65.8521 8.79981H58.0121V11.5998C58.0121 14.1597 55.9322 16.1597 53.4522 16.1597H26.6522C24.0924 16.1597 22.0924 14.0797 22.0924 11.5998L22.0921 8.79981H14.1721C12.1721 8.79981 10.4922 10.4797 10.4922 12.4797V74.3197C10.4922 76.3197 12.1721 77.9997 14.1721 77.9997H65.7721C67.7721 77.9997 69.4521 76.3197 69.4521 74.3197L69.4523 12.4797C69.4523 10.4797 67.8521 8.79981 65.8521 8.79981ZM26.6551 12.5599H53.3751C53.8551 12.5599 54.255 12.1599 54.255 11.6799V6.95987C54.255 4.2398 52.0149 2 49.2952 2H30.6552C27.9351 2 25.6953 4.24013 25.6953 6.95987V11.6799C25.775 12.1599 26.1749 12.5599 26.6551 12.5599ZM54.9906 33.0094C56.3365 34.3552 56.3365 36.5372 54.9906 37.883L37.8958 54.9778C36.2937 56.5799 33.6961 56.5799 32.0939 54.9778L25.0094 47.8932C23.6635 46.5474 23.6635 44.3654 25.0094 43.0196C26.3552 41.6738 28.5371 41.6738 29.883 43.0196L34.9949 48.1315L50.117 33.0094C51.4629 31.6636 53.6448 31.6636 54.9906 33.0094Z"
    />
  </svg>
);

export default qualityAudit;
