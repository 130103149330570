import React from 'react';

const trash = ({ width, height, x, y, ...props }) => (
  <svg
    width={props.width || '40px'}
    height={props.height || '40px'}
    x={props.x || '0px'}
    y={props.y || '0px'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.8804 2.89493C18.3507 2.89493 18.7319 3.27613 18.7319 3.74638C18.7319 4.21662 18.3507 4.59783 17.8804 4.59783H17.8685C17.7744 4.59783 17.6954 4.67238 17.6918 4.76878L17.0788 21.3202C17.034 22.5286 16.024 23.5 14.8152 23.5H3.91668C2.7073 23.5 1.69781 22.5268 1.65312 21.3202L1.0401 4.76878C1.0366 4.67437 0.959613 4.59783 0.863412 4.59783H0.851449C0.381207 4.59783 0 4.21662 0 3.74638C0 3.27613 0.381207 2.89493 0.851449 2.89493H5.00256C5.0961 2.89493 5.1932 2.82051 5.21836 2.73242L5.496 1.76069C5.78083 0.763797 6.79359 0 7.83044 0H10.9014C11.9384 0 12.951 0.763523 13.2359 1.76069L13.5135 2.73242C13.5392 2.82217 13.6349 2.89493 13.7293 2.89493H17.8804ZM8.68478 7.66304V19.2428C8.68478 19.6189 8.98975 19.9239 9.36594 19.9239C9.74214 19.9239 10.0471 19.6189 10.0471 19.2428V7.66304C10.0471 7.28685 9.74214 6.98188 9.36594 6.98188C8.98975 6.98188 8.68478 7.28685 8.68478 7.66304ZM4.9387 7.68307L5.27928 19.2628C5.29034 19.6388 5.60414 19.9347 5.98017 19.9236C6.3562 19.9126 6.65207 19.5988 6.64101 19.2227L6.30043 7.64302C6.28937 7.26699 5.97557 6.97112 5.59954 6.98218C5.22351 6.99324 4.92764 7.30704 4.9387 7.68307ZM12.4315 7.64302L12.0909 19.2227C12.0798 19.5988 12.3757 19.9126 12.7517 19.9236C13.1277 19.9347 13.4415 19.6388 13.4526 19.2628L13.7932 7.68307C13.8042 7.30704 13.5084 6.99324 13.1323 6.98218C12.7563 6.97112 12.4425 7.26699 12.4315 7.64302ZM7.11544 2.89493C7.02019 2.89493 6.96335 2.82361 6.98907 2.73361L7.13338 2.22851C7.20932 1.96273 7.55384 1.7029 7.83044 1.7029H10.9014C11.1782 1.7029 11.5225 1.96251 11.5985 2.22851L11.7428 2.73361C11.7683 2.8227 11.7128 2.89493 11.6164 2.89493H7.11544Z"
    />
  </svg>
);
export default trash;
