import React from 'react';
import './svg.css';

const auto = props => (
  <svg
    width={props.width ?? 30}
    height={props.height ?? 25}
    viewBox="0 0 80 80"
    x={props.x || '0px'}
    y={props.y || '0px'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M80 30.7499C80 28.3299 78.0201 26.3499 75.6 26.3499H70.3383L66.5231 16.4299C65.5752 13.9659 62.7231 11.3563 60.1838 10.6307C60.1841 10.6315 53.5999 8.75 39.9998 8.75C26.3997 8.75 19.8151 10.6315 19.8151 10.6315C17.2769 11.3571 14.4248 13.9659 13.4769 16.4307L9.66173 26.3501H4.4C1.97993 26.3501 0 28.33 0 30.7501C0 33.1701 1.97993 35.1501 4.4 35.1501H6.2768L4.92313 38.6701C3.9752 41.1331 3.19993 45.31 3.19993 47.9501V68.7501C3.19993 71.8301 5.72 74.3501 8.79993 74.3501C11.8799 74.3501 14.3999 71.8301 14.3999 68.7501V65.5501H65.5999V68.7501C65.5999 71.8301 68.12 74.3501 71.1999 74.3501C74.2799 74.3501 76.7999 71.8301 76.7999 68.7501V47.9501C76.7999 45.31 76.0239 41.1335 75.0767 38.6701L73.7223 35.1501H75.5999C78.02 35.1501 79.9999 33.1701 79.9999 30.7501L80 30.7499ZM14.1527 32.0491L18.6461 19.8518C18.9511 19.0263 19.9198 18.3503 20.7998 18.3503H59.1998C60.0797 18.3503 61.0493 19.0263 61.3527 19.8511L65.8461 32.0484C66.1503 32.8739 65.6797 33.5499 64.7998 33.5499H15.1998C14.3199 33.5499 13.8485 32.8739 14.1527 32.0491ZM13.9998 51.9498C11.5693 51.9498 9.5998 49.9779 9.5998 47.5498C9.5998 45.1217 11.5693 43.1498 13.9998 43.1498C16.4295 43.1498 18.3998 45.1217 18.3998 47.5498C18.3998 49.9779 16.4295 51.9498 13.9998 51.9498ZM65.9998 51.9498C63.5693 51.9498 61.5998 49.9803 61.5998 47.5498C61.5998 45.1193 63.5693 43.1498 65.9998 43.1498C68.4303 43.1498 70.3998 45.1193 70.3998 47.5498C70.3998 49.9803 68.4303 51.9498 65.9998 51.9498Z" />
  </svg>
);

export default auto;
