import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import moment from 'moment';
import history from '../../history';
import {
  getDistinctCustomDocTitles,
  fetchCustomDocumentTemplates,
  fetchDocumentsByType,
  archiveMany,
  fetchUserListFilter,
  updateUserListFilter
} from '../../api/v4';
import { addDocumentsToFolderRequest } from '../../actions/documents';
import { usePrevious } from '../../utils/hooks';
import { useSocket } from '../../utils/withSocket';
import useActiveHeirarchy from '../../utils/useActiveHeirarchy';
import customToast from '../../utils/customToast';
import { validListDateRange } from '../../utils/formHelpers';
import { getLoggedInUser } from '../../selectors/users';
import {
  getActiveFolderSelector,
  getDocumentFoldersSelector
} from '../../selectors/documents';
import { Dropdown } from '../../components/inputs';
import AddToFolderModal from '../../components/Modal/addToFolderModal';
import AnalyticsPeriodPicker from '../../components/AnalyticsPeriodPicker';
import DocumentFolder from '../../components/DocumentFolder';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import HierarchySelector from '../../components/HierarchySelector';
import List from '../../components/List';
import Modal from '../../components/Modal';
import TypeSelection from '../../components/TypeSelection';

import styles from './customDocs.module.scss';

export default function CustomDocumentList() {
  const dispatch = useDispatch();
  const socket = useSocket();
  const { activeCompany, activeGroup, activeProject } = useActiveHeirarchy();
  const activeFolder = useSelector(getActiveFolderSelector);
  const folderList = useSelector(getDocumentFoldersSelector);
  const folderOptions = folderList?.map(f => ({
    value: f._id,
    label: f?.name
  }));

  const [templates, setTemplates] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [titles, setTitles] = useState([]);
  const [generated, setGenerated] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [openCreateDocumentModal, setOpenCreateDocumentModal] = useState(false);
  const [openExportDocumentModal, setOpenExportDocumentModal] = useState(false);
  const [showDownloadModal, setDownloadModal] = useState(false);
  const [listType, setListType] = useState('Active');
  const [empPeriod, setEmpPeriod] = useState();
  const [currentPeriod, setCurrentPeriod] = useState();
  const [bulkDLModalOpen, setBulkDLModalOpen] = useState(false);
  const [bulkOptions, setBulkOptions] = useState({});
  const [bulkLoading, setBulkLoading] = useState(false);
  const [bulkReport, setBulkReport] = useState(null);
  const [missingRequired, setMissingRequired] = useState(false);
  const [bulkTemplates, setBulkTemplates] = useState([]);
  const [addToFolderModal, setAddToFolderModal] = useState(false);
  const [folderId, setFolderId] = useState();
  const [documentIds, setDocumentIds] = useState([]);

  const prevPeriod = usePrevious(currentPeriod);
  const prevType = usePrevious(listType);
  const prevFolder = usePrevious(activeFolder);
  const activeUser = useSelector(getLoggedInUser);

  useEffect(() => {
    fetchUserListFilter(activeUser._id, 'customDocument').then(r => {
      setCurrentPeriod(r);
      setEmpPeriod(r);
    });
  }, [activeUser._id]);

  useEffect(() => {
    fetchCustomDocumentTemplates().then(response => {
      response = response.filter(template => !template.isArchived);
      setTemplates(
        response?.map(template => ({
          value: template._id,
          label: template.label
        }))
      );
      setBulkTemplates(
        response?.map(template => ({
          value: template.label,
          label: template.label
        }))
      );
    });

    const hasStateChange =
      !isEqual(prevPeriod, currentPeriod) ||
      !isEqual(prevFolder, activeFolder) ||
      !isEqual(prevType, listType);

    if (currentPeriod && validListDateRange(currentPeriod) && hasStateChange)
      fetchDocumentsByType('CustomDocument', activeFolder?._id, {
        ...currentPeriod,
        selectedType: listType
      }).then(setDocuments);
    getDistinctCustomDocTitles().then(response => {
      setTitles(response.map(r => ({ label: r, value: r })));
    });
  }, [currentPeriod, prevPeriod, listType, prevType, activeFolder, prevFolder]);

  useEffect(() => {
    if (!socket) return;
    socket.emit('subscribeCustomDocList', {
      company: activeCompany?._id,
      group: activeGroup?._id,
      project: activeProject?._id
    });

    socket.on('customDocCSV', value => {
      setGenerated(value ?? {});
      if (value?.url?.length) setLoading(false);
    });

    socket.emit(
      'subscribeCustomDocBulk',
      activeUser._id +
        activeCompany._id +
        activeGroup?._id +
        (activeProject?._id ?? '')
    );

    socket.on('bulkcustomDocPdf', value => {
      if (!value) return;
      if (moment().isAfter(moment(value.createdAtTime).add(7, 'days')))
        socket.emit('deletePdf', {
          id:
            activeUser._id +
            activeCompany._id +
            activeGroup?._id +
            (activeProject?._id ?? ''),
          type: 'bulkcustomDoc'
        });
      else {
        setBulkReport(value);
        setBulkLoading(value && !value?.url);
      }
    });
    socket.on('printFail', error => {
      setGenerated(null);
      customToast(`Error Creating Custom Doc CSVs: ${error}`, 'error');
    });
  }, [
    activeCompany,
    activeGroup,
    activeProject,
    socket,
    activeCompany._id,
    activeUser._id,
    dispatch
  ]);

  const handleExportDocument = () => {
    socket.emit('generateCustomDocCSV', {
      company: activeCompany?._id,
      group: activeGroup?._id,
      project: activeProject?._id,
      ...bulkOptions
    });
    setGenerated({});
    setLoading(true);
    setOpenExportDocumentModal(false);
    setDownloadModal(true);
  };

  const handleArchive = ids => {
    if (!ids.length) return;
    archiveMany({
      type: 'CustomDocument',
      ids: ids,
      isArchived: listType === 'Active'
    }).then(() =>
      fetchDocumentsByType('CustomDocument', activeFolder._id, {
        ...currentPeriod,
        selectedType: listType
      }).then(setDocuments)
    );
  };

  const handleAddMultipleToFolders = () => {
    dispatch(
      addDocumentsToFolderRequest({
        documents: documentIds,
        documentFolderId: folderId,
        type: 'documents'
      })
    );
    setAddToFolderModal(false);
    setDocumentIds([]);
  };

  const header = (
    <Header
      title="Custom Documents"
      pageActionOptions={[
        {
          label: 'Create Document',
          visible: !activeGroup?.isHidden && !activeProject?.isHidden,
          color: 'blueOutline',
          onClick: () => setOpenCreateDocumentModal(true)
        },
        {
          label: 'Export Custom Document CSV',
          visible: !loading,
          color: 'blueOutline',
          onClick: () => {
            setOpenExportDocumentModal(true);
            setBulkOptions({ folders: [activeFolder?._id].filter(id => id) });
          }
        },
        {
          label: 'Creating Document CSV',
          visible: loading,
          color: 'greyOutline',
          disabled: true
        },
        {
          label: `Download ${generated.name} CSV`,
          visible: generated.url,
          color: 'greenOutline',
          onClick: () => window.open(generated.url, '_blank')
        },
        {
          label: 'Export Custom Documents PDF',
          visible: true,
          color: 'blueOutline',
          onClick: () => {
            setBulkDLModalOpen(true);
            setBulkOptions({
              group: activeGroup?._id,
              project: activeProject?._id,
              folders: [activeFolder?._id].filter(id => id)
            });
          }
        },
        {
          label: `${bulkLoading ? 'Generating' : 'Download'} Bulk PDF Report`,
          visible: bulkLoading || bulkReport?.url,
          color: bulkLoading ? 'blueOutline' : 'greenOutline',
          onClick: () =>
            !bulkLoading && bulkReport?.url
              ? window.open(bulkReport.url, '_blank')
              : null
        }
      ]}
    />
  );

  const columns = [
    { key: 'label', label: 'Title' },
    {
      key: 'createdByUserId',
      label: 'Created By',
      datatype: 'user',
      accessor: r => r?.auditLog[0]?.user ?? r?.auditLog[0]?.userId
    },
    { key: 'createdAt', label: 'Created Date', datatype: 'date' },
    { key: 'updatedAt', label: 'Updated Date', datatype: 'date' }
  ];

  if (
    activeCompany?.usersWithCustomDocConfidentialAccess?.some(
      id => id === activeUser._id
    )
  ) {
    columns.splice(2, 0, {
      key: 'isConfidential',
      accessor: r => (r?.isConfidential ? 'Yes' : 'No'),
      enum: ['Yes', 'No']
    });
  }

  const listTypeFilter =
    listType === 'Archived'
      ? documents.filter(doc => doc.isArchived)
      : documents.filter(doc => !doc.isArchived);

  const missingRequiredOptions =
    !bulkOptions.period?.mode ||
    (bulkOptions.period?.mode === 'absolute' &&
      !bulkOptions.period?.startDate &&
      !bulkOptions.period?.endDate);

  return (
    <>
      <HeaderAndFooter Header={header}>
        {activeUser.accessLevel > 400 ? (
          <TypeSelection
            selectedArray={['Active', 'Archived']}
            onClick={setListType}
            selected={listType}
          />
        ) : null}
        <AnalyticsPeriodPicker
          period={currentPeriod}
          onChange={setCurrentPeriod}
          onSave={v =>
            updateUserListFilter({
              empId: activeUser._id,
              type: 'customDocument',
              period: v
            }).then(() => setEmpPeriod(v))
          }
          allowAll
          savedPeriod={empPeriod}
          listPicker
        />
        <DocumentFolder content={documents} />
        <List
          saveKey="customDocList"
          data={listTypeFilter}
          dataIsHash
          settings={columns}
          rowClick={row =>
            history.push(`/app/customDocumentContainer/null/${row._id}`)
          }
          getRowId={r => r._id}
          actions={
            activeUser.accessLevel > 100
              ? [
                  {
                    color: 'blue',
                    label: 'Add to Folder',
                    onClick: ids => {
                      if (!ids?.length) return;
                      setAddToFolderModal(true);
                      setDocumentIds(ids);
                    }
                  },
                  {
                    color: 'blue',
                    label: `${
                      listType === 'Archived' ? 'Un-' : ''
                    }Archive Documents`,
                    onClick: handleArchive,
                    visible: activeUser.accessLevel > 400
                  }
                ]
              : null
          }
        />
      </HeaderAndFooter>
      <Modal
        medium
        title="Create Custom Document"
        titleClassName="blueHeader"
        isOpen={openCreateDocumentModal}
        submitButtonColor="blue"
        submitButtonText="Create"
        onRequestClose={() => setOpenCreateDocumentModal(false)}
        submitActions={() =>
          history.push(`/app/customDocumentContainer/${selectedTemplateId}`)
        }
        disableSubmit={!selectedTemplateId}
        testID="Create Custom Document"
      >
        <Dropdown
          fieldLabel="Select Custom Document to create"
          options={templates}
          searchable
          name="templateDD"
          onChange={values => setSelectedTemplateId(values)}
          isRequired
          currentValue={selectedTemplateId}
        />
      </Modal>
      <Modal
        title="Export Custom Document"
        titleClassName="blueHeader"
        isOpen={openExportDocumentModal}
        submitButtonColor="blue"
        submitButtonText="Export"
        onRequestClose={() => setOpenExportDocumentModal(false)}
        submitActions={() =>
          bulkOptions?.customDocLabel ? handleExportDocument() : null
        }
        submitTooltip={
          !bulkOptions?.customDocLabel
            ? 'You must select a Custom Document to export'
            : null
        }
      >
        <Dropdown
          fieldLabel="Folder"
          options={folderOptions}
          currentValue={bulkOptions.folders}
          onChange={values =>
            setBulkOptions({ ...bulkOptions, folders: values })
          }
          multi
          searchable
          selectButtons
        />
        <Dropdown
          fieldLabel="Select Custom Document to export"
          options={titles}
          searchable
          name="titleDD"
          onChange={value =>
            setBulkOptions({ ...bulkOptions, customDocLabel: value })
          }
          isRequired
          currentValue={bulkOptions.customDocLabel}
        />
      </Modal>
      <Modal
        title="Export Custom Document"
        titleClassName="greenHeader"
        isOpen={showDownloadModal}
        onRequestClose={() => setDownloadModal(false)}
        hideButtons
      >
        Download Scheduled in the background, please check back later.
      </Modal>
      <Modal
        isOpen={bulkDLModalOpen}
        onRequestClose={() => {
          setBulkDLModalOpen(false);
          setMissingRequired(false);
        }}
        title="Export Custom Documents"
        titleClassName="blueHeader"
        submitButtonText="Generate"
        submitActions={() => {
          if (!missingRequiredOptions) {
            setBulkLoading(true);
            socket.emit('generateBulkReport', {
              ...bulkOptions,
              user: activeUser._id,
              company: activeCompany._id,
              type: 'customDoc',
              activeGroup: activeGroup?._id,
              activeProject: activeProject?._id ?? ''
            });
            setBulkDLModalOpen(false);
            setBulkReport(null);
          }
        }}
        submitButtonColor="blue"
        onMouseEnter={() => {
          missingRequiredOptions
            ? setMissingRequired(true)
            : setMissingRequired(false);
        }}
        wide
      >
        <AnalyticsPeriodPicker
          period={bulkOptions.period}
          onChange={v => {
            setBulkOptions({ ...bulkOptions, period: v });
            setMissingRequired(false);
          }}
          error={missingRequired}
          isBulkPicker
        />
        <HierarchySelector
          groupRequired={false}
          groupId={bulkOptions?.group}
          projectId={bulkOptions?.project}
          onGroupChange={value =>
            setBulkOptions({ ...bulkOptions, group: value })
          }
          onProjectChange={value =>
            setBulkOptions({ ...bulkOptions, project: value })
          }
          projectDisabled={!bulkOptions.group}
        />
        <Dropdown
          options={folderOptions}
          onChange={v => setBulkOptions({ ...bulkOptions, folders: v })}
          currentValue={bulkOptions?.folders}
          fieldLabel="Folder"
          multi
          searchable
          selectButtons
          className={styles.folderDropdown}
        />
        <Dropdown
          options={bulkTemplates}
          onChange={v => setBulkOptions({ ...bulkOptions, templates: v })}
          currentValue={bulkOptions.templates}
          fieldLabel="Custom Document Title"
          multi
          searchable
          selectButtons
          className={styles.titleDropdown}
        />
        <p>
          Once the report has finished generating, it can be accessed for one
          week via the Download Bulk PDF Report option in the Custom Documents
          List Page Actions menu.
        </p>
      </Modal>
      <AddToFolderModal
        isOpen={addToFolderModal}
        folderId={folderId}
        onRequestClose={() => setAddToFolderModal(false)}
        submitActions={() => handleAddMultipleToFolders()}
        folderOptions={folderOptions}
        onChange={setFolderId}
      />
    </>
  );
}
