import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import history from '../../history';
import * as apiV4 from '../../api/v4';
import { updateUserRequest } from '../../actions/user';
import { getLoggedInUser, getAccessLevel } from '../../selectors/users';
import { getActiveCompany } from '../../selectors/company';
import { commonName } from '../../utils/formValidation';
import { SaveCancelFooter } from '../../components/Footer';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Notifications from './Notifications';
import UserProfile from './UserProfile';
import MissingRequiredModal from '../../components/Modal/missingRequiredModal';

export default function Profile() {
  let activeUser = useSelector(getLoggedInUser);
  let accessLevel = useSelector(getAccessLevel);
  const location = useLocation();
  const activeCompany = useSelector(getActiveCompany);
  const dispatch = useDispatch();

  const [isEditing, setIsEditing] = useState(false);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [username, setUsername] = useState();
  const [userProfileImage, setUserProfileImage] = useState();
  const [targetImage, setTargetImage] = useState();
  const [notifications, setNotifications] = useState({
    emailNotificationsActive: false
  });
  const [emailNotificationsActive, setEmailNotificationsActive] =
    useState(false);
  const [usernameExists, setUsernameExists] = useState(false);
  const [defaultNotifications, setDefaultNotifications] = useState(null);
  const [missingRequired, setMissingRequired] = useState(false);
  const [missingModalOpen, setMissingModalOpen] = useState(false);

  useEffect(() => {
    apiV4
      .getUserNotifications(activeUser._id)
      .then(response => {
        setEmailNotificationsActive(response.emailNotificationsActive);

        apiV4.getCompanyNotifications(activeCompany._id).then(r => {
          const defaults = r[accessLevel];
          delete defaults?._id;
          for (const [category, catNotifs] of Object.entries(defaults ?? {})) {
            for (const [notif, active] of Object.entries(catNotifs)) {
              if (response[category])
                response[category][notif] = response[category][notif] || active;
            }
          }
          setNotifications(response);
          for (const [key, value] of Object.entries(defaults ?? {})) {
            defaults[key] = Object.keys(value).filter(d => value[d]);
          }
          setDefaultNotifications(defaults);
        });
      })
      .catch(e => console.error(e));
    setFirstName(activeUser.firstName);
    setLastName(activeUser.lastName);
    setEmail(activeUser.email);
    setPhoneNumber(activeUser.phoneNumber);
    setUsername(activeUser.username);
    if (location?.state?.setUp) setIsEditing(true);
  }, [
    activeCompany,
    accessLevel,
    activeUser._id,
    activeUser.email,
    activeUser.firstName,
    activeUser.lastName,
    activeUser.phoneNumber,
    activeUser.username,
    location
  ]);

  const handleNotificationChange = (values, options, label) => {
    let userNotifications = { ...notifications };
    if (values === 'clearAll') {
      let newAnswer = userNotifications[label].filter(
        v => !options.includes(v)
      );
      userNotifications[label] = newAnswer;
    } else if (values === 'selectAll') {
      let answer = [];
      options.forEach(option => {
        answer.push(option.value);
      });
      userNotifications[label] = answer;
    } else if (label === 'groups' || label === 'projects') {
      let answer = [];
      options.forEach(option => {
        if (values?.includes(option.value)) {
          answer.push(option.value);
        }
      });
      userNotifications[label] = answer;
    } else {
      //eslint-disable-next-line no-unused-expressions
      options?.forEach(notification => {
        userNotifications[label][notification.value] =
          values.findIndex(value => value === notification.value) > -1;
      });
    }
    setNotifications(userNotifications);
  };

  const submit = async () => {
    const usernameCheck = await apiV4.doesUsernameExist({
      candidateUsername: username
    });
    if (username.trim() !== activeUser.username && usernameCheck.userExists) {
      setUsernameExists(true);
      return;
    }
    if (notifications) {
      notifications.emailNotificationsActive = emailNotificationsActive;
    }

    let data = null;
    if (targetImage) {
      const files = targetImage;

      data = new FormData();

      Object.entries(files).forEach(async file => {
        data.append('attachments', file[1], file[1].name);
      });
    }

    setIsEditing(false);
    dispatch(
      updateUserRequest({
        notifications,
        firstName,
        lastName,
        email,
        phoneNumber,
        username,
        data
      })
    );
  };

  const checkUsername = v => {
    setUsername(v);
    if (v.trim())
      apiV4
        .doesUsernameExist(v.trim())
        .then(r =>
          setUsernameExists(r.userExists && v.trim() !== activeUser.username)
        );
  };

  const cancel = () => {
    setFirstName(activeUser.firstName);
    setLastName(activeUser.lastName);
    setEmail(activeUser.email);
    setPhoneNumber(activeUser.phoneNumber);
    setUsername(activeUser.username);
    setIsEditing(false);
    setUsernameExists(false);
    setMissingRequired(false);
  };

  const updateUserProfileImage = e => {
    const reader = new FileReader();
    setTargetImage(e.target.files);

    reader.readAsDataURL(e.target.files[0]);
    const base64data = new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });

    base64data.then(response => {
      setUserProfileImage(response);
    });
  };

  const canSubmit =
    firstName &&
    lastName &&
    username?.trim() &&
    (email?.trim() || phoneNumber?.trim()) &&
    !usernameExists &&
    !commonName(firstName) &&
    !commonName(lastName);

  return (
    <HeaderAndFooter
      Header={
        <Header
          title="User Profile"
          clickBack={() => history.goBack()}
          rightButtons={{
            text: 'Edit User Info',
            color: 'blue',
            visible: !isEditing,
            onClick: () => setIsEditing(true)
          }}
        />
      }
      showFooter={isEditing}
      Footer={
        <SaveCancelFooter
          editing
          saveButtonClick={() =>
            !canSubmit ? setMissingModalOpen(true) : submit()
          }
          cancelButtonClick={cancel}
          onMouseEnter={() => setMissingRequired(!canSubmit)}
        />
      }
    >
      <UserProfile
        isEditing={isEditing}
        firstName={firstName}
        lastName={lastName}
        email={email}
        phoneNumber={phoneNumber}
        username={username}
        setFirstName={setFirstName}
        setLastName={setLastName}
        setEmail={setEmail}
        setPhoneNumber={setPhoneNumber}
        setUsername={checkUsername}
        updateUserProfileImage={updateUserProfileImage}
        userProfileImage={userProfileImage}
        usernameExists={usernameExists}
        missingRequired={missingRequired}
      />
      {accessLevel > 100 && (
        <Notifications
          isEditing={isEditing}
          emailNotificationsActive={emailNotificationsActive}
          notifications={notifications}
          setEmailNotificationsActive={setEmailNotificationsActive}
          setNotifications={handleNotificationChange}
          defaultNotifications={defaultNotifications}
        />
      )}
      <MissingRequiredModal
        isOpen={missingModalOpen}
        onRequestClose={() => setMissingModalOpen(false)}
        subject="User Profile"
        isEditing={isEditing}
      />
    </HeaderAndFooter>
  );
}
