import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import history from '../../../history';
import { deleteManyAttachments } from '../../../api/v4';
import { getLoggedInUser } from '../../../selectors/users';
import { getActiveSafetyWalkSelector } from '../../../selectors/safetyWalks';
import { setActiveSafetyWalk } from '../../../actions/safetyWalks';

import AttachmentCarousel from '../AttachmentCarousel';
import Card from '../../../components/Card';
import { EmployeeDropdown, Textbox } from '../../../components/inputs';
import Header from '../../../components/Header';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import MissingRequiredModal from '../../../components/Modal/missingRequiredModal';
import Modal from '../../../components/Modal';
import { SaveCancelFooter } from '../../../components/Footer';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';

import styles from '../itemCard.module.scss';

function LegacyBravoCard() {
  const dispatch = useDispatch();
  const { index } = useParams();
  const bIndex = parseInt(index, 10);
  const myName = history.location.state?.myName;

  const sw = useSelector(getActiveSafetyWalkSelector);
  const loggedInUser = useSelector(getLoggedInUser);
  const setSafetyWalk = payload => dispatch(setActiveSafetyWalk(payload));

  const [editing, setEditing] = useState(true);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [openModalType, setOpenModalType] = useState();
  const [missingRequired, setMissingRequired] = useState(false);
  const [bravo, setBravo] = useState({});

  const { title, description, impact, bravosGiven = [], pictures = [] } = bravo;

  useEffect(() => {
    if (!Number.isNaN(bIndex)) {
      const bravo = sw?.items?.find(b => b.overallOrder === bIndex);
      setBravo({ ...bravo });
      setEditing(!bravo?._id);
    } else {
      setBravo({ overallOrder: sw?.items?.length });
    }
  }, [bIndex, sw.items]);

  const updateBravo = values => {
    setHasUnsavedChanges(true);
    setBravo({ ...bravo, ...values });
  };

  const handleBravoSubmit = () => {
    const bravoObj = {
      ...bravo,
      type: 'bravo',
      bravosGiven: bravosGiven ?? []
    };

    if (!Number.isNaN(bIndex)) {
      const index = sw?.items?.findIndex(b => b.overallOrder === bIndex);
      sw.items[index] = bravoObj;
    } else {
      sw.items = [...sw?.items, bravoObj];
    }
    setSafetyWalk(sw);
    setHasUnsavedChanges(false);
    history.push('/app/safetyWalkContainer');
  };

  const handleDiscardBravo = async () => {
    let updated = sw?.items
      .toSpliced(bravo?.overallOrder, 1)
      ?.map((item, index) => ({ ...item, overallOrder: index }));

    if (bravo?.pictures?.length) {
      let ids = bravo?.pictures?.map(a => a._id);
      await deleteManyAttachments(ids);
    }

    setSafetyWalk({ ...sw, items: updated });
    history.push('/app/safetyWalkContainer');
  };

  const header = (
    <Header
      title={myName}
      section={`${editing ? 'Edit' : 'View'} Bravo Info`}
      needsSaved={hasUnsavedChanges}
      clickBack={() =>
        hasUnsavedChanges
          ? setOpenModalType('unsavedChanges')
          : history.push('/app/safetyWalkContainer')
      }
      rightButtons={{
        visible: editing,
        text: 'Discard bravo',
        color: 'red',
        onClick: () => setOpenModalType('discardBravo')
      }}
    />
  );

  const footer = (
    <SaveCancelFooter
      saveButtonClick={() =>
        !canSubmit ? setOpenModalType('missingRequired') : handleBravoSubmit()
      }
      cancelButtonClick={() =>
        hasUnsavedChanges
          ? setOpenModalType('unsavedChanges')
          : history.push('/app/safetyWalkContainer')
      }
      editing={editing}
      onMouseEnter={() => setMissingRequired(true)}
    />
  );

  const canSubmit = title?.trim() && description?.trim() && hasUnsavedChanges;

  return (
    <>
      <HeaderAndFooter Header={header} Footer={footer} showFooter={editing}>
        <Card className={styles.card}>
          <AttachmentCarousel
            isEditing={editing}
            pictures={pictures}
            loggedInUser={loggedInUser}
            ownerType="bravo"
            handlePhotoUpdate={v => updateBravo(v)}
          />
          <div className={styles.padding}>
            <>
              <Textbox
                fieldLabel="Bravo Title"
                isRequired
                placeholder="Type a clear and concise title."
                onChange={v => updateBravo({ title: v })}
                currentValue={title}
                disabled={!editing}
                touched={missingRequired && !title}
                error
              />
              <Textbox
                type="textarea"
                fieldLabel="Description"
                placeholder="Describe this Bravo. Be sure to include any important details."
                isRequired
                onChange={v => updateBravo({ description: v })}
                currentValue={description}
                disabled={!editing}
                touched={missingRequired && !description}
                error
              />
              <Textbox
                type="textarea"
                fieldLabel="Impact on Safety Culture"
                placeholder="Describe how this will positively impact your company's safety culture."
                onChange={v => updateBravo({ impact: v })}
                currentValue={impact}
                disabled={!editing}
              />
              <EmployeeDropdown
                multi
                fieldLabel="Assign Bravo"
                currentValue={bravosGiven}
                onChange={v => updateBravo({ bravosGiven: v })}
                disabled={!editing}
                searchable
                selectButtons
                name="multiEmployees"
              />
            </>
          </div>
        </Card>
      </HeaderAndFooter>
      <Modal
        title="Discard Bravo"
        titleClassName="redHeader"
        isOpen={openModalType === 'discardBravo'}
        submitButtonColor="red"
        submitButtonText="Discard bravo"
        onRequestClose={() => setOpenModalType()}
        submitActions={handleDiscardBravo}
      >
        <div className="reportSectionContainer-emailAssigneeText">
          <span style={{ color: '#c74846', fontWeight: 'bold' }}>
            Discarding this Bravo will delete the information within, pictures
            taken, and the corrective action associated with it (if any).
          </span>
        </div>
      </Modal>
      <MissingRequiredModal
        isOpen={openModalType === 'missingRequired'}
        onRequestClose={() => setOpenModalType()}
        subject={myName}
      />
      <SaveChangesModal
        isOpen={openModalType === 'unsavedChanges'}
        onRequestClose={() => setOpenModalType()}
        submitActions={() => history.push('/app/safetyWalkContainer')}
      />
    </>
  );
}

export default LegacyBravoCard;
