import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Checkbox,
  Dropdown,
  EmployeeDropdown,
  InputRow,
  Textbox
} from '../../../inputs';
import {
  DIGITAL_OPTIONS,
  INPERSON_OPTIONS,
  TRAINING_CATEGORY_DROPDOWN
} from '../../../../constants/constants';
import history from '../../../../history';
import { getAccessLevel } from '../../../../selectors/users';
import TrainingMaterials from '../../../../containers/Training/TrainingMaterials';
import { fetchQuizzes, uploadAttachment } from '../../../../api/v4';
import styles from './detailsCard.module.scss';
import { positiveIntsOnly } from '../../../../utils/formValidation';
import DateUnitPicker from '../../../inputs/DateUnitPicker';
import Card from '../../../Card';
import RadioButtons from '../../../inputs/RadioButtons';
import { IReportDatePicker } from '../../../inputs/DateTimePicker';

export default function TrainingDetails(props) {
  const {
    training,
    onAnswer,
    editing,
    creating,
    setCanSubmit,
    fromTemplate = false,
    courseTraining,
    fromApprove,
    location,
    titleWarning,
    fromView,
    hasUnsavedChanges,
    missingRequired,
    completedStages
  } = props;

  const [quizzes, setQuizzes] = useState([]);
  const accessLevel = useSelector(getAccessLevel);

  useEffect(() => {
    fetchQuizzes().then(setQuizzes);
  }, []);

  const setSubmit = useCallback(() => {
    if (titleWarning) return setCanSubmit(false);
    if (!setCanSubmit) return;
    if (!training.title?.trim()) setCanSubmit(false);
    else if (
      training.doesExpire &&
      (!training.expiresAfterNumber ||
        parseInt(training.expiresAfterNumber) < 1 ||
        !training.expiresAfterOption)
    )
      setCanSubmit(false);
    else if (
      training.needsApproval &&
      !fromTemplate &&
      training.trainingType !== 2 &&
      !training.approver
    )
      setCanSubmit(false);
    else if (
      !(training.attachments?.length || training.youtubeLinks?.length) &&
      (!training.quizMasterId ||
        (training.trainingType === 2 && training.schedule === 2))
    )
      setCanSubmit(false);
    else if (!fromTemplate && !courseTraining && !training.schedule)
      setCanSubmit(false);
    else setCanSubmit(true);
  }, [setCanSubmit, training, courseTraining, fromTemplate, titleWarning]);

  useEffect(() => {
    setSubmit();
  }, [training, setSubmit]);

  const addAttachments = async e => {
    const files = e.target.files;
    if (!files.length) return;

    for (let i = 0; i < files.length; i++) {
      const FileSize = files[i].size / 1024 / 1024; // in MB
      if (FileSize > 2000) {
        alert('File size exceeds 2000 MB');
        return;
      }
    }
    if (files.length > 0) {
      let data = new FormData();

      Object.entries(files).forEach(async file => {
        data.append('attachments', file[1], file[1].name);
      });

      uploadAttachment(data, {
        ownerId: training._id,
        ownerType: 'trainingTemplate'
      }).then(r =>
        onAnswer({ attachments: (training.attachments ?? []).concat(r) })
      );
    }
  };

  const handleDeleteAttachment = attachment => {
    let newAtts = training.attachments;
    newAtts = newAtts?.filter(attach => attach._id !== attachment._id);

    onAnswer({
      attachments: newAtts,
      attachmentsToDelete: (training.attachmentsToDelete ?? []).concat(
        attachment._id
      )
    });
  };

  const handleScheduleChange = value => {
    onAnswer({
      schedule: value,
      assignOn: null,
      dueDate: null,
      assignBy: null,
      assignedTo: [],
      frequency: null,
      repeatingOn: [],
      dueBy: null,
      admins: [],
      assignByJobTitles: [],
      completionDate: null,
      releaseType: null
    });
  };

  const isNSC = training?.nscCompanyIds?.length ?? false;

  const renderApprovalAndExpires = () => (
    <>
      {training.trainingType !== 2 ? (
        <Checkbox
          fieldLabel="Approval Flow"
          currentValue={training.needsApproval}
          displayTooltip
          disabled={
            !editing ||
            (training.trainingTemplateId &&
              !training.isCourseTemplate &&
              !isNSC &&
              !fromTemplate)
          }
          tooltip={
            <div>
              <p>
                When this is selected, all the trainings of this type will need
                to be approved or rejected one by one.
              </p>
              <p>
                The approver will be able to view the amount of time each
                employee spent on their training. This includes quiz scores, but
                not any individual quizzes.
              </p>
            </div>
          }
          onChange={value =>
            onAnswer({
              needsApproval: value,
              approver: value ? training.approver : null
            })
          }
        />
      ) : null}
      {training.needsApproval &&
      !fromTemplate &&
      training.trainingType !== 2 ? (
        <EmployeeDropdown
          fieldLabel="Approver"
          onChange={v =>
            onAnswer({
              approver: v
            })
          }
          group={
            location?._id
              ? [location._id]
              : training.groupIds?.length
                ? training.groupIds
                : null
          }
          disabled={!editing}
          permissions={[900, 500]}
          currentValue={training.approver}
          isRequired
          touched={missingRequired && !training.approver}
          searchable
        />
      ) : null}

      <Checkbox
        fieldLabel="Training Expires"
        currentValue={training.doesExpire}
        displayTooltip
        disabled={
          !editing ||
          (training.trainingTemplateId &&
            !training.isCourseTemplate &&
            !isNSC &&
            !fromTemplate)
        }
        tooltip={
          <div>
            <p>
              This is how long the training will last after the employee
              performs it.
            </p>
            <p>This will start counting after the training due date.</p>
          </div>
        }
        onChange={value =>
          onAnswer({
            doesExpire: value,
            expiresAfterOption: training.expiresAfterOption ?? 'days',
            expiresAfterNumber: value
              ? (training.expiresAfterNumber ?? 1)
              : null
          })
        }
      />
      {training.doesExpire ? (
        <InputRow>
          <Textbox
            fieldLabel="Expires After"
            type="number"
            onChange={v =>
              onAnswer({
                expiresAfterNumber: positiveIntsOnly(v)
              })
            }
            disabled={
              !editing ||
              (training.trainingTemplateId &&
                !training.isCourseTemplate &&
                !fromTemplate &&
                !isNSC)
            }
            currentValue={training.expiresAfterNumber}
            isRequired
            touched={
              missingRequired &&
              (parseInt(training.expiresAfterNumber) < 1 ||
                !training.expiresAfterNumber)
            }
            errorMessage={
              parseInt(training.expiresAfterNumber) < 1
                ? 'Must be greater than 0'
                : ''
            }
          />
          <div className={styles.topMargin}>
            <DateUnitPicker
              currentValue={training.expiresAfterOption}
              onChange={values =>
                onAnswer({
                  expiresAfterOption: values
                })
              }
              disabled={
                !editing ||
                (training.trainingTemplateId &&
                  !training.isCourseTemplate &&
                  !fromTemplate &&
                  !isNSC)
              }
              isRequired
              touched={missingRequired && !training.expiresAfterOption}
            />
          </div>
        </InputRow>
      ) : null}
      {training.expirationDate && !creating ? (
        <InputRow>
          <IReportDatePicker
            fieldLabel="Expiration Date"
            currentValue={training.expirationDate}
            pickDate
            timeNotDetermined
            name="expirationDate"
            disabled
          />
        </InputRow>
      ) : null}
      {(training.trainingType === 1 || fromTemplate) &&
      !(courseTraining || training.isCourseTraining) ? (
        <Checkbox
          fieldLabel="Immediately Reassign if Assignee(s) fail?"
          currentValue={training.immediatelyReassign}
          displayTooltip
          disabled={
            !editing ||
            (training.trainingTemplateId &&
              !training.isCourseTemplate &&
              !fromTemplate &&
              !isNSC)
          }
          tooltip={
            <div>
              <p>
                When this is selected, any assignee that fails a training will
                be automatically reassigned this training.
              </p>
              <p>
                This training will be reassigned after everyone has completed
                the training.
              </p>
            </div>
          }
          onChange={value =>
            onAnswer({
              immediatelyReassign: value
            })
          }
        />
      ) : null}
    </>
  );

  return (
    <Card
      showHeader={creating || fromApprove}
      title={fromTemplate ? 'Template' : 'Training Details'}
      name="details"
    >
      <Textbox
        isRequired
        fieldLabel={`${fromTemplate ? 'Template' : 'Training'} Title`}
        disabled={
          fromView ||
          !editing ||
          (training.trainingTemplateId &&
            !fromTemplate &&
            !training.isCourseTemplate)
        }
        currentValue={training.title}
        onChange={value => onAnswer({ title: value })}
        touched={!training.title?.length && missingRequired}
        errorMessage={titleWarning ? 'Title already taken' : ''}
      />
      <Dropdown
        fieldLabel="Category"
        currentValue={training.category}
        disabled={
          !editing ||
          (training.trainingTemplateId &&
            !fromTemplate &&
            !training.isCourseTemplate)
        }
        options={TRAINING_CATEGORY_DROPDOWN}
        onChange={value => onAnswer({ category: value })}
      />
      <Textbox
        fieldLabel="Instructions"
        currentValue={training.instructions}
        disabled={
          !editing ||
          (training.trainingTemplateId &&
            !fromTemplate &&
            !training.canUpdateInstructions &&
            !training.isCourseTemplate)
        }
        onChange={value => onAnswer({ instructions: value })}
        type="textarea"
      />
      {training.trainingType === 2 ? (
        <RadioButtons
          fieldLabel="Type of In-Person Training"
          options={INPERSON_OPTIONS}
          currentValue={training.schedule}
          onChange={handleScheduleChange}
          disabled={fromView || !editing}
          needSeparateLines
          isRequired
          touched={missingRequired && !training.schedule}
        />
      ) : (
        <></>
      )}
      {!fromTemplate ? renderApprovalAndExpires() : <></>}
      {training.trainingType === 2 ? (
        <Checkbox
          fieldLabel="Signature Collection"
          currentValue={training.collectSignatures}
          disabled={!editing}
          onChange={value =>
            onAnswer({
              collectSignatures: value
            })
          }
        />
      ) : null}
      <TrainingMaterials
        isRequired
        training={training}
        deleteAttachment={handleDeleteAttachment}
        isCreating={creating}
        isEditing={editing}
        handleAnswer={onAnswer}
        addAttachments={addAttachments}
        attachments={training.attachments?.filter(
          a => a.source_url && (a.original_filename || a.title)
        )}
        courseTraining={courseTraining && !training.trainingTemplateId}
        youtubeLinks={training.youtubeLinks}
        quizMasterId={training.quizMasterId}
        canAddMaterials={editing || creating}
        isTemplateCreate={fromTemplate || training.isCourseTemplate}
        trainingType={training.trainingType}
        hideTime={
          fromTemplate || creating || editing || fromApprove || fromView
        }
        isNsc={isNSC}
        missingRequired={missingRequired && !training.quizMasterId}
      />
      {!(training.trainingType === 2 && training.schedule === 2) ? (
        <>
          <Dropdown
            fieldLabel="Quiz"
            currentValue={training.quizMasterId}
            options={[{ value: null, label: '', isVisible: true }].concat(
              quizzes.map(q => ({
                ...q,
                value: q._id,
                label: q.title,
                isVisible: !q.isNsc && !q.isArchived
              }))
            )}
            disabled={
              !editing ||
              (training.trainingTemplateId &&
                !training.isCourseTemplate &&
                !fromTemplate)
            }
            onChange={value => onAnswer({ quizMasterId: value })}
            touched={
              missingRequired &&
              !training.attachments?.length &&
              !training.quizMasterId
            }
          />
          {fromApprove ? null : (
            <InputRow>
              {accessLevel < 900 ? null : (
                <Button
                  text="Create New Quiz"
                  color="blue"
                  disabled={
                    isNSC ||
                    !editing ||
                    (training.trainingTemplateId &&
                      !training.isCourseTemplate &&
                      !fromTemplate)
                  }
                  onClick={() => {
                    localStorage.setItem(
                      'trainingTemplate',
                      JSON.stringify({
                        ...training,
                        stage: 1,
                        hasUnsavedChanges: hasUnsavedChanges
                      })
                    );
                    history.push({
                      pathname: '/app/quiz/quizTemplate',
                      state: { fromScreen: 'trainingTemplate' }
                    });
                  }}
                  shrink
                />
              )}
              <Button
                text="Preview Quiz"
                color="blue"
                disabled={!editing || !training.quizMasterId}
                onClick={() => {
                  localStorage.setItem(
                    'trainingTemplate',
                    JSON.stringify({
                      ...training,
                      stage: 1,
                      hasUnsavedChanges: hasUnsavedChanges,
                      completedStages
                    })
                  );
                  history.push({
                    pathname: '/app/training/quiz',
                    state: {
                      passedQuiz: quizzes.find(
                        q => q._id === training.quizMasterId
                      ),
                      isPreview: true,
                      showAnswers: accessLevel === 900,
                      hideCheckmark: true
                    }
                  });
                }}
              />
            </InputRow>
          )}
        </>
      ) : null}

      {fromTemplate ? renderApprovalAndExpires() : null}
      {training.trainingType === 1 &&
      !fromTemplate &&
      !(courseTraining || training.isCourseTraining) ? (
        <RadioButtons
          fieldLabel="Schedule"
          options={DIGITAL_OPTIONS}
          currentValue={training.schedule}
          onChange={handleScheduleChange}
          disabled={fromView || !editing || training.equipmentId}
          needSeparateLines
          isRequired
          touched={missingRequired && !training.schedule}
        />
      ) : (
        <></>
      )}
    </Card>
  );
}
