import {
  ADD_SAFETY_WALK_REQUEST,
  FETCH_SAFETY_WALKS_REQUEST,
  FETCH_SAFETY_WALKS_RESPONSE,
  LOAD_SAFETY_WALK,
  SET_ACTIVE_SAFETY_WALK
} from '../constants/actionTypes';

export const loadSafetyWalk = payload => ({
  type: LOAD_SAFETY_WALK,
  payload
});

export const fetchSafetyWalksRequest = payload => ({
  type: FETCH_SAFETY_WALKS_REQUEST,
  payload
});

export const fetchSafetyWalksResponse = payload => ({
  type: FETCH_SAFETY_WALKS_RESPONSE,
  payload
});

export const addSafetyWalkRequest = payload => ({
  type: ADD_SAFETY_WALK_REQUEST,
  payload
});

export const setActiveSafetyWalk = payload => ({
  type: SET_ACTIVE_SAFETY_WALK,
  payload
});
