import React from 'react';
import './svg.css';

const spill = props => (
  <svg
    width={props.width ?? 30}
    height={props.height ?? 25}
    viewBox="0 0 80 80"
    x={props.x || '0px'}
    y={props.y || '0px'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M33.3454 7L26.6274 13.7172C15.1687 12.1953 5.50122 16.2299 4.41749 23.1059C3.94277 26.1153 5.15363 29.1625 7.85078 31.8596C7.95411 31.9623 8.07022 32.0643 8.17883 32.1662C8.50077 32.4682 8.84827 32.7676 9.2255 33.0609C12.2321 35.3929 16.4987 37.089 21.2375 37.8356C24.9641 38.4242 28.5853 38.3609 31.7021 37.6809C31.8807 36.9015 32.2687 36.1604 32.8754 35.5529C34.5934 33.8357 37.3781 33.8357 39.0959 35.5534C40.8145 37.2706 40.8145 40.0567 39.0965 41.774C37.3793 43.4911 34.5951 43.492 32.8771 41.7753C29.1757 42.6214 24.9127 42.722 20.5757 42.0392C15.1385 41.182 10.1822 39.1873 6.61744 36.4231C6.09467 36.017 5.61941 35.5993 5.17134 35.1726L2.21194 38.1326C-0.737221 41.0817 -0.737221 45.8637 2.21139 48.8133L24.1881 70.7914C27.138 73.7414 31.9192 73.7409 34.8696 70.7909L57.8112 47.85L72.6192 46.2747L33.3454 7ZM11.1846 29.1601C9.2818 27.3749 8.35402 25.4549 8.62069 23.7683C9.15457 20.3797 14.8192 17.4645 22.6806 17.665L11.1846 29.1601Z" />
    <path d="M79.8713 63.3434C79.1447 60.8186 77.3547 59.0828 76.2447 56.7087C75.1286 54.3154 73.8575 51.4187 73.8375 48.75C73.818 51.4194 72.5461 54.316 71.4302 56.7087C70.3208 59.0834 68.5303 60.8186 67.8042 63.3434C67.207 65.4128 68.6061 69.528 73.838 69.528C79.0689 69.528 80.4688 65.412 79.8713 63.3434Z" />
  </svg>
);

export default spill;
