import React, { useState, useEffect } from 'react';
import {
  createExpectations,
  getUserExpectations,
  fetchCustomTrainingTemplates,
  updateExpectations
} from '../../api/v4';
import { Button, Dropdown, InputRow } from '../inputs';
import customToast from '../../utils/customToast';
import List from '../List';
import Modal from '../Modal';

import '../../containers/MyDocumentsStages/index.css';

export default function EmployeeExpectations({ employeeId }) {
  const [userExps, setUserExps] = useState();
  const [completedTrainings, setTrainings] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplates, setSelected] = useState([]);

  useEffect(() => {
    getUserExpectations(employeeId).then(r => {
      setUserExps(r?.expectation);
      setTrainings(r?.userTrainings);
      setSelected(r.expectation?.trainings);
    });
    fetchCustomTrainingTemplates().then(setTemplates);
  }, [employeeId]);

  const handleResetExpectations = () => {
    getUserExpectations(employeeId).then(r => {
      setUserExps(r?.expectation);
      setTrainings(r?.userTrainings);
      setSelected(r.expectation?.trainings);
    });
    setOpenModal(false);
  };

  const handleExpectationSubmit = () => {
    let obj = {
      ...userExps,
      trainings: selectedTemplates?.filter(
        st => !userExps?.companyTrainings?.includes(st)
      ),
      type: 'employee',
      userId: employeeId
    };

    if (obj?._id) {
      updateExpectations(obj).then(r => {
        customToast('Updated Successfully!', 'success');
        handleResetExpectations(r);
      });
    } else {
      createExpectations(obj).then(r => {
        customToast('Updated Successfully!', 'success');
        handleResetExpectations(r);
      });
    }
  };

  const columns = [
    { key: 'title', label: 'Training Expectation Name' },
    { key: 'completionDate', label: 'Date Last Taken', datatype: 'date' }
  ];

  return (
    <div style={{ width: '100%' }}>
      <InputRow right>
        <Button
          color="blue"
          text="Add Expectation(s)"
          onClick={() => setOpenModal(true)}
        />
      </InputRow>

      <List
        saveKey="docRepo"
        data={completedTrainings}
        dataIsHash
        settings={columns}
      />
      <Modal
        title="Select Trainings"
        titleClassName="blueHeader"
        isOpen={openModal}
        onRequestClose={() => {
          setOpenModal(false);
        }}
        submitActions={handleExpectationSubmit}
        submitButtonColor="blue"
      >
        <Dropdown
          multi
          fieldLabel="Trainings"
          options={templates
            ?.filter(t => !t.isArchived)
            ?.map(t => ({
              value: t._id,
              label: t.title
            }))}
          currentValue={[
            ...selectedTemplates,
            ...(userExps?.companyTrainings ?? [])
          ]}
          onChange={v => setSelected(v)}
          searchable
          selectButtons
          required
          defaultAnswers={userExps?.companyTrainings}
        />
      </Modal>
    </div>
  );
}
