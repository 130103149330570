import React from 'react';
import { REACTIVE_REPORT_TYPES } from '../../../constants/constants';
import { Button } from '../Button';
import svgMapper from '../../../utils/svgMapper';
import classnames from 'classnames/bind';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import styles from './styles.module.scss';

export default function ReportSelector({
  showAircraft,
  handleChange,
  filteredReportTypes
}) {
  let reportTypes = filteredReportTypes
    ? filteredReportTypes
    : showAircraft
      ? REACTIVE_REPORT_TYPES.filter(r => !r.label !== 'Spill')
      : REACTIVE_REPORT_TYPES?.filter(r => !r.isAircraft);

  return (
    <div className={styles.modalButtons}>
      {reportTypes.map(x => {
        return (
          <div data-tooltip-id={x.label} data-tip="customTheme">
            <Button
              className={styles.modalButton}
              image={svgMapper(x.svg)}
              imageClass={styles.imageStyles}
              inputClassName={styles.button}
              text={<p className={styles.buttonText}>{x.label}</p>}
              onClick={() => handleChange(x)}
              testID={x.label}
            />
            <ReactTooltip
              className={classnames(styles.tooltip, 'customTheme')}
              id={x.label}
            >
              {x.label}
            </ReactTooltip>
          </div>
        );
      })}
    </div>
  );
}
