import React from 'react';
import moment from 'moment';
import Button from '../../inputs/Button';
import UserBadge from '../../UserBadge';

import './index.css';

const isPastDue = task => {
  return !task.isCompleted && moment().diff(task.dueDate, 'days') > 0;
};

const isCompleted = task => {
  return task.isCompleted;
};

const getStyles = task =>
  task.isDeleted || task.isArchived || isPastDue(task)
    ? 'Red'
    : isCompleted(task)
      ? 'Green'
      : 'Blue';

const ReportTaskCard = ({
  task,
  handleReportSelection,
  canEditTask,
  loadTask
}) => {
  const color = getStyles(task);
  return (
    <div className="reportTask">
      <div className="reportTaskCard">
        <div className="reportTaskCard-createdDate">
          <div>{moment(task.createdAt).format('hh:mm a')}</div>
          <div>{moment(task.createdAt).format('MM/DD/YYYY')}</div>
        </div>
        <h2 className={`reportTaskCard-header ${color?.toLowerCase()}Heading`}>
          <div className="reportTaskCard-headerText">
            {task.isDeleted
              ? 'Task Deleted'
              : task.isArchived
                ? 'Task Archived'
                : isCompleted(task)
                  ? 'Task Completed'
                  : isPastDue(task)
                    ? ' Task Past Due'
                    : 'Assigned To Task'}
          </div>
        </h2>
        <div className="reportTaskCard-content">
          {task.reportComponentName ? (
            <div className="reportTaskCard-contentSummary">
              <div className={`reportTaskCard-reportContainer${color}`}>
                <div className="reportTaskCard-reportContainerText">
                  {task.reportComponentName}
                </div>
              </div>
              <div className="reportTaskCard-dueDate">
                <span>Due: </span>{' '}
                {moment(task.dueDate).format('MM/DD/YYYY hh:mm a')}
              </div>
            </div>
          ) : (
            <div className="reportTaskCard-contentSummary">
              <div className="reportTaskCard-customContainer">
                <div className="reportTaskCard-customTaskText">Custom Task</div>
                <div className="reportTaskCard-customTaskTextLabel">
                  {task.label}
                </div>
              </div>
              <div className="reportTaskCard-dueDate">
                <span>Due: </span>{' '}
                {moment(task.dueDate).format('MM/DD/YYYY hh:mm a')}
              </div>
            </div>
          )}
          <div className={`reportTaskCard-assignee${color}`}>Assignee</div>
          <UserBadge
            userId={task?.assignedTo}
            showImage
            showName
            className="reportTaskCard-assignee"
            hideMultipleUsers={
              Array.isArray(task?.assignedTo) && task?.assignedTo?.length > 1
            }
          />
        </div>
      </div>
      {task.isDeleted ? null : (
        <div className="reportTaskCard-buttons">
          {task.reportComponentName ? (
            <Button
              onClick={() => handleReportSelection(task)}
              type="button"
              text={canEditTask ? 'Edit' : 'View'}
              color="white"
            />
          ) : (
            <Button
              onClick={() => loadTask()}
              type="button"
              text={canEditTask ? 'Edit Task' : 'View Task'}
              color="white"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ReportTaskCard;
