import moment from 'moment';
import { isFutureDate, notFutureDate } from './formValidation';

export const canSubmitRepeatingSchedule = training => {
  const validRepeatingOn =
    training.frequency === 'quarterly' || training.repeatingOn?.length;

  return (
    validRepeatingOn &&
    training?.dueBy &&
    (training?.assignBy === 1
      ? isByJobTitleValid(training)
      : isByEmployeeValid(training))
  );
};

export const canSubmitImmediateSchedule = training => {
  const validDates = areValidDates(training);
  return validDates && training?.assignBy === 1
    ? isByJobTitleValid(training)
    : isByEmployeeValid(training);
};

export const canSubmitSodSchedule = (training, accessLevel) => {
  const validGroups = accessLevel === 900 || training?.groupIds?.length;

  return validGroups && training.dueBy;
};

export const canSubmitToDoInPerson = training => {
  const validRelease =
    training?.quizMasterId || training.collectSignatures
      ? training.releaseType
      : true;

  return validRelease && areValidDates(training) && areAdminsValid(training);
};

export const canSubmitCompletedInPerson = (training, accessLevel) => {
  if (accessLevel < 500 && !training.groupIds?.length) return false;

  const completionDateWarning =
    training?.completionDate && notFutureDate(training.completionDate, true);

  return (
    training.completionDate &&
    (training.assignedTo?.length || training.assignByJobTitles?.length) &&
    !completionDateWarning
  );
};

export const canSubmitCourse = course => {
  const validAssignees =
    course?.assignBy === 1
      ? isByJobTitleValid(course)
      : isByEmployeeValid(course);

  const validDates = areValidDates(course);

  const validTemplates =
    course?.trainings?.length &&
    course.trainings.every(t => !t.needsApproval || t.approver);

  return (
    course?.title?.trim()?.length &&
    validAssignees &&
    validDates &&
    validTemplates
  );
};

export const isByEmployeeValid = training =>
  training.groupAssigneePairs?.length &&
  training.groupAssigneePairs?.every(a => a.groupId && a.assignees?.length);

export const isByJobTitleValid = training =>
  training.groupAssigneePairs?.length &&
  training.groupAssigneePairs?.every(a => a.groupId && a.jobTitles?.length);

const areAdminsValid = training =>
  training.admins?.length &&
  training.admins.every(a => a.adminId && a.groupIds?.length);

export const areValidDates = training => {
  const assignOnAfterDueDate = moment(training.assignOn).isBefore(
    training.dueDate
  );
  const futureDateWarning = isFutureDate(moment(training.dueDate).endOf('day'));

  const validAssignOn =
    training?.assignOn &&
    assignOnAfterDueDate &&
    moment(training.assignOn).isSameOrAfter(moment(), 'day');

  return assignOnAfterDueDate && validAssignOn && !futureDateWarning;
};

export const getValidAssignees = (groupAssigneePair, users) => {
  let newGroup = groupAssigneePair?.groupId;
  const validUsers = groupAssigneePair?.assignees?.filter(user =>
    users.find(
      u => u._id.toString() === user && u.groupIds.some(g => newGroup === g)
    )
  );

  return validUsers;
};
