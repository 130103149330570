import React, { Component } from 'react';
import * as datefns from 'date-fns';
import DatePicker from 'react-datepicker';
import classnames from 'classnames/bind';
import { renderTime } from '../../../utils/dateHelpers';
import { normalizeTime, isValidDate } from '../../../utils/formValidation';
import Checkbox from '../Checkbox';
import Dropdown from '../Dropdown';
import Input from '..';

import styles from './dateTimePicker.module.scss';
import 'react-datepicker/dist/react-datepicker.css';
const bStyles = classnames.bind(styles);

export class IReportDatePicker extends Component {
  state = {
    timeNotDetermined: false,
    isDatePickerFocused: false,
    warningMessage: null
  };

  componentDidMount() {
    if (this.props.timeNotDetermined) {
      this.setState({ timeNotDetermined: this.props.timeNotDetermined });
    }
    if (this.props.isNA) {
      this.setState({ warningMessage: '' });
    }
  }

  componentDidUpdate = prevProps => {
    if (this.props.timeNotDetermined !== prevProps.timeNotDetermined) {
      this.setState({ timeNotDetermined: this.props.timeNotDetermined });
    }
    if (this.props.isNA !== prevProps.isNA) {
      this.setState({ warningMessage: '' });
    }
  };

  handleTimeChange = (hours, minutes) => {
    let date = this.props.currentValue
      ? new Date(this.props.currentValue)
      : new Date();

    date = datefns.setHours(date, hours);
    date = datefns.setMinutes(date, minutes);

    this.props.onChange &&
      this.props.onChange(date, this.state.timeNotDetermined);
    this.props.onBlur && this.props.onBlur();
  };

  handleTimeNotExactChange = value => {
    this.setState({ timeNotDetermined: value });

    this.props.onChange && this.props.onChange(this.props.currentValue, value);
  };

  handleDateChange = date => {
    let dateString = document.getElementById(this.props.name).value;
    if (isValidDate(dateString) === 'Invalid date' && dateString !== '') {
      this.setState({ warningMessage: 'Invalid Date' });
    } else {
      this.setState({ warningMessage: null });
    }

    if (!date) {
      this.props.onChange &&
        this.props.onChange(date, this.state.timeNotDetermined);
      return;
    } else {
      this.setState({ warningMessage: null });
    }

    if (this.props.currentValue) {
      let oldDate = new Date(this.props.currentValue);
      datefns.setHours(date, oldDate.getHours());
      datefns.setMinutes(date, oldDate.getMinutes());
      datefns.setSeconds(date, oldDate.getSeconds());
      datefns.setMilliseconds(date, oldDate.getMilliseconds());
    }

    this.props.onChange &&
      this.props.onChange(date, this.state.timeNotDetermined);
    this.props.onBlur && this.props.onBlur();
  };

  render() {
    let date = this.props.currentValue;

    const {
      name,
      disabled,
      pickDate,
      pickTime,
      pickNotExact,
      className,
      appendToBody,
      touched,
      numberOfMonths,
      errorMessage,
      ...props
    } = this.props;
    if (date && !datefns.isValid(date)) {
      date = new Date(date);
    }
    if (date && !datefns.isDate(date)) {
      date = new Date(date);
    }

    if (date?.isValid && !date.isValid()) date = null;

    return (
      <Input
        {...props}
        inactiveLabel
        className={bStyles(className, 'container', {
          pickTime: pickTime && !this.state.timeNotDetermined
        })}
        name={name}
        error={this.props.warning || this.state.warningMessage}
        touched={this.props.touched || this.state.warningMessage}
        disabled={disabled}
        errorMessage={errorMessage}
      >
        <div className={styles.pickerWrapper}>
          <DatePicker
            showIcon
            selected={date}
            onChange={this.handleDateChange}
            id={name}
            disabled={disabled}
            placeholderText="MM/DD/YYYY"
            isClearable={!disabled}
            minDate={this.props.minDate}
            maxDate={this.props.maxDate}
            className={
              (this.state.invalidField || touched || this.props.warning) &&
              !disabled
                ? styles.invalidPicker
                : disabled
                  ? styles.disabledPicker
                  : styles.picker
            }
            calendarIconClassName={styles.calendarIcon}
            dayClassName={d =>
              date?.toString() === d?.toString() ? styles.selected : undefined
            }
            popperPlacement="top-end"
            strictParsing
          />
          {pickTime && !this.state.timeNotDetermined && (
            <TimePicker
              hours={date ? date?.getHours() : null}
              minutes={date ? date?.getMinutes() : null}
              onChange={this.handleTimeChange}
              className={styles.timePicker}
              disabled={disabled}
              invalidDate={
                this.state.invalidField || touched || this.props.warning
              }
            />
          )}
        </div>
        {pickNotExact && (
          <Checkbox
            fieldLabel="Exact Time Cannot Be Determined"
            name="timeNotDetermined"
            onChange={this.handleTimeNotExactChange}
            currentValue={this.state.timeNotDetermined}
            disabled={disabled}
          />
        )}
      </Input>
    );
  }
}

export class TimePicker extends Component {
  state = {
    hours: null,
    minutes: null,
    selectedTime: ''
  };

  componentDidMount() {
    const { hours, minutes } = this.props;
    this.setState({
      hours,
      minutes,
      selectedTime: renderTime(hours, minutes)
    });
  }

  componentDidUpdate = prevProps => {
    const { hours, minutes } = this.props;
    if (hours !== prevProps.hours || minutes !== prevProps.minutes) {
      this.setState({
        hours,
        minutes,
        selectedTime: renderTime(hours, minutes)
      });
    }
  };

  handleTimeChange = () => {
    this.setState(
      state => {
        let v = state.selectedTime;
        let hours = null;
        let minutes = null;
        if (v) {
          [hours, minutes] = v.split(':');
          hours = parseInt(hours, 10);
          minutes = parseInt(minutes, 10);
          if (hours === 12) hours = 0;
          if (hours > 23) hours = 23;
          if (minutes > 59) minutes = 59;
          if (state.hours >= 12 && hours < 12) hours += 12;
        }

        return {
          hours,
          minutes,
          selectedTime: renderTime(hours, minutes)
        };
      },
      () => {
        this.props.onChange &&
          this.props.onChange(this.state.hours, this.state.minutes);
        this.props.onBlur && this.props.onBlur();
      }
    );
  };

  handlePeriodChange = value => {
    this.setState(
      state => {
        let isPM = value === 'PM';
        let hours = state.hours % 12;

        if (isPM) hours += 12;

        return {
          hours,
          selectedTime: renderTime(hours, state.minutes)
        };
      },
      () => {
        this.props.onChange &&
          this.props.onChange(this.state.hours, this.state.minutes);
        this.props.onBlur && this.props.onBlur();
      }
    );
  };

  render() {
    const { name, disabled, ...props } = this.props;

    const amPm = [
      { value: 'AM', label: 'AM' },
      { value: 'PM', label: 'PM' }
    ];

    return (
      <Input name={name} {...props}>
        <div className={styles.time}>
          <input
            value={this.state.selectedTime}
            name={name}
            type="text"
            placeholder="00:00"
            className={bStyles(`${styles.textbox} textbox`, {
              onlyTime: this.props.onlyTime,
              invalidTime: this.props.invalidDate || this.props.touched
            })}
            onChange={e => {
              this.setState({ selectedTime: normalizeTime(e.target.value) });
            }}
            disabled={disabled}
            maxLength={5}
            onBlur={this.handleTimeChange}
            data-cy="timePicker"
          />
          <Dropdown
            options={amPm}
            currentValue={this.state.hours >= 12 ? 'PM' : 'AM'}
            onChange={this.handlePeriodChange}
            className={styles.dropdown}
            inputClassName={styles.input}
            disabled={disabled}
            invalidDateTime={this.props.invalidDate || this.props.touched}
          />
        </div>
      </Input>
    );
  }
}
