import React from 'react';
import {
  COMBINED_EMPLOYEE_STATUS,
  DAY_OF_WEEK_DROPDOWN,
  GENDER_DROPDOWN
} from '../../constants/constants';
import { states } from '../../constants/states';
import {
  getSelectedCompanies,
  getGroupDropdown,
  getProjectDropdown
} from '../../utils/employeeDGPHelper';
import {
  commonName,
  normalizeSSN,
  validZip,
  email,
  normalizePhone
} from '../../utils/formValidation';
import { TwoColumn, Textbox, Dropdown, Button, InputRow } from '../inputs';
import { IReportDatePicker } from '../inputs/DateTimePicker';
import RadioButtons from '../inputs/RadioButtons';
import UserAccessButtons from '../inputs/UserAccessButtons';
import BlockText from '../inputs/BlockText';

export default function EmployeeForm(props) {
  const {
    employee,
    onDelete,
    disabled,
    isUpdate,
    userCompanies,
    accessLevel,
    isAccountOwner,
    limitCollabEdits,
    missingRequired,
    onSubmit,
    updateEmployee,
    checkUsernameAvailability,
    userExists
  } = props;

  const isAdmin = accessLevel === 900 && isUpdate;

  const handleAdminCompanyChange = ids => {
    const selectedCompanies = getSelectedCompanies(userCompanies, ids);

    let groupIds = [];
    let projectIds = [];

    if (selectedCompanies.length > 0)
      selectedCompanies.forEach(company =>
        company.groups.forEach(group => {
          groupIds.push(group._id);
          group.projects.forEach(project => projectIds.push(project._id));
        })
      );

    updateEmployee({
      companyIds: ids,
      groupIds: groupIds,
      projectIds: projectIds
    });
  };

  const companiesDropdown = (userCompanies ?? []).map(company => ({
    value: company._id,
    label: company.name
  }));

  return (
    <>
      {isUpdate ? (
        <InputRow right>
          <Button text="Update" color="blue" onClick={onSubmit} />
          {!isAccountOwner ? (
            <Button
              text="Delete"
              color="red"
              onClick={onDelete}
              disabled={limitCollabEdits}
            />
          ) : (
            <></>
          )}
        </InputRow>
      ) : (
        <></>
      )}
      <TwoColumn>
        <div>
          <Dropdown
            options={companiesDropdown}
            fieldLabel="Companies"
            currentValue={employee?.companyIds}
            onChange={v => {
              if (isAdmin) handleAdminCompanyChange(v);
              else updateEmployee({ companyIds: v });
            }}
            isRequired
            touched={!employee?.companyIds && missingRequired}
            multi
            disabled={disabled || isAccountOwner || limitCollabEdits}
            searchable
            selectButtons
          />
          <BlockText blockOfText="Admins are  given access to all Groups/Establishments and Areas within the selected companies. To limit access change the user type to Collaborator" />
          <Dropdown
            options={getGroupDropdown(userCompanies, employee)}
            fieldLabel="Group or Establishment"
            currentValue={employee?.groupIds}
            onChange={v => updateEmployee({ groupIds: v })}
            isRequired
            touched={!employee?.groupIds && missingRequired}
            multi
            disabled={disabled || isAccountOwner || isAdmin || limitCollabEdits}
            searchable
            selectButtons
          />
          <Dropdown
            options={getProjectDropdown(userCompanies, employee)}
            fieldLabel="Area"
            currentValue={employee?.projectIds}
            onChange={v => updateEmployee({ projectIds: v })}
            multi
            disabled={disabled || isAccountOwner || isAdmin || limitCollabEdits}
            searchable
            selectButtons
          />
          <Textbox
            fieldLabel="Employee ID"
            currentValue={employee?.employeeNumber}
            onChange={v => updateEmployee({ employeeNumber: v })}
            disabled={disabled}
            isRequired
            touched={!employee?.employeeNumber && missingRequired}
          />
          <Textbox
            fieldLabel="First Name"
            currentValue={employee?.firstName}
            onChange={v => updateEmployee({ firstName: v })}
            disabled={disabled}
            isRequired
            touched={
              (!employee?.firstName && missingRequired) ||
              commonName(employee?.firstName)
            }
            errorMessage={commonName(employee?.firstName) ?? ''}
          />
          <Textbox
            fieldLabel="Middle Name"
            currentValue={employee?.middleName}
            onChange={v => updateEmployee({ middleName: v })}
            disabled={disabled}
            touched={commonName(employee?.middleName)}
            errorMessage={commonName(employee?.middleName)}
          />
          <Textbox
            fieldLabel="Last Name"
            currentValue={employee?.lastName}
            onChange={v => updateEmployee({ lastName: v })}
            disabled={disabled}
            isRequired
            touched={
              (!employee?.lastName && missingRequired) ||
              commonName(employee?.lastName)
            }
            errorMessage={commonName(employee?.lastName)}
          />
          <Dropdown
            options={GENDER_DROPDOWN}
            fieldLabel="Gender"
            currentValue={employee?.gender}
            onChange={v => updateEmployee({ gender: v })}
            disabled={disabled}
          />
          <IReportDatePicker
            fieldLabel="Date of Birth"
            currentValue={employee?.dateOfBirth}
            onChange={v => updateEmployee({ dateOfBirth: v })}
            maxDate={new Date()}
            disabled={disabled}
            name="empDOB"
          />
          <Textbox
            fieldLabel="SSN"
            currentValue={employee?.ssn}
            onChange={v => updateEmployee({ ssn: normalizeSSN(v) })}
            disabled={disabled}
          />
          <IReportDatePicker
            fieldLabel="Date of Hire"
            currentValue={employee?.dateOfHire}
            onChange={v => updateEmployee({ dateOfHire: v })}
            disabled={disabled}
            isRequired
            touched={missingRequired && !employee?.dateOfHire}
            name="empDOH"
          />
        </div>
        <div>
          <Textbox
            fieldLabel="Job Title"
            currentValue={employee?.jobTitle}
            onChange={v => updateEmployee({ jobTitle: v })}
            disabled={disabled}
            isRequired
            touched={missingRequired && !employee?.jobTitle}
          />
          <Dropdown
            fieldLabel="Employee Status"
            options={COMBINED_EMPLOYEE_STATUS}
            currentValue={employee?.status}
            onChange={v => updateEmployee({ status: v })}
            isRequired
            touched={missingRequired && !employee?.status}
            disabled={disabled}
          />
          <Dropdown
            fieldLabel="Training Required"
            options={[
              { value: true, label: 'Yes' },
              { value: false, label: 'No' }
            ]}
            currentValue={employee?.trainingRequired}
            onChange={v => updateEmployee({ trainingRequired: v })}
            disabled={disabled}
          />
          <Textbox
            fieldLabel="Location"
            currentValue={employee?.location}
            onChange={v => updateEmployee({ location: v })}
            disabled={disabled}
          />
          <Textbox
            fieldLabel="Department"
            currentValue={employee?.department}
            onChange={v => updateEmployee({ department: v })}
            disabled={disabled}
          />
          <Textbox
            fieldLabel="Supervisor"
            currentValue={employee?.supervisor}
            onChange={v => updateEmployee({ supervisor: v })}
            disabled={disabled}
          />
          <Dropdown
            fieldLabel="Work Days"
            options={DAY_OF_WEEK_DROPDOWN}
            currentValue={employee?.workDays}
            onChange={v => updateEmployee({ workDays: v })}
            disabled={disabled}
            multi
            selectButtons
            searchable
          />
          <Textbox
            type="number"
            fieldLabel="Hours Per Week"
            currentValue={employee?.hoursPerWeek}
            onChange={v => updateEmployee({ hoursPerWeek: v })}
            disabled={disabled}
          />
          <IReportDatePicker
            fieldLabel="Date of Termination"
            currentValue={employee?.dateOfTermination}
            onChange={v => updateEmployee({ dateOfTermination: v })}
            disabled={disabled}
            name="empDOT"
          />
        </div>
      </TwoColumn>
      <h2>Contact Information</h2>
      <TwoColumn>
        <div>
          <Textbox
            fieldLabel="Home Address"
            currentValue={employee?.address?.streetAddress}
            onChange={v =>
              updateEmployee({
                address: {
                  ...employee.address,
                  streetAddress: v
                }
              })
            }
            disabled={disabled}
          />
          <Textbox
            fieldLabel="City"
            currentValue={employee?.address?.city}
            onChange={v =>
              updateEmployee({ address: { ...employee.address, city: v } })
            }
            disabled={disabled}
          />
        </div>
        <div>
          <Dropdown
            fieldLabel="State"
            options={states}
            currentValue={employee?.address?.stateName}
            onChange={v =>
              updateEmployee({ address: { ...employee.address, stateName: v } })
            }
            disabled={disabled}
            searchable
          />
          <Textbox
            type="number"
            fieldLabel="Zip Code"
            currentValue={employee?.address?.zip}
            onChange={v =>
              updateEmployee({ address: { ...employee.address, zip: v } })
            }
            touched={validZip(employee?.address?.zip) && missingRequired}
            errorMessage={validZip(employee?.address?.zip)}
          />
        </div>
      </TwoColumn>
      <h2>Emergency Contact Information</h2>
      <TwoColumn>
        <div>
          <Textbox
            fieldLabel="Contact"
            currentValue={employee?.emergencyContact?.emergencyContact}
            onChange={v =>
              updateEmployee({
                emergencyContact: {
                  ...employee?.emergencyContact,
                  emergencyContact: v
                }
              })
            }
            disabled={disabled}
          />
          <Textbox
            type="tel"
            fieldLabel="Phone"
            currentValue={employee?.emergencyContact?.phoneNumber}
            onChange={v => {
              const phone = normalizePhone(v);
              updateEmployee({
                emergencyContact: {
                  ...employee?.emergencyContact,
                  phoneNumber: phone
                }
              });
            }}
            disabled={disabled}
          />
        </div>
        <div>
          <Textbox
            fieldLabel="Relationship"
            currentValue={employee?.emergencyContact?.relationship}
            onChange={v =>
              updateEmployee({
                emergencyContact: {
                  ...employee?.emergencyContact,
                  relationship: v
                }
              })
            }
            disabled={disabled}
          />
          <Textbox
            fieldLabel="Email"
            currentValue={employee?.emergencyContact?.email}
            onChange={v =>
              updateEmployee({
                emergencyContact: {
                  ...employee?.emergencyContact,
                  email: v
                }
              })
            }
            touched={
              email(employee?.emergencyContact?.email) && missingRequired
            }
            errorMessage={email(employee?.emergencyContact?.email)}
          />
        </div>
      </TwoColumn>
      {!isUpdate && accessLevel === 900 ? (
        <div>
          <h2>User Information</h2>
          <Textbox
            fieldLabel="Username"
            currentValue={employee?.username}
            onChange={checkUsernameAvailability}
            isRequired
            touched={(missingRequired && !employee?.username) || userExists}
            errorMessage={userExists ? 'Username is unavailable' : ''}
          />
          <h3>
            In order to receive notifications, a valid email must be provided or
            push notifications activated on the employee's device.
          </h3>
          <TwoColumn>
            <div>
              <Textbox
                fieldLabel="Phone"
                currentValue={employee?.phoneNumber}
                onChange={v => {
                  const phone = normalizePhone(v);
                  updateEmployee({ phoneNumber: phone });
                }}
                disabled={disabled}
              />
            </div>
            <div>
              <Textbox
                fieldLabel="Email"
                currentValue={employee?.email}
                onChange={v => updateEmployee({ email: v })}
                disabled={disabled}
                error
                touched={email(employee?.email) && missingRequired}
                errorMessage={email(employee?.email)}
              />
            </div>
          </TwoColumn>
          <RadioButtons
            fieldLabel="Using Microsoft login? NOTE: Must have first set up your company's Azure Active Directory with iReportSource. If unsure, choose N/A."
            currentValue={employee?.isMicrosoftUser}
            options={[
              { value: true, label: 'Microsoft Login' },
              { value: false, label: 'N/A' }
            ]}
            onChange={v => updateEmployee({ isMicrosoftUser: v })}
            isRequired
            touched={missingRequired && employee?.isMicrosoftUser === undefined}
          />
          <RadioButtons
            fieldLabel="Should this user be activated? Activated users will receive a welcome email once they are created, giving them instructions on how to log in to iReport. Deactivated users can be activated later on."
            currentValue={employee?.isDeactivated}
            options={[
              { value: false, label: 'Activated User' },
              { value: true, label: 'Deactivated User (cannot login)' }
            ]}
            onChange={v => updateEmployee({ isDeactivated: v })}
            isRequired
            touched={missingRequired && employee?.isDeactivated === undefined}
          />
          <UserAccessButtons
            onClick={v => updateEmployee({ accessLevel: v })}
            currentValue={employee?.accessLevel}
            touched={missingRequired && !employee?.accessLevel}
          />
        </div>
      ) : null}
    </>
  );
}
