import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import history from '../../../history';
import {
  updateSafetyMeetingTemplate,
  createSafetyMeetingTemplate,
  deleteSafetyMeetingTemplate,
  fetchSafetyMeetingTemplateById,
  uploadAttachment,
  deleteAttachment
} from '../../../api/v4';
import { getLoggedInUser } from '../../../selectors/users';
import { formatFiles } from '../../../utils/attachmentSelector';
import customToast from '../../../utils/customToast';
import DeleteTemplateModal from '../../../components/Modal/deleteTemplateModal';
import Header from '../../../components/Header';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import { SaveCancelFooter } from '../../../components/Footer';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';
import TemplateCard from '../../../components/behaviorObservations/TemplateCard';
import AttachmentUploadCard from '../../../components/AttachmentUploadCard';
import MissingRequiredModal from '../../../components/Modal/missingRequiredModal';

export default function SafetyMeetingTemplate() {
  let { id, duplicate } = useParams();
  const user = useSelector(getLoggedInUser);
  const [meeting, setMeeting] = useState({});
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [editing, setEditing] = useState(false);
  const [creating, setCreating] = useState(false);
  const [attachmentsToUpload, setAttachmentsToUpload] = useState([]);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [openSaveChangesModal, setOpenSaveChangesModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [missingRequired, setMissingRequired] = useState(false);
  const [missingModalOpen, setMissingModalOpen] = useState(false);
  useEffect(() => {
    if (id) {
      fetchSafetyMeetingTemplateById(id).then(r => {
        if (duplicate) {
          delete r._id;
          r.fields.forEach(f => delete f._id);
          r.title = `${r.title} - Copy`;
          setCreating(true);
        }
        setMeeting(r);
      });
    } else {
      setCreating(true);
    }
  }, [id, duplicate]);

  const addAttachments = attachments => {
    let data = formatFiles(attachments);
    uploadAttachment(data, {
      ownerType: 'safetyMeetingTemplate',
      isSignature: false,
      isPhotoEvidence: false
    }).then(response => {
      if (response === 'No file object attached') {
        customToast(response, 'error');
        return;
      }

      creating
        ? createSafetyMeetingTemplate({
            ...meeting,
            attachments: [...response, ...(meeting.attachments ?? [])]
          }).then(r => {
            setAttachmentsToUpload([]);
            setCreating(false);
            setEditing(false);
            setUnsavedChanges(false);
            setMeeting(r);
          })
        : updateSafetyMeetingTemplate({
            ...meeting,
            attachments:
              (meeting.attachments ?? [])?.concat(response) ?? response
          }).then(r => {
            setMeeting(r);
          });
    });
  };
  const deleteAttach = attachment => {
    if (duplicate) {
      setMeeting({
        ...meeting,
        attachments: meeting.attachments.filter(a => a._id !== attachment._id)
      });
      return;
    }
    deleteAttachment(attachment._id, false).then(() => {
      let attachments = meeting.attachments.filter(a => a !== attachment);

      if (editing) {
        updateSafetyMeetingTemplate({
          ...meeting,
          attachments
        }).then(setMeeting);
      }
    });
  };

  const canSubmit = () => {
    const questionsHaveAllParts = meeting?.fields?.filter(
      question =>
        !question.type ||
        !question.label?.replace(/\u200B/g, '').trim() ||
        (question.type &&
          ['dropdown', 'multiselect', 'radiobuttons', 'checkbox'].includes(
            question.type
          ) &&
          question.options.length === 0)
    );

    if (
      meeting?.fields?.length > 0 &&
      questionsHaveAllParts.length === 0 &&
      meeting?.title?.trim().length
    ) {
      return true;
    }
    return false;
  };

  const handleSubmit = () => {
    if (!creating) {
      updateSafetyMeetingTemplate(meeting).then(response => {
        setMeeting(response);
        setEditing(false);
        setUnsavedChanges(false);
      });
    } else {
      if (filesToUpload.length) addAttachments(filesToUpload);
      else
        createSafetyMeetingTemplate(meeting).then(
          response => {
            setMeeting(response);
            setCreating(false);
            setUnsavedChanges(false);
          },
          e => customToast(e.data, 'error')
        );
    }
  };

  const deleteTemplate = () => {
    deleteSafetyMeetingTemplate(meeting._id).then(() => {
      history.push('/app/safetyMeeting/templates');
    });
  };

  const handleArchive = () => {
    updateSafetyMeetingTemplate({
      ...meeting,
      isArchived: !meeting?.isArchived
    }).then(response => {
      if (response.isArchived) history.push('/app/safetyMeeting/templates');
      else {
        setMeeting(response);
        setUnsavedChanges(false);
      }
    });
  };

  const header = (
    <Header
      title="Safety Meeting Template"
      section={
        !creating && !editing
          ? 'View Template'
          : creating
            ? 'Create Template'
            : 'Edit Template'
      }
      needsSaved={unsavedChanges}
      clickBack={() =>
        unsavedChanges ? setOpenSaveChangesModal(true) : history.goBack()
      }
      rightButtons={{
        text: editing ? 'Delete Template' : 'Edit Template',
        onClick: editing
          ? () => setOpenDeleteModal(true)
          : () => setEditing(true),
        visible: !creating,
        color: editing ? 'red' : 'blue'
      }}
      pageActionOptions={
        !creating
          ? [
              {
                label:
                  editing || meeting?.isArchived
                    ? 'Delete Template'
                    : 'Edit Template',
                onClick:
                  editing || meeting?.isArchived
                    ? () => setOpenDeleteModal(true)
                    : () => setEditing(true),
                visible: !creating,
                color:
                  editing || meeting?.isArchived ? 'redOutline' : 'blueOutline'
              },
              {
                label: `${meeting?.isArchived ? 'Un-' : ''}Archive Template`,
                onClick: () => handleArchive(),
                visible: true,
                color: 'blueOutline'
              }
            ]
          : null
      }
    />
  );

  const footer = (
    <SaveCancelFooter
      editing={editing}
      saveButtonClick={() =>
        !canSubmit() ? setMissingModalOpen(true) : handleSubmit()
      }
      saveButtonDisabled={!unsavedChanges && editing}
      cancelButtonClick={() =>
        editing && unsavedChanges
          ? setOpenSaveChangesModal(true)
          : editing && !unsavedChanges
            ? setEditing(false)
            : setOpenSaveChangesModal(true)
      }
      onMouseEnter={() => setMissingRequired(!canSubmit())}
    />
  );

  return (
    <HeaderAndFooter
      Header={header}
      Footer={footer}
      showFooter={creating || editing}
    >
      <TemplateCard
        onChange={(values, e) => {
          setMeeting({ ...meeting, ...values });
          setUnsavedChanges(true);
        }}
        disabled={!editing && !creating}
        editing={editing}
        document={meeting}
        type="safetyMeeting"
        templateType="Safety Meeting"
        blockTextNote="Each Safety Meeting will automatically have fields for Administrator and Attendees"
        missingRequired={missingRequired}
      />
      <AttachmentUploadCard
        name={2}
        hasStatus={false}
        addAttachments={addAttachments}
        deleteAttachment={deleteAttach}
        isCreating={creating}
        isEditing={editing}
        isDuping={duplicate}
        addedAttachments={meeting?.attachments || []}
        attachmentsToUpload={attachmentsToUpload}
        formattedAttachmentsToUpload={filesToUpload}
        ownerType="safetyMeetingTemplate"
        handleAnswer={(currentAttachments, currentFiles) => {
          setAttachmentsToUpload(currentAttachments);
          setFilesToUpload(currentFiles);
          setUnsavedChanges(true);
        }}
        createdBy={user._id}
      />
      <DeleteTemplateModal
        deletingWhat="Safety Meeting"
        isOpen={openDeleteModal}
        onRequestClose={() => setOpenDeleteModal(false)}
        submitActions={deleteTemplate}
      />
      <SaveChangesModal
        savingWhat="Safety Meeting"
        isOpen={openSaveChangesModal}
        onRequestClose={() => setOpenSaveChangesModal(false)}
        submitActions={() => history.goBack()}
      />
      <MissingRequiredModal
        isOpen={missingModalOpen}
        onRequestClose={() => setMissingModalOpen(false)}
        subject="Safety Meeting Template"
        isEditing={editing}
      />
    </HeaderAndFooter>
  );
}
