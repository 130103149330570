import React, { Component } from 'react';
import { connect } from 'react-redux';
import { leftNavigation } from '../../constants/leftNavigation';
import { clearUploadedAttachments } from '../../actions/attachments';
import {
  addDocumentFolderRequest,
  fetchDocumentFoldersRequest,
  setActiveDocumentFolderRequest
} from '../../actions/documents';
import {
  fetchIncidentBasicsRequest,
  setActiveIncidentResponse
} from '../../actions/incidents';
import { setActiveSafetyWalk } from '../../actions/safetyWalks';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject
} from '../../selectors/company';
import {
  getDocumentFoldersSelector,
  getActiveFolderSelector
} from '../../selectors/documents';
import { getLoggedInUser } from '../../selectors/users';

import LeftNavSection from '../../components/LeftNavSection/';

import styles from './leftNav.module.scss';

export class LeftNav extends Component {
  state = {
    sectionClickedOn: ''
  };

  handleSectionOpen = sectionClickedOn => {
    this.setState({ sectionClickedOn });
  };

  render() {
    const {
      loggedInUser,
      activeCompany,
      activeLocation,
      activeProject,
      documentFolders,
      addDocumentFolder,
      setActiveFolder,
      fetchQualifications,
      fetchDocumentFolders,
      showLeftNav,
      activeSection,
      activeFolder
    } = this.props;

    const { sectionClickedOn } = this.state;
    return (
      <div className={showLeftNav ? styles.sidebar : styles.hideSidebar}>
        <aside className={showLeftNav ? styles.sections : styles.hideSections}>
          {leftNavigation.sections.map((navSection, index) => {
            return navSection.accessLevel.indexOf(loggedInUser.accessLevel) !==
              -1 ? (
              <LeftNavSection
                testID={navSection.title}
                activeSection={
                  index === activeSection?.[0] ? activeSection[1] : null
                }
                activeSubSection={index}
                title={navSection.title}
                section={navSection.section}
                key={index}
                accessLevel={loggedInUser.accessLevel}
                loggedInUser={loggedInUser}
                sectionOpen={
                  parseInt(sectionClickedOn) >= 0
                    ? sectionClickedOn === index
                    : activeSection?.[0] === index
                }
                openNewSection={this.handleSectionOpen}
                activeCompany={activeCompany}
                activeLocation={activeLocation}
                activeProject={activeProject}
                documentFolders={documentFolders}
                addDocumentFolder={addDocumentFolder}
                setActiveFolder={setActiveFolder}
                fetchQualifications={fetchQualifications}
                fetchDocumentFolders={fetchDocumentFolders}
                activeFolder={activeFolder}
              />
            ) : (
              ''
            );
          })}
        </aside>
      </div>
    );
  }
}
export const mapStateToProps = state => ({
  loggedInUser: getLoggedInUser(state),
  activeCompany: getActiveCompany(state),
  activeLocation: getActiveLocationId(state),
  activeProject: getActiveProject(state),
  documentFolders: getDocumentFoldersSelector(state),
  activeFolder: getActiveFolderSelector(state)
});

export const mapDispatchToProps = dispatch => ({
  addDocumentFolder: values => dispatch(addDocumentFolderRequest(values)),
  setActiveFolder: values => dispatch(setActiveDocumentFolderRequest(values)),
  fetchIncidentBasics: reportTypes =>
    dispatch(fetchIncidentBasicsRequest(reportTypes)),
  setIncident: () => dispatch(setActiveIncidentResponse({})),
  clearAttachments: () => dispatch(clearUploadedAttachments()),
  setSafetyWalk: () => dispatch(setActiveSafetyWalk({})),
  fetchDocumentFolders: values => dispatch(fetchDocumentFoldersRequest(values))
});

// eslint-disable-next-line import/no-anonymous-default-export
export default connect(mapStateToProps, mapDispatchToProps)(LeftNav);
