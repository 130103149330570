import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as msal from 'msal';
import localforage from 'localforage';
import { isMobile, isAndroid, isIOS } from 'react-device-detect';
import { fetchMicrosoftLoginConfig } from '../../api/v4';
import {
  forgotPasswordRequest,
  signIn,
  submitSecurityQuestions,
  signOut,
  loginFailure
} from '../../actions/auth';
import { setActiveCompany } from '../../actions/company';
import {
  getLoginFailure,
  getSecurityQuestionsSelector
} from '../../selectors/auth';
import customToast from '../../utils/customToast';
import { usePrevious, useEffectUpdate } from '../../utils/hooks';
import { Button } from '../../components/inputs';
import Chevron from '../../svgs/chevron';
import LoginForm from '../../forms/LoginForm';
import logoImage from '../TopHeader/logo.png';
import Modal from '../../components/Modal';
import ResetPasswordForm from '../../forms/ResetPasswordForm';
import { ResetUserPasswordForm } from '../../forms/ResetUserPasswordForm';

import styles from './login.module.scss';

export let SSOClient = null;

export default function Login(props) {
  const securityQuestions = useSelector(getSecurityQuestionsSelector);
  const loginFail = useSelector(getLoginFailure);

  const dispatch = useDispatch();
  const logoutUser = () => dispatch(signOut());
  const signInDispatcher = payload => dispatch(signIn(payload));
  const forgotPassword = email => dispatch(forgotPasswordRequest(email));
  const submitQuestions = payload => dispatch(submitSecurityQuestions(payload));
  const setLoginFailure = payload => dispatch(loginFailure(payload));
  const setCompany = useCallback(payload => {
    dispatch(setActiveCompany(payload));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [badUsername, setBadUsername] = useState(false);
  const [badPassword, setBadPassword] = useState(false);
  const [rememberUser, setRememberUser] = useState(false);
  const [mobileModal, setMobileModal] = useState(isMobile);
  const [loginPage, setLoginPage] = useState('login');
  const [isLoading, setIsLoading] = useState(false);

  const prev = usePrevious({ isLoading, loginFail });

  useEffect(() => {
    localforage.clear();
    sessionStorage.clear();
    setCompany({});
    if (localStorage.getItem('username')) setRememberUser(true);
  }, [setCompany]);

  useEffectUpdate(() => {
    if (prev?.isLoading) setIsLoading(false);

    if (prev.loginFail === 'Your username or password is incorrect') {
      setBadPassword(true);
      setBadUsername(true);
      setLoginFailure('');
    }
  }, [prev?.isLoading, prev?.loginFailure]);

  const handleChangeCheckbox = checked => {
    if (!checked) {
      localStorage.removeItem('username');
    }
    setRememberUser(checked);
  };

  const handleLoginSubmit = values => {
    if (!values.username) {
      if (!values.password) {
        setBadUsername(true);
        setBadPassword(true);
        customToast('Missing Username and Password.', 'error');
      } else {
        setBadUsername(true);
        setBadPassword(false);
        customToast('Missing Username.', 'error');
      }
    } else if (!values.password) {
      setBadUsername(false);
      setBadPassword(true);
      customToast('Missing Password.', 'error');
    } else {
      signInDispatcher({
        ...values,
        referrer: props?.location?.state?.referrer
      });
      setIsLoading(true);
      if (rememberUser) {
        localStorage.setItem('username', values.username);
      }
    }
  };
  return (
    <>
      {loginPage === 'login' ? (
        <div className={styles.loginWholePage}>
          <div className={styles.loginFlexbox}>
            <div className={styles.login}>
              <img src={require('../../assets/images/Logo.png')} alt="" />
              <LoginForm
                onSubmit={handleLoginSubmit}
                handleChangeCheckbox={handleChangeCheckbox}
                rememberUser={rememberUser}
                isLoading={isLoading}
                badUsername={badUsername}
                badPassword={badPassword}
                setLoginPage={value => setLoginPage(value)}
                onMicrosoftSubmit={async event => {
                  const config = await fetchMicrosoftLoginConfig();
                  if (config) {
                    const msalConfig = {
                      auth: config
                    };
                    SSOClient = new msal.UserAgentApplication(msalConfig);
                    SSOClient.loginPopup()
                      .then(response => {
                        return (
                          signInDispatcher({
                            ...response,
                            referrer: props?.location?.state?.referrer,
                            isMicrosoft: true
                          }) && setIsLoading(true)
                        );
                      })
                      .catch(e => {
                        SSOClient = null;
                      });
                  }
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.passwordRecoveryWholePage}>
          <Button
            className={styles.passwordRecoveryBackButton}
            color="greenOutline"
            text="Back to Login"
            image={<Chevron className={styles.greenChevron} />}
            shrink
            onClick={() => {
              sessionStorage.clear();
              logoutUser();
              return setLoginPage('login');
            }}
          />
          <div className={styles.passwordRecovery}>
            {securityQuestions &&
            Array.isArray(securityQuestions) &&
            securityQuestions.length > 1 ? (
              <ResetUserPasswordForm
                securityQuestions={securityQuestions}
                submitQuestions={submitQuestions}
                logoutUser={logoutUser}
              />
            ) : (
              <ResetPasswordForm onSubmit={values => forgotPassword(values)} />
            )}
          </div>
        </div>
      )}
      <Modal
        isOpen={mobileModal}
        title="Best Viewed on our Mobile App"
        titleClassName="blueHeader"
        className={styles.modalMobile}
        hideButtons
        onRequestClose={() => setMobileModal(false)}
      >
        <img
          src={logoImage}
          alt="iReportSource logo"
          className={styles.loginModalLogo}
        />
        <h4 className={styles.loginMessageHeader}>
          Simplify your safety wherever you go
        </h4>
        {isAndroid && (
          <a href="https://play.google.com/store/apps/details?id=com.ireportsource.ireport&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
            <img
              className={styles.loginAndroidBadge}
              alt="Get it on Google Play"
              src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
            />
          </a>
        )}
        {isIOS && (
          <a href="https://itunes.apple.com/app/id1439251127">
            <img
              src="https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2018-10-19&kind=iossoftware&bubble=ios_apps"
              alt="Get it from iTunes"
            />
          </a>
        )}
      </Modal>
    </>
  );
}
