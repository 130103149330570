import React from 'react';
import './svg.css';

const nearMiss = props => (
  <svg
    width={props.width ?? 30}
    height={props.height ?? 25}
    viewBox="0 0 80 80"
    x={props.x || '0px'}
    y={props.y || '0px'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M62.4651 71.22L44.2644 0H35.7364L17.5357 71.22L10.4004 73.5984V79.9984H69.6004V73.5984L62.4651 71.22ZM25.9704 57.5987L29.6503 43.1987H50.3503L54.0303 57.5987H25.9704ZM37.0111 14.3987H42.9895L46.6702 28.7987H33.3302L37.0111 14.3987Z" />
  </svg>
);

export default nearMiss;
