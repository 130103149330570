import React, { useEffect } from 'react';

import {
  BoldExtension,
  BulletListExtension,
  ItalicExtension,
  MarkdownExtension,
  OrderedListExtension
} from 'remirror/extensions';

import { Remirror, useRemirror } from '@remirror/react';
import {
  Toolbar,
  ToggleBoldButton,
  ToggleItalicButton,
  ToggleOrderedListButton,
  ToggleBulletListButton
} from '@remirror/react-ui';

import classnames from 'classnames/bind';

import { nameInput } from '..';

import 'remirror/styles/all.css';
import styles from './richTextEditor.module.scss';
import '../input.css';
const bStyles = classnames.bind(styles);

const RichTextTextbox = ({
  name,
  placeholder,
  onChange,
  onBlur,
  currentValue,
  disabled,
  type,
  inputClassName,
  rows,
  onMouseDown,
  autoFocus,
  inputRef,
  testID,
  enter,
  onKeyDown,
  error,
  touched,
  readOnly,
  ...props
}) => {
  const needsErrorStyling = touched && error;

  const { manager, state } = useRemirror({
    extensions: () => [
      new BoldExtension(),
      new ItalicExtension(),
      new MarkdownExtension(),
      new BulletListExtension({ enableSpine: true }),
      new OrderedListExtension()
    ],
    selection: 'start',
    stringHandler: 'markdown',
    content: currentValue ?? ''
  });

  useEffect(() => {
    manager.view.updateState(manager.createState({ content: currentValue }));
  }, [manager, currentValue]);

  return (
    <div
      className={bStyles({
        'remirror-theme': true,
        remirrorTheme: !needsErrorStyling,
        remirrorThemeError: needsErrorStyling,
        everything: true
      })}
    >
      {/* the className is used to define css variables necessary for the editor */}
      <Remirror
        manager={manager}
        autoRender="end"
        editable={!disabled}
        initialContent={state}
        onChange={({ helpers, state }) => {
          let markdown = helpers.getMarkdown(state);
          onChange(markdown);
        }}
        classNames={[
          bStyles({
            editorError: needsErrorStyling,
            'remirror-editor': true,
            'Prose Mirror': true
          })
        ]}
      >
        {disabled ? null : (
          <Toolbar>
            <ToggleBoldButton />
            <ToggleItalicButton />
            <ToggleOrderedListButton />
            <ToggleBulletListButton />
          </Toolbar>
        )}
      </Remirror>
    </div>
  );
};

let t = nameInput(RichTextTextbox);
export { t as RichTextTextbox };
export default t;
