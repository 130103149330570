import React, { useState, useEffect } from 'react';
import { Portal } from 'react-portal';
import { usePopper } from 'react-popper';
import classnames from 'classnames/bind';

import { Input, InputRow, nameInput } from '../';
import { sameWidth, zIndex } from '../../../utils/popperModifiers';
import Textbox from '../Textbox';
import Button from '../Button';
import Checkbox from '../Checkbox';
import { noBubble } from '../../../utils/events';

import styles from './dropdown.module.scss';
const bStyles = classnames.bind(styles);

const Dropdown = ({
  options,
  name,
  currentValue,
  placeholder,
  disabled,
  searchable,
  selectButtons,
  onChange,
  bareValues = true,
  clipText,
  multi,
  testID,
  inputClassName,
  isImage,
  valueKey = 'value',
  labelKey = 'label',
  filterChoices,
  alphabetize = false,
  defaultAnswers = [],
  hideClear = false,
  firstOptions,
  initialTypeArray,
  allowUnset,
  ...props
}) => {
  const [filters, setFilters] = useState([]);
  const [showFilters, setShowFilters] = useState(filterChoices ? true : false);
  const [showOptions, setShowOptions] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [menuRef, setMenuRef] = useState(null);
  const [ddRef, setDdRef] = useState(null);
  const [invalidField, setInvalidField] = useState(false);

  const popper = usePopper(menuRef, ddRef, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'flip',
        options: { fallbackPlacements: ['top-start'] }
      },
      sameWidth,
      zIndex
    ]
  });

  const invalid = props.isRequired
    ? multi || initialTypeArray
      ? !currentValue?.length
      : !currentValue
    : false;

  useEffect(() => {
    if (filterChoices) {
      setShowFilters(true);
    }
  }, [filterChoices]);

  const alphabetizeDropdownn = options => {
    options = options.sort((a, b) =>
      a?.label?.toLowerCase().localeCompare(b?.label?.toLowerCase())
    );

    return options;
  };

  let filterOptions;
  if (filterChoices === null || filterChoices === undefined) {
    filterOptions = [];
  } else if (Array.isArray(filterChoices)) {
    filterOptions = filterChoices;
  } else if (typeof filterChoices === 'object' && filterChoices !== null) {
    filterOptions = Object.keys(filters).map(k => ({
      [labelKey]: filterChoices[k],
      [valueKey]: k
    }));
  } else {
    filterOptions = [filterChoices];
  }

  const getOptionDropdown = currentFilters => {
    let dd;
    if (filterChoices && currentFilters.length > 0) {
      let newFilter = filterChoices.filter(
        f => !currentFilters.includes(f.value)
      );
      options = options.filter(o => !newFilter.some(f => o.status === f.value));
    } else if (filterChoices) {
      options = options.filter(
        o => !filterChoices.some(f => o.status === f.value)
      );
    }

    if (options === null || options === undefined) {
      dd = [];
    } else if (Array.isArray(options)) {
      dd = options;
    } else if (typeof options === 'object' && options !== null) {
      dd = Object.keys(options).map(k => ({
        [labelKey]: options[k],
        [valueKey]: k
      }));
    } else {
      dd = [options];
    }
    if (alphabetize) dd = alphabetizeDropdownn(dd);
    if (firstOptions?.length) {
      let firstItems = dd.filter(d => firstOptions?.includes(d?.label));
      let rest = dd.filter(d => !firstOptions?.includes(d?.label));
      dd = [...firstItems, ...rest];
    }

    // this may need some fine tuning if it gets used outside of folder selection
    if (allowUnset) dd = [{ value: '', label: '' }, ...dd];

    return dd;
  };

  let ourOptions = getOptionDropdown(filters);

  const handleSelectionChange = selection => {
    let sValue = selection[valueKey];

    if (!multi) {
      setShowOptions(false);
      onChange(bareValues ? sValue : selection);
      return;
    }

    let values = null;
    if (Array.isArray(currentValue)) {
      values = [...currentValue];
    } else {
      values = [currentValue];
    }
    let found = false;
    values = values.reduce((acc, cur) => {
      let cValue = cur?.[valueKey] ?? cur;
      if (cValue === sValue && !defaultAnswers.includes(sValue)) {
        found = true;
      } else {
        acc.push(cValue);
      }
      return acc;
    }, []);

    if (!found) {
      values.push(sValue);
    }

    if (!bareValues) {
      values = values.reduce((acc, cur) => {
        const v = ourOptions.find(o => o[valueKey] === cur);
        if (v) acc.push(v);
        return acc;
      }, []);
    }

    onChange(values);
    if (popper?.update) popper.update();
  };

  const handleFilterSelectionChange = selection => {
    let sValue = selection[valueKey];

    let values = null;
    if (Array.isArray(filters)) {
      values = [...filters];
    } else {
      values = [filters];
    }
    let found = false;
    values = values.reduce((acc, cur) => {
      let cValue = cur?.[valueKey] ?? cur;
      if (cValue === sValue) {
        found = true;
      } else {
        acc.push(cValue);
      }
      return acc;
    }, []);

    if (!found) {
      values.push(sValue);
    }

    setFilters(values);

    let dd = getOptionDropdown(values);
    currentValue = dd.filter(o => currentValue.includes(o.value) ?? o.value);

    onChange(currentValue);
    if (popper?.update) popper.update();
  };

  const clearAll = () => {
    onChange(defaultAnswers);
  };

  const selectAll = () => {
    let options = [...ourOptions];
    if (bareValues) options = options.map(o => o[valueKey]);
    onChange(options);
  };

  const open = e => {
    noBubble(e);
    setShowOptions(disabled ? false : !showOptions);
    setInvalidField(false);
  };

  const close = e => {
    let node = e?.relatedTarget;
    while (node) {
      if (node === e?.currentTarget || node === ddRef) {
        return;
      }
      node = node.parentNode;
    }
    setShowOptions(false);
    setInvalidField(invalid ?? false);
  };

  const handleSearchInput = () => setSearchText('');

  if (!multi) selectButtons = false;

  if (!Array.isArray(currentValue)) {
    currentValue =
      currentValue !== null && currentValue !== undefined ? [currentValue] : [];
  }

  currentValue = currentValue.map(o => o?.[valueKey] ?? o);
  const searchOptions = searchText
    ? ourOptions.filter(o =>
        o?.[labelKey]?.toLowerCase()?.includes(searchText?.toLowerCase())
      )
    : ourOptions;

  if (!multi) currentValue = currentValue.slice(0, 1);

  let ulStyles = bStyles(inputClassName, {
    disabled,
    showOptions,
    clipText,
    multiSelect: true,
    openUp:
      popper.attributes?.popper?.['data-popper-placement'] === 'top-start',
    isInvalid: invalidField || props.invalidDateTime || props.touched
  });

  let ddStyles = bStyles({
    dropdown: true,
    showOptions,
    openUp: popper.attributes?.popper?.['data-popper-placement'] === 'top-start'
  });

  let selectStyles = bStyles({
    placeHolder: currentValue.length === 0,
    multiText: multi
  });

  const selectionNames = ourOptions.filter(
    option =>
      currentValue.findIndex(v => (v?.[valueKey] ?? v) === option[valueKey]) >
      -1
  );

  return (
    <Input name={name} disabled={disabled} {...props}>
      <div
        data-cy={testID ?? props.fieldLabel}
        className={ulStyles}
        onBlur={close}
        onClick={open}
        tabIndex={0}
        ref={setMenuRef}
      >
        {selectionNames.length ? (
          selectionNames.map((option, index) => (
            <span
              className={classnames(
                defaultAnswers.includes(option[valueKey])
                  ? styles.defaultAnswers
                  : '',
                selectStyles
              )}
              key={index}
            >
              {isImage ? (
                <img
                  src={option[labelKey]}
                  alt={option.alt}
                  className={option.className}
                />
              ) : (
                option[labelKey]
              )}
              {multi &&
                !disabled &&
                !defaultAnswers.includes(option[valueKey]) && (
                  <div onClick={noBubble(() => handleSelectionChange(option))}>
                    X
                  </div>
                )}
            </span>
          ))
        ) : (
          <span
            className={
              invalidField || props.touched
                ? styles.invalidPlaceHolder
                : styles.placeHolder
            }
          >
            {placeholder || 'Select an option'}
          </span>
        )}
        <div className={styles.right}>
          <img
            src={
              showOptions
                ? require('../../../assets/images/Chevron.png')
                : require('../../../assets/images/downChevron.png')
            }
            alt={showOptions ? 'multiselect open' : 'multiselect closed'}
          />
        </div>
      </div>
      {showOptions && (
        <Portal>
          <ul
            className={ddStyles}
            ref={setDdRef}
            style={popper.styles.popper}
            onBlur={close}
            {...popper.attributes.popper}
            tabIndex="0"
          >
            {searchable && (
              <li>
                <InputRow className={styles.row}>
                  <Textbox
                    currentValue={searchText}
                    onChange={setSearchText}
                    placeholder="Type to Search..."
                    onMouseDown={handleSearchInput}
                    name={name}
                    className={styles.grow}
                  />
                  {selectButtons && (
                    <>
                      <Button
                        text="Select All"
                        color="blue"
                        onMouseDown={noBubble}
                        onClick={selectAll}
                        className={styles.button}
                        testID="selectAllButton"
                      />
                      {hideClear ? (
                        <></>
                      ) : (
                        <Button
                          text="Clear"
                          color="white"
                          onMouseDown={noBubble}
                          onClick={clearAll}
                          className={styles.button}
                        />
                      )}
                      {filterOptions.length > 0 && (
                        <Button
                          color="blue"
                          onMouseDown={noBubble}
                          onClick={() => setShowFilters(!showFilters)}
                          inputClassName={styles.filterButton}
                          image={require('../../../assets/images/filter.png')}
                        />
                      )}
                    </>
                  )}
                </InputRow>
              </li>
            )}
            {filterOptions.length > 0 && showFilters && (
              <div className={styles.border}>
                <InputRow>
                  <p className={styles.filterText}>Filter:</p>
                  {filterOptions?.map((f, index) => (
                    <Checkbox
                      key={index}
                      name="filters"
                      onChange={() => handleFilterSelectionChange(f)}
                      currentValue={filters.includes(f[valueKey])}
                      fieldLabel={f[labelKey]}
                      labelStyles={
                        filters.includes(f[valueKey])
                          ? styles.filterCheckedText
                          : styles.filterOptionText
                      }
                      checkboxStyle={styles.filterCheckbox}
                    />
                  ))}
                </InputRow>
              </div>
            )}

            {searchOptions
              ?.filter(r => r.isVisible !== false)
              .map((option, index) => {
                return !multi ? (
                  <li
                    key={index}
                    onMouseDown={noBubble(() => handleSelectionChange(option))}
                    className={
                      currentValue.includes(option[valueKey])
                        ? styles.optionSelected
                        : styles.option
                    }
                  >
                    <div
                      className={styles.text}
                      data-cy={
                        (testID ?? props.fieldLabel) + ' ' + option[labelKey]
                      }
                    >
                      {isImage ? (
                        <img
                          src={option[labelKey]}
                          alt={option.alt}
                          className={option.className}
                        />
                      ) : (
                        option[labelKey]
                      )}
                    </div>
                  </li>
                ) : (
                  <Checkbox
                    key={index}
                    disabled={defaultAnswers.includes(option[valueKey])}
                    onChange={() => handleSelectionChange(option)}
                    currentValue={currentValue.includes(option[valueKey])}
                    fieldLabel={option[labelKey]}
                    testID={
                      (testID ?? props.fieldLabel) + ' ' + option[labelKey]
                    }
                  />
                );
              })}
          </ul>
        </Portal>
      )}
    </Input>
  );
};

const d = nameInput(Dropdown);
export { d as Dropdown };
export default d;

export { EmployeeDropdown } from './employee';
