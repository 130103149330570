import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';

import history from '../../../history';
import {
  TRAINING_CATEGORY_DROPDOWN,
  TRAINING_TYPES
} from '../../../constants/constants';
import { getActiveCompany } from '../../../selectors/company';
import { getLoggedInUser } from '../../../selectors/users';
import customToast from '../../../utils/customToast';
import Card from '../../../components/Card';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import Header from '../../../components/Header';
import { SaveCancelFooter } from '../../../components/Footer';
import DueDateText from '../../../components/DueDateText';
import {
  deleteTraining,
  deleteTrainingRepeatable,
  deleteTrainingScanOnDemand,
  fetchPerformTraining,
  fetchTrainingRepeatableById,
  fetchTrainingScanOnDemandById,
  updateTraining,
  updateTrainingRepeatable,
  updateTrainingScanOnDemand
} from '../../../api/v4';
import LeftNav from '../../../components/LeftNav';
import Input, { EmployeeDropdown } from '../../../components/inputs';
import TrainingMaterials from '../TrainingMaterials';
import { QuizStatusCard } from '../../../components/training/Cards/QuizStatusCard';
import DeleteItemModal from '../../../components/Modal/deleteItemModal';
import TrainingSummary from '../../../components/training/Summary';
import SignatureCard from '../../../components/SignatureCard';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';

import styles from './styles.module.scss';

export default function TrainingView(props) {
  const location = useLocation();
  const activeCompany = useSelector(getActiveCompany);
  const loggedInUser = useSelector(getLoggedInUser);
  const { trainingType, id } = useParams();
  const [training, setTraining] = useState({});
  const [editing, setEditing] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [canSubmit, setCanSubmit] = useState(true);
  const [openDiscardModal, setOpenDiscardModal] = useState(false);

  const sodOrRepeatable = ['sod', 'repeating']?.includes(trainingType);

  useEffect(() => {
    switch (trainingType) {
      case 'repeating':
        fetchTrainingRepeatableById(id).then(r =>
          setTraining({
            ...r,
            ...r.training,
            assignedTo: r?.assignedTo,
            schedule: 2,
            assignBy: r?.training?.assignBy ?? 2,
            groupAssigneePairs: [
              {
                groupId: r?.training?.groupIds,
                assignees: r?.assignedTo,
                jobTitles: r?.training?.assignByJobTitles
              }
            ]
          })
        );
        break;
      case 'sod':
        fetchTrainingScanOnDemandById(id).then(r =>
          setTraining({ ...r, ...r.training, schedule: 3 })
        );
        break;
      default:
        fetchPerformTraining(
          id,
          location.state?.userId ?? loggedInUser._id
        ).then(setTraining);
        break;
    }
  }, [location.state, id, trainingType, loggedInUser]);

  const handleSubmit = () => {
    (trainingType === 'repeating'
      ? updateTrainingRepeatable(training)
      : trainingType === 'sod'
        ? updateTrainingScanOnDemand(training)
        : updateTraining(training)
    ).then(() => {
      customToast('Updated Successfully');
      history.push('/app/training/list');
    });
  };

  const handleDelete = () => {
    (trainingType === 'repeating'
      ? deleteTrainingRepeatable(training)
      : trainingType === 'sod'
        ? deleteTrainingScanOnDemand(training)
        : deleteTraining(training)
    ).then(() => history.push('/app/training/list'));
  };

  const handleAnswer = values => {
    setTraining({
      ...training,
      ...values,
      training: { ...training.training, ...values }
    });
    setHasChanges(true);
  };

  let leftNav = [{ label: 'Summary', id: 'title' }];

  if (sodOrRepeatable)
    leftNav.push({ label: 'Training Details', id: 'details' });
  if (training.attachments?.length)
    leftNav.push({ label: 'Materials', id: 'materials' });
  if (training.quiz) leftNav.push({ label: 'Quiz', id: 'quiz' });
  if (training.employeeSignature)
    leftNav.push({ label: 'Signature', id: 'signature' });

  const header = (
    <Header
      title="Training"
      section={training.title}
      rightButtons={{
        text: editing ? 'Delete Training' : 'Edit Training',
        onClick: () => (editing ? setDeleteModal(true) : setEditing(true)),
        visible: sodOrRepeatable,
        color: editing ? 'red' : 'blue'
      }}
      needsSaved={hasChanges}
      clickBack={() =>
        hasChanges ? setOpenDiscardModal(true) : history.goBack()
      }
    />
  );

  const footer = (
    <SaveCancelFooter
      saveButtonText="Save"
      saveButtonDisabled={!hasChanges || !canSubmit}
      saveButtonClick={handleSubmit}
      cancelButtonClick={() =>
        hasChanges ? setOpenDiscardModal(true) : setEditing(false)
      }
    />
  );

  const admins = training?.onTheSpotAdmin
    ? [training?.onTheSpotAdmin]
    : training?.admins?.filter(a => a.adminId)?.length
      ? training?.admins.map(a => a.adminId)
      : null;

  return (
    <>
      <HeaderAndFooter
        Header={header}
        Footer={footer}
        showFooter={editing}
        Left={<LeftNav items={leftNav} />}
        className={styles.trainingContainer}
      >
        {sodOrRepeatable ? (
          <TrainingSummary
            training={training}
            company={activeCompany}
            type={trainingType}
            fromView
            editing={editing}
            editingAssignees={editing && training.assignedTo}
            onAnswer={handleAnswer}
            setCanSubmit={setCanSubmit}
          />
        ) : (
          <>
            <Card showHeader title="Summary" name="title">
              <Input
                className={styles.instructions}
                fieldLabel="Training Title"
              >
                {training.title}
              </Input>
              {training.category ? (
                <Input className={styles.instructions} fieldLabel="Category">
                  {
                    TRAINING_CATEGORY_DROPDOWN?.find(
                      d => d.value === training.category
                    )?.label
                  }
                </Input>
              ) : null}
              {training.instructions ? (
                <Input
                  className={styles.instructions}
                  fieldLabel="Instructions"
                >
                  {training.instructions}
                </Input>
              ) : null}
              <Input className={styles.instructions} fieldLabel="Format">
                {
                  TRAINING_TYPES?.find(a => a.value === training?.trainingType)
                    ?.label
                }
              </Input>
              <EmployeeDropdown
                fieldLabel="Assignee"
                disabled
                multi
                currentValue={training.assignedTo}
              />
              {training.stage ? (
                <Input className={styles.instructions} fieldLabel="Status">
                  {training.stage}
                </Input>
              ) : null}

              {training.completionDate ? (
                <Input
                  className={styles.instructions}
                  fieldLabel="Completion Date"
                >
                  {moment(training.completionDate).format('MM/DD/YYYY')}
                </Input>
              ) : null}
              {training.doesExpire || training.expirationDate ? (
                <DueDateText
                  date={training.expirationDate}
                  label="Expiration Date"
                  left
                />
              ) : training.dueDate ? (
                <DueDateText date={training.dueDate} label="Due Date" left />
              ) : null}
              {admins?.length ? (
                <EmployeeDropdown
                  fieldLabel="Admin"
                  currentValue={admins}
                  disabled
                />
              ) : null}
            </Card>
            {training.attachments?.length ? (
              <Card showHeader title="Materials">
                <TrainingMaterials
                  hideLabel
                  training={training}
                  fromApproveTraining
                  hideTime={training.trainingType === 2}
                  attachments={training?.attachments}
                  youtubeLinks={training.youtubeLinks}
                />
              </Card>
            ) : null}
            {training.quiz && !trainingType ? (
              <QuizStatusCard
                training={training}
                canTakeQuiz={false}
                name="quiz"
              />
            ) : null}
            {training.employeeSignature ? (
              <SignatureCard
                name="signature"
                header="Employee Signature"
                currentValue={training.employeeSignature}
                disabled
              />
            ) : null}
          </>
        )}
        <DeleteItemModal
          deletingWhat="Training"
          isOpen={deleteModal}
          onRequestClose={() => setDeleteModal(false)}
          submitActions={handleDelete}
        />
        <SaveChangesModal
          isOpen={openDiscardModal}
          onRequestClose={() => setOpenDiscardModal(false)}
          submitActions={() => history.goBack()}
          savingWhat="Training"
        />
      </HeaderAndFooter>
    </>
  );
}
