import React, { useState } from 'react';
import moment from 'moment';
import { extendMoment } from 'moment-range';
import DatePicker from 'react-datepicker';
import Button from '../Button';
import Datebox from './dateBox';
import Checkbox from '../Checkbox';
import RadioButtons from '../RadioButtons';
import Modal from '../../Modal';
import { Input, nameInput } from '../';
import { numberOfDays } from '../../../utils/oshaDbMapper';

import styles from './styles.module.scss';

extendMoment(moment);

const MultipleDateRangePicker = ({
  onChange,
  minDate,
  fieldLabel,
  datesArray,
  datesCheckbox,
  disabled,
  daysAway,
  name,
  handleDatesSubmit,
  deleteDate,
  modalLabel,
  missingRequired,
  ...props
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [dateButtonChoice, setDateButtonChoice] = useState(0);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState(null);
  const [editing, setEditing] = useState(false);
  const [previousStartDate, setPreviousStartDate] = useState(null);
  const [previousEndDate, setPreviousEndDate] = useState(null);

  const closeModal = () => {
    setOpenModal(false);
    setStartDate(null);
    setEndDate(null);
    setDateButtonChoice(0);
  };

  const findBlockedDatesArray = () => {
    let start = moment(previousStartDate);
    let end = moment(previousEndDate);

    let dates = editing
      ? datesArray.filter(d => {
          if (!d.endDate === null) {
            return (
              !start.isSame(d.startDate, 'day') && !end.isSame(d.endDate, 'day')
            );
          } else {
            return !start.isSame(d.startDate, 'day');
          }
        })
      : (datesArray ?? []);

    return dates.map(d => {
      let end = d?.endDate ? new Date(d.endDate) : new Date(d.startDate);
      if (!d?.endDate) end.setFullYear(end.getFullYear() + 5);

      return {
        start: new Date(d.startDate),
        // can no longer have null to block out the rest of calendar for onging to defaulting to blocking out the next 5 years
        end
      };
    });
  };

  const checkForBlockedDates = () => {
    if (startDate) {
      let end = endDate;
      let dates = findBlockedDatesArray();

      const selectedDateRange = moment.range(startDate, end);

      for (let i = 0; i < dates.length; i++) {
        let endDate = dates[i].end ?? moment();
        const range = moment.range(dates[i].start, endDate);
        if (selectedDateRange.overlaps(range)) {
          return true;
        }
      }

      return false;
    }
    return false;
  };

  const handleDateChange = dates => {
    let start;
    let end;
    if (Array.isArray(dates)) {
      start = dates[0];
      end = dates[1];
    } else {
      start = dates;
      end = dateButtonChoice === 1 ? dates : null;
    }

    setStartDate(start);
    setEndDate(end);
  };

  const checkForInvalidDateRange = () => {
    if (dateButtonChoice === 0 && moment(startDate).isAfter(endDate)) {
      return 'Start date cannot be after end date';
    }

    if (dateButtonChoice !== 1 && checkForBlockedDates()) {
      return 'Date range cannot include blocked dates';
    }
  };

  const disableSubmit = () => {
    if (startDate === null || checkForInvalidDateRange()) {
      return true;
    }

    const validEndDate = dateButtonChoice === 2 || endDate;
    return !startDate || !validEndDate;
  };

  const handleDateBoxClick = values => {
    let startDate = values.date.startDate;
    let endDate = values.date.endDate;
    setOpenModal(true);
    setStartDate(startDate);
    setEndDate(endDate);
    setEditing(true);
    setPreviousEndDate(endDate);
    setPreviousStartDate(startDate);
    setDateButtonChoice(values.dateType);
  };

  return (
    <Input {...props} name={name} disabled={disabled} fieldLabel={fieldLabel}>
      <Checkbox
        fieldLabel={0 + ' ' + fieldLabel}
        name={`${name}Checkbox`}
        onChange={onChange}
        currentValue={datesCheckbox}
        disabled={disabled}
        error={missingRequired && !datesCheckbox && !datesArray?.length}
      />
      <span className={styles.numberOfDaysBox}>
        {numberOfDays(datesArray, datesCheckbox) || 0} {fieldLabel}
      </span>
      {!datesCheckbox &&
        datesArray?.map((date, index) => (
          <Datebox
            index={index}
            date={date}
            deleteDate={deleteDate}
            onClick={handleDateBoxClick}
            disabled={disabled}
          />
        ))}
      <Button
        className={styles.button}
        color={
          missingRequired && !datesCheckbox && !datesArray?.length
            ? 'red'
            : 'blue'
        }
        text="Add Dates"
        onClick={() => setOpenModal(true)}
        disabled={datesCheckbox || !minDate || disabled}
      />
      {!minDate ? (
        <span className={styles.incidentDateWarning}>
          Please enter an incident date
        </span>
      ) : (
        ''
      )}
      <Modal
        tall
        title="Add Dates"
        titleClassName="blueHeader"
        isOpen={openModal}
        submitButtonColor="blue"
        submitButtonText="Add Dates"
        onRequestClose={() => closeModal()}
        submitActions={() => {
          setOpenModal(false);
          setStartDate(null);
          setEndDate(null);
          setDateButtonChoice(0);
          setEditing(false);
          handleDatesSubmit({
            startDate: startDate,
            endDate: endDate,
            peviousEndDate: previousEndDate,
            previousStartDate: previousStartDate,
            editing: editing
          });
        }}
        disableSubmit={disableSubmit()}
        wide
      >
        <div>
          <span>{modalLabel}</span>
        </div>
        <RadioButtons
          fieldLabel=""
          options={[
            { value: 0, label: 'Date Range' },
            { value: 1, label: 'Single Day' },
            { value: 2, label: 'Ongoing' }
          ]}
          isRequired
          onChange={value => {
            if (!(value === 0 && dateButtonChoice === 2 && editing)) {
              setStartDate(null);
            }
            setEndDate(null);
            setDateButtonChoice(value);
          }}
          currentValue={dateButtonChoice}
          disabled={disabled}
          monthsShown={2}
        />
        <div id="hello">
          <div className={styles.warning}>{checkForInvalidDateRange()}</div>
          <DatePicker
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            onChange={handleDateChange}
            monthsShown={3}
            className={styles.textbox}
            popperPlacement="top-end"
            minDate={minDate}
            selectsRange={dateButtonChoice === 0}
            excludeDateIntervals={findBlockedDatesArray()}
            isClearable
            openToDate={new Date(moment().subtract(1, 'month'))}
            shouldCloseOnSelect={false}
            placeholderText="MM/DD/YYYY"
            inline
          />
        </div>
      </Modal>
    </Input>
  );
};

const m = nameInput(MultipleDateRangePicker);
export { m as MultipleDateRangePicker };
export default m;
