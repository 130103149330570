import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import {
  fetchSafetyAuditExceptionReport,
  downloadSafetyAuditExceptionList,
  fetchSafetyAuditExceptionTitles,
  sendSafetyAuditExceptionNotification
} from '../../api/v4';
import { usePrevious } from '../../utils/hooks';
import useActiveHeirarchy from '../../utils/useActiveHeirarchy';
import {
  EXCEPTION_REPORTING_FREQUENCY_OPTIONS,
  SAFETY_AUDIT_STAGES
} from '../../constants/constants';

import BlockText from '../../components/inputs/BlockText';
import { Button, Dropdown, TwoColumn } from '../../components/inputs';
import Card from '../../components/Card';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Header from '../../components/Header';
import { IReportDatePicker } from '../../components/inputs/DateTimePicker';
import HierarchySelector from '../../components/HierarchySelector';
import List from '../../components/List';
import MissingRequiredModal from '../../components/Modal/missingRequiredModal';
import Modal from '../../components/Modal';
import RemoveExceptionItemModel from '../../components/Modal/removeExcptionItemModal';

import styles from './styles.module.scss';

export default function AuditReport() {
  const { activeCompany, activeGroup, activeProject } = useActiveHeirarchy();
  const path = useLocation().pathname;
  const auditType = path.split('/').pop();
  const auditName = auditType.charAt(0).toUpperCase() + auditType.slice(1);

  const [dateRange, setDateRange] = useState(null);
  const [auditTitles, setAuditTitles] = useState([]);
  const [nonValidTitles, setNonValidTitles] = useState([]);
  const [selectedAudits, setSelectedAudits] = useState();
  const [groupIds, setGroupIds] = useState([]);
  const [projectIds, setProjectIds] = useState([]);
  const [stages, setStages] = useState([]);
  const [exceptionData, setExceptionData] = useState([]);
  const [exceptionColumns, setExceptionColumns] = useState([]);
  const [emailUsers, setEmailUsers] = useState([]);
  const [hasRanReport, setHasRanReport] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [rangeStartDate, setRangeStartDate] = useState(null);
  const [rangeEndDate, setRangeEndDate] = useState(null);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [showEmailModal, setEmailModal] = useState(false);
  const [tempDateRange, setTempDateRange] = useState(null);
  const [tempRangeStartDate, setTempRangeStartDate] = useState(null);
  const [tempRangeEndDate, setTempRangeEndDate] = useState(null);
  const [dateRangeErrorMessage, setDateRangeErrorMessage] = useState(null);
  const [missingRequired, setMissingRequired] = useState(false);
  const [missingModalOpen, setMissingModalOpen] = useState(false);

  const prevDateRange = usePrevious(dateRange);

  const handleExportTable = async () => {
    const src = await downloadSafetyAuditExceptionList({
      dateRange,
      startDate: rangeStartDate,
      endDate: rangeEndDate,
      auditTitles: selectedAudits,
      stages,
      groupIds,
      projectIds,
      auditName
    });
    window.location = src;
  };

  const handleRunReport = () => {
    fetchSafetyAuditExceptionReport({
      dateRange,
      startDate: rangeStartDate,
      endDate: rangeEndDate,
      auditTitles: selectedAudits,
      stages,
      groupIds,
      projectIds
    }).then(response => {
      setExceptionData(response);
      getColumns();
      setEmailUsers(response.filter(e => e.assigned > e.completed));
      setHasRanReport(true);
      setHasChanges(false);
    });
  };

  const getColumns = () => {
    let columns = [
      {
        key: 'assignee',
        label: 'Employee Name',
        datatype: 'users'
      },
      { key: 'assigned', label: '# Assigned', disableFilterBy: true },
      { key: 'completed', label: '# Completed', disableFilterBy: true }
    ];

    setExceptionColumns(columns);
  };

  const handleCustomDateChange = async (start, end, type = 'end') => {
    let titles;

    if (type === 'start') {
      setTempRangeStartDate(start);
      if (!selectedAudits?.length) setRangeStartDate(start);
    } else {
      setTempRangeEndDate(end);
      if (!selectedAudits?.length) setRangeEndDate(end);
    }
    if (start && end) {
      if (moment(start).isBefore(end)) {
        titles = await fetchSafetyAuditExceptionTitles({
          dateRange: 'customRange',
          startDate: start,
          endDate: end,
          auditType
        });
        if (selectedAudits) {
          if (!titles.map(t => t.value).includes(selectedAudits))
            setOpenRemoveModal(true);
          setDateRangeErrorMessage('');
        } else {
          setAuditTitles(titles);
          setDateRange('customRange');
          setDateRangeErrorMessage('');
          setRangeEndDate(end);
          setRangeStartDate(start);
        }
      } else {
        setDateRangeErrorMessage('Invalid Date Range');
      }
    }
  };

  const handleDateChange = async value => {
    setDateRange(value);
    let titles;
    if (value !== 'customRange') {
      titles = await fetchSafetyAuditExceptionTitles({
        dateRange: value,
        auditType
      });
      setTempDateRange(value);
      handleTitlesForDateChange(value, titles);
      setExceptionData([]);
    } else {
      if (!selectedAudits?.length) {
        // make sure values get reset
        setTempRangeStartDate(null);
        setTempRangeEndDate(null);
        setRangeStartDate(null);
        setRangeEndDate(null);
      } else {
        setTempDateRange(value);
        setRangeStartDate(null);
        setRangeEndDate(null);
      }
    }
    setHasRanReport(false);
  };

  const handleTitlesForDateChange = (date, titles) => {
    setDateRange(date);
    setAuditTitles(titles);
    if (!titles.find(t => t.label === selectedAudits)) setSelectedAudits();
  };

  const handleSubmitRemoval = () => {
    setOpenRemoveModal(false);
    setSelectedAudits(null);
  };

  const handleCancelRemoval = () => {
    setOpenRemoveModal(false);
    setNonValidTitles([]);
    setTempRangeStartDate(rangeStartDate);
    setTempRangeEndDate(rangeEndDate);
    setTempDateRange(null);
    setDateRange(prevDateRange);
  };

  const handleGroupChange = values => {
    const validProjectIds = activeCompany.groups
      .map(g => {
        if (values.includes(g._id)) {
          return g.projects.map(p => p._id);
        } else {
          return [];
        }
      })
      .reduce((acc, x) => acc.concat(x), []);
    setGroupIds(values);

    const newProjectIds = projectIds.filter(p => validProjectIds.includes(p));
    setProjectIds(newProjectIds);
    if (hasRanReport) setHasChanges(true);
  };

  const emailReportUsers = users => {
    if (!users.length) return;
    sendSafetyAuditExceptionNotification({ users, auditType });
  };

  const header = (
    <Header
      title={`${auditName} Audit Report`}
      pageActionOptions={
        hasRanReport
          ? [
              {
                color: 'blueOutline',
                label: 'Export Table',
                visible: true,
                onClick: () => handleExportTable()
              },
              {
                label: 'Email Users',
                color: 'blueOutline',
                visible: emailUsers.length,
                onClick: () => {
                  setEmailModal(true);
                }
              }
            ]
          : null
      }
    />
  );

  const canRun =
    (dateRange === 'customRange'
      ? rangeStartDate && rangeEndDate
      : dateRange) && selectedAudits?.length > 0;

  return (
    <HeaderAndFooter Header={header}>
      <Card>
        <TwoColumn>
          {dateRange === 'customRange' || tempDateRange === 'customRange' ? (
            <TwoColumn>
              <Dropdown
                options={EXCEPTION_REPORTING_FREQUENCY_OPTIONS}
                fieldLabel="Date"
                currentValue={tempDateRange ?? dateRange}
                onChange={value => handleDateChange(value)}
                isRequired
                touched={missingRequired && !dateRange}
              />
              <div
                className={{ display: 'flex', flexDirection: 'column-reverse' }}
              >
                <div className={styles.datePicker}>
                  <IReportDatePicker
                    currentValue={tempRangeStartDate ?? rangeStartDate}
                    onChange={value =>
                      selectedAudits?.length > 0
                        ? handleCustomDateChange(
                            value,
                            tempRangeEndDate,
                            'start'
                          )
                        : handleCustomDateChange(value, rangeEndDate, 'start')
                    }
                    name="startDate"
                    touched={
                      missingRequired &&
                      (dateRange === 'customRange' ||
                        tempDateRange === 'customRange') &&
                      !(tempRangeStartDate ?? rangeStartDate)
                    }
                  />

                  <IReportDatePicker
                    currentValue={tempRangeEndDate ?? rangeEndDate}
                    onChange={value =>
                      selectedAudits?.length > 0
                        ? handleCustomDateChange(tempRangeStartDate, value)
                        : handleCustomDateChange(rangeStartDate, value)
                    }
                    name="endDate"
                    touched={
                      missingRequired &&
                      (dateRange === 'customRange' ||
                        tempDateRange === 'customRange') &&
                      !(tempRangeEndDate ?? rangeEndDate)
                    }
                  />
                </div>
                <span className={styles.dateWarning}>
                  {dateRangeErrorMessage}
                </span>
              </div>
            </TwoColumn>
          ) : (
            <Dropdown
              options={EXCEPTION_REPORTING_FREQUENCY_OPTIONS}
              fieldLabel="Date"
              currentValue={tempDateRange ?? dateRange}
              onChange={value => handleDateChange(value)}
              isRequired
              touched={missingRequired && !dateRange}
            />
          )}
          <Dropdown
            options={auditTitles}
            fieldLabel={`${auditName} Audit Title`}
            currentValue={selectedAudits}
            onChange={value => {
              setSelectedAudits(value);
              if (hasRanReport) setHasChanges(true);
            }}
            isRequired
            disabled={!dateRange?.length}
            selectButtons
            searchable
            touched={missingRequired && !selectedAudits?.length}
          />
        </TwoColumn>
        <HierarchySelector
          onGroupChange={values => handleGroupChange(values)}
          groupId={activeGroup ? [activeGroup._id] : groupIds}
          groupDisabled={
            dateRange?.length < 1 ||
            selectedAudits?.length < 1 ||
            activeGroup._id
          }
          onProjectChange={values => {
            setProjectIds(values);
            if (hasRanReport) setHasChanges(true);
          }}
          projectId={activeProject ? [activeProject._id] : projectIds}
          projectDisabled={
            activeProject ?? (groupIds?.length < 1 && !activeGroup)
          }
          groupRequired={false}
          multi
          alwaysShowGroupDropdown
          alwaysShowProjectDropdown
        />
        <Dropdown
          searchable
          options={SAFETY_AUDIT_STAGES}
          fieldLabel="Stage"
          currentValue={stages}
          onChange={value => {
            setStages(value);
            if (hasRanReport) setHasChanges(true);
          }}
          disabled={dateRange?.length < 1 || selectedAudits?.length < 1}
          placeholder="Choose one or more options"
          multi
        />
        <BlockText blockOfText="If a user has multiple audits with the same template and due date the average completion will be shown" />
        <Button
          color="blue"
          text={hasRanReport ? 'Update Report' : 'Run Report'}
          onClick={() =>
            !canRun ? setMissingModalOpen(true) : handleRunReport()
          }
          disabled={hasRanReport && !hasChanges}
          className={styles.button}
          onMouseEnter={() => setMissingRequired(!canRun)}
        />
      </Card>
      <Card>
        {canRun && hasRanReport ? (
          <List data={exceptionData} dataIsHash settings={exceptionColumns} />
        ) : (
          <div className={styles.messageText}>
            Fill fields above to generate report
          </div>
        )}
      </Card>
      <RemoveExceptionItemModel
        isOpen={openRemoveModal}
        onRequestClose={() => handleCancelRemoval()}
        submitActions={() => handleSubmitRemoval()}
        itemsToRemove={nonValidTitles}
        removeWhat={`${auditName} Audits`}
      />

      <Modal
        title="Email Users"
        titleClassName="blueHeader"
        isOpen={showEmailModal}
        submitButtonColor="blue"
        submitButtonText="Send"
        onRequestClose={() => {
          setEmailModal(false);
        }}
        submitActions={() => {
          emailReportUsers(emailUsers);
          setEmailModal(false);
        }}
      >
        {`Are you sure you want to send a reminder to users with incomplete ${auditName}
        Audits?`}
      </Modal>
      <MissingRequiredModal
        isOpen={missingModalOpen}
        onRequestClose={() => setMissingModalOpen(false)}
        subject={`${auditName} Audit Exception Report`}
        isRunning
      />
    </HeaderAndFooter>
  );
}
