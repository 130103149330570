import React from 'react';
import './svg.css';

const company = props => (
  <svg
    width={props.width ?? 30}
    height={props.height ?? 25}
    viewBox="0 0 80 80"
    x={props.x || '0px'}
    y={props.y || '0px'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1224_131809)">
      <path d="M73.9258 52.6376C73.9258 51.2992 75.011 50.1864 76.3494 50.1864H80C79.5711 40.8716 75.9966 32.4803 70.3232 25.9047L67.8393 28.3729C67.3662 28.8461 66.7458 29.0747 66.1255 29.0747C65.5051 29.0747 64.8851 28.834 64.4116 28.3611C63.4652 27.4148 63.4652 25.8782 64.4116 24.9316L67.0136 22.4001C60.5661 16.5256 52.1988 12.724 43.047 12.0585V15.7088C43.047 17.0473 41.9342 18.1324 40.5957 18.1324C39.2573 18.1324 38.1445 17.0473 38.1445 15.7088V12C28.9934 12.4424 20.4322 16.0297 13.8644 21.7139L16.4755 24.3473C17.4218 25.2936 17.4109 26.8284 16.4646 27.7748C15.9914 28.2479 15.3657 28.4844 14.7456 28.4844C14.1252 28.4844 13.5022 28.2476 13.029 27.7748L10.3531 25.126C4.48914 31.5816 0.700169 40.0542 0.0485616 49.2053H3.64221C4.98063 49.2053 6.06574 50.3181 6.06574 51.6566C6.06574 52.995 4.98063 54.1078 3.64221 54.1078H0C0.256945 59.1738 1.50943 64.1799 3.56647 68.6717C3.56798 68.6765 3.7444 69.0161 3.79446 69.1217C4.30112 70.075 5.07498 70.4492 6.04305 70.4492H73.8041C74.824 70.4492 75.7131 69.9139 76.1965 69.0951C76.2272 69.0303 76.3994 68.6699 76.4199 68.6252C76.4215 68.621 76.4224 68.6801 76.4239 68.6759C78.3524 64.4635 79.5742 59.8275 79.931 55.0879H76.3485C75.0101 55.0879 73.9253 53.9751 73.9253 52.637L73.9258 52.6376ZM58.4602 36.8215L48.9787 54.9955C48.5296 56.3502 47.7697 57.6234 46.6936 58.6995C44.9046 60.4885 42.5258 61.4738 39.9958 61.4738C37.4658 61.4738 35.087 60.4885 33.298 58.6995C29.6048 55.006 29.6048 48.9972 33.298 45.3037C34.2929 44.3091 35.4706 43.5636 36.7544 43.0971C36.7731 43.0865 36.7903 43.0741 36.8096 43.0642L55.1974 33.548C56.1363 33.0627 57.2817 33.2412 58.0281 33.9895C58.7739 34.7377 58.9488 35.8837 58.4599 36.821L58.4602 36.8215Z" />
    </g>
    <defs>
      <clipPath id="clip0_1224_131809">
        <rect width="80" height="80" />
      </clipPath>
    </defs>
  </svg>
);

export default company;
