import { toast } from 'react-toastify';

const toastOptions = {
  position: 'top-center',
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored'
};

const getToastTimeout = message => {
  let timeout = 150 * message.length;
  if (timeout < 4000) {
    return 4000;
  } else if (timeout > 15000) {
    timeout = 15000;
  }

  return timeout;
};

export const customToast = (message, type = '', options = {}) => {
  switch (type) {
    case 'info':
      toast.info(message, {
        ...toastOptions,
        autoClose: getToastTimeout(message),
        ...options,
        toastId: 'infoToast'
      });
      break;
    case 'error':
      toast.error(message, {
        ...toastOptions,
        ...options,
        autoClose: getToastTimeout(message)
      });
      break;
    default:
      toast.success(message, {
        ...toastOptions,
        ...options,
        autoClose: getToastTimeout(message)
      });
      break;
  }
};

export default customToast;
