import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';

import { addUploadedAttachmentsRequest } from '../../actions/attachments';
import { getAddedAttachmentsSelector } from '../../selectors/attachments';
import { getActiveCompany } from '../../selectors/company';
import { isInputCSV } from '../../utils/attachmentSelector';

import './index.css';

class CSVUploader extends Component {
  onDrop = files => {
    for (let i = 0; i < files.length; i++) {
      if (!isInputCSV(files[i])) {
        alert('File must be a .csv file.');

        return;
      }
    }

    let data = new FormData();
    files.forEach(file => {
      data.append('attachments', file, file.name);
    });

    this.props.addAttachment({
      data,
      ownerId: this.props.activeCompanyId,
      ownerType: this.props.documentType
    });
  };

  render() {
    const { attachments } = this.props;

    return (
      <div>
        {this.props.label ? (
          <label className="label">{this.props.label}</label>
        ) : (
          <span className="space" />
        )}
        <div className={this.props.className}>
          <Dropzone onDrop={this.onDrop} className="dropzone-box">
            <div>Click or drag a file here to upload!</div>
            <div>Only a *.csv file will be accepted.</div>
          </Dropzone>
          {attachments.length > 0 ? (
            <div>
              <h2>Uploaded {attachments.length} files</h2>
              <div>
                {attachments.map((file, index) => (
                  <img
                    src={require(`../../assets/images/csvImage.png`)}
                    key={index}
                    alt="images"
                    style={{ width: '30px', height: '30px' }}
                  />
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  activeCompany: getActiveCompany(state),
  attachments: getAddedAttachmentsSelector(state)
});

const mapDispatchToProps = dispatch => ({
  addAttachment: attachment =>
    dispatch(addUploadedAttachmentsRequest(attachment))
});

// eslint-disable-next-line import/no-anonymous-default-export
export default connect(mapStateToProps, mapDispatchToProps)(CSVUploader);
