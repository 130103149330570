import React from 'react';
import './svg.css';

const sortArrows = props => (
  <svg
    version="1.1"
    x={props.x || '0px'}
    y={props.y || '0px'}
    viewBox="0 0 24 24"
    width="24px"
    height="24px"
  >
    <path d="M0 8L6 0L12 8H7V16H5V8H0Z" />
    <path d="M24 16L18 24L12 16H17V8H19V16H24Z" />
  </svg>
);

export default sortArrows;
