import React from 'react';

import styles from './styles.module.scss';

export default function TrainingStages({
  stages,
  selectedStage,
  onClick,
  completedStages
}) {
  const stageStyle = stage => {
    return [
      styles.stage,
      selectedStage === stage || stage === selectedStage
        ? styles.stageSelected
        : '',
      selectedStage === stage || stage === selectedStage
        ? styles.stageStarted
        : '',
      completedStages?.includes(stage)
        ? styles.stageCompleted
        : stage > selectedStage
          ? styles.stageDisabled
          : ''
    ].join(' ');
  };
  return (
    <div className={`incidentContainer-incidentCard--stages ${styles.stages}`}>
      {stages?.map((stage, i) => (
        <div className={stageStyle(i)} key={i} onClick={() => onClick(i)}>
          <div>
            <div
              style={
                i === 0
                  ? { paddingTop: 5 }
                  : {
                      paddingTop: 5,
                      position: 'relative',
                      top: '-34px'
                    }
              }
            >
              {i + 1 + '. ' + stage}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
