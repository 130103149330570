import React from 'react';
import './svg.css';

const trainingException = props => (
  <svg
    width={props.width ?? 30}
    height={props.height ?? 25}
    viewBox="0 0 80 80"
    x={props.x || '0px'}
    y={props.y || '0px'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M56.1875 7.76458V19.8116H68.2346L56.1875 7.76458Z" />
    <path d="M34.35 57.4589H45.6441V64.9883H34.35V57.4589Z" />
    <path d="M34.35 34.8706H45.6441V42.4H34.35V34.8706Z" />
    <path d="M34.35 46.1648H45.6441V53.6943H34.35V46.1648Z" />
    <path d="M19.2917 46.1648H30.5858V53.6943H19.2917V46.1648Z" />
    <path d="M19.2917 34.8706H30.5858V42.4H19.2917V34.8706Z" />
    <path d="M19.2917 57.4589H30.5858V64.9883H19.2917V57.4589Z" />
    <path d="M49.4083 34.8706H60.7025V42.4H49.4083V34.8706Z" />
    <path d="M49.4083 57.4589H60.7025V64.9883H49.4083V57.4589Z" />
    <path d="M52.4235 23.5765V4H11.7647C9.65634 4 8 5.65634 8 7.76471V72.5176C8 74.626 9.65634 76.2823 11.7647 76.2823H68.2353C70.3437 76.2823 72 74.626 72 72.5176V23.5765H52.4235ZM64.4706 68.7529H15.5294V31.1059H64.4706V68.7529Z" />
    <path d="M49.4083 46.1648H60.7025V53.6943H49.4083V46.1648Z" />
  </svg>
);

export default trainingException;
