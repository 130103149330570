import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import history from '../../../history';
import { usePrevious } from '../../../utils/hooks';
import {
  fetchAnalyticsDatasources,
  fetchAnalyticsDatapoints,
  fetchAnalyticsChart,
  createAnalyticsChart,
  updateAnalyticsChart
} from '../../../api/v4';
import svgMapper from '../../../utils/svgMapper';
import useActiveHeirarchy from '../../../utils/useActiveHeirarchy';
import AnalyticsChart from '../../../components/AnalyticsChart';
import Card from '../../../components/Card';
import Header from '../../../components/Header';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import HierarchySelector from '../../../components/HierarchySelector';
import { SaveCancelFooter } from '../../../components/Footer';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';
import {
  Textbox,
  Dropdown,
  TwoColumn,
  Checkbox
} from '../../../components/inputs';
import AnalyticWarning from '../../../assets/images/Analytics_Warning.png';
import MissingRequiredModal from '../../../components/Modal/missingRequiredModal';

import styles from './styles.module.scss';

export default function MyAnalyticsChart(props) {
  const { activeCompany, activeGroup } = useActiveHeirarchy();

  const { cardId } = useParams();
  const [datasources, setDatasources] = useState([]);
  const [datapoints, setDatapoints] = useState([]);
  const [chartTypes, setChartTypes] = useState([]);
  const [showStandards, setShowStandards] = useState(true);
  const [datasource, setDatasource] = useState(null);
  const [category, setCategory] = useState(null);
  const [title, setTitle] = useState();
  const [chartType, setChartType] = useState('bar');
  const [subcategory, setSubcategory] = useState();
  const [showTrend, setShowTrend] = useState(false);
  const [standards, setStandards] = useState();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [openUnsavedChangesModal, setOpenUnsavedChangesModal] = useState(false);
  const [filterOptions, setFilterOptions] = useState([]);
  const [filterAnswers, setFilterAnswers] = useState([]);
  const [allowTrend, setAllowTrend] = useState(true);
  const [shouldBeHigherThanStandards, setShouldBeHigherThanStandards] =
    useState(false);
  const [charts, setCharts] = useState([]);
  const [missingRequired, setMissingRequired] = useState(false);
  const [missingModalOpen, setMissingModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const hideAdditionalFilters =
    datasource === 'OSHA Compliance' && category !== 'getOSHALostDays';

  const clearStandards = () => {
    setShowStandards(false);
    setStandards(null);
    setShowTrend(false);
  };

  const getCharts = () => {
    let newCharts = [
      {
        visible: chartTypes?.showBarChart ?? true,
        image: svgMapper('BarChart'),
        label: 'Bar Chart',
        value: 'bar'
      },
      {
        visible: chartTypes?.showLineChart ?? true,
        image: svgMapper('LineChart'),
        label: 'Line Chart',
        value: 'line'
      },
      {
        visible: chartTypes?.showPieChart ?? true,
        image: svgMapper('PieChart'),
        label: 'Pie Chart',
        value: 'pie'
      },
      {
        visible: chartTypes?.showStat ?? true,
        image: svgMapper('Statistic'),
        label: 'Statistic',
        value: 'statistic'
      }
    ].filter(c => c.visible);
    setCharts(newCharts);
    return newCharts;
  };

  useEffect(() => {
    setLoading(true);
    if (cardId) {
      fetchAnalyticsChart(cardId).then(response => {
        let autotitle = response.category.split(/(?=[A-Z])/);
        autotitle.shift();
        autotitle = autotitle.toString().replace(/,/g, ' ');
        autotitle = `${autotitle} for ${response.datasource}`;
        const autoGeneratedTitle = autotitle === response.title;

        setDatasource(response.datasource);
        setCategory(response.category);
        setSubcategory(response.subcategory);
        setTitle(autoGeneratedTitle ? null : response.title);
        setChartType(response.type?.replace('-trend', ''));
        setStandards(response.standards);
        setShowTrend(response.showTrend);
        setFilterAnswers(response.filterArgs);
        setShouldBeHigherThanStandards(response.shouldBeHigherThanStandards);
        setShowStandards(response.showStandards);
        setAllowTrend(response.allowTrend);
      });
    }
    fetchAnalyticsDatasources()
      .then(response => {
        setDatasources(response);
        setDatapoints();
        setLoading(false);
      })
      .catch(e => console.log(e));
  }, [cardId, activeCompany]);

  const prevDatasource = usePrevious(datasource);
  useEffect(() => {
    if (datasource && prevDatasource !== datasource)
      fetchAnalyticsDatapoints(datasource).then(response => {
        let defaultAnswers = {};
        for (const f of response?.filterDropdownOptions) {
          defaultAnswers[f.dbLabel] = [];
        }
        let newChartTypes = response.datapoints.find(
          d => d.value === category
        )?.options;
        setFilterOptions(response?.filterDropdownOptions ?? []);
        setDatapoints(response.datapoints);
        setChartTypes(newChartTypes);
      });

    if (prevDatasource && prevDatasource !== datasource) setCategory(null);
  }, [datasource, category, prevDatasource]);

  const prevCategory = usePrevious(category);
  useEffect(() => {
    if (category !== prevCategory && !loading) {
      const dp = datapoints?.find(d => d.value === category);
      setChartTypes(dp?.options);
      setSubcategory(dp?.subCategory);
      if (!(dp?.options?.showStandards ?? true)) clearStandards();
      else setShowStandards(true);

      setShouldBeHigherThanStandards(
        dp?.options?.shouldBeHigherThanStandards ?? true
      );
      setAllowTrend(dp?.options?.allowTrend ?? true);

      if (category?.includes('getDaysSince')) {
        setChartType('statistic');
      }
    }
  }, [category, datapoints, prevCategory, loading]);

  useEffect(() => {
    if (chartTypes === undefined) return;
    let charts = getCharts();
    if (!charts.map(c => c.value).includes(chartType)) {
      setChartType(charts[0].value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartTypes]);

  const updateDataSource = dataSource => {
    if (datasource !== dataSource) {
      setDatasource(dataSource);
      setDatapoints([]);
      setChartTypes([]);
      setFilterAnswers({});
      setFilterOptions([]);
    }
  };

  const getSettings = () => {
    // if the answers are cleared they get saved as an empty array - we dont want that
    if (filterAnswers)
      Object.keys(filterAnswers).forEach(key => {
        if (filterAnswers[key]?.length === 0) {
          delete filterAnswers[key];
        }
      });
    return {
      datasource,
      category,
      subcategory,
      type: showTrend ? `${chartType}-trend` : chartType,
      filterArgs: filterAnswers,
      standards,
      showTrend
    };
  };

  const validStandard = standards
    ? standards?.toString()?.match(/^[0-9]+$/g) && standards?.toString() !== '0'
    : true;

  const canSubmit = datasource && category && validStandard;

  const handleSubmit = () => {
    const payload = {
      ...getSettings(),
      title: title?.trim(),
      shouldBeHigherThanStandards,
      showStandards,
      allowTrend
    };
    if (cardId) {
      updateAnalyticsChart(cardId, payload);
    } else {
      createAnalyticsChart(props.location.state.dashboardId, payload);
    }

    setTimeout(() => history.goBack(), 1000);
  };

  const leavePage = () => {
    if (hasUnsavedChanges) setOpenUnsavedChangesModal(true);
    else history.goBack();
  };

  let preview;
  if (!datasource || !category) {
    preview = (
      <div className={`${styles.previewBody} ${styles.previewPending}`}>
        <div className={styles.noCardMessage}>
          <img src={AnalyticWarning} alt="analyticsWarning" />
          <div className={styles.createMessage}>
            Choose Data Source and Category to see a preview.
          </div>
        </div>
      </div>
    );
  } else {
    preview = (
      <AnalyticsChart
        width={445}
        height={445}
        className={styles.previewBody}
        settings={getSettings()}
        preview={true}
        shouldBeHigherThanStandards={shouldBeHigherThanStandards}
      />
    );
  }

  const header = (
    <Header
      title="My Analytics"
      section={cardId ? 'Edit Card' : 'Create Card'}
      clickBack={leavePage}
      needsSaved={hasUnsavedChanges}
    />
  );
  const footer = (
    <SaveCancelFooter
      saveButtonClick={() =>
        canSubmit ? handleSubmit() : setMissingModalOpen(true)
      }
      cancelButtonClick={leavePage}
      editing={cardId}
      onMouseEnter={() => setMissingRequired(true)}
    />
  );

  return (
    <HeaderAndFooter
      Header={header}
      Footer={footer}
      className={styles.container}
    >
      <Card wide className={styles.widget}>
        <div className={styles.selects}>
          <Dropdown
            fieldLabel="Datasource"
            isRequired
            placeholder="Choose One"
            currentValue={datasource}
            onChange={value => {
              updateDataSource(value);
              setHasUnsavedChanges(true);
            }}
            options={datasources}
            searchable
            touched={missingRequired && !datasource}
          />
          <Dropdown
            fieldLabel="KPI"
            isRequired
            placeholder={
              datasource ? 'Choose a KPI' : 'Please select Datasource first'
            }
            onChange={value => {
              setCategory(value);
              setHasUnsavedChanges(true);
            }}
            currentValue={category}
            disabled={!datasource}
            options={datapoints}
            searchable
            touched={missingRequired && datasource && !category}
          />
          <Textbox
            displayTooltip
            tooltip="If left blank, the title will autofill with the datasource and category"
            currentValue={title}
            fieldLabel="Card Title"
            placeholder="Be clear and concise"
            onChange={value => {
              setTitle(value);
              setHasUnsavedChanges(true);
            }}
          />
          {category?.length > 0 ? (
            <>
              <Dropdown
                fieldLabel="Card Type"
                currentValue={chartType}
                isRequired
                onChange={value => {
                  if (value !== 'bar' || value !== 'line') {
                    setShowTrend(false);
                  }
                  setChartType(value);
                  setHasUnsavedChanges(true);
                }}
                options={charts}
              />
              <TwoColumn>
                {showStandards && chartType !== 'pie' ? (
                  <Textbox
                    fieldLabel="Standards"
                    currentValue={standards}
                    onChange={value => {
                      setStandards(value === '' ? null : value);
                      setHasUnsavedChanges(true);
                    }}
                    displayTooltip
                    tooltip="Amount Acceptable"
                    touched={!validStandard && missingRequired}
                    errorMessage={
                      !validStandard ? 'Positive Whole Numbers Only' : null
                    }
                  />
                ) : null}
                {allowTrend && (chartType === 'bar' || chartType === 'line') ? (
                  <Checkbox
                    className={styles.trend}
                    fieldLabel="Show Comparison Data"
                    displayTooltip
                    tooltip="When active, it will show the comparison data for the previous time period selected on dashboard."
                    onChange={value => {
                      setShowTrend(value);
                      setHasUnsavedChanges(true);
                    }}
                    currentValue={showTrend}
                  />
                ) : null}
              </TwoColumn>
              <h3 className={styles.filterHeader}>Filters</h3>
              <hr />
              <HierarchySelector
                groupRequired={false}
                onGroupChange={value => {
                  setFilterAnswers({ ...filterAnswers, groupIds: value });
                  setHasUnsavedChanges(true);
                }}
                groupId={filterAnswers?.groupIds}
                onProjectChange={value => {
                  setFilterAnswers({
                    ...filterAnswers,
                    projectIds: value,
                    groupIds: filterAnswers.groupIds ?? [activeGroup._id]
                  });
                  setHasUnsavedChanges(true);
                }}
                projectId={filterAnswers?.projectIds}
                projectDisabled={
                  !filterAnswers?.groupIds?.length && !activeGroup
                }
                multi
              />
              <TwoColumn>
                {hideAdditionalFilters
                  ? null
                  : filterOptions?.map(f => (
                      <Dropdown
                        fieldLabel={f.label}
                        currentValue={filterAnswers?.[f.dbLabel]}
                        onChange={value => {
                          let updated = { ...filterAnswers };
                          updated[f.dbLabel] = value;
                          setFilterAnswers(updated);
                          setHasUnsavedChanges(true);
                        }}
                        placeholder="Choose one or more"
                        options={f.options}
                        multi
                        searchable
                        selectButtons
                        displayTooltip={f.label === 'Has Unacceptable Items'}
                        tooltip={'This will only look at completed Audits'}
                      />
                    ))}
              </TwoColumn>
            </>
          ) : null}
        </div>
        <div className={styles.preview}>
          <div className={styles.previewHeader}>Preview</div>
          {preview}
        </div>
      </Card>
      <SaveChangesModal
        isOpen={openUnsavedChangesModal}
        onRequestClose={() => setOpenUnsavedChangesModal(false)}
        submitActions={() => history.goBack()}
        savingWhat="a card"
      />
      <MissingRequiredModal
        isOpen={missingModalOpen}
        onRequestClose={() => setMissingModalOpen(false)}
        subject="Analytics Card"
      />
    </HeaderAndFooter>
  );
}
