export const PART_OF_BODY_OPTIONS = [
  { label: 'Head', value: 10 },
  { label: 'Skull', value: 11 },
  { label: 'Brain', value: 12 },
  { label: 'Ears', value: 13 },
  { label: 'Eye', value: 14 },
  { label: 'Nose', value: 15 },
  { label: 'Teeth', value: 16 },
  { label: 'Mouth', value: 17 },
  { label: 'Facial Soft Tissue', value: 18 },
  { label: 'Facial Bones', value: 19 },
  { label: 'Neck', value: 20 },
  { label: 'Vertebrae In Neck', value: 21 },
  { label: 'Disc In Neck', value: 22 },
  { label: 'Spinal Cord Neck Area', value: 23 },
  { label: 'Larynx', value: 24 },
  { label: 'Neck Soft Tissue', value: 25 },
  { label: 'Trachea', value: 26 },
  { label: 'Upper Extremities', value: 30 },
  { label: 'Upper Arm Clavicle or Scapula', value: 31 },
  { label: 'Elbow', value: 32 },
  { label: 'Lower Arm', value: 33 },
  { label: 'Wrist', value: 34 },
  { label: 'Hand', value: 35 },
  { label: 'Finger', value: 36 },
  { label: 'Thumb', value: 37 },
  { label: 'Shoulder', value: 38 },
  { label: 'Wrist and Hand', value: 39 },
  { label: 'Trunk', value: 40 },
  { label: 'Upper Back Thoracic', value: 41 },
  { label: 'Low Back Lumbar or Lumbosacral', value: 42 },
  { label: 'Disc in Low or Middle Back', value: 43 },
  { label: 'Chest Ribs or Sternum', value: 44 },
  { label: 'Sacrum or Coccyx', value: 45 },
  { label: 'Pelvis', value: 46 },
  { label: 'Spinal Cord Low or Middle Back Area', value: 47 },
  { label: 'Internal Organs', value: 48 },
  { label: 'Heart Angina', value: 49 },
  { label: 'Lower Extremities', value: 50 },
  { label: 'Hip', value: 51 },
  { label: 'Thigh', value: 52 },
  { label: 'Knee', value: 53 },
  { label: 'Lower Leg', value: 54 },
  { label: 'Ankle', value: 55 },
  { label: 'Foot', value: 56 },
  { label: 'Toe', value: 57 },
  { label: 'Great Toe', value: 58 },
  { label: 'Lungs', value: 60 },
  { label: 'Abdomen', value: 61 },
  { label: 'Buttocks', value: 62 },
  { label: 'Lumbar and/or Sacral Vertebrae', value: 63 },
  { label: 'Artifical Appliance', value: 64 },
  { label: 'Insufficient Information', value: 65 },
  { label: 'No Physical Injury', value: 66 },
  { label: 'Multiple Body Parts', value: 90 },
  { label: 'Body Systems and Mulitple Systems', value: 91 }
];

export const NATURE_OF_INJURY_OPTIONS = [
  {
    label: 'Acquired Immunodeficiency Syndrome',
    value: 75,
    applicableBodyParts: [90, 91]
  },
  {
    label: 'Adverse Reaction to Vaccination or Inoculation',
    value: 38,
    applicableBodyParts: [91]
  },
  {
    label: 'Amputation',
    value: 2,
    applicableBodyParts: [
      11, 13, 15, 23, 24, 25, 26, 30, 31, 32, 33, 34, 35, 36, 37, 40, 47, 48,
      50, 51, 52, 53, 54, 55, 56, 57, 58, 90
    ]
  },
  { label: 'Angina Pectoris', value: 3, applicableBodyParts: [44, 49] },
  {
    label: 'Asphyxiation',
    value: 54,
    applicableBodyParts: [10, 12, 15, 17, 19, 20, 24, 26, 44, 60]
  },
  {
    label: 'Burn',
    value: 4,
    applicableBodyParts: [
      10, 12, 13, 14, 15, 17, 18, 19, 20, 24, 25, 26, 30, 31, 32, 34, 35, 36,
      37, 38, 39, 40, 41, 42
    ]
  },
  {
    label: 'Cancer',
    value: 74,
    applicableBodyParts: [
      10, 11, 12, 13, 14, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 30, 31,
      32, 33, 34, 35, 36, 37, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51,
      52, 53, 54, 55, 56, 57, 60, 90, 91
    ]
  },
  { label: 'Concussion', value: 7, applicableBodyParts: [10, 11, 12, 19] },
  { label: 'Contagious Disease', value: 73, applicableBodyParts: [10] },
  {
    label: 'Contusion',
    value: 10,
    applicableBodyParts: [
      10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 30,
      31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48,
      50, 51, 52, 53, 54, 55, 56, 57, 58, 61, 62, 63, 90
    ]
  },
  { label: 'Covid19/Coronavirus', value: 83, applicableBodyParts: [60, 91] },
  {
    label: 'Crushing',
    value: 13,
    applicableBodyParts: [
      10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 30,
      31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48,
      50, 51, 52, 53, 54, 55, 56, 57, 58, 61, 63, 90
    ]
  },
  {
    label: 'Cumulative Injury',
    value: 80,
    applicableBodyParts: [
      10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 30,
      31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 45, 46, 47, 48, 49,
      50, 51, 52, 53, 54, 55, 56, 57, 90
    ]
  },
  {
    label: 'Dermatitis',
    value: 68,
    applicableBodyParts: [
      10, 11, 13, 15, 17, 18, 20, 25, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
      40, 41, 42, 44, 50, 51, 52, 53, 54, 55, 56, 57, 58, 61, 62, 90
    ]
  },
  {
    label: 'Dislocation',
    value: 16,
    applicableBodyParts: [
      15, 16, 19, 20, 21, 22, 23, 30, 31, 32, 34, 36, 37, 38, 39, 41, 42, 43,
      45, 46, 47, 48, 50, 51, 53, 55, 57, 58, 63
    ]
  },
  {
    label: 'Dust Exposure',
    value: 60,
    applicableBodyParts: [10, 20, 24, 30, 40, 44, 48, 50, 60, 90]
  },
  {
    label: 'Electric Shock',
    value: 19,
    applicableBodyParts: [
      10, 11, 12, 13, 14, 15, 16, 17, 18, 20, 23, 25, 30, 31, 35, 36, 37, 40,
      47, 49, 50, 54, 56, 57, 90, 91
    ]
  },
  {
    label: 'Foreign Body',
    value: 25,
    applicableBodyParts: [
      10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 30,
      31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48,
      49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 61, 62, 90
    ]
  },
  {
    label: 'Fracture',
    value: 28,
    applicableBodyParts: [
      10, 11, 15, 16, 19, 20, 21, 22, 23, 24, 26, 30, 31, 32, 33, 34, 35, 36,
      37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 50, 51, 52, 53, 54, 55, 56,
      57, 58, 63, 90
    ]
  },
  {
    label: 'Freezing',
    value: 30,
    applicableBodyParts: [
      10, 11, 13, 14, 15, 16, 17, 18, 20, 25, 30, 31, 33, 35, 36, 37, 40, 41,
      42, 44, 50, 54, 56, 57, 58, 90
    ]
  },
  {
    label: 'Hearing Loss Non Traumatic',
    value: 72,
    applicableBodyParts: [10, 12, 13]
  },
  {
    label: 'Hearing Loss Traumatic',
    value: 31,
    applicableBodyParts: [10, 13, 20]
  },
  {
    label: 'Heat Prostration',
    value: 32,
    applicableBodyParts: [10, 12, 20, 30, 40, 50, 90]
  },
  {
    label: 'Hernia',
    value: 34,
    applicableBodyParts: [21, 22, 23, 24, 25, 26, 43, 44, 46, 48, 61]
  },
  {
    label: 'Infection',
    value: 36,
    applicableBodyParts: [
      10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 30,
      31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48,
      49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 60, 61, 62, 90
    ]
  },
  {
    label: 'Inflammation',
    value: 37,
    applicableBodyParts: [
      10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 30,
      31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48,
      49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 60, 61, 62, 90
    ]
  },
  {
    label: 'Laceration',
    value: 40,
    applicableBodyParts: [
      10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 30,
      31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48,
      49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 61, 62, 90
    ]
  },
  {
    label: 'Lung Disease Asbestosis',
    value: 61,
    applicableBodyParts: [10, 20, 24, 30, 40, 44, 50, 60, 90]
  },
  {
    label: 'Lung Disease Byssinosis',
    value: 63,
    applicableBodyParts: [10, 20, 24, 30, 40, 44, 50, 60, 90]
  },
  {
    label: 'Lung Disease Pneumoconiosis',
    value: 62,
    applicableBodyParts: [10, 20, 24, 30, 40, 44, 48, 50, 60, 90]
  },
  {
    label: 'Lung Disease Silicosis',
    value: 64,
    applicableBodyParts: [10, 20, 24, 30, 40, 44, 50, 60, 90]
  },
  {
    label: 'Mental Disorder',
    value: 69,
    applicableBodyParts: [10, 12, 20, 23, 30, 40, 50, 90]
  },
  { label: 'Mental Stress', value: 77, applicableBodyParts: [10, 12, 66] },
  { label: 'Multiple Injuries', value: 91, applicableBodyParts: [90, 91] },
  {
    label: 'Multiple Physical Injuries',
    value: 90,
    applicableBodyParts: [48, 90, 91]
  },
  { label: 'Myocardial Infarction', value: 41, applicableBodyParts: [49] },
  { label: 'No Physical Injury', value: 1, applicableBodyParts: [64] },
  {
    label: 'Occupational Disease',
    value: 71,
    applicableBodyParts: [
      10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 30,
      31, 32, 33, 34, 35, 36, 37, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50,
      51, 52, 53, 54, 55, 56, 57, 60, 90, 91
    ]
  },
  {
    label: 'Other Injury Not Otherwise Catagorized',
    value: 59,
    applicableBodyParts: [
      10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 30,
      31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48,
      49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 60, 61, 62, 63, 65, 90, 91
    ]
  },
  {
    label: 'Poisoning - Chemical',
    value: 66,
    applicableBodyParts: [
      10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 30,
      31, 32, 33, 34, 35, 36, 37, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50,
      51, 52, 53, 54, 55, 56, 57, 60, 90, 91
    ]
  },
  {
    label: 'Poisoning - Metal',
    value: 67,
    applicableBodyParts: [
      10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 30,
      31, 32, 33, 34, 35, 36, 37, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50,
      51, 52, 53, 54, 55, 56, 57, 60, 90, 91
    ]
  },
  {
    label: 'Poisoning Other than Chemical or Metal',
    value: 42,
    applicableBodyParts: [48, 60, 90, 91]
  },
  {
    label: 'Puncture',
    value: 43,
    applicableBodyParts: [
      10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 30,
      31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48,
      49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 60, 61, 62, 90
    ]
  },
  {
    label: 'Radiation',
    value: 70,
    applicableBodyParts: [
      10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 30,
      31, 32, 33, 34, 35, 36, 37, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50,
      51, 52, 53, 54, 55, 56, 57, 60, 90, 91
    ]
  },
  {
    label: 'Respiratory Disorder',
    value: 65,
    applicableBodyParts: [
      10, 12, 15, 17, 20, 23, 24, 26, 30, 40, 44, 47, 48, 50, 60, 90
    ]
  },
  {
    label: 'Severance',
    value: 47,
    applicableBodyParts: [
      10, 13, 15, 23, 25, 30, 31, 32, 33, 34, 35, 36, 37, 40, 45, 47, 50, 53,
      54, 55, 56, 57, 58, 90
    ]
  },
  {
    label: 'Sprain',
    value: 49,
    applicableBodyParts: [
      10, 13, 19, 20, 21, 23, 25, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
      41, 42, 44, 45, 46, 47, 50, 51, 52, 53, 54, 55, 56, 57, 58, 62, 90
    ]
  },
  {
    label: 'Strain',
    value: 52,
    applicableBodyParts: [
      10, 13, 14, 15, 17, 19, 20, 21, 23, 24, 25, 26, 30, 31, 32, 33, 34, 35,
      36, 37, 38, 40, 41, 42, 44, 45, 46, 47, 49, 50, 51, 52, 53, 54, 55, 56,
      57, 58, 61, 62, 90
    ]
  },
  {
    label: 'Syncope',
    value: 53,
    applicableBodyParts: [10, 12, 49, 60, 66, 90, 91]
  },
  {
    label: 'Vascular',
    value: 55,
    applicableBodyParts: [
      10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 30,
      31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48,
      49, 50, 51, 52, 53, 54, 55, 56, 57, 90, 91
    ]
  },
  {
    label: 'Video Display Terminal Related Disease',
    value: 76,
    applicableBodyParts: [10, 14, 20, 30, 40, 50, 90]
  },
  { label: 'Vision Loss', value: 58, applicableBodyParts: [10, 12, 14] }
];

export const INJURY_CAUSE_CODE_OPTIONS = [
  { label: 'Burn or scald by chemicals', value: 1 },
  { label: 'Burn or scald by hot object or substance', value: 2 },
  { label: 'Burn or scald by temperature extreme', value: 3 },
  { label: 'Burn or scald by fire or flame', value: 4 },
  { label: 'Burn or scald by steam or hot fluids', value: 5 },
  { label: 'Burn or scald by dust gases, fumes, or vapors', value: 6 },
  { label: 'Burn or scald by welding operation', value: 7 },
  { label: 'Burn or scald by radiation', value: 8 },
  { label: 'Other cause of burn', value: 9 },
  { label: 'Caught in/under/between machine or machinery', value: 10 },
  { label: 'Burn or scald by cold objects or substances', value: 11 },
  { label: 'Caught in/under/between object handled', value: 12 },
  { label: 'Other caught in/under/between', value: 13 },
  { label: 'Burn or scald by abnormal air pressure', value: 14 },
  { label: 'Cut/punctured/scraped by broken glass', value: 15 },
  {
    label: 'Cut/punctured/scraped by hand tool/utensil not powered',
    value: 16
  },
  {
    label: 'Cut/punctured/scraped by object being lifted or handled',
    value: 17
  },
  { label: 'Cut/punctured/scraped by powered hand tool appliance', value: 18 },
  { label: 'Other cause of cut/puncture/scrape', value: 19 },
  {
    label: 'Caught in/under/betwwen collapsing materials/slides of earth',
    value: 20
  },
  { label: 'Slip/fall/trip from different level', value: 25 },
  { label: 'Slip/fall/trip from ladder or scaffolding', value: 26 },
  { label: 'Slip/fall/trip from liquid or grease spills', value: 27 },
  { label: 'Slip/fall/trip into opening', value: 28 },
  { label: 'Slip/fall/trip on same level', value: 29 },
  { label: 'Slip or trip did no fall', value: 30 },
  { label: 'Other cause of slip/trip/fall', value: 31 },
  { label: 'Slip/fall/trip on ice or snow', value: 32 },
  { label: 'Slip/fall/trip on stairs', value: 33 },
  { label: 'Crash of water vehicle', value: 40 },
  { label: 'Crash of rail vehicle', value: 41 },
  { label: 'Motor vehicle collision with another vehicle', value: 45 },
  { label: 'Motor vehicle collision with fixed object', value: 46 },
  { label: 'Crash of ariplane', value: 47 },
  { label: 'Motor vehicle upset', value: 48 },
  { label: 'Other motor vehicle cause or injury', value: 50 },
  { label: 'Strain or injury by continual noise', value: 52 },
  { label: 'Strain or injury by twisting', value: 53 },
  { label: 'Strain or injury by jumping or leaping', value: 54 },
  { label: 'Strain or injury by holding or carrying', value: 55 },
  { label: 'Strain or injury by lifting', value: 56 },
  { label: 'Strain or injury by pushing or pulling', value: 57 },
  { label: 'Strain or injury by reaching', value: 58 },
  { label: 'Strain or injury by using tool or machine', value: 59 },
  { label: 'Other cause of strain', value: 60 },
  { label: 'Strain or injury by wielding or throwing', value: 61 },
  { label: 'Struck against or stepped on moving parts', value: 65 },
  {
    label: 'Struck against or stepped on object being lifted or held',
    value: 66
  },
  { label: 'Injured by sanding/scraping/cleaning operations', value: 67 },
  { label: 'Struck by or stepped on stationary object', value: 68 },
  { label: 'Struck by or stepped on sharp object', value: 69 },
  { label: 'Other struck against or stepped on causes', value: 70 },
  {
    label: 'Struck or injured by person, fellow worker, patient or other',
    value: 74
  },
  { label: 'Struck or injured by object falling or flying object', value: 75 },
  {
    label: 'Struck or injured by object hand tool or machine in use',
    value: 76
  },
  { label: 'Struck or injured by object motor vehicle', value: 77 },
  { label: 'Struck or injured by object moving parts of machine', value: 78 },
  { label: 'Struck or injured by object being lifted or handled', value: 79 },
  { label: 'Struck or injured by object handled by others', value: 80 },
  { label: 'Other struck or injured by causes', value: 81 },
  { label: 'Absorption/ingestion/inhalation/vaccination', value: 82 },
  { label: 'Pandemic', value: 83 },
  { label: 'Burn or scald by electric current', value: 84 },
  { label: 'Struck or injured by animal or insect', value: 85 },
  { label: 'Struck or injured by explosion or flare back', value: 86 },
  { label: 'Foreign matter in eye', value: 87 },
  { label: 'Natural disasters/earthquake/hurricane/tornado/etc', value: 88 },
  {
    label: 'Act of crime, robbery or criminal assult by other person',
    value: 89
  },
  { label: 'Other than physical cause of injury', value: 90 },
  { label: 'Mold', value: 91 },
  { label: 'Rubbed or abraded by repetitive motion', value: 94 },
  { label: 'Otehr rubbed or abraded by causes', value: 95 },
  { label: 'Terrorism', value: 96 },
  { label: 'Strain or injury by repetitive motion', value: 97 },
  { label: 'Cumulative injury', value: 98 },
  { label: 'Miscellaneous/not otherwise categorized causes', value: 99 }
];

export const MARITAL_STATUS = [
  { label: 'Single', value: 'single' },
  { label: 'Married', value: 'married' },
  { label: 'Separated', value: 'separated' },
  { label: 'Divorced', value: 'divorced' },
  { label: 'Widowed', value: 'widowed' },
  { label: 'Other', value: 'other' },
  { label: 'Unknown', value: 'unknown' }
];

export const EMPLOYEMENT_STATUS = [
  { label: 'Full Time', value: 'fullTimeRegularEmployee' },
  { label: 'Part Time', value: 'partTimeEmployee' },
  { label: 'Unemployed', value: 'unemployed' },
  { value: 'onStrikeEmployee', label: 'On Strike' },
  { label: 'Disabled', value: 'disabledEmployee' },
  { label: 'Retired', value: 'retiredEmployee' },
  { label: 'Other', value: 'other' },
  { label: 'Seasonal', value: 'seasonalOrTemporaryEmployee' },
  { label: 'Volunteer', value: 'volunteer' },
  { label: 'Full Time Apprentice', value: 'fullTimeApprenticeOrIntern' },
  { label: 'Part Time Apprentice', value: 'partTimeApprenticeOrIntern' },
  { label: 'Piece Worker', value: 'pieceWorker' },
  { label: 'Unknown', value: 'unknown' }
];

export const WAGES_PAID_FOR_DATE_OF_INJURY_OPTIONS = [
  { label: 'No pay for day of injury', value: 'noPayForDayOfInjury' },
  { label: 'Unknown', value: 'unknown' },
  { label: 'Full pay for day of injury', value: 'fullPayForDayOfInjury' },
  { label: 'Partial pay for day of injury', value: 'partialPayForDayOfInjury' }
];

export const YES_NO_UNKNOWN = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
  { label: 'Unknown', value: 'unknown' }
];

export const INITIAL_TREATMENT_OPTIONS = [
  { label: 'No medical treatment', value: 'noMedicalTreatment' },
  { label: 'Minor onsite treatment', value: 'minorOnsiteTreatment' },
  {
    label: "Treatment at physican's office",
    value: 'treatmentAtPhysiciansOffice'
  },
  { label: 'Treatment at clinic', value: 'treatmentAtClinic' },
  {
    label: 'Hospital emergency room treated and released',
    value: 'hospitalEmergencyRoomTreatedAndReleased'
  },
  {
    label: 'Hospital admitted treated longer than 24 hours',
    value: 'hospitalAdmittedTreatedLongerThan24Hours'
  },
  {
    label: 'Hospital admitted treated less than 24 hours',
    value: 'hospitalAdmittedTreatedLessThan24Hours'
  },
  { label: 'Treatment unknown', value: 'treatmentUnknown' }
];

export const EVENT_LOCATION_DESCRIPTION = [
  {
    label: 'Employees designated company location',
    value: 'EmployeesDesignatedCompanyLocation'
  },
  { label: 'A different company location', value: 'DifferentCompanyLocation' },
  {
    label: 'Another location, not a company location',
    value: 'NonCompanyLocation'
  },
  {
    label: 'Working from home employees address',
    value: 'WorkingFromHomeEmployeesAddress'
  }
];

export const CLAIM_REPORTED_BY_TITLE = [
  { label: 'Injured Worker', value: 'InjuredWorker' },
  { label: 'Customer Contact', value: 'CustomerContact' },
  { label: 'Supervisor Of Injured Worker', value: 'SupervisorOfInjuredWorker' },
  { label: 'Attorney For Injured Worker', value: 'AttorneyForInjuredWorker' },
  { label: 'Insurance Broker Or Agent', value: 'InsuranceBrokerOrAgent' },
  {
    label: 'Human Resources Or Personnel Department',
    value: 'HumanResourcesOrPersonnelDepartment'
  },
  { label: 'Company Nurse Or Doctor', value: 'CompanyNurseOrDoctor' },
  {
    label: 'Work Comp Board Or Commission',
    value: 'WorkCompBoardOrCommission'
  },
  { label: 'Medical Provider', value: 'MedicalProvider' },
  { label: 'Managed Care Organization', value: 'ManagedCareOrganization' },
  { label: 'Other', value: 'Other' }
];

export const BODILY_INJURED_INCURRED_CAUSE = [
  { label: 'Aircraft/Railroad Incidents', value: 'AircraftRailroadIncidents' },
  { label: 'Assault/Harassment', value: 'AssaultHarassment' },
  { label: 'Defamation/Libel/Slander', value: 'DefamationLibelSlander' },
  { label: 'Discrimination', value: 'Discrimination' },
  {
    label: 'Elevator/Escalator Incidents',
    value: 'ElevatorEscalatorIncidents'
  },
  { label: 'Flammable Fabrics', value: 'FlammableFabrics' },
  { label: 'Food Borne Illness', value: 'FoodBorneIllness' },
  { label: 'Impact With Object', value: 'ImpactWithObject' },
  { label: 'Other', value: 'Other' },
  { label: 'Prescription Error', value: 'PrescriptionError' },
  { label: 'Product Tampering', value: 'ProductTampering' },
  { label: 'Slip and Fall', value: 'SlipAndFall' }
];

export const BODILY_INJURED_INCURRED_SEVERITY = [
  { label: 'Amputation', value: 'Amputation' },
  { label: 'Burn 1st Degree', value: 'Burn1stDegree' },
  { label: 'Burn 2nd Degree', value: 'Burn2ndDegree' },
  { label: 'Burn 3rd Degree', value: 'Burn3rdDegree' },
  { label: 'Concussion', value: 'Concussion' },
  { label: 'Cut/Scrape/Abrasion', value: 'CutScrapeAbrasion' },
  {
    label: 'Dislocation Or Soft Tissue Sprain/Strain',
    value: 'DislocationOrSoftTissueSprainStrain'
  },
  { label: 'Fatality', value: 'Fatality' },
  { label: 'Fracture Major Body Part', value: 'FractureMajorBodyPart' },
  { label: 'Head Trauma', value: 'HeadTrauma' },
  { label: 'Heart Attack Or Stroke', value: 'HeartAttackOrStroke' },
  { label: 'Injury Extent Unknown', value: 'InjuryExtentUnknown' },
  { label: 'Mental Stress/Anxiety', value: 'MentalStressAnxiety' },
  { label: 'Other', value: 'Other' },
  { label: 'Paralysis', value: 'Paralysis' },
  { label: 'Repetitive Motion Injury', value: 'RepetitiveMotionInjury' },
  {
    label: 'Respiratory Pulmonary Problem',
    value: 'RespiratoryPulmonaryProblem'
  }
];

export const CLAIM_REPORTED_BY_RELATIONSHIP_TO_INSURED_CODE = [
  { label: 'Employee or Internal Agent', value: 'EmployeeOrInternalAgent' },
  { label: 'External Agent', value: 'ExternalAgent' },
  { label: 'Other', value: 'Other' }
];

export const VEHICLE_OWNERSHIP_TYPE_CODE = [
  { label: 'Hired Or Rented', value: 'HiredOrRented' },
  { label: 'Leased Long Term', value: 'LeasedLongTerm' },
  { label: 'Leased Short Term', value: 'LeasedShortTerm' },
  {
    label: 'Non-Owned/Belongs To A Customer',
    value: 'NonOwnedBelongsToACustomer'
  },
  {
    label: 'Non-Owned/Belongs To An Employee',
    value: 'NonOwnedBelongsToAnEmployee'
  },
  {
    label: 'Non-Owned/Borrowed From Someone',
    value: 'NonOwnedBorrowedFromSomeone'
  },
  { label: 'Owned', value: 'Owned' }
];

export const TYPE_OF_VEHICLE = [
  { label: 'Bus', value: 'Bus' },
  { label: 'Motorcycle', value: 'Motorcycle' },
  { label: 'Other', value: 'Other' },
  { label: 'Passenger Automobile', value: 'PassengerAutomobile' },
  { label: 'Recreational Vehicle', value: 'RecreationalVehicle' },
  { label: 'Tractor Only', value: 'TractorOnly' },
  { label: 'Trailer Only', value: 'TrailerOnly' },
  { label: 'Tractor Trailer', value: 'TractorTrailer' }
];

export const POINT_OF_IMPACT_CODE = [
  { label: 'Front', value: 'Front' },
  { label: 'Left Center Side', value: 'LeftCenterSide' },
  { label: 'Left Front Corner', value: 'LeftFrontCorner' },
  { label: 'Left Front Side', value: 'LeftFrontSide' },
  { label: 'Left Rear Corner', value: 'LeftRearCorner' },
  { label: 'Left Rear Side', value: 'LeftRearSide' },
  { label: 'Non-Collision', value: 'NonCollision' },
  { label: 'Not Applicable', value: 'NotApplicable' },
  { label: 'Point of Impact Unknown', value: 'PointOfImpactUnknown' },
  { label: 'Rear', value: 'Rear' },
  { label: 'Right Center Side', value: 'RightCenterSide' },
  { label: 'Right Front Center', value: 'RightFrontCenter' },
  { label: 'Right Front Side', value: 'RightFrontSide' },
  { label: 'Right Rear Corner', value: 'RightRearCorner' },
  { label: 'Right Rear Side', value: 'RightRearSide' },
  { label: 'Roof', value: 'Roof' },
  { label: 'Total Loss', value: 'TotalLoss' },
  { label: 'Undercarriage', value: 'Undercarriage' }
];

export const ESTIMATED_DAMAGE_SEVERITY_CODE = [
  { label: 'Minor (Scratches, Scrapes, Dings)', value: 'Minor' },
  { label: 'Moderate (Large Dents)', value: 'Moderate' },
  { label: 'Severe (Very Heavy Damage)', value: 'Severe' },
  { label: 'Damage Severity Unknown', value: 'Unknown' },
  { label: 'Not Applicable/No Vehicle Damage', value: 'NotApplicable' }
];

export const RELATIONSHIP_VEHICLE_OWNER = [
  { label: 'Employee', value: 'Employee' },
  { label: 'Other', value: 'Other' },
  { label: 'Self', value: 'Self' }
];

export const AUTO_EVENT_DESCRIPTION = [
  { label: 'Hit Claiment in Rear', value: '01' },
  { label: 'Intersection', value: '02' },
  { label: 'Hit/Pedestrian/Cyclist/Other', value: '03' },
  { label: 'Head on Collision', value: '04' },
  { label: 'Cut in/out Sideswipe', value: '05' },
  { label: 'Insured Backing', value: '06' },
  { label: 'Hit Parked Car', value: '07' },
  { label: 'Insured Pulled From Parked Position', value: '08' },
  { label: 'Load/Unload Delivery Falling Cargo', value: '09' },
  { label: 'Jackknife', value: '10' },
  { label: 'Customer Vehicle Upset/Overturned', value: '11' },
  { label: 'Customer Parked', value: '12' },
  { label: 'Customer Hit in Rear', value: '13' },
  { label: 'Hit Stationary Object', value: '15' },
  { label: 'Other', value: '18' },
  { label: 'Fire', value: '19' },
  { label: 'Partial Theft', value: '21' },
  { label: 'Garagekeepers', value: '26' },
  { label: 'Comprehensive Glass', value: '30' },
  { label: 'Theft of Personal Effects', value: '40' },
  { label: 'Slip/Fall on Ice or Snow', value: '41' },
  { label: 'Slip/Fall not on Ice or Snow', value: '42' },
  { label: 'Sprayed on Paint', value: '44' },
  { label: 'Stepped on/into', value: '45' },
  { label: 'Malicious Mischief or Vandalism', value: '50' },
  { label: 'Tornado', value: '60' },
  { label: 'Wind', value: '61' },
  { label: 'Earthquake', value: '62' },
  { label: 'Hail', value: '63' },
  { label: 'Explosion', value: '64' },
  { label: 'Water Damage', value: '65' },
  { label: 'Falling Objects', value: '66' },
  { label: 'Hit Animal', value: '67' },
  { label: 'Flood and Rising Water', value: '70' },
  { label: 'Hurricane/Tropical Storm', value: '71' },
  { label: 'Environmental/BI/PD', value: '79' },
  { label: 'Fell on/off Vehicle', value: '81' },
  { label: 'Road Debris', value: '82' },
  { label: 'Loading/Unloading Passengers or Patients', value: '83' },
  { label: 'Other Comprehensive', value: '90' }
];

export const EXTENT_OF_INJURY_CODE = [
  { value: 'Amputation', label: 'Amputation' },
  { value: 'Burn1stDegree', label: 'Burn 1st Degree' },
  { value: 'Burn2ndDegree', label: 'Burn 2nd Degree' },
  { value: 'Burn3rdDegree', label: 'Burn 3rd Degree' },
  { value: 'Concussion', label: 'Concussion' },
  { value: 'CutScrapeAbrasion', label: 'Cut/Scrape/Abrasion' },
  {
    value: 'DislocationorSoftTissueSpainStrain',
    label: 'Dislocation or Soft Tissue Spain/Strain'
  },
  { value: 'Fatality', label: 'Fatality' },
  { value: 'FractureMajorBodyPart', label: 'Fracture (Major Body Part)' },
  { value: 'FractureMinorBodyPart', label: 'Fracture (Minor Body Part)' },
  { value: 'HeadTrauma', label: 'Head Trauma' },
  { value: 'HeartAttackorStroke', label: 'Heart Attack or Stroke' },
  { value: 'InjuryExtentUnknown', label: 'Injury Extent Unknown' },
  { value: 'MentalStressAnxiety', label: 'Mental Stress/Anxiety' },
  { value: 'Other', label: 'Other' },
  { value: 'Paralysis', label: 'Paralysis' },
  { value: 'RepetitiveMotionInjury', label: 'Repetitive Motion Injury' },
  {
    value: 'RespiratoryPulmonaryProblem',
    label: 'Respiratory/Pulmonary Problem'
  }
];

export const PROPERTY_DAMAGE_LOSS_OCCURRED = [
  {
    label: 'At Premisis',
    value: 'AtPremisis'
  },
  { label: 'In Transit', value: 'InTransit' },
  {
    label: 'Other',
    value: 'Other'
  }
];

export const PROPERTY_DAMAGE_TYPE_OF_LOSS = [
  {
    label: 'Cargo Property Damaged in Your Care as a Common Carrier',
    value: 'CargoCommonCarrier'
  },
  { label: 'Employee Dishonesty', value: 'EmployeeDishonesty' },
  { label: 'Freight Forwarder', value: 'FreightForwarder' },
  {
    label: 'Loss to Property You Own (including business interruption)',
    value: 'OwnedProperty'
  },
  { label: 'Loss to Property You Lease', value: 'LeasedProperty' },
  { label: 'Other', value: 'Other' },
  { label: 'Riggers Liability', value: 'RiggersLiability' },
  { label: 'Warehouse Liability', value: 'WarehouseLiability' }
];

export const PROPERTY_DAMAGE_ESTIMATE = [
  {
    label: '$0-5,000',
    value: '$0-5,000'
  },
  { label: '$5,001-15,000', value: '$5,001-15,000' },
  { label: '$15,001-25,000', value: '$15,001-25,000' },
  { label: '$25,001-50,000', value: '$25,001-50,000' },
  { label: '$50,001-150,000', value: '$50,001-150,000' },
  { label: '$150,000+', value: '$150,000+' }
];

export const PROPERTY_DAMAGE_EVENT = [
  {
    label: 'Accidental Discharge of Fire Protection System',
    value: 'AccidentalDischargeFireProtectionSystem'
  },
  { label: 'Damage from Water/Ice/Dam', value: 'DamageFromWaterIceDam' },
  {
    label: 'Water Backup Through Sewer Drains',
    value: 'WaterBackUpThroughSewerDrains'
  },
  { label: 'Appliance Equipment Leak', value: 'ApplianceEquipmentLeak' },
  { label: 'Flooding', value: 'Flooding' },
  { label: 'Freezing', value: 'Freezing' },
  { label: 'Damage from Water (Other)', value: 'DamageFromWaterOther' },
  {
    label: 'Plumbing System or Water Main',
    value: 'PlumbingSystemOrWaterMain'
  },
  { label: 'Sprinkler Discharge', value: 'SprinklerDischarge' },
  { label: 'Electrical Breakdown', value: 'ElectricalBreakdown' },
  { label: 'Mechanical Breakdown', value: 'MechanicalBreakdown' },
  {
    label: 'Pressure Equipment Breakdown',
    value: 'PressureEquipmentBreakdown'
  },
  { label: 'Service Line', value: 'ServiceLine' },
  {
    label: 'Fire (Damage due to Fire Removal)',
    value: 'FireDamageDueToFireRemoval'
  },
  { label: 'Fire (Accidental)', value: 'FireAccidental' },
  { label: 'Fire (Cooking)', value: 'FireCooking' },
  { label: 'Fire (Electrical)', value: 'FireElectrical' },
  { label: 'Fire (Other)', value: 'FireOther' },
  { label: 'Fire (Smoke)', value: 'FireSmoke' },
  { label: 'Fire (Wildfire)', value: 'FireWildfire' },
  { label: 'Hail', value: 'Hail' },
  { label: 'Lightning', value: 'Lightning' },
  { label: 'Motor Vehicle Caused Damage', value: 'MotorVehicleCausedDamage' },
  { label: 'Equipment Breakdown', value: 'EquipmentBreakdown' },
  { label: 'Service Interruption', value: 'ServiceInterruption' },
  { label: 'Theft', value: 'Theft' },
  { label: 'Acts of Destruction', value: 'ActsOfDestruction' },
  {
    label: 'Riot/Civil Commotion including Strikes',
    value: 'RiotCivilCommotionIncludingStrikes'
  },
  {
    label: 'Vandalism and Malicious Mischief',
    value: 'VandalismAndMaliciousMischief'
  },
  { label: 'Tropical Storms', value: 'TropicalStorms' },
  { label: 'Tornado', value: 'Tornado' },
  { label: 'Wind', value: 'Wind' }
];

export const PROPERTY_DAMAGE_PROPERTY_TYPE = [
  { label: 'Building', value: 'Building' },
  { label: 'Cargo', value: 'Cargo' },
  { label: 'Contents', value: 'Contents' },
  { label: 'Equipment', value: 'Equipment' },
  { label: 'Other', value: 'Other' }
];

export const RETURN_TO_WORK_DUTIES = [
  { label: 'Alternate Duty Assignments', value: 'alternateDutyAssignments' },
  { label: 'Light Duty Assignments', value: 'lightDutyAssignments' },
  { label: 'Regular Duty Assignments', value: 'regularDutyAssignments' }
];

export const AUTO_THIRD_PARTY_TYPES = [
  {
    label: 'Pedestrian/Bicyclist',
    value: 'PedestrianOrBicyclist'
  },
  { label: 'Building', value: 'Building' },
  { label: 'Vehicle', value: 'Vehicle' }
];

export const AUTO_BICYCLIST_PEDESTRIAN_ROLE_CODE = [
  { label: 'Bicyclist', value: 'Bicyclist' },
  { label: 'Pedestrian', value: 'Pedestrian' }
];

export const AUTO_OTHER_PROPERTY_DAMAGE_ROLE_CODE = [
  { label: 'Animal Owner', value: 'AnimalOwner' },
  { label: 'Object Owner', value: 'ObjectOwner' },
  { label: 'Structure Owner', value: 'StructureOwner' }
];
