import moment from 'moment';

export const getLocationDropdown = locationList =>
  locationList.map(location => {
    return { value: location._id, label: location.name };
  });

export const getProjectDropdown = (groupIds, activeLocation, locationList) => {
  let group = '';
  if (!groupIds && !activeLocation) {
    return [];
  } else if (activeLocation) {
    group = [activeLocation._id];
  } else {
    group = groupIds;
  }

  const selectedGroup = locationList.filter(o =>
    group.find(o2 => {
      if (o2?.value) {
        return o._id === o2?.value;
      } else {
        return o._id === o2;
      }
    })
  );

  let projectsDropdown = [];

  if (selectedGroup.length > 0) {
    selectedGroup.forEach(group => {
      group.projects.forEach(project => {
        projectsDropdown.push({
          value: project._id,
          label: `${project.name}`
        });
      });
    });
  }

  return projectsDropdown;
};

export const isValidTableQuestion = question => {
  const headerValues = Object.values(question?.options.columnHeaders ?? {});
  if (
    !question?.options?.columns ||
    !question?.options?.rows ||
    !headerValues.length
  )
    return false;

  if (
    Object.values(question?.options?.columnHeaders).length !==
    parseInt(question?.options?.columns)
  )
    return false;

  if (new Set(headerValues).size !== headerValues.length) return false;
  if (!headerValues.every(head => /[A-Za-z_@./#&+-]/g.test(head))) return false;
  if (!headerValues?.every(a => a?.length)) return false;

  return true;
};

export const validListDateRange = currentPeriod =>
  currentPeriod?.mode !== 'absolute' ||
  (currentPeriod.startDate &&
    currentPeriod.endDate &&
    moment(currentPeriod.startDate).isSameOrBefore(currentPeriod?.endDate));
