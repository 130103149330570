import React from 'react';
import './svg.css';

const cb = props => (
  <svg
    width={props.width ?? 30}
    height={props.height ?? 25}
    viewBox="0 0 80 80"
    x={props.x || '0px'}
    y={props.y || '0px'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M56.2187 20.7848L53.5279 21.6686C52.9593 20.9984 52.3366 20.3757 51.6664 19.8071L52.5502 17.1163C51.3434 16.1967 50.0291 15.4282 48.6356 14.8277L46.7247 16.9602C45.8993 16.6624 45.0507 16.4336 44.1875 16.2764L43.6026 13.4928H39.0556L38.4704 16.2764C37.6071 16.4336 36.7587 16.6624 35.9331 16.9602L34.0225 14.8277C32.6293 15.4281 31.3148 16.1964 30.1083 17.1158L30.9921 19.8066C30.3219 20.3751 29.6991 20.9978 29.1306 21.6681L26.4398 20.7842C25.5201 21.9911 24.7517 23.3053 24.1511 24.6988L26.2836 26.6094C25.9859 27.4349 25.7573 28.2834 25.5998 29.1467L22.6643 29.7319V34.2791L25.5998 34.8641C25.757 35.7273 25.9856 36.5758 26.2836 37.4013L24.1511 39.3119C24.7516 40.7051 25.5199 42.0196 26.4392 43.2262L29.13 42.3424C29.6986 43.0126 30.3213 43.6353 30.9915 44.2038L30.1077 46.8947C31.3145 47.8143 32.6288 48.5828 34.0223 49.1833L35.9332 47.0508C36.7586 47.3485 37.6071 47.5771 38.4704 47.7346L39.0553 50.5181H43.6023L44.1872 47.7346C45.0505 47.5774 45.8992 47.3485 46.7248 47.0508L48.6354 49.1836C50.0285 48.5831 51.3431 47.8146 52.5496 46.8952L51.6658 44.2044C52.336 43.6359 52.9588 43.0134 53.5273 42.3429L56.2181 43.2267C57.1378 42.0199 57.9062 40.7056 58.5068 39.3122L56.3743 37.4013C56.672 36.5758 56.9009 35.7273 57.0581 34.864L59.8416 34.2791V29.7321L57.0581 29.1469C56.9009 28.2837 56.672 27.4352 56.3743 26.6097L58.5071 24.6991C57.9066 23.3059 57.138 21.9913 56.2187 20.7848ZM41.329 42.671C38.5005 42.671 35.7874 41.5472 33.7871 39.5471C31.7869 37.5471 30.6633 34.8341 30.6633 32.0053C30.6633 29.1764 31.7871 26.4636 33.7871 24.4634C35.7872 22.4631 38.5002 21.3395 41.329 21.3395C44.1579 21.3395 46.8706 22.4633 48.8709 24.4634C50.8712 26.4634 51.9947 29.1764 51.9947 32.0053C51.9947 34.8341 50.871 37.5469 48.8709 39.5471C46.8709 41.5474 44.1579 42.671 41.329 42.671Z" />
    <path d="M38.6627 37.3383L46.6617 32.0056L38.6627 26.6728V37.3383Z" />
    <path d="M73.3253 31.9963C73.3253 23.5102 69.9543 15.3717 63.954 9.37136C57.9536 3.37099 49.8152 0 41.329 0C32.8429 0 24.7045 3.37099 18.7041 9.37136C12.7037 15.3717 9.33272 23.5102 9.33272 31.9963L4 48.0037H14.6654V62.6687H33.3299V80H73.3253V31.9963ZM41.329 56.0028C34.9645 56.0028 28.8604 53.4744 24.3603 48.9741C19.86 44.4738 17.3317 38.3698 17.3317 32.0054C17.3317 25.641 19.86 19.5368 24.3603 15.0367C28.8606 10.5364 34.9646 8.00803 41.329 8.00803C47.6934 8.00803 53.7976 10.5364 58.2977 15.0367C62.7981 19.537 65.3264 25.641 65.3264 32.0054C65.3264 38.3698 62.7981 44.474 58.2977 48.9741C53.7974 53.4744 47.6934 56.0028 41.329 56.0028Z" />
  </svg>
);

export default cb;
