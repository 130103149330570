import React from 'react';

import { TRANSITIONAL_INFORMATION } from '../../../constants/constants';
import Card from '../../Card';
import { Checkbox, EmployeeDropdown } from '../../inputs';
import { IReportDatePicker } from '../../inputs/DateTimePicker/';
import Notebox from '../../Notebox';
import Signature from '../../inputs/Signature';
import Textbox from '../../inputs/Textbox';

export default function TransitionalInfoCardCard({
  employeeName,
  workRestrictions,
  dateOfTransition,
  endDateOfTransition,
  onChange,
  disabled,
  name,
  missingRequired,
  incidentId,
  group,
  employeeId,
  employeeSignature,
  supervisorId,
  supervisorSignature,
  supervisorCanSign,
  availableApprovers,
  hrRepId,
  hrSignature,
  hrCanSign,
  isNa,
  disableNa,
  _id,
  changeApprovers,
  changeEmployees,
  needsToCreate,
  handleRemoveForm,
  isColumbus,
  isAssignment,
  isCreating
}) {
  const renderInfoForIncident = (
    <>
      <Signature
        fieldLabel="Employee Signature"
        currentValue={employeeSignature}
        onChange={v => onChange({ employeeSignature: v })}
        ownerType="incidentCc"
        ownerId={_id}
        isRequired
        disabled={disabled || employeeSignature}
        touched={missingRequired && !employeeSignature}
      />
      {isColumbus ? null : (
        <>
          <EmployeeDropdown
            fieldLabel="Select Supervisor"
            onChange={v =>
              onChange({ supervisorId: v, supervisorSignature: null })
            }
            currentValue={supervisorId}
            disabled={
              isNa
                ? true
                : !changeApprovers && (disabled || supervisorSignature)
            }
            searchable
            name="approver"
            employees={availableApprovers}
            touched={missingRequired && !supervisorId}
          />
          {supervisorSignature || supervisorCanSign ? (
            <Signature
              fieldLabel="Supervisor Signature"
              currentValue={supervisorSignature}
              onChange={v => onChange({ supervisorSignature: v })}
              ownerType="incidentCc"
              ownerId={_id}
              disabled={!supervisorCanSign}
              isRequired
              touched={missingRequired && !supervisorSignature}
            />
          ) : null}
          <EmployeeDropdown
            fieldLabel="Select HR Representative"
            onChange={v => onChange({ hrRepId: v, hrSignature: null })}
            currentValue={hrRepId}
            disabled={
              isNa ? true : !changeApprovers && (disabled || hrSignature)
            }
            searchable
            name="approver"
            employees={availableApprovers}
            touched={missingRequired && !hrRepId}
          />
          {hrSignature || hrCanSign ? (
            <Signature
              fieldLabel="HR Representative Signature"
              currentValue={hrSignature}
              onChange={v => onChange({ hrSignature: v })}
              ownerType="incidentCc"
              ownerId={_id}
              disabled={!hrCanSign}
              isRequired
              touched={missingRequired && !hrSignature}
            />
          ) : null}
        </>
      )}
    </>
  );

  return (
    <Card
      name={name}
      showHeader
      title="Transitional Return to Work Program"
      wide
      rightButton={
        isAssignment ? null : incidentId ? (
          needsToCreate ? (
            <img
              src={require('../../../assets/images/remove.png')}
              alt="remove row"
              onClick={handleRemoveForm}
              style={{ cursor: 'pointer' }}
            />
          ) : (
            <Checkbox
              fieldLabel="Not Applicable"
              currentValue={isNa}
              onChange={v => onChange({ isNa: v })}
              disabled={disableNa}
            />
          )
        ) : null
      }
    >
      {!employeeName ? (
        <EmployeeDropdown
          fieldLabel="Employee Name"
          onChange={v => onChange({ employeeId: v, employeeSignature: null })}
          currentValue={employeeId}
          disabled={
            isNa
              ? true
              : (!changeEmployees &&
                  (disabled || employeeSignature || isCreating)) ||
                isColumbus
          }
          searchable
          name="approver"
          group={group}
          isRequired
          touched={missingRequired && !employeeId}
        />
      ) : (
        <Textbox
          fieldLabel="Employee Name"
          placeholder="ex. Jane Doe"
          isRequired
          onChange={employeeName => onChange({ employeeName })}
          currentValue={employeeName}
          disabled={disabled}
          touched={missingRequired && !employeeName}
        />
      )}
      <Notebox note={TRANSITIONAL_INFORMATION[0]} />
      <Textbox
        fieldLabel="List your Work Restrictions (according to your physician)"
        type="textarea"
        placeholder="Type here"
        onChange={workRestrictions => onChange({ workRestrictions })}
        currentValue={workRestrictions}
        isRequired
        disabled={disabled}
        touched={missingRequired && !workRestrictions}
      />
      <IReportDatePicker
        pickTime
        fieldLabel="Date Transitional Return to Work Program starts"
        isRequired
        disabled={disabled}
        onChange={value => onChange({ dateOfTransition: value })}
        currentValue={dateOfTransition}
        name="returnToWorkStarts"
        touched={missingRequired && !dateOfTransition}
      />
      <IReportDatePicker
        pickTime
        fieldLabel="Date Transitional Return to Work Program ends"
        disabled={disabled}
        onChange={value => onChange({ endDateOfTransition: value })}
        currentValue={endDateOfTransition}
        name="returnToWorkends"
      />
      <Notebox note={TRANSITIONAL_INFORMATION[1]} />
      {incidentId ? renderInfoForIncident : null}
    </Card>
  );
}
