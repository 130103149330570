import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  approveTrainings,
  deleteTraining,
  fetchTrainingById,
  updateTraining
} from '../../../api/v4';
import { getActiveCompany } from '../../../selectors/company';
import history from '../../../history';
import { getLoggedInUser } from '../../../selectors/users';
import { useSocket } from '../../../utils/withSocket';
import customToast from '../../../utils/customToast';
import ApprovalItemModal from '../../../components/Modal/approvalItemModal';
import AssigneeTable from '../../../components/training/AssigneeTable';
import { Button } from '../../../components/inputs';
import Card from '../../../components/Card';
import DeleteItemModal from '../../../components/Modal/deleteItemModal';
import { EmployeeDropdown } from '../../../components/inputs/Dropdown';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import Header from '../../../components/Header';
import LeftNav from '../../../components/LeftNav';
import Modal from '../../../components/Modal';
import ReassignUserModal from '../../../components/Modal/reassignUserModal';
import QuizTable from '../../../components/training/QuizTable';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';
import SaveProgressModal from '../../../components/Modal/saveProgressModal';
import SignatureCard from '../../../components/SignatureCard';
import {
  SaveCancelFooter,
  SubmitSaveForLaterFooter
} from '../../../components/Footer';
import TrainingSummary from '../../../components/training/Summary';
import FileDownloader from '../../../components/FileDownloader';

import styles from './styles.module.scss';

export default function ApproveTraining() {
  const { trainingId } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const socket = useSocket();
  const user = useSelector(getLoggedInUser);
  const isApproving = location?.state?.isApproving ?? false;
  const activeCompany = useSelector(getActiveCompany);
  const loggedInUser = useSelector(getLoggedInUser);

  const [training, setTraining] = useState({});
  const [savedSign, setSavedSign] = useState(null);
  const [editing, setEditing] = useState(false);
  const [children, setChildren] = useState([]);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [unsavedModal, setUnsavedModal] = useState(false);
  const [approvalModal, setApprovalModal] = useState(false);
  const [changeApproverModal, setChangeApproverModal] = useState(false);
  const [changeApproverId, setApproverId] = useState(null);
  const [saveModal, setSaveModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [generated, setGenerated] = useState(null);
  const [generatedCertificates, setGeneratedCertificates] = useState(null);
  const [removeAssigneeModal, setRemoveAssigneeModal] = useState(false);
  const [removedAssignees, setRemoved] = useState([]);

  useEffect(() => {
    const storedTraining = localStorage.getItem('trainingTemplate');
    if (storedTraining) {
      const jsonTraining = JSON.parse(storedTraining);
      setTraining(jsonTraining.training);
      setChildren(jsonTraining.children);
      localStorage.removeItem('trainingTemplate');
    } else if (trainingId)
      fetchTrainingById(trainingId).then(r => {
        setTraining(r);
        setSavedSign(r.approverSignature);
        setChildren(r.children);
      });
  }, [trainingId]);

  useEffect(() => {
    if (!socket) return;
    socket.emit('subscribeTraining', trainingId);
    socket.emit('subscribetrainingCertificate', trainingId);
    if (!socket) return;
    socket.on('trainingPdf', setGenerated);
    socket.on('trainingCertificatePdf', setGeneratedCertificates);
    socket.on('printFail', error => {
      setGenerated(null);
      customToast(`Could not generate Training PDF: ${error}`, 'error');
    });
  }, [trainingId, socket, dispatch, user]);

  const handleSubmit = isComplete => {
    let response = { isCompleted: isComplete };

    if (isComplete) {
      response.completionDate = moment();
    }

    approveTrainings({
      masterTraining: {
        ...training,
        ...response
      },
      children
    }).then(history.goBack);
  };

  const canSubmit =
    (!training.needsApproval || children?.every(a => a.approveReject)) &&
    training?.approverSignature;

  const updateAnswer = values => {
    setTraining({ ...training, ...values });
    setUnsavedChanges(true);
  };
  const updateApproveReject = values => {
    setChildren(
      children.map(child => {
        if (values.assignee.includes(child.assignedTo.toString()))
          child.approveReject = values.approveReject;
        return child;
      })
    );
    setUnsavedChanges(true);
  };

  const getQuickNav = () => {
    let leftNav = [
      { label: 'Summary', id: 'title' },
      { label: 'Training Details', id: 'details' },
      { label: 'Schedule', id: 'schedule' }
    ];
    if (training.trainingType === 1 && training.attachments?.length) {
      leftNav = [...leftNav, { label: 'Materials List', id: '2' }];
    }

    if (training.quiz) {
      leftNav = [...leftNav, { label: 'Quiz List', id: '3' }];
    }

    if (training.needsApproval)
      leftNav = [...leftNav, { label: 'Approver Signature', id: '4' }];

    return leftNav;
  };

  const handleChangeApprover = () => {
    updateTraining({
      ...training,
      approver: changeApproverId,
      isApprove: false
    });
    history.goBack();
  };

  const handleRemoveAssignees = () => {
    let removedChildTrainings = training.children.filter(c =>
      removedAssignees?.includes(c.assignedTo)
    );

    updateTraining({
      ...training,
      removedChildTrainings
    }).then(() => {
      customToast(`Successfully Removed Assignee(s)!`, 'success');
      history.goBack();
    });
  };

  const removeableAssignees = training?.children
    ?.filter(c => !c.isCompleted)
    ?.map(t => t.assignedTo);
  const allMaterials = training?.attachments?.concat(
    training?.youtubeLinks ?? []
  );

  const generatedReport = generated && generated.url;

  const certificatesAvailable =
    generatedCertificates && generatedCertificates.url;

  const canDownloadCertificates =
    (training.isCompleted || training.completionDate) &&
    children?.every(t => t.completionDate) &&
    children?.some(t => t.approveReject !== 'reject');

  const notAssignedYet = moment(training.assignOn)
    .startOf('day')
    .isAfter(moment());

  const pageActionOptions = [
    {
      label: 'Edit Training',
      onClick: () => setEditing(true),
      color: 'blueOutline',
      visible:
        notAssignedYet &&
        !training.hasBeenNotifiedAboutCreation &&
        !editing &&
        !training.isCourseTraining
    },
    {
      label: 'Change Approver',
      onClick: () => setChangeApproverModal(true),
      color: 'blueOutline',
      visible:
        training.needsApproval && !training.isCompleted && !training.isArchived
    },
    {
      label: `Remove Assignees`,
      onClick: () => setRemoveAssigneeModal(true),
      color: 'blueOutline',
      visible:
        removeableAssignees?.length &&
        !training.isCourseTraining &&
        !training.isCompleted &&
        (loggedInUser.accessLevel === 900 ||
          training.createdByUserId === loggedInUser._id)
    },
    {
      label: `Delete Training`,
      onClick: () => setDeleteModal(true),
      color: 'redOutline',
      visible:
        (loggedInUser.accessLevel === 900 ||
          training.createdByUserId === loggedInUser._id) &&
        !training.isCourseTraining
    },
    {
      label: generatedReport ? 'Regenerate Summary' : 'Generate Summary',
      onClick: () => {
        socket.emit('generateTrainingPdf', trainingId);
        setGenerated('Loading');
      },
      color: 'blueOutline',
      visible:
        (training.isCompleted || training.completionDate) &&
        children?.every(t => t.completionDate) &&
        (!generated || generatedReport)
    },
    {
      label: 'Generating Report',
      color: 'greyOutline',
      disabled: true,
      visible: generated && !generated.url
    },
    {
      label: 'Download Report',
      color: 'greenOutline',
      onClick: () => window.open(generated.url, '_blank'),
      visible: generatedReport
    },
    {
      label: certificatesAvailable
        ? 'Regenerate Certificates'
        : 'Generate Certificates',
      onClick: () => {
        socket.emit('generateTrainingCertificatePdf', trainingId);
        setGeneratedCertificates('Loading');
      },
      color: 'blueOutline',
      visible:
        canDownloadCertificates &&
        (!generatedCertificates || certificatesAvailable)
    },
    {
      label: 'Generating Certificates',
      color: 'greyOutline',
      disabled: true,
      visible: generatedCertificates && !generatedCertificates.url
    },
    {
      label: 'Download Certificates',
      color: 'greenOutline',
      onClick: () => window.open(generatedCertificates.url, '_blank'),
      visible: certificatesAvailable
    },
    {
      label: `${training?.isArchived ? 'Un-' : ''}Archive Training`,
      color: 'blueOutline',
      onClick: () => {
        updateTraining({ ...training, isArchived: !training?.isArchived }).then(
          response => {
            if (response.isArchived) history.push('/app/training/list');
            else {
              fetchTrainingById(trainingId).then(r => {
                setTraining(r);
                setSavedSign(r.approverSignature);
                setChildren(r.children);
              });
              setUnsavedChanges(false);
            }
          }
        );
      },
      visible: loggedInUser.accessLevel > 400 && !training.isCourseTraining
    }
  ].filter(p => p.visible);
  const header = (
    <Header
      title="Training"
      section={training?.title}
      clickBack={() =>
        unsavedChanges ? setUnsavedModal(true) : history.goBack()
      }
      needsSaved={unsavedChanges}
      pageActionOptions={!isApproving && pageActionOptions}
    />
  );

  const footer = editing ? (
    <SaveCancelFooter
      saveButtonDisabled={
        (!training.assignByJobTitles?.length && !training.assignedTo?.length) ||
        !training.dueDate
      }
      saveButtonClick={() => {
        updateTraining(training).then(() => setEditing(false));
      }}
      cancelButtonClick={() =>
        unsavedChanges ? setUnsavedModal(true) : setEditing(false)
      }
      saveButtonText="Save"
    />
  ) : (
    <SubmitSaveForLaterFooter
      submitButtonDisabled={!canSubmit}
      submitButtonClick={() => setApprovalModal(true)}
      saveButtonClick={() => handleSubmit(false)}
      submitButtonText="Submit"
    />
  );

  return (
    <>
      <HeaderAndFooter
        Header={header}
        Footer={footer}
        showFooter={(isApproving && !training?.isCompleted) || editing}
        Left={<LeftNav items={getQuickNav()} />}
      >
        <TrainingSummary
          company={activeCompany}
          training={training}
          fromApprove
          onAnswer={t => {
            setUnsavedChanges(true);
            setTraining({ ...training, ...t });
          }}
          editingAssignees={editing}
          disabled
        />
        {training.releaseType === 2 ? (
          <Card
            showHeader
            title="Attendance"
            name="attendance"
            className={styles.instructions}
          >
            <img
              src={training.qrCodeImageUrl}
              className={styles.qrCode}
              alt={'Error Displaying QR Code'}
            />
            <Button
              color="blue"
              text="Download QR Code"
              onClick={() =>
                window.open(`https://${training.qrCodeUrl}`, '_blank')
              }
              inputClassName={styles.button}
            />
            {training.attendanceDoc ? (
              <FileDownloader
                attachment={training.attendanceDoc}
                ownerType="TrainingAttendanceSheet"
                disabled
              />
            ) : null}
          </Card>
        ) : null}
        {children?.length &&
        training.trainingType === 1 &&
        allMaterials?.length ? (
          <AssigneeTable
            attachments={allMaterials}
            needsApproval={training.needsApproval && !training?.quiz}
            updateAnswer={updateApproveReject}
            name="2"
            dueDate={training.dueDate}
            isApproving={isApproving}
            updateModal={() => setSaveModal(true)}
            trainings={children}
            disabled={training.isCompleted}
          />
        ) : null}
        {training?.quiz && children?.length ? (
          <QuizTable
            training={training}
            childTrainings={children}
            needsApproval={training.needsApproval}
            isApprover={training.approver === loggedInUser._id}
            updateAnswer={updateApproveReject}
            name="3"
            dueDate={training.dueDate}
            fromApproveTraining={isApproving}
            masterTraining={training}
            updateModal={() => setSaveModal(true)}
            disabled={
              (training.isCompleted &&
                (!training.needsApproval || savedSign)) ||
              training.approver !== loggedInUser._id
            }
          />
        ) : null}
        {training.needsApproval ? (
          <SignatureCard
            header="Approver Signature"
            currentValue={training.approverSignature}
            userDisabled={true}
            dropdownName="Approver Name"
            permissions={[400, 500, 900]}
            userId={training.approver}
            name="4"
            onChange={v => updateAnswer({ approverSignature: v })}
            ownerType="training"
            ownerId={training._id}
            disabled={
              !isApproving ||
              training.approver !== loggedInUser._id ||
              training.isCompleted
            }
          />
        ) : null}
      </HeaderAndFooter>
      <SaveChangesModal
        isOpen={unsavedModal}
        savingWhat={training.title}
        onRequestClose={() => setUnsavedModal(false)}
        submitActions={history.goBack}
      />
      <ApprovalItemModal
        isOpen={approvalModal}
        approvingWhat="training"
        onRequestClose={() => setApprovalModal(false)}
        submitActions={() => handleSubmit(true)}
        cancelActions={() => handleSubmit(false)}
      />
      <ReassignUserModal
        title="Change Approver"
        reassignUser={changeApproverId}
        disableSubmit={!changeApproverId}
        titleClassName="redHeader"
        previousUser={training.approver}
        message={
          <div className="reportSectionContainer-emailAssigeeText">
            From here you can assign a different user as the Approver for this
            Training.{' '}
          </div>
        }
        onRequestClose={() => {
          setChangeApproverModal(false);
          setApproverId();
        }}
        isOpen={changeApproverModal}
        onChange={setApproverId}
        submitActions={() => handleChangeApprover()}
        permissions={[500, 900]}
        placeholder="Select one.."
      />
      <Modal
        title="Remove Assignee(s)"
        titleClassName="redHeader"
        isOpen={removeAssigneeModal}
        submitButtonColor="red"
        submitButtonText={
          removedAssignees?.length === training?.assignedTo?.length
            ? 'Delete'
            : 'Save Changes'
        }
        onRequestClose={() => setRemoveAssigneeModal(false)}
        submitActions={() =>
          removedAssignees?.length === training?.assignedTo?.length
            ? deleteTraining(training).then(r =>
                history.push('/app/training/list')
              )
            : handleRemoveAssignees()
        }
        disableSubmit={!removedAssignees?.length}
      >
        <span style={{ color: '#c74846', fontWeight: 'bold' }}>
          {`Removing an assignee is permanent. ${
            removedAssignees?.length !== training?.assignedTo?.length
              ? 'The assignee will not be able to complete this training.'
              : 'Since all assignee have been selected this training will be deleted.'
          } A new Training that is similar or identical can still be assigned.`}
        </span>

        <EmployeeDropdown
          limit={removeableAssignees}
          currentValue={removedAssignees}
          onChange={setRemoved}
          multi
        />
      </Modal>
      <SaveProgressModal
        isOpen={saveModal}
        savingWhat="Training"
        onRequestClose={() => setUnsavedModal(false)}
        submitActions={() => handleSubmit(false, true)}
      />
      <DeleteItemModal
        deletingWhat="Training"
        isOpen={deleteModal}
        onRequestClose={() => setDeleteModal(false)}
        submitActions={() =>
          deleteTraining(training).then(r => history.push('/app/training/list'))
        }
      />
    </>
  );
}
