import React from 'react';
import moment from 'moment';

import UserBadge from '../../UserBadge';

import './index.css';

const isReopened = activity => {
  return activity.action === 'updated';
};

const isCompleted = activity => {
  return activity.action === 'Completed';
};

const isCreated = activity => {
  return activity.action === 'Created';
};

const isNewIncidentOwner = activity => activity.action === 'New Incident Owner';

const isNewReport = activity => activity.action === 'Added Report Types';

const activityCard = ({ activity }) => {
  return (
    <div className="reportActivityCard">
      <div className="reportActivityCard-createdDate">
        <div>{moment(activity.when).format('hh:mm a')}</div>
        <div>{moment(activity.when).format('MM/DD/YYYY')}</div>
      </div>
      <div className="reportActivityCard-content">
        <h2 className="reportActivityCard-header">
          <div
            className={
              isCompleted(activity)
                ? 'reportActivityCard-header greenHeading'
                : isReopened(activity) || isCreated(activity)
                  ? 'reportActivityCard-header blueHeading'
                  : isNewReport(activity) || isNewIncidentOwner(activity)
                    ? 'reportActivityCard-header newIncidentOwner'
                    : 'reportActivityCard-header greyHeading'
            }
          >
            <div className="reportActivityCard-headerText">
              {activity.action}
            </div>
          </div>
        </h2>
        <div
          className={
            !isNewIncidentOwner(activity)
              ? 'reportActivityCard-contentSummary'
              : 'reportActivityCard-contentSummary--noResource'
          }
        >
          {!isNewIncidentOwner(activity) && (
            <div
              className={
                isCompleted(activity)
                  ? 'reportActivityCard-reportContainerGreen'
                  : isReopened(activity) ||
                      isNewIncidentOwner(activity) ||
                      isCreated(activity) ||
                      isNewReport(activity)
                    ? 'reportActivityCard-reportContainerBlue'
                    : 'reportActivityCard-reportContainerGrey'
              }
            >
              <div className="reportActivityCard-reportContainerText">
                {activity.resource}
              </div>
            </div>
          )}
        </div>
        {isNewIncidentOwner(activity) && (
          <div className="reportActivityCard-assigneeBlue">Assignee</div>
        )}
        <UserBadge
          userId={
            isNewIncidentOwner(activity) && activity.updatedProperty
              ? activity.updatedProperty.incidentOwner
              : (activity.userId ?? activity.user)
          }
          showImage
          showName
          className="reportActivityCard-assigned"
        />
      </div>
    </div>
  );
};

export default activityCard;
