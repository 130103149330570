import { call, put, select } from 'redux-saga/effects';
import moment from 'moment';
import {
  downloadEmployeeQRcodeResponse,
  setActiveEmployeeResponse
} from '../actions/personnel';
import { clearAllUsers } from '../actions/user';
import { getActiveEmployeeSelector } from '../selectors/personnel';
import customToast from '../utils/customToast';

export function* updateLocationEmployee(apiV4, { payload }) {
  const activeEmployee = yield select(getActiveEmployeeSelector);
  try {
    payload = {
      ...payload,
      dateOfHire: moment(payload.dateOfHire),
      dateOfTermination: payload.dateOfTermination
        ? moment(payload.dateOfTermination)
        : '',
      dateOfBirth: payload.dateOfBirth ? moment(payload.dateOfBirth) : ''
    };

    const updateResponse = yield call(apiV4.updateEmployee, payload);

    yield put(
      setActiveEmployeeResponse({
        ...updateResponse,
        trainings: payload.trainings,
        documents: payload.documents,
        qualifications: payload.qualifications,
        incidents: payload.incidents
      })
    );
    yield put(clearAllUsers());
    customToast('Saved Successfully!', 'success');
  } catch (e) {
    customToast(e?.data, 'error');
    yield put(setActiveEmployeeResponse(activeEmployee));
  }
}

export function* setActiveEmployee(apiV4, { payload }) {
  try {
    const employee = yield call(apiV4.fetchEmployee, payload?._id ?? payload);
    // only grab what we need for snapshot screen to hopefully speed calls up
    const empTrainings = yield call(
      apiV4.fetchEmployeeTrainings,
      employee._id,
      'expires'
    );
    const empQualifications = yield call(
      apiV4.fetchEmployeeQualifications,
      employee._id,
      'expires'
    );
    const empIncidents = yield call(
      apiV4.fetchEmployeeIncidents,
      employee._id,
      '90'
    );
    employee.expiringTrainings = empTrainings;
    employee.last90Days = empIncidents;
    employee.expiringQualifications = empQualifications;

    if (employee.accessLevel) {
      employee.systemInteractions = yield call(
        apiV4.fetchEmployeeInteractions,
        employee._id
      );
    }

    yield put(setActiveEmployeeResponse(employee));
  } catch (e) {
    console.log(e);
  }
}

export function* downloadPersonnelQRCode(apiV4, { payload }) {
  try {
    const response = yield call(apiV4.fetchQrCodeSheet, payload.ids);

    const link = document.createElement('a');
    link.href = `${response}`;
    link.target = '_blank';
    link.download = `Employee QR Codes`;
    link.click();

    yield put(downloadEmployeeQRcodeResponse());
  } catch (e) {
    console.log(e);
  }
}
