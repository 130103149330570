import React from 'react';
import { getPercentages, hasAccessStage } from '../../../utils/incidentHelper';

import styles from './styles.module.scss';

function stageStyle(
  selectedStage,
  myStage,
  loggedInUser,
  activeIncident,
  forbiddenPermissions
) {
  let hasPermission = false;
  selectedStage = parseInt(selectedStage, 10) || 0;
  const percentages = getPercentages(
    activeIncident.reportComponents,
    myStage,
    activeIncident.reportTemplateIds
  );
  const myLevel = loggedInUser?.accessLevel || 0;
  const stage =
    myStage === 1 ? 'action' : myStage === 2 ? 'closure' : 'investigation';
  if (hasAccessStage(loggedInUser, stage, activeIncident) === 'None')
    hasPermission = false;
  else if (!forbiddenPermissions) hasPermission = true;
  else if (typeof forbiddenPermissions !== 'object')
    hasPermission = forbiddenPermissions !== myLevel;
  else hasPermission = forbiddenPermissions.indexOf(myLevel) === -1;

  return [
    styles.stage,
    selectedStage === myStage ? styles.stageSelected : '',
    percentages.pastDue > 0 ? styles.stagePastDue : '',
    percentages.completed === percentages.total ? styles.stageCompleted : '',
    percentages.started > 0 || percentages.completed > 0
      ? styles.stageStarted
      : '',
    hasPermission ? '' : styles.stageDisabled
  ].join(' ');
}

function stageComplete(myStage, activeIncident) {
  const percentages = getPercentages(
    activeIncident.reportComponents,
    myStage,
    activeIncident.reportTemplateIds
  );

  return percentages.total === percentages.completed;
}

const incidentStages = ({
  changeStage,
  selectedStage,
  activeIncident,
  loggedInUser
}) => {
  return (
    <div className={`incidentContainer-incidentCard--stages ${styles.stages}`}>
      <div
        className={stageStyle(selectedStage, 0, loggedInUser, activeIncident)}
      >
        <div
          onClick={() =>
            hasAccessStage(loggedInUser, 'investigation', activeIncident) !==
              'None' && changeStage('')
          }
        >
          <div style={{ paddingTop: 5 }}>
            {hasAccessStage(loggedInUser, 'investigation', activeIncident) ===
            'None'
              ? 'Restricted'
              : 'Investigation'}
          </div>
        </div>
        {stageComplete(0, activeIncident) && (
          <img
            src={require('../../../assets/images/stageCompleteStar.png')}
            alt="Investigation stage completed"
          />
        )}
      </div>
      <div
        className={stageStyle(selectedStage, 1, loggedInUser, activeIncident, [
          100
        ])}
      >
        <div
          onClick={() =>
            hasAccessStage(loggedInUser, 'action', activeIncident) !== 'None' &&
            changeStage(1)
          }
        >
          <div
            style={{
              paddingTop: 5,
              position: 'relative',
              top: '-34px'
            }}
          >
            {hasAccessStage(loggedInUser, 'action', activeIncident) === 'None'
              ? 'Restricted'
              : 'Action'}
          </div>
        </div>
        {stageComplete(1, activeIncident) && (
          <img
            src={require('../../../assets/images/stageCompleteStar.png')}
            alt="Action stage completed"
          />
        )}
      </div>
      <div
        className={stageStyle(selectedStage, 2, loggedInUser, activeIncident, [
          100
        ])}
      >
        <div
          onClick={() =>
            hasAccessStage(loggedInUser, 'closure', activeIncident) !==
              'None' && changeStage(2)
          }
        >
          <div
            style={{
              paddingTop: 5,
              position: 'relative',
              top: '-34px'
            }}
          >
            {hasAccessStage(loggedInUser, 'closure', activeIncident) === 'None'
              ? 'Restricted'
              : 'Closure'}
          </div>
        </div>
        {stageComplete(2, activeIncident) && (
          <img
            src={require('../../../assets/images/stageCompleteStar.png')}
            alt="Closure stage completed"
          />
        )}
      </div>
    </div>
  );
};

export default incidentStages;
