import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import history from '../../history';
import moment from 'moment';
import { EQUIPMENT_STATUS_DROPDOWN } from '../../constants/constants';
import {
  createEquipment,
  deleteAttachment,
  deleteEquipment,
  fetchEquipmentById,
  fetchSafetyAuditTemplates,
  updateEquipmentStage,
  fetchCustomTrainingTemplates,
  fetchDocumentsByType,
  downloadEquipmentQrCodes,
  uploadAttachment,
  updateEquipmentById
} from '../../api/v4';
import { loadTask, setActiveTaskRequest } from '../../actions/tasks';
import { formatFiles } from '../../utils/attachmentSelector';
import { useActiveHeirarchy } from '../../utils/useActiveHeirarchy';
import AttachmentUploadCard from '../../components/AttachmentUploadCard';
import Card from '../../components/Card';
import { IReportDatePicker } from '../../components/inputs/DateTimePicker';
import DeleteItemModal from '../../components/Modal/deleteItemModal';
import EquipmentDocumentsList from '../../components/EquipmentCards/EquipmentDocumentsList';
import EquipmentAuditList from '../../components/EquipmentCards/EquipmentAuditList';
import EquipmentTaskList from '../../components/EquipmentCards/EquipmentTaskList';
import EquipmentTrainingList from '../../components/EquipmentCards/EquipmentTrainingList';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import HierarchySelector from '../../components/HierarchySelector';
import { Dropdown, TwoColumn, Textbox, Button } from '../../components/inputs';
import LeftNav from '../../components/LeftNav';
import MissingRequiredModal from '../../components/Modal/missingRequiredModal';
import Money from '../../components/inputs/Money';
import { SaveCancelFooter } from '../../components/Footer';
import SaveChangesModal from '../../components/Modal/saveChangesModal';
import StageSelector from '../../components/StageSelector';
import TypeSelection from '../../components/TypeSelection';
import styles from './index.module.scss';

export default function EquipmentContainer() {
  const { equipmentId } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const docEquip = location?.state?.equipment;

  const { activeCompany, activeGroup, activeProject } = useActiveHeirarchy();

  const [editing, setEditing] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [openUnsavedChangesModal, setOpenUnsavedChangesModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [listView, setListView] = useState('Safety Audits');
  const [missingRequired, setMissingRequired] = useState(false);
  const [missingModalOpen, setMissingModalOpen] = useState(false);
  const [saTemplateDropdown, setSATemplateDropdown] = useState([]);
  const [lotoTemplateDropdown, setLotoTemplateDropdown] = useState([]);
  const [trainingTemplateDropdown, setTrainingTemplateDropdown] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [attachmentsToUpload, setAttachmentsToUpload] = useState([]);
  const [activeEquipment, setActiveEquipment] = useState({
    groupIds: [],
    projectIds: [],
    title: '',
    location: '',
    serial: '',
    companyThatMade: '',
    model: '',
    status: 1,
    linkedSafetyAuditTemplates: [],
    linkedLOTOTemplates: [],
    linkedDocuments: [],
    linkedTrainingTemplates: [],
    expirationDate: '',
    manufactureDate: '',
    costOfEquipment: '',
    equipmentQuantity: ''
  });

  const handleAnswer = update => {
    setActiveEquipment({ ...activeEquipment, ...update });
    setHasUnsavedChanges(true);
  };

  const findGroupNames = groupIds =>
    activeCompany.groups
      ?.filter(g => groupIds?.includes(g._id))
      ?.map(g => g.name)
      .join(', ');

  const findProjectNames = (groupIds, projectIds) => {
    let groups = activeCompany.groups?.filter(g => groupIds?.includes(g._id));
    const projects = groups
      ?.map(g => {
        return g.projects?.map(p => ({ ...p, groupName: g?.name }));
      })
      .reduce((acc, x) => acc.concat(x), []);
    return projects
      ?.filter(p => projectIds?.includes(p._id))
      ?.map(g => `${g.groupName} - ${g.name}`)
      ?.join(', ');
  };

  const canSubmit =
    activeEquipment?.groupIds?.length &&
    activeEquipment?.title?.trim()?.length > 2 &&
    activeEquipment?.location?.trim()?.length > 2;

  const handleBack = () =>
    hasUnsavedChanges
      ? setOpenUnsavedChangesModal(true)
      : history.push('/app/equipmentList');

  const handleFileUpload = (equipment, attachments = null) => {
    let upload =
      filesToUpload?.length > 0
        ? filesToUpload
        : attachments?.length > 0
          ? attachments
          : [];
    if (upload?.length > 0) {
      let data = formatFiles(upload);

      uploadAttachment(data, {
        ownerId: equipment._id,
        ownerType: 'equipment',
        isSignature: false,
        isPhotoEvidence: false
      }).then(res => {
        setActiveEquipment({
          ...equipment,
          attachments: equipment?.attachments?.concat(res)
        });
      });

      setAttachmentsToUpload([]);
      setFilesToUpload([]);
    }
  };

  const submitEquipment = () => {
    if (activeEquipment?._id) {
      updateEquipmentById(activeEquipment).then(res =>
        fetchEquipmentById(activeEquipment?._id).then(setActiveEquipment)
      );
    } else {
      createEquipment(activeEquipment).then(res => {
        fetchEquipmentById(res?._id).then(res2 => {
          setActiveEquipment(res2);
          if (filesToUpload?.length) handleFileUpload(res2);
        });
      });
    }
    setEditing(false);
    setHasUnsavedChanges(false);
  };

  const updateStage = status =>
    updateEquipmentStage({
      _id: activeEquipment._id,
      status
    }).then(setActiveEquipment);

  const addAttachments = attachments => {
    handleFileUpload(activeEquipment, attachments);
  };

  const deleteAttachments = attachment => {
    deleteAttachment(attachment._id).then(() => {
      fetchEquipmentById(activeEquipment._id).then(setActiveEquipment);
    });
  };

  const getEquipment = useCallback(() => {
    if (equipmentId) {
      fetchEquipmentById(equipmentId).then(setActiveEquipment);
    } else {
      setEditing(true);
      setActiveEquipment(
        docEquip?.title
          ? { ...docEquip }
          : {
              companyId: activeCompany?._id,
              groupIds: activeGroup ? [activeGroup?._id] : [],
              projectIds: activeProject ? [activeProject?._id] : [],
              title: '',
              location: ''
            }
      );
    }
  }, [activeGroup, activeProject, activeCompany._id, docEquip, equipmentId]);

  useEffect(() => {
    getEquipment();
    fetchSafetyAuditTemplates().then(res =>
      setSATemplateDropdown(
        res.map(template => ({ label: template.title, _id: template._id }))
      )
    );

    if (activeCompany?.hasLockoutTagout) {
      fetchSafetyAuditTemplates('loto').then(res =>
        setLotoTemplateDropdown(
          res.map(template => ({ label: template.title, _id: template._id }))
        )
      );
    }

    fetchCustomTrainingTemplates().then(res =>
      setTrainingTemplateDropdown(
        res.map(template => ({ label: template.title, _id: template._id }))
      )
    );

    fetchDocumentsByType('SafetyInstruction').then(res =>
      setDocuments(
        res.map(document => ({ label: document.label, _id: document._id }))
      )
    );
  }, [activeCompany.hasLockoutTagout, getEquipment]);

  const handleArchive = () => {
    updateEquipmentById({
      ...activeEquipment,
      isArchived: !activeEquipment.isArchived
    }).then(res => {
      if (res.isArchived) history.push('/app/equipmentList');
      else {
        setActiveEquipment(res);
        setHasUnsavedChanges(false);
      }
    });
  };

  const header = (
    <Header
      title={
        !editing
          ? activeEquipment?.title
          : editing && !equipmentId
            ? 'Add Equipment'
            : `Edit ${activeEquipment.title}`
      }
      needsSaved={hasUnsavedChanges}
      clickBack={handleBack}
      center={
        !editing && (
          <StageSelector
            label="Status"
            options={EQUIPMENT_STATUS_DROPDOWN}
            currentValue={activeEquipment?.status}
            onChange={updateStage}
            testID="stageSelector"
          />
        )
      }
      pageActionOptions={
        activeEquipment?._id
          ? [
              {
                label: 'Edit Equipment',
                visible: !editing && !activeEquipment.isArchived,
                color: 'blueOutline',
                onClick: () => setEditing(true)
              },
              {
                label: 'Delete Equipment',
                visible: editing || activeEquipment.isArchived,
                color: 'redOutline',
                onClick: () => setOpenDeleteModal(true)
              },
              {
                label: `${
                  activeEquipment?.isArchived ? 'Un-' : ''
                }Archive Equipment`,
                visible: true,
                color: 'blueOutline',
                onClick: () => handleArchive()
              }
            ]
          : null
      }
    />
  );

  const footer = (
    <SaveCancelFooter
      saveButtonClick={() =>
        canSubmit ? submitEquipment() : setMissingModalOpen(true)
      }
      cancelButtonClick={() =>
        editing && hasUnsavedChanges
          ? setOpenUnsavedChangesModal(true)
          : editing && activeEquipment._id
            ? setEditing(false)
            : history.goBack()
      }
      editing={equipmentId}
      onMouseEnter={() => setMissingRequired(true)}
    />
  );

  const formatCost = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  const leftNavEditing = [
    { label: 'Equipment Information', id: 'equipmentEditing' },
    { label: 'Attachments', id: 'attachments' },
    { label: 'Documents', id: 'documents' },
    { label: 'Safety Audits', id: 'safetyAudits' },
    { label: 'Trainings', id: 'trainings' }
  ];

  if (activeCompany?.hasLockoutTagout)
    leftNavEditing.splice(-1, 0, {
      label: 'Lockout/Tagout Procedures',
      id: 'lotoAudits'
    });

  const leftNav = [
    { label: 'Equipment Information', id: 'equipmentInfo' },
    { label: 'Attachments', id: 'attachments' },
    { label: 'Assignables', id: 'assignables' }
  ];

  const handleGroupChange = values => {
    const validProjectIds = activeCompany.groups
      .map(g => {
        if (values.includes(g._id)) {
          return g.projects.map(p => p._id);
        } else {
          return [];
        }
      })
      .reduce((acc, x) => acc.concat(x), []);

    const newProjectIds = activeEquipment?.projectIds?.filter(p =>
      validProjectIds.includes(p)
    );
    handleAnswer({ groupIds: values, projectIds: newProjectIds });
  };

  const childGroupDropdown = activeCompany.groups
    ?.filter(g => activeEquipment?.groupIds?.includes(g._id) && !g.isHidden)
    ?.map(g => ({ label: g.name, value: g._id }));

  const childProjectDropdown = activeCompany?.groups
    ?.filter(g => !g.isHidden)
    ?.reduce((acc, cur) => acc.concat(cur.projects), [])
    ?.filter(p => activeEquipment?.projectIds?.includes(p._id) && !p.isHidden)
    ?.map(p => ({
      value: p._id,
      label: p.name,
      groupId: p.groupId
    }));

  return (
    <HeaderAndFooter
      Header={header}
      Footer={footer}
      showFooter={editing}
      Left={<LeftNav items={editing ? leftNavEditing : leftNav} />}
    >
      {editing ? (
        <Card name="equipmentEditing" title="Equipment Information" showHeader>
          <TwoColumn>
            <Textbox
              currentValue={activeEquipment?.title}
              fieldLabel="Title"
              onChange={v => handleAnswer({ title: v })}
              placeholder="Type a clear and concise title."
              isRequired={true}
              touched={missingRequired && activeEquipment?.title?.length < 3}
              errorMessage="Title must be 3 or more characters"
              showWarning={
                activeEquipment?.title && activeEquipment?.title?.length < 3
              }
            />
            <Textbox
              currentValue={activeEquipment?.location}
              fieldLabel="Location"
              onChange={v => handleAnswer({ location: v })}
              placeholder="Type here"
              isRequired={true}
              touched={missingRequired && activeEquipment?.location?.length < 3}
              errorMessage="Location must be 3 or more characters"
              showWarning={
                activeEquipment?.location &&
                activeEquipment?.location?.length < 3
              }
            />
          </TwoColumn>
          <HierarchySelector
            onGroupChange={handleGroupChange}
            groupId={activeEquipment?.groupIds}
            onProjectChange={v =>
              handleAnswer({ projectIds: v?.length ? v : [] })
            }
            projectId={activeEquipment?.projectIds}
            projectDisabled={!editing}
            multi
            groupTouched={missingRequired && !activeEquipment?.groupIds?.length}
          />
          <TwoColumn>
            <Textbox
              currentValue={activeEquipment?.serial}
              fieldLabel="Serial Number"
              onChange={v => handleAnswer({ serial: v })}
              placeholder="Type here"
            />
            <Textbox
              currentValue={activeEquipment?.companyThatMade}
              fieldLabel="Manufacturer"
              onChange={v => handleAnswer({ companyThatMade: v })}
              placeholder="Type here"
            />
            <Textbox
              currentValue={activeEquipment?.model}
              fieldLabel="Model"
              onChange={v => handleAnswer({ model: v })}
              placeholder="Type here"
            />
            <Dropdown
              options={EQUIPMENT_STATUS_DROPDOWN}
              fieldLabel="Status"
              currentValue={activeEquipment?.status}
              onChange={v => handleAnswer({ status: v })}
            />
            <IReportDatePicker
              fieldLabel="Expiration Date"
              onChange={v => handleAnswer({ expirationDate: v })}
              currentValue={activeEquipment?.expirationDate}
              name="expirationDate"
            />
            <IReportDatePicker
              fieldLabel="Manufacture Date"
              onChange={v => handleAnswer({ manufactureDate: v })}
              currentValue={activeEquipment?.manufactureDate}
              name="manufactureDate"
            />
            <Money
              fieldLabel="Cost of Equipment"
              name="costOfEquipment"
              onChange={v => handleAnswer({ costOfEquipment: v })}
              currentValue={activeEquipment?.costOfEquipment || null}
              placeholder="0.00"
            />
            <Textbox
              fieldLabel="Quantity of Equipment"
              currentValue={activeEquipment?.equipmentQuantity}
              type="number"
              onChange={v => handleAnswer({ equipmentQuantity: v })}
              placeholder="0"
            />
          </TwoColumn>
        </Card>
      ) : (
        <div id="equipmentInfo" className={styles.infoAndQrFlexbox}>
          <Card
            title="Equipment Information"
            className={styles.equipmentInfo}
            showHeader
          >
            <div className={styles.infoCard}>
              <div className={styles.infoLabels}>
                {[
                  'Title',
                  'Location',
                  'Group/Est.',
                  'Area',
                  'Serial Number',
                  'Manufacturer',
                  'Model',
                  'Status',
                  'Expiration Date',
                  'Manufacture Date',
                  'Cost of Equipment',
                  'Quantity of Equipment'
                ].map((label, index) => (
                  <div key={index}>
                    {`${label}:`}
                    <br />
                  </div>
                ))}
              </div>
              <div className={styles.info}>
                {[
                  activeEquipment?.title,
                  activeEquipment?.location,
                  <>
                    <span data-tip data-tooltip-id="groupNames">
                      {findGroupNames(activeEquipment?.groupIds) || ''}
                    </span>
                    {activeEquipment?.groupIds?.length > 3 ? (
                      <ReactTooltip className={styles.tooltip} id="groupNames">
                        <span className={styles.userLocationBubble}>
                          {findGroupNames(activeEquipment?.groupIds) || ''}
                        </span>
                      </ReactTooltip>
                    ) : null}
                  </>,
                  <>
                    <span data-tip data-tooltip-id={`projectNames`}>
                      {findProjectNames(
                        activeEquipment?.groupIds,
                        activeEquipment?.projectIds
                      ) || ''}
                    </span>
                    {activeEquipment?.projectIds?.length > 3 ? (
                      <ReactTooltip
                        className={styles.tooltip}
                        id="projectNames"
                      >
                        <span>
                          {findProjectNames(
                            activeEquipment?.groupIds,
                            activeEquipment?.projectIds
                          ) || ''}
                        </span>
                      </ReactTooltip>
                    ) : null}
                  </>,
                  activeEquipment?.serial || '',
                  activeEquipment?.companyThatMade || '',
                  activeEquipment?.model || '',
                  EQUIPMENT_STATUS_DROPDOWN.filter(
                    v => v.value === activeEquipment.status
                  )[0]?.label || 'N/A',
                  activeEquipment?.expirationDate
                    ? moment(activeEquipment?.expirationDate).format(
                        'MM/DD/YYYY'
                      )
                    : '',
                  activeEquipment?.manufactureDate
                    ? moment(activeEquipment?.manufactureDate).format(
                        'MM/DD/YYYY'
                      )
                    : '',
                  activeEquipment?.costOfEquipment
                    ? formatCost.format(activeEquipment?.costOfEquipment)
                    : '',
                  activeEquipment?.equipmentQuantity || ''
                ].map((v, index) => (
                  <div key={index}>
                    {v}
                    <br />
                  </div>
                ))}
              </div>
            </div>
          </Card>
          <Card
            name="qrCode"
            title="QR Code"
            className={styles.qrCodeCard}
            showHeader
          >
            <img
              src={'https://' + activeEquipment.qrCodeImageUrl}
              className={styles.qrCode}
              alt={'Error Displaying QR Code'}
            />
            <Button
              color="blue"
              text="Download"
              onClick={() =>
                downloadEquipmentQrCodes({
                  ids: activeEquipment?._id
                }).then(response => window.open(response, '_blank'))
              }
            />
          </Card>
        </div>
      )}
      <AttachmentUploadCard
        name="attachments"
        addAttachments={addAttachments}
        deleteAttachment={deleteAttachments}
        isCreating={editing && !equipmentId}
        isEditing={editing && !equipmentId}
        addedAttachments={activeEquipment.attachments || []}
        attachmentsToUpload={attachmentsToUpload}
        handleAnswer={(currentAttachments, currentFiles) => {
          setAttachmentsToUpload(currentAttachments);
          setFilesToUpload(currentFiles);
          setHasUnsavedChanges(true);
        }}
        ownerType="equipment"
        formattedAttachmentsToUpload={filesToUpload}
      />
      {editing ? (
        <>
          <Card
            name="documents"
            title="Documents"
            showHeader
            rightButton={{
              text: 'Upload Document',
              testID: 'uploadDocument',
              color: 'blue',
              onClick: () =>
                history.push('/app/safetyInstructionContainer', {
                  equipmentGroupDropdown: childGroupDropdown,
                  equipmentProjectDropdown: childProjectDropdown,
                  fromEquipment: true,
                  equipment: {
                    ...activeEquipment
                  }
                }),
              disabled: !canSubmit
            }}
          >
            <Dropdown
              multi
              placeholder="Choose one or more"
              options={documents}
              disabled={!canSubmit}
              currentValue={activeEquipment?.linkedDocuments}
              onChange={v => handleAnswer({ linkedDocuments: v })}
              searchable
              selectButtons
              testID="Documents Dropdown"
              valueKey={'_id'}
            />
          </Card>
          <Card name="safetyAudits" title="Safety Audits" showHeader>
            <Dropdown
              multi
              placeholder="Choose one or more"
              options={saTemplateDropdown}
              disabled={!canSubmit}
              currentValue={activeEquipment?.linkedSafetyAuditTemplates}
              onChange={v => handleAnswer({ linkedSafetyAuditTemplates: v })}
              searchable
              selectButtons
              testID="Safety Audits Dropdown"
              valueKey={'_id'}
            />
          </Card>
          {activeCompany?.hasLockoutTagout ? (
            <Card
              name="lotoAudits"
              title="Lockout/Tagout Procedures"
              showHeader
            >
              <Dropdown
                multi
                placeholder="Choose one or more"
                options={lotoTemplateDropdown}
                disabled={!canSubmit}
                currentValue={activeEquipment?.linkedLOTOTemplates}
                onChange={v => handleAnswer({ linkedLOTOTemplates: v })}
                searchable
                selectButtons
                testID="Lockout/Tagout Dropdown"
                valueKey={'_id'}
              />
            </Card>
          ) : null}
          <Card name="trainings" title="Trainings" showHeader>
            <Dropdown
              multi
              placeholder="Choose one or more"
              options={trainingTemplateDropdown}
              disabled={!canSubmit}
              currentValue={activeEquipment?.linkedTrainingTemplates}
              onChange={v => handleAnswer({ linkedTrainingTemplates: v })}
              searchable
              selectButtons
              testID="Trainings Dropdown"
              valueKey={'_id'}
            />
          </Card>
        </>
      ) : (
        <div
          className={styles.AssignablesList}
          id="assignables"
          title="Assignables"
        >
          <TypeSelection
            className={styles.typeSelection}
            selected={listView}
            selectedArray={[
              'Safety Audits',
              activeCompany?.hasLockoutTagout
                ? 'Lockout/Tagout Procedures'
                : null,
              'Trainings',
              'Tasks',
              'Documents'
            ]}
            onClick={setListView}
          />
          {listView === 'Tasks' && (
            <EquipmentTaskList
              setActiveTask={task => dispatch(setActiveTaskRequest(task))}
              loadTask={t => dispatch(loadTask(t))}
              activeEquipment={activeEquipment}
              cardClassName={styles.equipmentAttachmentLists}
              childGroupDropdown={childGroupDropdown}
              childProjectDropdown={childProjectDropdown}
              activeGroupId={activeGroup?._id}
              activeProjectId={activeProject?._id}
              disabled={activeEquipment?.isArchived}
            />
          )}
          {listView === 'Documents' && (
            <EquipmentDocumentsList
              data={activeEquipment.linkedDocuments}
              cardClassName={styles.equipmentAttachmentLists}
              disabled={activeEquipment?.isArchived}
              goToDoc={() =>
                history.push('/app/safetyInstructionContainer', {
                  equipmentGroupDropdown: childGroupDropdown,
                  equipmentProjectDropdown: childProjectDropdown,
                  fromEquipment: true,
                  equipment: {
                    ...activeEquipment
                  }
                })
              }
              equipmentId={activeEquipment?._id}
            />
          )}
          {(listView === 'Safety Audits' ||
            listView === 'Lockout/Tagout Procedures') && (
            <EquipmentAuditList
              activeEquipment={activeEquipment}
              cardClassName={styles.equipmentAttachmentLists}
              childGroupDropdown={childGroupDropdown}
              childProjectDropdown={childProjectDropdown}
              activeGroupId={activeGroup?._id}
              activeProjectId={activeProject?._id}
              type={listView}
              setEquipment={setActiveEquipment}
              disabled={activeEquipment?.isArchived}
            />
          )}
          {listView === 'Trainings' && (
            <EquipmentTrainingList
              activeEquipment={activeEquipment}
              cardClassName={styles.equipmentAttachmentLists}
              childGroupDropdown={childGroupDropdown}
              childProjectDropdown={childProjectDropdown}
              disabled={activeEquipment?.isArchived}
            />
          )}
        </div>
      )}
      <SaveChangesModal
        isOpen={openUnsavedChangesModal}
        onRequestClose={() => setOpenUnsavedChangesModal(false)}
        submitActions={history.goBack}
        savingWhat="a page"
      />
      <DeleteItemModal
        isOpen={openDeleteModal}
        onRequestClose={() => setOpenDeleteModal(false)}
        deletingWhat="Equipment"
        submitActions={() => deleteEquipment(activeEquipment).then(handleBack)}
      />
      <MissingRequiredModal
        isOpen={missingModalOpen}
        onRequestClose={() => setMissingModalOpen(false)}
        subject="Equipment"
        isEditing={editing}
      />
    </HeaderAndFooter>
  );
}
