import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { noBubble } from '../../utils/events';
import {
  createAnalyticsDashboard,
  getAnalyticsDashboard,
  cloneAnalyticsDashboard,
  updateAnalyticsDashboard,
  deleteAnalyticsDashboard,
  setDashboardFavorite
} from '../../api/v4';
import { getMyUserId } from '../../selectors/users';
import { useEffectUpdate } from '../../utils/hooks';
import { useActiveHeirarchy } from '../../utils/useActiveHeirarchy';
import UserBadge from '../../components/UserBadge';
import { Textbox, EmployeeDropdown, Checkbox } from '../../components/inputs';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import DashboardShares from '../DashboardShares';
import DashboardCard from '../DashboardCard';
import AnalyticWarning from '../../assets/images/Analytics_Warning.png';
import DashboardReports from '../DashboardReports';
import { email } from '../../utils/formValidation';
import history from '../../history';

import styles from './analytics.module.scss';

export const MyAnalytics = () => {
  const myUserId = useSelector(getMyUserId);
  const { activeCompany } = useActiveHeirarchy();
  const [dashboards, setDashboards] = useState([]);
  // Delete, Clone, Edit, Create, Share
  const [operation, setOperation] = useState(null);
  const [editing, setEditing] = useState(null);
  const [name, setName] = useState('');
  const [sharingDirty, setSharingDirty] = useState(false);
  const [search, setSearch] = useState(undefined);
  const [emailText, setEmailText] = useState('');
  const [warningText, setWarning] = useState('');

  useEffect(() => {
    getAnalyticsDashboard().then(data => {
      setDashboards(
        data.map(d => ({
          ...d,
          createdAt: d.createdAt ? moment(d.createdAt) : -1
        }))
      );
    });
  }, []);

  useEffectUpdate(() => {
    if (!operation && sharingDirty) {
      getAnalyticsDashboard().then(data => {
        setDashboards(
          data.map(d => ({
            ...d,
            createdAt: d.createdAt ? moment(d.createdAt) : -1
          }))
        );
      });
      setSharingDirty(false);
    }
  }, [sharingDirty, operation]);

  const cloneDashboard = () => {
    cloneAnalyticsDashboard(editing).then(() => {
      getAnalyticsDashboard().then(data => {
        setDashboards(
          data.map(d => ({
            ...d,
            createdAt: d.createdAt ? moment(d.createdAt) : -1
          }))
        );
        setOperation(null);
        setEditing(null);
      });
    });
  };

  const scheduleDashboard = () => {
    updateAnalyticsDashboard(editing._id, { reports: editing.reports }).then(
      () => {
        setDashboards(current => [
          ...current.map(d => (d._id === editing._id ? editing : d))
        ]);
        setEditing(undefined);
        setOperation(null);
        setEmailText('');
      }
    );
  };

  const updateShares = () => {
    updateAnalyticsDashboard(editing._id, { shares: editing.shares }).then(
      () => {
        setDashboards(current => [
          ...current.map(d => (d._id === editing._id ? editing : d))
        ]);
        setEditing(undefined);
        setOperation(null);
      }
    );
  };

  const updateDashboard = () => {
    const _id = editing?._id;
    if (_id) {
      updateAnalyticsDashboard(_id, { name }).then(data => {
        setDashboards(current => {
          let dashboards = [...current];
          let dashboard = dashboards.find(d => d._id === _id);
          if (dashboard) dashboard.name = name;
          return dashboards;
        });
        setOperation(null);
        setEditing(null);
      });
    } else {
      const dashboard = {
        name,
        period: {
          mode: 'thisYear',
          frequency: 'monthly'
        }
      };
      createAnalyticsDashboard(dashboard).then(data => {
        setDashboards(current => [data, ...current]);
        setOperation(null);
      });
    }
  };

  const deleteDashboard = () => {
    const { _id } = editing;
    deleteAnalyticsDashboard(_id).then(data => {
      setDashboards(current => current.filter(d => d._id !== _id));
      setEditing(null);
      setOperation(null);
    });
  };

  const toggleFavorite = id => {
    let dashboard = dashboards.find(d => d._id === id);
    let isFavorite = !dashboard.isFavorite;
    setDashboardFavorite(id, isFavorite).then(data => {
      setDashboards(current => {
        let dashboards = [...current];

        let dashboard = dashboards.find(d => d._id === id);
        dashboard.isFavorite = isFavorite;

        return dashboards;
      });
    });
  };
  const addEmailToReport = emailToAdd => {
    emailToAdd = emailToAdd.trim();
    if (!emailToAdd) return;
    if (emailToAdd.split(',').length > 1) {
      emailToAdd.split(',').forEach(addEmailToReport);
      return;
    }
    const warning = editing?.reports?.find(r => r.externalEmail === emailToAdd)
      ? 'Email already receiving report'
      : email(emailToAdd);
    if (!warning?.length) {
      setEditing(current => ({
        ...current,
        reports: [
          ...(current.reports ?? []),
          {
            userId: myUserId,
            externalEmail: emailToAdd,
            type: 'pdf',
            companyId: activeCompany?._id
          }
        ]
      }));
    } else {
      setWarning(warning);
    }
  };

  const header = (
    <Header
      title="My Analytics"
      pageActionOptions={[
        {
          label: 'Create Dashboard',
          visible: true,
          color: 'greenOutline',
          onClick: () => {
            setName('');
            setOperation('New');
          }
        },
        {
          label: 'Customize Export Columns',
          visible: true,
          color: 'blueOutline',
          onClick: () => {
            history.push('/app/myAnalytics/columns');
          }
        }
      ]}
    />
  );

  let sortedDashboards = dashboards.sort((a, b) => b.createdAt - a.createdAt);
  if (search) {
    const s = search.trim().toLowerCase();
    sortedDashboards = dashboards.filter(d => d.name.toLowerCase().includes(s));
  }

  const favorites = sortedDashboards.filter(d => d.isFavorite);
  const normal = sortedDashboards.filter(d => !d.isFavorite);

  return (
    <>
      <HeaderAndFooter Header={header} className={styles.container}>
        <Textbox
          placeholder="Search"
          currentValue={search}
          onChange={setSearch}
          className={styles.search}
        />
        {[...favorites, ...normal]?.length ? (
          <>
            <div className={styles.favoritesWrapper}>
              <div className={styles.favoritesLabel}>Favorites</div>
              <div
                className={
                  favorites.length ? styles.favorites : styles.addFavorites
                }
              >
                {favorites.length ? (
                  favorites.map(f => (
                    <DashboardCard
                      dashboard={f}
                      handleShareClick={value => {
                        noBubble(value);
                        setEditing(f);
                        setOperation('Share');
                        setSharingDirty(false);
                      }}
                      handleCloneClick={value => {
                        noBubble(value);
                        setEditing(f);
                        setOperation('Clone');
                      }}
                      handleEditClick={value => {
                        noBubble(value);
                        setEditing(f);
                        setName(f.name);
                        setOperation('Edit');
                      }}
                      handleToggleFavorite={value => {
                        noBubble(value);
                        toggleFavorite(f._id);
                      }}
                      handleDeleteClick={value => {
                        noBubble(value);
                        setEditing(f);
                        setOperation('Delete');
                      }}
                      handleReportClick={value => {
                        noBubble(value);
                        let updatedReports = f?.reports?.map(r => ({
                          ...r,
                          type: r?.type ?? 'csv'
                        }));
                        setEditing({ ...f, reports: updatedReports });
                        setOperation('Schedule');
                      }}
                      bottomStyles={styles.bottom}
                    />
                  ))
                ) : (
                  <>Mark dashboards as favorite to have them appear here.</>
                )}
              </div>
            </div>
            <div className={styles.other}>
              {normal.map(n => (
                <DashboardCard
                  dashboard={n}
                  handleShareClick={value => {
                    noBubble(value);
                    setEditing(n);
                    setOperation('Share');
                    setSharingDirty(false);
                  }}
                  handleCloneClick={value => {
                    noBubble(value);
                    setEditing(n);
                    setOperation('Clone');
                  }}
                  handleEditClick={value => {
                    noBubble(value);
                    setEditing(n);
                    setName(n.name);
                    setOperation('Edit');
                  }}
                  handleToggleFavorite={value => {
                    noBubble(value);
                    toggleFavorite(n._id);
                  }}
                  handleDeleteClick={value => {
                    noBubble(value);
                    setEditing(n);
                    setOperation('Delete');
                  }}
                  handleReportClick={value => {
                    noBubble(value);
                    let updatedReports = n?.reports?.map(r => ({
                      ...r,
                      type: r?.type ?? 'csv'
                    }));
                    setEditing({ ...n, reports: updatedReports });
                    setOperation('Schedule');
                  }}
                  bottomStyles={styles.bottom}
                />
              ))}
            </div>
          </>
        ) : (
          <div className={styles.noCardMessage}>
            <img src={AnalyticWarning} alt="analyticsWarning" />
            <div className={styles.createMessage}>
              Create a Dashboard and access it here
            </div>
          </div>
        )}
      </HeaderAndFooter>
      <Modal
        title="Confirm Delete"
        titleClassName="redHeader"
        isOpen={operation === 'Delete'}
        submitButtonColor="red"
        submitButtonText="Delete"
        onRequestClose={() => {
          setEditing(null);
          setOperation(null);
        }}
        submitActions={deleteDashboard}
      >
        <h2>Are you sure you want to delete {editing?.name}?</h2>
        {editing?.sharedBy ? (
          <>
            This dashboard was shared with you by{' '}
            <UserBadge userId={editing?.sharedBy} nameOnly />.
          </>
        ) : (
          <></>
        )}
      </Modal>
      <Modal
        title="Analytics Report"
        titleClassName="blueHeader"
        isOpen={operation === 'Schedule'}
        submitButtonColor="blue"
        submitButtonText="Update"
        onRequestClose={() => {
          setEditing(null);
          setEmailText('');
          setOperation(null);
        }}
        wide
        submitActions={scheduleDashboard}
        disableSubmit={editing?.reports?.some(
          r => !r?.type?.length || !r.sendOn || !r.frequency
        )}
      >
        <div className={styles.topMessage}>
          Add analytics reports for the {editing?.name} Dashboard
        </div>
        <EmployeeDropdown
          multi
          fieldLabel="Send to user"
          searchable
          permissions={[400, 500, 900]}
          exclude={[...(editing?.reports ?? []).map(s => s.userId)]}
          placeholder="Select a user to schedule a report for"
          selectButtons
          onChange={userIds => {
            setEditing(current => ({
              ...current,
              reports: [
                ...(current.reports ?? []),
                ...userIds.map(userId => {
                  return {
                    userId,
                    createdBy: myUserId,
                    type: ['pdf'],
                    companyId: activeCompany?._id
                  };
                })
              ]
            }));
          }}
        />
        <Textbox
          fieldLabel="Send to external email"
          name={`emailText`}
          currentValue={emailText}
          onChange={t => {
            setEmailText(t);
            setWarning('');
          }}
          placeholder="Email or comma separated list, Enter to submit"
          enter={addEmailToReport}
          error={warningText}
          touched={warningText}
        />
        <DashboardReports
          title="Reports"
          expired={false}
          onChange={value => {
            setEditing({ ...editing, reports: value });
            setSharingDirty(true);
          }}
          setSharingDirty={value => setSharingDirty(value)}
          reports={editing?.reports}
        />
      </Modal>
      <Modal
        title={`${operation} Dashboard`}
        titleClassName={operation === 'Create' ? 'greenHeader' : 'blueHeader'}
        isOpen={['Edit', 'New', 'Clone'].includes(operation)}
        submitButtonColor={operation === 'New' ? 'green' : 'blue'}
        submitButtonText={operation === 'Edit' ? 'Save' : operation}
        onRequestClose={() => {
          setEditing(null);
          setOperation(null);
        }}
        submitActions={operation === 'Clone' ? cloneDashboard : updateDashboard}
        disableSubmit={
          operation === 'Clone'
            ? !editing?.cloneToMyProfile && !editing?.clones?.length
            : !name?.trim()
        }
      >
        {operation === 'Clone' ? (
          <div>
            <Checkbox
              fieldLabel="Clone to my own profile"
              onChange={value =>
                setEditing(current => ({ ...current, cloneToMyProfile: value }))
              }
              currentValue={editing?.cloneToMyProfile}
            />
            <EmployeeDropdown
              multi
              currentValue={editing?.clones}
              fieldLabel="Clone to Profile"
              searchable
              exclude={[myUserId]}
              permissions={[400, 500, 900]}
              placeholder="Choose one or more user(s)"
              selectButtons
              onChange={userId => {
                setEditing(current => ({
                  ...current,
                  clones: userId
                }));
              }}
            />
          </div>
        ) : (
          <Textbox
            fieldLabel="Dashboard Title"
            currentValue={name}
            onChange={setName}
            enter={updateDashboard}
            placeholder={editing?.name}
          />
        )}
        <div className={styles.modalMessage}>
          {operation === 'New'
            ? 'This will add a new dashboard to your Analytics Page.'
            : operation === 'Clone'
              ? 'This will duplicate the dashboard to the selected user profile(s) and will be updated separately.'
              : ''}
        </div>
      </Modal>
      {operation === 'Share' ? (
        <Modal
          title={`Share ${editing?.name}`}
          isOpen={operation === 'Share'}
          titleClassName="blueHeader"
          submitButtonColor="blue"
          submitButtonText="Update"
          onRequestClose={() => {
            setEditing(null);
            setOperation(null);
          }}
          submitActions={updateShares}
          wide
          disableSubmit={!sharingDirty}
        >
          {editing?.shares?.length && sharingDirty ? (
            <div
              className={styles.sharingWith}
            >{`Sharing with ${editing?.shares?.length} user(s)`}</div>
          ) : null}
          <EmployeeDropdown
            multi
            fieldLabel="Share With"
            searchable
            permissions={[400, 500, 900]}
            exclude={[...(editing?.shares ?? []).map(s => s.userId), myUserId]}
            placeholder="Select a user to share with"
            selectButtons
            onChange={userIds => {
              setEditing(current => ({
                ...current,
                shares: [
                  ...(current.shares ?? []),
                  ...userIds.map(userId => {
                    return { userId, readOnly: true };
                  })
                ]
              }));
              setSharingDirty(true);
            }}
          />
          <DashboardShares
            title="Active Permissions"
            expired={false}
            onChange={value => {
              setEditing({ ...editing, shares: value });
              setSharingDirty(true);
            }}
            setSharingDirty={value => setSharingDirty(value)}
            shares={editing?.shares}
          />
          <DashboardShares
            title="Expired Permissions"
            expired
            onChange={value => {
              setEditing({ ...editing, shares: value });
              setSharingDirty(true);
            }}
            setSharingDirty={value => setSharingDirty(value)}
            shares={editing?.shares}
          />
        </Modal>
      ) : null}
    </>
  );
};

export default MyAnalytics;
