import React from 'react';
import moment from 'moment';

import history from '../../../history';
import AuthorizeRejectButtons from '../../inputs/AuthorizeRejectButtons';
import List from '../../List';

export const QuizTable = ({
  training,
  isApprover,
  updateAnswer,
  childTrainings,
  name,
  needsApproval,
  dueDate,
  fromApproveTraining,
  updateModal,
  disabled
}) => {
  const listQuizzes = childTrainings.map(c => ({
    ...c.quiz,
    quizScores: c.quizScores,
    score: c.quizScores ? c.quizScores?.[c.quizScores?.length - 1] : null,
    assignedTo: c.assignedTo,
    approveReject: c.approveReject,
    attemptNumber:
      !c.quiz.passedQuiz || c.quiz.attemptNumber > 1
        ? c.quiz?.attemptNumber - 1
        : c.quiz.isCompleted
          ? c.quiz.attemptNumber
          : 'Has Not Attempted'
  }));
  const columns = [
    { key: 'assignedTo', datatype: 'user' },
    {
      key: 'passedQuiz',
      label: 'Pass/Fail',
      accessor: r =>
        !r?.isCompleted && !r?.completionDate
          ? 'In Progress'
          : Number(r?.score) >= r?.percentToPass || r?.passedQuiz
            ? 'Passed'
            : 'Failed'
    },
    {
      key: 'attemptNumber',
      label: 'Number of Attempts',
      accessor: v => (v.passedQuiz ? v.quizScores?.length : v.attemptNumber)
    },
    {
      key: 'quizScore',
      label: 'Quiz Scores',
      accessor: r => r.quizScores?.map(s => `${parseInt(s)}%`).join(', ')
    },
    { key: 'completionDate', datatype: 'date' }
  ];

  if (needsApproval)
    columns.push({
      key: 'approveReject',
      label: 'Authorize',
      noClick: true,
      Cell: ({ value, row }) => (
        <AuthorizeRejectButtons
          answer={row.original.approveReject}
          updateAnswer={approveReject =>
            updateAnswer({
              approveReject,
              assignee: [row.original.assignedTo]
            })
          }
          afterApproving={
            !(fromApproveTraining && needsApproval && isApprover) || disabled
          }
          disabled={
            !isApprover ||
            !row.original.completionDate ||
            !fromApproveTraining ||
            disabled
          }
        />
      )
    });

  const handleApproveRejectTrainings = (trainingIds, approveReject) => {
    updateAnswer({
      approveReject,
      assignee: trainingIds
    });
  };

  const actions = [];
  const current = moment();
  const afterDueDate = moment(dueDate).isBefore(current);
  // should this be here?
  // if (
  //   afterDueDate &&
  //   !fromApproveTraining &&
  //   accessLevel > 400 &&
  //   masterTraining.masterTemplateId === ''
  // ) {
  //   actions.push({
  //     label: 'Reassign',
  //     onClick: () => {
  //       history.push('/app/training/create');
  //     }
  //   });
  // }

  if (fromApproveTraining && needsApproval && isApprover) {
    actions.unshift(
      {
        label: 'Approve',
        onClick: row => handleApproveRejectTrainings(row, 'approve')
      },
      {
        label: 'Reject',
        onClick: row => handleApproveRejectTrainings(row, 'reject')
      }
    );

    if (afterDueDate) {
      actions.push(
        {
          label: 'Reject & Reassign',
          onClick: row => {
            handleApproveRejectTrainings(row, 'reject');
            updateModal(true);
          }
        },
        {
          label: 'Reassign',
          onClick: () => {
            updateModal(true);
          }
        }
      );
    }
  }

  return (
    <List
      saveKey="quizList"
      data={listQuizzes}
      settings={columns}
      dataIsHash
      rowClick={row => {
        localStorage.setItem(
          'trainingTemplate',
          JSON.stringify({ training: training, children: childTrainings })
        );
        history.push({
          pathname: '/app/training/quiz',
          state: {
            passedQuiz: {
              ...row
            },
            isPreview: true,
            showAnswers:
              (fromApproveTraining && !disabled) || training.completionDate
          }
        });
      }}
      title="Assignee(s) Quiz List"
      name={name}
      getRowId={r => r.assignedTo}
      actions={actions}
      wide={false}
    />
  );
};

export default QuizTable;
