import React from 'react';
import './svg.css';

const fuelReports = props => (
  <svg
    width={props.width ?? 30}
    height={props.height ?? 25}
    viewBox="0 0 80 80"
    x={props.x || '0px'}
    y={props.y || '0px'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M72.0348 25.502L75 23.3898C70.537 12.6501 62.0283 4.58837 51.647 2V7.26676C51.647 7.26676 52.5849 7.70283 53.0407 7.94461C59.8738 11.5575 64.6105 18.4683 65.3677 26.1736L67.8276 58.3636L67.8292 58.5052C67.8292 61.1279 65.6945 63.2635 63.0701 63.2635C60.4458 63.2635 58.3094 61.1268 58.3094 58.5052V40.0991C58.3094 34.2854 53.9414 29.4802 48.3144 28.7744V9.16024C48.3144 5.49554 45.3151 2.4962 41.6504 2.4962H14.9949C11.3302 2.4962 8.33091 5.49554 8.33091 9.16024V71.0372H5V77.7012H51.6462V71.0372H48.3133V35.583C50.2381 36.1916 51.6462 37.9747 51.6462 40.0986V58.5048C51.6462 64.8027 56.7697 69.9281 63.0694 69.9281C69.3674 69.9281 74.4927 64.803 74.4927 58.5048C74.4927 58.3596 74.4911 58.2172 74.4859 58.0739L72.0348 25.502ZM14.9949 9.16115H41.6497V28.5572H14.9949V9.16115Z" />
  </svg>
);

export default fuelReports;
