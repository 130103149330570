import React from 'react';
import './svg.css';

const incidents = props => (
  <svg
    width={props.width ?? 30}
    height={props.height ?? 25}
    viewBox="0 0 80 80"
    x={props.x || '0px'}
    y={props.y || '0px'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1224_131770)">
      <path d="M39.9985 80C17.9061 80 0 62.0923 0 40.0015C0 29.2259 4.28254 19.4684 11.2081 12.2778C11.7478 11.3765 12.7192 10.7697 13.8424 10.7697C15.5373 10.7697 16.9201 12.1466 16.9201 13.8472C16.9201 14.8098 16.4708 15.6558 15.7765 16.2217L15.8553 16.3005C9.85742 22.4058 6.1525 30.7661 6.1525 40.0015C6.1525 58.691 21.3018 73.8392 39.9956 73.8392C58.6894 73.8392 73.8417 58.691 73.8417 40.0015C73.8417 21.3119 58.6865 6.15789 39.9985 6.15789C38.2978 6.15497 36.9208 4.77812 36.9208 3.07748C36.9208 1.37685 38.2978 0 39.9985 0C40.0102 0 40.0219 0.00291705 40.0365 0.00291705C62.1114 0.0233364 80 17.9194 80 39.9985C80 62.0777 62.0909 79.9971 39.9985 79.9971V80ZM39.9985 30.769C38.2978 30.769 36.9208 29.3922 36.9208 27.6915C36.9208 25.9909 38.2978 24.614 39.9985 24.614C39.9985 24.614 40.0073 24.614 40.0102 24.614C48.5023 24.6199 55.3871 31.5012 55.3871 39.9985C55.3871 48.4959 48.514 55.3626 40.0306 55.3801C40.0219 55.3801 40.0131 55.383 39.9985 55.383C38.2978 55.383 36.9208 54.0062 36.9208 52.3056C36.9208 50.6049 38.2978 49.2281 39.9985 49.2281C45.0979 49.2281 49.2287 45.0946 49.2287 39.9985C49.2287 34.8995 45.0979 30.769 39.9985 30.7661V30.769ZM61.5367 40.0015C61.5367 28.1057 51.8922 18.4649 39.9927 18.4649C38.2919 18.4649 36.915 17.0851 36.915 15.3845C36.915 13.6839 38.2919 12.307 39.9927 12.307C40.0015 12.307 40.0073 12.307 40.0131 12.307C55.2996 12.3187 67.6833 24.7103 67.6833 39.9985C67.6833 55.2868 55.2879 67.6871 39.9927 67.6871C24.6975 67.6871 12.3138 55.3043 12.3021 40.0248C12.3021 40.016 12.3021 40.0073 12.3021 39.9985C12.3021 38.2979 13.679 36.9181 15.3798 36.9181C17.0806 36.9181 18.4575 38.2979 18.4575 39.9985C18.4575 51.8913 28.102 61.5351 39.9956 61.5351C51.8893 61.5351 61.5367 51.8913 61.5367 39.9985V40.0015Z" />
    </g>
    <defs>
      <clipPath id="clip0_1224_131770">
        <rect width="80" height="80" />
      </clipPath>
    </defs>
  </svg>
);

export default incidents;
